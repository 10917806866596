import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterDate,
} from "../../component/common";
import {
  momentNumberDateToDisplayDate,
  momentGeneral,
  momentNumberDateToDisplayFullDate,
  isRoleSuperAdmin,
  confirmNotif,
} from "../../helper";
import { fetchGetPatientInvoiceList, fetchPostPatientInvoiceDelete } from "../../store/patientInvoice";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = [
  {
    url: `/patient-invoice`,
    name: "Kwitansi",
  },
];
const backURL = `/`;
const detailURL = (patientInvoiceId, locationSearch) =>
  `/patient-invoice/detail/${patientInvoiceId}${locationSearch}`;

export const PatientInvoiceList = () => {
  const dispatch = useDispatch();

  const $patientInvoiceList = useSelector((state) => state.patientInvoiceList);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchGetPatientInvoiceList({ ...queryParams }));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan menghapus data Invoice ${data.patientFullName} ?`,
      onOK: () => {
        dispatch(
          fetchPostPatientInvoiceDelete({
            patientInvoiceId: data._id,
            next: () => {
              dispatch(fetchGetPatientInvoiceList({ ...queryParams }));
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {$patientInvoiceList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Kwitansi"}
        backURL={backURL}
        onRefresh={() => {
          dispatch(fetchGetPatientInvoiceList({ ...queryParams }));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetPatientInvoiceList({
                    ...newQueryParams,
                  })
                );
              }}
              SearchChildren={() => {
                return (
                  <div className="m-l-16">
                    {momentNumberDateToDisplayFullDate(queryParams.invoiceDate)}
                  </div>
                );
              }}
            />
            <FilterDate
              filterKey={`invoiceDate`}
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetPatientInvoiceList({
                    ...newQueryParams,
                  })
                );
              }}
            />
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>Tgl Invoice/Kunjungan</th>
                  <th>No Invoice</th>
                  <th>Pasien Baru</th>
                  <th>No RM</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Poliklinik</th>
                  <th>Dokter</th>
                  <th>Asisten</th>

                  <th>Dibuat</th>
                  {isRoleSuperAdmin() && <td>Action</td>}
                </tr>
              </thead>
              <tbody>
                {$patientInvoiceList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={detailURL(data._id, locationSearch)}>
                          {momentNumberDateToDisplayDate(data.invoiceDate)}/
                          {momentNumberDateToDisplayDate(data.visitDate)}
                        </Link>
                      </td>
                      <td>{data.invoiceNo}</td>
                      <td>
                        {data.isNewPatient ? (
                          <i className="fa fa-check-square"></i>
                        ) : (
                          <i className="fa fa-square-o"></i>
                        )}
                      </td>
                      <td>{data.patientNoRM}</td>
                      <td>
                        {data.patientGender === "f" ? (
                          <i className="fa fa-female"></i>
                        ) : (
                          <i className="fa fa-male"></i>
                        )}
                        {` `}
                        {`${data.patientFullName}`}
                      </td>
                      <td>{data.doctorDepartmentName}</td>
                      <td>{data.mobile}</td>
                      <td>{data.doctorFullName}</td>
                      <td>
                        <div>{data.nurseFullName}</div>
                        {data.nurseFullName2 && (
                          <div>{data.nurseFullName2}</div>
                        )}
                        {data.nurseFullName3 && (
                          <div>{data.nurseFullName3}</div>
                        )}
                      </td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      {isRoleSuperAdmin() && (
                        <td width="1" className="center-align">
                          <span
                            onClick={(e) => confirmArchive({ e, data })}
                            className="red-text cursor-pointer font-16"
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetPatientInvoiceList({
                    ...newQueryParams,
                  })
                );
              }}
              totalItem={$patientInvoiceList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
