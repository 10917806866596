import { createSlice } from '@reduxjs/toolkit';
import { restPostAllergicCreate as restAdminPostAllergicCreate } from '../../apiAdmin/allergic';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    name: '',
  },
};

const allergicCreateSlice = createSlice({
  name: 'allergicCreate',
  initialState: initialState,
  reducers: {
    allergicCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    allergicCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
      };
    },
    postAllergicCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postAllergicCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postAllergicCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  allergicCreateSetForm,
  allergicCreateInit,
  postAllergicCreateStart,
  postAllergicCreateSuccess,
  postAllergicCreateError,
} = allergicCreateSlice.actions;

export default allergicCreateSlice.reducer;

export const fetchAdminPostAllergicCreate = ({ push, name = '' }) => async (
  dispatch,
  getState
) => {
  name = name.trim();

  if (name.length < 3) {
    return dispatch(postAllergicCreateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().allergicCreate;
  if (isLoading) return;
  try {
    dispatch(postAllergicCreateStart());
    await restAdminPostAllergicCreate({
      name,
    });
    dispatch(postAllergicCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/admin/master-allergic?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postAllergicCreateError(JSON.parse(err).message));
    }
    dispatch(postAllergicCreateError(err));
  }
};
