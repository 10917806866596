import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export const Page404 = () => {
  return (
    <Fragment>
      <div className='full-center-container'>
        <h1 className='m-b-0'>404</h1>
        <h4 className='m-t-0 m-b-0'>Tidak Ditemukan</h4>
        <p>Maaf Halaman yang anda cari tidak ditemukan.</p>
        <Link to='/'>
          <button className='btn'>Halaman Utama</button>
        </Link>
      </div>
    </Fragment>
  );
};
