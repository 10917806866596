import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import { momentGeneral, formatCurrency } from '../../helper';
import { fetchAdminGetAdminFeeList } from '../../store/adminFee';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-admin-fee`,
    name: 'Master Data Biaya Admin',
  },
];

export const AdminFeeList = () => {
  const dispatch = useDispatch();
  const $adminFeeList = useSelector((state) => state.adminFeeList);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetAdminFeeList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$adminFeeList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Biaya Admin'}
        backURL={'/admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetAdminFeeList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetAdminFeeList(newQueryParams));
              }}
              sortOptions={[
                {
                  key: 'createdAt',
                  name: 'Dibuat',
                },
                {
                  key: 'updatedAt',
                  name: 'Diubah',
                },
                {
                  key: 'price',
                  name: 'Tarif',
                },
                {
                  key: 'name',
                  name: 'Nama',
                },
              ]}
              SearchChildren={() => {
                return <span />;
                // return (
                //   <Link
                //     to={`/admin/master-admin-fee/create`}
                //     className='btn-small teal white-text'
                //   >
                //     <i className='fa fa-plus' />
                //   </Link>
                // );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Tarif</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$adminFeeList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={`/admin/master-admin-fee/update/${data._id}`}>
                          {data.name}
                        </Link>
                      </td>
                      <td>{formatCurrency(data.price)}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetAdminFeeList(newQueryParams));
              }}
              totalItem={$adminFeeList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
