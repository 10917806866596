import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import {
  userDetailSetForm,
  userDetailInit,
  fetchRootGetUserDetail,
  fetchRootPostUserUpdate,
} from '../../store/user';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-user`,
    name: 'Master User',
  },
  {
    url: `/root/master-user/update`,
    name: 'Update',
  },
];

export const UserUpdate = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const $userDetail = useSelector((state) => state.userDetail);
  const { data, form } = $userDetail;
  const { userId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(userDetailInit());
      if (userId) {
        dispatch(fetchRootGetUserDetail({ userId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    if (data._id === window.myUser._id) {
      return;
    }
    dispatch(
      fetchRootPostUserUpdate({
        push,
        userId: data._id,
        isVerified: form.isVerified,
        name: form.name,
        password: form.password,
      })
    );
  };

  return (
    <Fragment>
      {$userDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Master User - Update'}
        backURL={'/root/master-user'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    defaultValue={data._id}
                    placeholder="ID"
                    id="_id"
                    name="_id"
                    type="text"
                    className="validate"
                    disabled
                  />
                  <label className="active" htmlFor="_id">
                    ID
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <div className="p-t-4">
                    {data.isArchived ? (
                      <i className="fa fa-check-square fa-2x"></i>
                    ) : (
                      <i className="fa fa-square-o fa-2x"></i>
                    )}
                  </div>
                  <label className="active" htmlFor="_id">
                    isArchived ?
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <div className="cursor-pointer blue-text p-t-4">
                    {form.isVerified ? (
                      <i
                        onClick={() =>
                          dispatch(
                            userDetailSetForm({
                              key: 'isVerified',
                              value: false,
                            })
                          )
                        }
                        className="fa fa-check-square fa-2x"
                      ></i>
                    ) : (
                      <i
                        onClick={() =>
                          dispatch(
                            userDetailSetForm({
                              key: 'isVerified',
                              value: true,
                            })
                          )
                        }
                        className="fa fa-square-o fa-2x"
                      ></i>
                    )}
                  </div>
                  <label className="active" htmlFor="_id">
                    Terverifikasi ?
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        userDetailSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    defaultValue={data.email}
                    placeholder="Nama"
                    id="email"
                    name="email"
                    type="text"
                    className="validate"
                    disabled
                  />
                  <label className="active" htmlFor="email">
                    Email
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.password}
                    onChange={(e) =>
                      dispatch(
                        userDetailSetForm({
                          key: 'password',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kosongkan jika tidak mau diganti"
                    id="password"
                    name="password"
                    type="password"
                    className="validate"
                  />
                  <label className="active" htmlFor="password">
                    Ganti Password
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$userDetail.error}
                  </div>
                </div>
                <div className="col s12">
                  <button
                    type="submit"
                    className="btn teal m-b-20 right"
                    disabled={data._id === window.myUser._id}
                  >
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/root/master-user`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
