import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { PatientInvoiceList } from './PatientInvoiceList';
import { PatientInvoiceDetail } from './PatientInvoiceDetail';

export const PatientInvoiceRoute = () => {
  const pathname = '/patient-invoice';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PatientInvoiceList} />
      <Route
        exact
        path={`${pathname}/detail/:patientInvoiceId`}
        component={PatientInvoiceDetail}
      />

      <Route component={Page404} />
    </Switch>
  );
};
