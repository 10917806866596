import { createSlice } from '@reduxjs/toolkit';
import { restPostUserCreate as restRootPostUserCreate } from '../../apiRoot/user';
import { restPostUserCreate as restAdminPostUserCreate } from '../../apiAdmin/user';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    isVerified: false,
    name: '',
    email: '',
    password: '',
  },
};

const userCreateSlice = createSlice({
  name: 'userCreate',
  initialState: initialState,
  reducers: {
    userCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    userCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        isVerified: false,
        name: '',
        email: '',
        password: '',
      };
    },
    postUserCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  userCreateSetForm,
  userCreateInit,
  postUserCreateStart,
  postUserCreateSuccess,
  postUserCreateError,
} = userCreateSlice.actions;

export default userCreateSlice.reducer;

export const fetchAdminPostUserCreate = ({
  push,
  isVerified,
  name = '',
  email = '',
  password = '',
}) => async (dispatch, getState) => {
  name = name.trim();
  email = email.trim();
  password = password.trim();

  if (name.length < 3) {
    return dispatch(postUserCreateError('Nama minimal 3 karakter'));
  }
  if (email.length < 3) {
    return dispatch(postUserCreateError('Email minimal 6 karakter'));
  }
  if (password.length < 3) {
    return dispatch(postUserCreateError('Password minimal 6 karakter'));
  }

  const { isLoading } = getState().userCreate;
  if (isLoading) return;
  try {
    dispatch(postUserCreateStart());
    await restAdminPostUserCreate({
      isVerified,
      name,
      email,
      password,
    });
    dispatch(postUserCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/admin/master-user?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postUserCreateError(JSON.parse(err).message));
    }
    dispatch(postUserCreateError(err));
  }
};

export const fetchRootPostUserCreate = ({
  push,
  isVerified,
  name = '',
  email = '',
  password = '',
}) => async (dispatch, getState) => {
  name = name.trim();
  email = email.trim();
  password = password.trim();

  if (name.length < 3) {
    return dispatch(postUserCreateError('Nama minimal 3 karakter'));
  }
  if (email.length < 3) {
    return dispatch(postUserCreateError('Email minimal 6 karakter'));
  }
  if (password.length < 3) {
    return dispatch(postUserCreateError('Password minimal 6 karakter'));
  }

  const { isLoading } = getState().userCreate;
  if (isLoading) return;
  try {
    dispatch(postUserCreateStart());
    await restRootPostUserCreate({
      isVerified,
      name,
      email,
      password,
    });
    dispatch(postUserCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/root/master-user?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postUserCreateError(JSON.parse(err).message));
    }
    dispatch(postUserCreateError(err));
  }
};
