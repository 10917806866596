import fetchApi from '../api/fetchApi';
import { BASE_API_ROOT } from '../config';

// GET
export function restGetConfigList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ROOT}/config/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetConfigDetail({ configId }) {
  const path = `${BASE_API_ROOT}/config/detail/${configId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostConfigCreate({ _id, name, selected }) {
  const path = `${BASE_API_ROOT}/config/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      _id,
      name,
      selected,
    },
  });
}

export function restPostConfigUpdate({ configId, name, selected }) {
  const path = `${BASE_API_ROOT}/config/update/${configId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
      selected,
    },
  });
}
