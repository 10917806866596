import { createSlice } from '@reduxjs/toolkit';

import { restPostAuthResetVerify } from '../../api/auth';
import { isValidJSON, setTimeoutAsync, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
};

const resetFormSlice = createSlice({
  name: 'resetForm',
  initialState: initialState,
  reducers: {
    postResetRequestInit(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = false;
    },
    postResetRequestStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
    },
    postResetRequestSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = true;
    },
    postResetRequestError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  postResetRequestInit,
  postResetRequestStart,
  postResetRequestSuccess,
  postResetRequestError,
} = resetFormSlice.actions;

export default resetFormSlice.reducer;

export const fetchPostAuthResetVerify = ({
  push,
  locationSearch,
  password,
  passwordConfirmation,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().resetForm;

  password = password.trim();
  passwordConfirmation = passwordConfirmation.trim();

  if (password.length < 6) {
    return dispatch(postResetRequestError('password minimal 6 karakter'));
  }
  if (password !== passwordConfirmation) {
    return dispatch(
      postResetRequestError('password tidak sama dengan password konfirmasi')
    );
  }

  if (isLoading) return;

  try {
    dispatch(postResetRequestStart());
    await restPostAuthResetVerify({
      locationSearch,
      password,
      passwordConfirmation,
    });
    dispatch(postResetRequestSuccess());
    successNotif(`Ganti Password Berhasil, Silahkan Kembali Ke Login`);
    await setTimeoutAsync(5000);
    if (push) push('/');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postResetRequestError(JSON.parse(err).message));
    }
    dispatch(postResetRequestError(err));
  }
};
