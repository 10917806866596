import { createSlice } from '@reduxjs/toolkit';
import { restGetDiagnosisList as restAdminGetDiagnosisList } from '../../apiAdmin/diagnosis';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const diagnosisListSlice = createSlice({
  name: 'diagnosisList',
  initialState: initialState,
  reducers: {
    getDiagnosisListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getDiagnosisListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getDiagnosisListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getDiagnosisListStart,
  getDiagnosisListSuccess,
  getDiagnosisListError,
} = diagnosisListSlice.actions;

export default diagnosisListSlice.reducer;

export const fetchAdminGetDiagnosisList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().diagnosisList;
  if (isLoading) return;
  try {
    dispatch(getDiagnosisListStart());
    const { listData, totalItem } = await restAdminGetDiagnosisList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getDiagnosisListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getDiagnosisListError(JSON.parse(err).message));
    }
    dispatch(getDiagnosisListError(err));
  }
};
