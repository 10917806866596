import fetchApi from './fetchApi';

// GET

// POST
export function restPostAuthLogin({ email, password }) {
  const path = `/auth/login`;
  return fetchApi(path, {
    method: 'POST',
    body: { email, password },
  });
}

export function restPostAuthLogout() {
  const path = `/auth/logout`;
  return fetchApi(path, {
    method: 'POST',
    body: {},
  });
}

export function restPostAuthRegister({
  email,
  name,
  password,
  passwordConfirmation,
}) {
  const path = `/auth/register`;
  return fetchApi(path, {
    method: 'POST',
    body: { email, name, password, passwordConfirmation },
  });
}

export function restPostEmailVerify({ locationSearch }) {
  const path = `/auth/email-verify${locationSearch}`;
  return fetchApi(path, {
    method: 'POST',
  });
}

export function restPostAuthEmailResend({ email }) {
  const path = `/auth/email-resend`;
  return fetchApi(path, {
    method: 'POST',
    body: { email },
  });
}

export function restPostAuthResetRequest({ email }) {
  const path = `/auth/reset-request`;
  return fetchApi(path, {
    method: 'POST',
    body: { email },
  });
}

export function restPostAuthResetVerify({
  locationSearch = '',
  password,
  passwordConfirmation,
}) {
  const path = `/auth/reset-verify${locationSearch}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      password,
      passwordConfirmation,
    },
  });
}
