import patientDoctorActionList from './patientDoctorActionList';
import patientDoctorActionCreate from './patientDoctorActionCreate';
import patientDoctorActionDetail from './patientDoctorActionDetail';
import patientUpdate from './patientUpdate';

export * from './patientDoctorActionList';
export * from './patientDoctorActionCreate';
export * from './patientDoctorActionDetail';
export * from './patientUpdate';

const patientDoctorActionReducers = {
  patientDoctorActionList,
  patientDoctorActionCreate,
  patientDoctorActionDetail,
  patientUpdate,
};

export default patientDoctorActionReducers;
