import React, { Fragment } from 'react';
import styled from 'styled-components';

import { HeaderNav } from '../../component/common';
import { Profile, HomeMenu } from './component';

const Div = styled.div``;

export const Home = () => {
  return (
    <Fragment>
      <HeaderNav headerSize="m" headerName={'E-Hospital'} backURL={''} isHome />
      <Div className="root-container section no-pad-bot">
        <div className="container container-home">
          <Profile />
          <HomeMenu />
        </div>
      </Div>
    </Fragment>
  );
};
