export const isUserRoleExists = (user) => {
  if (user && user.roles && Object.keys(user.roles).length > 0) {
    return true;
  }

  return false;
};

export const isUserRoleRoot = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  return false;
};

export const isUserRoleSuperAdmin = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  return false;
};

export const isUserRoleAdmin = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['admin']) {
    return true;
  }
  return false;
};

export const isUserRoleDoctor = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['doctor']) {
    return true;
  }
  return false;
};

export const isUserRoleNurse = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['nurse']) {
    return true;
  }

  return false;
};

export const isUserRoleStudent = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['student']) {
    return true;
  }
  return false;
};

export const isUserRoleOperator = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['admin']) {
    return true;
  }
  if (user && user.roles && user.roles['operator']) {
    return true;
  }
  return false;
};

export const isUserRoleReadOnly = (user) => {
  if (user && user.roles && user.roles['read-only']) {
    return true;
  }
  return false;
};
export const isRoleStudentReadOnly = (user) => {
  if (user && user.roles && user.roles['student']) {
    return true;
  }
  if (user && user.roles && user.roles['read-only']) {
    return true;
  }
  return false;
};

export const isUserRoleNurseOperator = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['admin']) {
    return true;
  }
  if (user && user.roles && user.roles['nurse']) {
    return true;
  }
  if (user && user.roles && user.roles['operator']) {
    return true;
  }
  return false;
};

export const isUserRoleDoctorNurseStudent = (user) => {
  if (user && user.roles && user.roles['root']) {
    return true;
  }
  if (user && user.roles && user.roles['super-admin']) {
    return true;
  }
  if (user && user.roles && user.roles['doctor']) {
    return true;
  }
  if (user && user.roles && user.roles['nurse']) {
    return true;
  }
  if (user && user.roles && user.roles['student']) {
    return true;
  }
  return false;
};
