import { createSlice } from "@reduxjs/toolkit";
import {
  restGetSubstanceDetail as restAdminGetSubstanceDetail,
  restPostSubstanceUpdate as restAdminPostSubstanceUpdate,
  restPostSubstanceArchive as restAdminPostSubstanceArchive,
} from "../../apiAdmin/substance";
import {
  isValidJSON,
  successNotif,
  warningNotif,
  errorNotif,
} from "../../helper";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    name: "",
    price: 0,
    unit: "",
  },
};

const substanceDetailSlice = createSlice({
  name: "substanceDetail",
  initialState: initialState,
  reducers: {
    substanceDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    substanceDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: "",
        price: 0,
        unit: "",
      };
    },
    getSubstanceDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSubstanceDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: data.name,
        price: data.price,
        unit: data.unit,
      };
    },
    getSubstanceDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postSubstanceUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postSubstanceUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postSubstanceUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postSubstanceDeleteStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postSubstanceDeleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postSubstanceDeleteError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  substanceDetailSetForm,
  substanceDetailInit,
  getSubstanceDetailStart,
  getSubstanceDetailSuccess,
  getSubstanceDetailError,
  postSubstanceUpdateStart,
  postSubstanceUpdateSuccess,
  postSubstanceUpdateError,
  postSubstanceDeleteStart,
  postSubstanceDeleteSuccess,
  postSubstanceDeleteError,
} = substanceDetailSlice.actions;

export default substanceDetailSlice.reducer;

export const fetchAdminGetSubstanceDetail = ({ substanceId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().substanceDetail;
  if (isLoading) return;
  try {
    dispatch(getSubstanceDetailStart());
    const data = await restAdminGetSubstanceDetail({
      substanceId,
    });
    dispatch(
      getSubstanceDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getSubstanceDetailError(JSON.parse(err).message));
    }
    dispatch(getSubstanceDetailError(err));
  }
};

export const fetchAdminPostSubstanceUpdate = ({
  next,
  substanceId,
  name = "",
  price = 0,
  unit = "",
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!substanceId) {
    return dispatch(postSubstanceUpdateError("ID tidak ditemukan"));
  }
  if (name.length < 3) {
    return dispatch(postSubstanceUpdateError("Nama minimal 3 karakter"));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postSubstanceUpdateError("Tarif minimal 0"));
  }
  if (price < 0) {
    return dispatch(postSubstanceUpdateError("Tarif minimal 0"));
  }
  if (unit.length < 1) {
    return dispatch(postSubstanceUpdateError("Unit minimal 1 karakter"));
  }

  const { isLoading } = getState().substanceDetail;
  if (isLoading) return;
  try {
    dispatch(postSubstanceUpdateStart());
    await restAdminPostSubstanceUpdate({
      substanceId,
      name,
      price,
      unit,
    });
    dispatch(postSubstanceUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postSubstanceUpdateError(JSON.parse(err).message));
    }
    dispatch(postSubstanceUpdateError(err));
  }
};

export const fetchAdminPostSubstanceArchive = ({
  next,
  isArchived,
  substanceId = "",
  name = "",
}) => async (dispatch, getState) => {
  const savedName = name;

  if (!substanceId) {
    errorNotif("ID tidak ditemukan");
    return dispatch(postSubstanceDeleteError("ID tidak ditemukan"));
  }
  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postSubstanceDeleteStart());
    await restAdminPostSubstanceArchive({
      substanceId,
      isArchived,
    });
    dispatch(postSubstanceDeleteSuccess());
    if (isArchived) {
      warningNotif(`Berhasil arsipkan ${savedName}`);
    } else {
      successNotif(`Berhasil mengembalikan ${savedName}`);
    }
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postSubstanceDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postSubstanceDeleteError(err));
  }
};
