import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import {
  personDetailSetForm,
  personDetailInit,
  fetchAdminGetPersonDetail,
  fetchAdminPostPersonUpdate,
} from '../../store/person';
import {
  momentFormatInputDate,
  moment,
  parsePersonTypeName,
} from '../../helper';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-people`,
    name: 'Master Data Pegawai',
  },
  {
    url: `/admin/super-admin/master-people/update`,
    name: 'Update',
  },
];

export const PeopleUpdate = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const $global = useSelector((state) => state.global);
  const $personDetail = useSelector((state) => state.personDetail);
  const { listDepartment } = $global;

  const { data, form } = $personDetail;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === data.type
  );
  const { personId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(personDetailInit());
      if (personId) {
        dispatch(fetchAdminGetPersonDetail({ personId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostPersonUpdate({
        next: () => {
          push(`/admin/super-admin/master-people?sort=updatedAt&sortDir=desc`);
        },
        personId: data._id,
        joinDate: form.joinDate,
        prefix: form.prefix,
        suffix: form.suffix,
        name: form.name,
        mobile: form.mobile,
        gender: form.gender,
        departmentId: form.departmentId,
      })
    );
  };

  return (
    <Fragment>
      {($personDetail.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Update'}
        backURL={'/admin/super-admin/master-people'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='input-field col m3 s12'>
                  <input
                    defaultValue={parsePersonTypeName(data.type)}
                    placeholder='Gelar Depan'
                    id='prefix'
                    name='prefix'
                    type='text'
                    className='validate'
                    disabled
                  />
                  <label className='active' htmlFor='prefix'>
                    Tipe
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col m3 s12'>
                  <input
                    value={form.joinDate}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'joinDate',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Tanggal Bergabung'
                    id='joinDate'
                    name='joinDate'
                    type='date'
                    max={moment().endOf('month').format('YYYY-MM-DD')}
                    className='validate'
                  />
                  <label className='active' htmlFor='joinDate'>
                    Tanggal Bergabung{' '}
                    <b>{momentFormatInputDate(form.joinDate)}</b>
                  </label>
                </div>
                <div className='input-field col m3 s12'>
                  <input
                    value={form.mobile}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'mobile',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='No Handphone'
                    id='mobile'
                    name='mobile'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='mobile'>
                    No Handphone <b>contoh: 08122222222</b>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col m3 s12'>
                  <input
                    value={form.prefix}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'prefix',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Gelar Depan'
                    id='prefix'
                    name='prefix'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='prefix'>
                    Gelar Depan
                  </label>
                </div>
                <div className='input-field col m6 s12'>
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='name'
                    name='name'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='name'>
                    Nama <b>{`${form.prefix} ${form.name} ${form.suffix}`}</b>
                  </label>
                </div>
                <div className='input-field col m3 s12'>
                  <input
                    value={form.suffix}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'suffix',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Gelar Belakang'
                    id='suffix'
                    name='suffix'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='suffix'>
                    Gelar Belakang
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col m3 s12'>
                  <select
                    value={form.gender}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'gender',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Gender'
                    id='gender'
                    name='gender'
                    type='text'
                    className='browser-default validate m-t-8'
                  >
                    <option value=''>Silahkan Pilih</option>
                    <option value='m'>Pria</option>
                    <option value='f'>Wanita</option>
                  </select>
                  <label className='active' htmlFor='gender'>
                    Gender
                  </label>
                </div>
                <div className='input-field col m3 s12'>
                  <select
                    value={form.departmentId}
                    onChange={(e) =>
                      dispatch(
                        personDetailSetForm({
                          key: 'departmentId',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Departemen'
                    id='departmentId'
                    name='departmentId'
                    type='text'
                    className='browser-default validate m-t-8'
                  >
                    <option value=''>Silahkan Pilih</option>
                    {listDepartmentFilter.map((dpt) => (
                      <option key={dpt._id} value={dpt._id}>
                        {dpt.name}
                      </option>
                    ))}
                  </select>
                  <label className='active' htmlFor='departmentId'>
                    Departemen
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='red-text m-b-4 right-align'>
                    {$personDetail.error}
                  </div>
                </div>
                <div className='col s12'>
                  <button type='submit' className='btn teal m-b-20 right'>
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/admin/super-admin/master-people`}
                    className='btn grey m-b-20 right m-r-8'
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
