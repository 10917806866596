import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { pagination } from '../../helper';

const Div = styled.div`
  display: flex;
  align-items: center;
  .page-box {
  }
  select {
    display: block;
    width: 53px;
    height: 35px;
  }
`;

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const PaginationPage = ({
  defaultLimit = 10,
  defaultPage = 1,
  totalItem = 0,
  onChange = defaultOnChange,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();

  const queryParams = queryString.parse(locationSearch);
  const limit = queryParams.limit ? Number(queryParams.limit) : defaultLimit;
  const page = queryParams.page ? Number(queryParams.page) : defaultPage;

  const totalPage =
    totalItem && Math.ceil(totalItem / limit) > 1
      ? Math.ceil(totalItem / limit)
      : 1;
  const pageOptions = Array.from(Array(totalPage).keys());

  const validPrev = page - 1 > 0;
  const validNext = page + 1 <= totalPage;

  let pageBtnArr = [];

  if (page && totalPage) {
    pageBtnArr = pagination(page, totalPage);
  }

  const changePage = (v) => {
    const newQueryParams = queryParams;
    newQueryParams.page = v;
    if (newQueryParams.page === String(defaultPage)) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  const selectPage = (e) => {
    e.preventDefault();
    changePage(e.target.value);
  };

  const prevPageButton = (e) => {
    e.preventDefault();
    if (validPrev) {
      changePage(page - 1);
    }
  };
  const nextPageButton = (e) => {
    e.preventDefault();
    if (validNext) {
      changePage(page + 1);
    }
  };
  const clickPageButton = ({ e, idx, p }) => {
    e.preventDefault();
    if (idx === 0 && p === '...') {
      return changePage(pageBtnArr[idx + 1] - 1);
    }
    if (idx === pageBtnArr.length - 1 && p === '...') {
      return changePage(pageBtnArr[pageBtnArr.length - 2] + 1);
    }
    if (p !== page) {
      return changePage(p);
    }
  };

  return (
    <Div className='m-v-2'>
      <select onChange={selectPage} value={page}>
        {pageOptions.map((option, idx) => {
          return (
            <option key={idx} value={idx + 1}>
              {idx + 1}
            </option>
          );
        })}
      </select>
      <div className='m-l-8'>
        <button
          onClick={prevPageButton}
          disabled={!validPrev}
          type='button'
          className='btn-small white black-text'
        >
          <i className='fa fa-angle-left' />
        </button>
        {pageBtnArr.map((p, idx) => {
          return (
            <button
              key={idx}
              onClick={(e) => clickPageButton({ e, idx, p })}
              disabled={p === page}
              type='button'
              className='btn-small white black-text'
            >
              {p}
            </button>
          );
        })}
        <button
          onClick={nextPageButton}
          disabled={!validNext}
          type='button'
          className='btn-small white black-text'
        >
          <i className='fa fa-angle-right' />
        </button>
      </div>
    </Div>
  );
};
