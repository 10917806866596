import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetUserAccessList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/user-access/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}

export function restGetUserAccessPersonList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  type = '',
  departmentId = '',
}) {
  let path = `${BASE_API_ADMIN}/user-access/person-list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&type=${type}`;
  path += `&departmentId=${departmentId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostUserAccessUpdate({ userId, isAdd, roleId, roleName }) {
  const path = `${BASE_API_ADMIN}/user-access/update/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isAdd,
      roleId,
      roleName,
    },
  });
}

export function restPostUserAccessAssignPerson({ userId, personId, roleId }) {
  const path = `${BASE_API_ADMIN}/user-access/assign-person/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      personId,
      roleId,
    },
  });
}

export function restPostUserAccessUnassignPerson({ userId, personId, roleId }) {
  const path = `${BASE_API_ADMIN}/user-access/unassign-person/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      personId,
      roleId,
    },
  });
}
