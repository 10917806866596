import fetchApi from "../api/fetchApi";
import { BASE_API_ADMIN } from "../config";

// GET
export function restGetPatientList({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  isNewPatient = "",
}) {
  let path = `${BASE_API_ADMIN}/patient/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&isNewPatient=${isNewPatient}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientListArchived({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  isNewPatient = "",
}) {
  let path = `${BASE_API_ADMIN}/patient/list-archived`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&isNewPatient=${isNewPatient}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientDetail({ patientId }) {
  const path = `${BASE_API_ADMIN}/patient/detail/${patientId}`;
  return fetchApi(path, {
    method: "GET",
  });
}

// POST
export function restPostPatientCreate({
  noRM,
  prefix,
  name,
  suffix,
  birthLocation,  // tambahan
  birthDate,
  nik, // tambahan

  mobile,
  gender,
  // Tambahan
  bloodType,
  nationality,
  address,
  kelurahan,
  kecamatan,
  kota,
  lastEducation,
  religion,
  job,
  martialStatus,
  patientGroup,
  paymentMethod,
  responsibleName,
  responsiblePhone,
  // Physical Examination
  allergies,
  height,
  weight,
  kesadaran,
  suhu,
  keadaanUmum,
  nadi,
  saturasiOksigen,
  tekananDarah,
  respirasi,
  notesPhysicalExamination,
}) {
  const path = `${BASE_API_ADMIN}/patient/create`;
  return fetchApi(path, {
    method: "POST",
    body: {
      noRM,
      prefix,
      name,
      suffix,
      birthLocation,  // tambahan
      birthDate,
      nik, // tambahan

      mobile,
      gender,
      // Tambahan
      bloodType,
      nationality,
      address,
      kelurahan,
      kecamatan,
      kota,
      lastEducation,
      religion,
      job,
      martialStatus,
      patientGroup,
      paymentMethod,
      responsibleName,
      responsiblePhone,
      // Physical Examination
      allergies,
      height,
      weight,
      kesadaran,
      suhu,
      keadaanUmum,
      nadi,
      saturasiOksigen,
      tekananDarah,
      respirasi,
      notesPhysicalExamination,
    },
  });
}

export function restPostPatientUpdate({
  patientId,
  noRM,
  prefix,
  name,
  suffix,
  birthLocation,
  birthDate,
  nik, // tambahan

  mobile,
  gender,
  // Tambahan
  bloodType,
  nationality,
  address,
  kelurahan,
  kecamatan,
  kota,
  lastEducation,
  religion,
  job,
  martialStatus,
  patientGroup,
  paymentMethod,
  responsibleName,
  responsiblePhone,
  // Physical Examination
  allergies,
  height,
  weight,
  kesadaran,
  suhu,
  keadaanUmum,
  nadi,
  saturasiOksigen,
  tekananDarah,
  respirasi,
  notesPhysicalExamination,
}) {
  const path = `${BASE_API_ADMIN}/patient/update/${patientId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      noRM,
      prefix,
      name,
      suffix,
      birthLocation,
      birthDate,
      nik, // tambahan

      mobile,
      gender,
      // Tambahan
      bloodType,
      nationality,
      address,
      kelurahan,
      kecamatan,
      kota,
      lastEducation,
      religion,
      job,
      martialStatus,
      patientGroup,
      paymentMethod,
      responsibleName,
      responsiblePhone,
      // Physical Examination
      allergies,
      height,
      weight,
      kesadaran,
      suhu,
      keadaanUmum,
      nadi,
      saturasiOksigen,
      tekananDarah,
      respirasi,
      notesPhysicalExamination,
    },
  });
}

export function restPostPatientArchive({ patientId, isArchived }) {
  const path = `${BASE_API_ADMIN}/patient/archive/${patientId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      isArchived,
    },
  });
}
