import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../page/common';
import {
  Login,
  ForgotPassword,
  ResetPassword,
  Register,
  EmailVerify,
} from '../page/auth';

export const GuestLayout = () => {
  return (
    <Switch>
      <Route exact path='/' component={Login} />
      <Route path='/login' component={Login} />
      <Route path='/forgot-password' component={ForgotPassword} />
      <Route path='/reset-password' component={ResetPassword} />
      <Route path='/register' component={Register} />
      <Route exact path='/email-verify' component={EmailVerify} />
      <Route component={Page404} />
    </Switch>
  );
};
