import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
} from '../../component/common';
import { momentGeneral, parsePersonTypeName } from '../../helper';
import { fetchAdminGetDepartmentList } from '../../store/department';
import { PERSON_TYPE } from '../../config';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-department`,
    name: 'Master Departemen',
  },
];

export const DepartmentList = () => {
  const dispatch = useDispatch();
  const $departmentList = useSelector((state) => state.departmentList);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetDepartmentList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$departmentList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Master Departemen'}
        backURL={'/admin/master-admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetDepartmentList(queryParams));
        }}
      />

      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetDepartmentList(newQueryParams));
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/admin/super-admin/master-department/create`}
                    className="btn-small teal white-text"
                  >
                    <i className="fa fa-plus" />
                  </Link>
                );
              }}
            />
            <FilterSelect
              options={PERSON_TYPE}
              filterKey={`type`}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetDepartmentList(newQueryParams));
              }}
            />
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>Tipe</th>
                  <th>Nama</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$departmentList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>{parsePersonTypeName(data.type)}</td>
                      <td>
                        <Link
                          to={`/admin/super-admin/master-department/update/${data._id}`}
                        >
                          {data.name}
                        </Link>
                      </td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetDepartmentList(newQueryParams));
              }}
              totalItem={$departmentList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
