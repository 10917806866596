import React from 'react';
import styled from 'styled-components';

import { PaginationPage } from './PaginationPage';
import { PaginationShow } from './PaginationShow';

const Div = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1px;
    margin-right: 1px;
    .pagination-wrapper {
      /* container class alias */
    }
  }
`;

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

const defaultLimitOptions = [10, 20, 50, 100];

export const Pagination = ({
  totalItem = 0,
  limitOptions = defaultLimitOptions,
  defaultLimit = 10,
  defaultPage = 1,
  onChange = defaultOnChange,
}) => {
  return (
    <Div>
      <PaginationPage
        onChange={onChange}
        totalItem={totalItem}
        limitOptions={limitOptions}
        defaultLimit={defaultLimit}
        defaultPage={defaultPage}
      />
      <PaginationShow
        onChange={onChange}
        totalItem={totalItem}
        limitOptions={limitOptions}
        defaultLimit={defaultLimit}
        defaultPage={defaultPage}
      />
    </Div>
  );
};
