import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  confirmNotif,
  momentGeneral,
  momentNumberDateToDisplayDate,
  momentCountAge,
} from '../../helper';
import {
  fetchAdminGetPatientList,
  fetchAdminPostPatientArchive,
} from '../../store/patient';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-patient`,
    name: 'Master Data Pasien Arsip',
  },
];

export const PatientArchiveList = () => {
  const dispatch = useDispatch();

  const $patientList = useSelector((state) => state.patientList);
  const $patientDetail = useSelector((state) => state.patientDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetPatientList({ ...queryParams, isArchived: true }));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      title: `Konfirmasi Kembalikan`,
      msg: `Anda yakin akan mengembalikan ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostPatientArchive({
            isArchived: false,
            patientId: data._id,
            name: data.name,
            next: () => {
              dispatch(
                fetchAdminGetPatientList({ ...queryParams, isArchived: true })
              );
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($patientList.isLoading || $patientDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Pasien Arsip'}
        backURL={'/admin/super-admin'}
        onRefresh={() => {
          dispatch(
            fetchAdminGetPatientList({ ...queryParams, isArchived: true })
          );
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPatientList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
            />

            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Pasien Baru</th>
                  <th>No RM</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Umur</th>
                  <th>Tgl Lahir</th>
                  <th>Dihapus</th>
                  <th>Dihapus Oleh</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$patientList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {data.isNewPatient ? (
                          <i className='fa fa-check-square'></i>
                        ) : (
                          <i className='fa fa-square-o'></i>
                        )}
                      </td>
                      <td>{data.noRM}</td>
                      <td>
                        {data.gender === 'f' ? (
                          <i className='fa fa-female'></i>
                        ) : (
                          <i className='fa fa-male'></i>
                        )}
                        {` `}
                        {`${data.prefix} ${data.name} ${data.suffix}`}
                      </td>
                      <td>{data.mobile}</td>
                      <td>{momentCountAge(data.birthDate)}</td>
                      <td>{momentNumberDateToDisplayDate(data.birthDate)}</td>
                      <td>{momentGeneral(data.deletedAt)}</td>
                      <td title={data.deletedById}>{data.deletedByName}</td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmArchive({ e, data })}
                          className='blue-text cursor-pointer font-16'
                        >
                          <i className='fa fa-reply'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPatientList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
              totalItem={$patientList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
