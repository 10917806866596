import userList from './userList';
import userCreate from './userCreate';
import userDetail from './userDetail';

export * from './userList';
export * from './userCreate';
export * from './userDetail';

const userReducers = {
  userList,
  userCreate,
  userDetail,
};

export default userReducers;
