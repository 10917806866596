import departmentList from './departmentList';
import departmentCreate from './departmentCreate';
import departmentDetail from './departmentDetail';

export * from './departmentList';
export * from './departmentCreate';
export * from './departmentDetail';

const departmentReducers = {
  departmentList,
  departmentCreate,
  departmentDetail,
};

export default departmentReducers;
