import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';

import {
  BreadCrumb,
  HeaderNav,
  LoaderBlock,
  SearchSort,
  Pagination,
  Label,
} from '../../component/common';
import {
  momentCountAge,
  momentNumberDateToDisplayDate,
  momentNumberDateToDisplayFullDate,
  parsePatientDoctorActionStatus,
} from '../../helper';
import {
  medicalRecordDetailInit,
  fetchGetMedicalRecordDetail,
  fetchGetMedicalRecordActionList,
} from '../../store/medicalRecord';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = (patientId, locationSearch) => [
  {
    url: `/medical-record${locationSearch}`,
    name: 'Medical Record Pasien',
  },
  {
    url: `/medical-record/detail/${patientId}${locationSearch}`,
    name: 'Medical Record Pasien',
  },
];
const backURL = (locationSearch) => `/medical-record${locationSearch}`;

export const MedicalRecordDetail = () => {
  const { patientId } = useParams();
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  const dispatch = useDispatch();
  const $global = useSelector((state) => state.global);
  const $medicalRecordActionList = useSelector(
    (state) => state.medicalRecordActionList
  );
  const $medicalRecordDetail = useSelector(
    (state) => state.medicalRecordDetail
  );

  const { data: patientData } = $medicalRecordDetail;
  const getListData = (queryParams, patientId) => {
    dispatch(fetchGetMedicalRecordActionList({ ...queryParams, patientId }));
  };
  const getAllData = (queryParams, patientId) => {
    getListData(queryParams, patientId);
    dispatch(fetchGetMedicalRecordDetail({ patientId }));
  };
  useEffect(() => {
    const didMount = () => {
      dispatch(medicalRecordDetailInit());
      if (patientId) {
        getAllData(queryParams, patientId);
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const uiSubActionList = (subActionList, actionId) => {
    let subActionListFilter = [];
    if (subActionList && subActionList.length > 0) {
      subActionListFilter = subActionList.filter(
        (d) => d.actionId === actionId
      );
    }
    if (subActionListFilter && subActionListFilter.length > 0) {
      return (
        <ul className='m-0 m-l-16'>
          {subActionListFilter.map((sa) => (
            <li key={sa._id}>
              <i className='fa fa-circle-o'></i>
              {` `}
              {sa.name}
            </li>
          ))}
        </ul>
      );
    }
    return <span />;
  };
  return (
    <Fragment>
      {($medicalRecordActionList.isLoading ||
        $medicalRecordDetail.isLoading ||
        $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Update'}
        backURL={backURL(locationSearch)}
      />
      <BreadCrumb breadCrumbData={breadCrumbData(patientId, locationSearch)} />
      <Div className='container'>
        {/* PATIENT */}
        <div className="card m-t-0">
          <div className="card-content p-0">
            <div className="row">
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="100">
                        No RM
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.noRM}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Nama
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">
                        {patientData.gender === "f" ? <i className="fa fa-female"></i> : <i className="fa fa-male"></i>}
                        {` `}
                        {patientData.prefix} {patientData.name} {patientData.suffix}
                        {` `}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tgl Lahir</td>
                      <td className="p-v-4">:</td>

                      <td className="p-v-4">
                        {momentNumberDateToDisplayDate(patientData.birthDate)} / {momentCountAge(patientData.birthDate) ? `${momentCountAge(patientData.birthDate)} tahun` : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">NIK</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.nik}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        No HP
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.mobile}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Pasien Baru?
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.isNewPatient ? <i className="fa fa-check-square"></i> : <i className="fa fa-square-o"></i>}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="150">
                        Alergi
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.allergies && patientData.allergies.map((aName) => <Label key={aName} text={aName} />)}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="150" title="Tinggi Badan / Berat Badan">
                        TB / BB
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.height} / {patientData.weight}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Kesadaran</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.kesadaran}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Suhu / Nadi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.suhu} / {patientData.nadi}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tekanan Darah</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.tekananDarah}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Respirasi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.respirasi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      
        <div className='card'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                getListData(newQueryParams, patientId);
              }}
              sortOptions={[
                {
                  key: 'createdAt',
                  name: 'Tanggal Kunjungan',
                },
              ]}
              defaultSort='createdAt'
              defaultSortDir='desc'
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Tgl</th>
                  <th>Bagian</th>
                  <th>Dokter</th>
                  <th>Asisten</th>
                  <th>Keluhan</th>
                  <th>Diagnosis</th>
                  <th>Tindakan</th>
                  <th>Bahan Tambahan</th>
                  <th>Status</th>
                  <th>RM Kembali?</th>
                </tr>
              </thead>

              <tbody>
                {/* ROW WITHOUT ACTION */}
                {$medicalRecordActionList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {momentNumberDateToDisplayFullDate(data.visitDate)}
                      </td>
                      <td>{data.doctorDepartmentName}</td>
                      <td>{data.doctorFullName}</td>
                      <td>
                        <div>{data.nurseFullName}</div>
                        {data.nurseFullName2 && (
                          <div>{data.nurseFullName2}</div>
                        )}
                        {data.nurseFullName3 && (
                          <div>{data.nurseFullName3}</div>
                        )}
                      </td>
                      <td>{data.mainComplaint}</td>
                      <td>
                        <ul className='m-0'>
                          {data.diagnosisList.map((d) => (
                            <li key={d._id}>
                              <i className='fa fa-circle'></i>
                              {` `}
                              {d.icd10} {d.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul className='m-0'>
                          {data.actionList.map((d) => (
                            <li key={d._id}>
                              <i className='fa fa-circle'></i>
                              {` `}
                              {d.name}
                              {uiSubActionList(data.subActionList, d._id)}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul className='m-0'>
                          {data.substanceList.map((d) => (
                            <li key={d._id}>
                              <i className='fa fa-circle'></i>
                              {` `}
                              {d.qty} {d.unit} {d.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        {parsePatientDoctorActionStatus({
                          isCanceled: data.isCanceled,
                          patientDoctorActionId: data._id,
                          patientInvoiceId: data.patientInvoiceId,
                        })}
                      </td>
                      <td className='center-align cursor-pointer'>
                        {data.isReturnedRM ? (
                          <i className='fa fa-check-square-o'></i>
                        ) : (
                          <i className='fa fa fa-square-o'></i>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                getListData(newQueryParams, patientId);
              }}
              totalItem={$medicalRecordActionList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
