import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import {
  allergicDetailSetForm,
  allergicDetailInit,
  fetchAdminGetAllergicDetail,
  fetchAdminPostAllergicUpdate,
} from '../../store/allergic';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-allergic`,
    name: 'Master Data Riwayat Alergi',
  },
  {
    url: `/admin/master-allergic/update`,
    name: 'Update',
  },
];

export const AllergicUpdate = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();
  const $allergicDetail = useSelector((state) => state.allergicDetail);
  const { data, form } = $allergicDetail;
  const { allergicId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(allergicDetailInit());
      if (allergicId) {
        dispatch(fetchAdminGetAllergicDetail({ allergicId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostAllergicUpdate({
        push,
        allergicId: data._id,
        name: form.name,
      })
    );
  };

  return (
    <Fragment>
      {$allergicDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Update'}
        backURL={'/admin/master-allergic'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        allergicDetailSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$allergicDetail.error}
                  </div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right">
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/admin/master-allergic`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
