import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { PatientList } from './PatientList';
import { PatientCreate } from './PatientCreate';
import { PatientUpdate } from './PatientUpdate';

export const MasterPatientRoute = () => {
  const pathname = '/admin/master-patient';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PatientList} />
      <Route exact path={`${pathname}/create`} component={PatientCreate} />
      <Route
        exact
        path={`${pathname}/update/:patientId`}
        component={PatientUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
