import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  Search,
} from '../../component/common';
import {
  confirmNotif,
  momentNumberDateToDisplayDate,
  momentCountAge,
  momentNumberDateToDisplayFullDate,
  parseDepartmentName,
  warningNotif,
  isRoleReadOnly,
} from '../../helper';
import {
  patientVisitCreateInit,
  patientVisitCreateSetForm,
  fetchPostPatientVisitCreate,
} from '../../store/patientVisit';
import { fetchGetPatientVisitPatientList } from '../../store/patient';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = (queryParams) => {
  delete queryParams.search;
  const locationSearch = queryString.stringify(queryParams);
  return [
    {
      url: `/patient-visit?${locationSearch}`,
      name: 'Pendaftaran Pasien',
    },
    {
      url: `/patient-visit/search`,
      name: 'Pasien Lama',
    },
  ];
};
const backURL = (queryParams) => {
  delete queryParams.search;
  const locationSearch = queryString.stringify(queryParams);
  return `/patient-visit?${locationSearch}`;
};
const newURL = (queryParams) => {
  delete queryParams.search;
  const locationSearch = queryString.stringify(queryParams);
  return `/patient-visit/new?${locationSearch}`;
};

export const PatientVisitSearch = () => {
  const doBlockButton = () => {
    if (isRoleReadOnly()) {
      return true;
    }

    return false;
  };
  const dispatch = useDispatch();

  const $patientList = useSelector((state) => state.patientList);
  const $patientVisitCreate = useSelector((state) => state.patientVisitCreate);
  const { form } = $patientVisitCreate;
  const { push } = useHistory();

  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );

  useEffect(() => {
    const didMount = () => {
      dispatch(patientVisitCreateInit());
      if (queryParams.search) {
        dispatch(fetchGetPatientVisitPatientList({ ...queryParams }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmAddPatient = ({ e, data }) => {
    e.preventDefault();
    if (!form.doctorDepartmentId || !form.doctorDepartmentName) {
      return warningNotif(`Silahkan pilih poliklinik terlebih dahulu`);
    }
    if (doBlockButton()) return;

    confirmNotif({
      title: `Konfirmasi`,
      msg: `Daftarkan "${data.name}" ke poli "${form.doctorDepartmentName}" ?`,
      onOK: () => {
        dispatch(
          fetchPostPatientVisitCreate({
            visitDate: queryParams.visitDate,
            doctorDepartmentId: form.doctorDepartmentId,
            doctorDepartmentName: form.doctorDepartmentName,
            patientId: data._id,
            next: () => {
              push(backURL({ ...queryParams }));
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($patientList.isLoading ||
        $patientVisitCreate.isLoading ||
        $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Pasien Lama'}
        backURL={backURL({ ...queryParams })}
        onRefresh={() => {
          dispatch(fetchGetPatientVisitPatientList({ ...queryParams }));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData({ ...queryParams })} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <Search
              searchPlaceholder='Cari No RM / Nama / No HP'
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetPatientVisitPatientList({
                    ...newQueryParams,
                  })
                );
              }}
            >
              <div className='m-l-16'>
                {momentNumberDateToDisplayFullDate(queryParams.visitDate)}
              </div>
            </Search>

            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th width='70' className='center-align'>
                    Pasien Baru
                  </th>
                  <th>No RM</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Umur</th>
                  <th>Tgl Lahir</th>
                  <th>Poliklinik</th>
                  <th>Action</th>
                </tr>
              </thead>
              {queryParams.visitDate && queryParams.search ? (
                <tbody>
                  {$patientList.listData.map((data) => {
                    return (
                      <tr key={data._id}>
                        <td className='center-align'>
                          {data.isNewPatient ? (
                            <i className='fa fa-check-square'></i>
                          ) : (
                            <i className='fa fa-square-o'></i>
                          )}
                        </td>
                        <td>{data.noRM}</td>
                        <td>
                          {data.gender === 'f' ? (
                            <i className='fa fa-female'></i>
                          ) : (
                            <i className='fa fa-male'></i>
                          )}
                          {` `}
                          {`${data.prefix} ${data.name} ${data.suffix}`}
                        </td>
                        <td>{data.mobile}</td>
                        <td>{momentCountAge(data.birthDate)}</td>
                        <td>{momentNumberDateToDisplayDate(data.birthDate)}</td>
                        <td>
                          <select
                            value={form.doctorDepartmentId}
                            onChange={(e) => {
                              dispatch(
                                patientVisitCreateSetForm({
                                  key: 'doctorDepartmentId',
                                  value: e.target.value,
                                })
                              );
                              dispatch(
                                patientVisitCreateSetForm({
                                  key: 'doctorDepartmentName',
                                  value: parseDepartmentName({
                                    listDepartment: listDepartmentFilter,
                                    departmentId: e.target.value,
                                  }),
                                })
                              );
                            }}
                            id='doctorDepartmentId'
                            name='doctorDepartmentId'
                            type='text'
                            className='browser-default validate m-t-8'
                          >
                            <option value='' name=''>
                              Pilih Poliklinik
                            </option>
                            {listDepartmentFilter.map((dpt) => (
                              <option
                                key={dpt._id}
                                value={dpt._id}
                                name={dpt.name}
                              >
                                {dpt.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td width='1' className='center-align'>
                          {doBlockButton() ? (
                            <span className='font-16'>
                              <i className='fa fa-external-link-square'></i>
                            </span>
                          ) : (
                            <span
                              onClick={(e) => confirmAddPatient({ e, data })}
                              className='blue-text cursor-pointer font-16'
                            >
                              <i className='fa fa-external-link-square'></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan='20' className='center-align'>
                      Silahkan Cari Pasien
                    </td>
                  </tr>
                </tbody>
              )}
              {queryParams.search && $patientList.listData.length < 1 ? (
                <tbody>
                  <tr>
                    <td colSpan='20' className='center-align'>
                      Pasien tidak ditemukan.{' '}
                      <Link to={newURL(queryParams)}> Tambah Pasien Baru</Link>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <Fragment />
              )}
            </table>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
