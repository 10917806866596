import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import { formatCurrency } from '../../helper';
import {
  actionDetailSetForm,
  actionDetailInit,
  fetchAdminGetActionDetail,
  fetchAdminPostActionUpdate,
} from '../../store/action';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-action`,
    name: 'Master Data Tindakan',
  },
  {
    url: `/admin/master-action/update`,
    name: 'Update',
  },
];

export const ActionUpdate = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();
  const $actionDetail = useSelector((state) => state.actionDetail);
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );
  const { data, form } = $actionDetail;
  const { actionId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(actionDetailInit());
      if (actionId) {
        dispatch(fetchAdminGetActionDetail({ actionId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostActionUpdate({
        next: () => {
          push('/admin/master-action?sort=updatedAt&sortDir=desc');
        },
        actionId: data._id,
        doctorDepartmentId: form.doctorDepartmentId,
        name: form.name,
        price: form.price,
      })
    );
  };

  return (
    <Fragment>
      {($actionDetail.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Update'}
        backURL={'/admin/master-action'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='input-field col s12'>
                  <select
                    value={form.doctorDepartmentId}
                    onChange={(e) =>
                      dispatch(
                        actionDetailSetForm({
                          key: 'doctorDepartmentId',
                          value: e.target.value,
                        })
                      )
                    }
                    id='doctorDepartmentId'
                    name='doctorDepartmentId'
                    type='text'
                    className='browser-default validate m-t-8'
                  >
                    <option value='doctor'>Semua Poliklinik</option>
                    {listDepartmentFilter.map((dpt) => (
                      <option key={dpt._id} value={dpt._id}>
                        {dpt.name}
                      </option>
                    ))}
                  </select>
                  <label className='active' htmlFor='doctorDepartmentId'>
                    Poliklinik
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        actionDetailSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='name'
                    name='name'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='name'>
                    Nama
                  </label>
                </div>
              </div>

              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.price}
                    onChange={(e) =>
                      dispatch(
                        actionDetailSetForm({
                          key: 'price',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Tarif'
                    id='price'
                    price='price'
                    type='number'
                    className='validate'
                  />
                  <label className='active' htmlFor='price'>
                    Tarif <b>{formatCurrency(form.price)}</b>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='red-text m-b-4 right-align'>
                    {$actionDetail.error}
                  </div>
                </div>
                <div className='col s12'>
                  <button type='submit' className='btn teal m-b-20 right'>
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/admin/master-action`}
                    className='btn grey m-b-20 right m-r-8'
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
