import { createSlice } from '@reduxjs/toolkit';
import {
  restGetAllergicDetail as restAdminGetAllergicDetail,
  restPostAllergicUpdate as restAdminPostAllergicUpdate,
} from '../../apiAdmin/allergic';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    name: '',
  },
};

const allergicDetailSlice = createSlice({
  name: 'allergicDetail',
  initialState: initialState,
  reducers: {
    allergicDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    allergicDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
      };
    },
    getAllergicDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getAllergicDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: data.type,
        name: data.name,
      };
    },
    getAllergicDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postAllergicUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postAllergicUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postAllergicUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  allergicDetailSetForm,
  allergicDetailInit,
  getAllergicDetailStart,
  getAllergicDetailSuccess,
  getAllergicDetailError,
  postAllergicUpdateStart,
  postAllergicUpdateSuccess,
  postAllergicUpdateError,
} = allergicDetailSlice.actions;

export default allergicDetailSlice.reducer;

export const fetchAdminGetAllergicDetail = ({ allergicId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().allergicDetail;
  if (isLoading) return;
  try {
    dispatch(getAllergicDetailStart());
    const data = await restAdminGetAllergicDetail({
      allergicId,
    });
    dispatch(
      getAllergicDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getAllergicDetailError(JSON.parse(err).message));
    }
    dispatch(getAllergicDetailError(err));
  }
};

export const fetchAdminPostAllergicUpdate = ({
  push,
  allergicId,
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!allergicId) {
    return dispatch(postAllergicUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postAllergicUpdateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().allergicDetail;
  if (isLoading) return;
  try {
    dispatch(postAllergicUpdateStart());
    await restAdminPostAllergicUpdate({
      allergicId,
      name,
    });
    dispatch(postAllergicUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/admin/master-allergic?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postAllergicUpdateError(JSON.parse(err).message));
    }
    dispatch(postAllergicUpdateError(err));
  }
};
