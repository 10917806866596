import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  momentGeneral,
  momentNumberDateToDisplayDate,
  momentCountAge,
} from '../../helper';
import { fetchGetMedicalRecordList } from '../../store/medicalRecord';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = [
  {
    url: `/medical-record`,
    name: 'Medical Record Pasien',
  },
];
const backURL = `/`;
const detailURL = (patientId, locationSearch) =>
  `/medical-record/detail/${patientId}${locationSearch}`;

export const MedicalRecordList = () => {
  const { search: locationSearch } = useLocation();

  const dispatch = useDispatch();

  const $medicalRecordList = useSelector((state) => state.medicalRecordList);

  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchGetMedicalRecordList({ ...queryParams, type: 'patient' }));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$medicalRecordList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Medical Record Pasien'}
        backURL={backURL}
        onRefresh={() => {
          dispatch(
            fetchGetMedicalRecordList({ ...queryParams, type: 'patient' })
          );
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetMedicalRecordList({
                    ...newQueryParams,
                    type: 'patient',
                  })
                );
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/admin/master-patient/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Pasien Baru</th>
                  <th>No RM</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Umur</th>
                  <th>Tgl Lahir</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$medicalRecordList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {data.isNewPatient ? (
                          <i className='fa fa-check-square'></i>
                        ) : (
                          <i className='fa fa-square-o'></i>
                        )}
                      </td>
                      <td>{data.noRM}</td>
                      <td>
                        <Link to={detailURL(data._id, locationSearch)}>
                          {data.gender === 'f' ? (
                            <i className='fa fa-female'></i>
                          ) : (
                            <i className='fa fa-male'></i>
                          )}
                          {` `}
                          {`${data.prefix} ${data.name} ${data.suffix}`}
                        </Link>
                      </td>
                      <td>{data.mobile}</td>
                      <td>{momentCountAge(data.birthDate)}</td>
                      <td>{momentNumberDateToDisplayDate(data.birthDate)}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetMedicalRecordList({
                    ...newQueryParams,
                    type: 'patient',
                  })
                );
              }}
              totalItem={$medicalRecordList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
