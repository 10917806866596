import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { MedicalRecordList } from './MedicalRecordList';
import { MedicalRecordDetail } from './MedicalRecordDetail';

export const MedicalRecordRoute = () => {
  const pathname = '/medical-record';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={MedicalRecordList} />
      <Route
        exact
        path={`${pathname}/detail/:patientId`}
        component={MedicalRecordDetail}
      />

      <Route component={Page404} />
    </Switch>
  );
};
