import { createSlice } from '@reduxjs/toolkit';
import { restGetSubActionList as restAdminGetSubActionList } from '../../apiAdmin/subAction';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const subActionListSlice = createSlice({
  name: 'subActionList',
  initialState: initialState,
  reducers: {
    getSubActionListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSubActionListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getSubActionListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSubActionListStart,
  getSubActionListSuccess,
  getSubActionListError,
} = subActionListSlice.actions;

export default subActionListSlice.reducer;

export const fetchAdminGetSubActionList = ({
  actionId,
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  doctorDepartmentId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().subActionList;
  if (isLoading) return;
  try {
    dispatch(getSubActionListStart());
    const { listData, totalItem } = await restAdminGetSubActionList({
      actionId,
      limit,
      page,
      sort,
      sortDir,
      search,
      doctorDepartmentId,
    });
    dispatch(
      getSubActionListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getSubActionListError(JSON.parse(err).message));
    }
    dispatch(getSubActionListError(err));
  }
};
