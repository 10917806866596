import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  @media print {
    display: none;
  }

  background-color: #fafafa !important;
  box-shadow: none;
  line-height: 36px !important;
  height: 46px !important;

  .container-class {
    /* container class alias */
  }
  .breadcrumb {
    font-size: 12px;
    line-height: 40px !important;
    height: 46px !important;
  }

  .breadcrumb:before {
    color: #009688 !important;
  }
`;

export const BreadCrumb = ({ breadCrumbData = [] }) => {
  return (
    <div className='container'>
      <Nav>
        <div className='nav-wrapper teal-text'>
          {breadCrumbData.length > 0 && (
            <Link to={`/`} className='breadcrumb teal-text'>
              Home
            </Link>
          )}
          {breadCrumbData.length < 1 && (
            <span className='breadcrumb black-text'>Home</span>
          )}
          {breadCrumbData.map((bc, idx) => {
            if (idx + 1 === breadCrumbData.length) {
              return (
                <span key={idx} className='breadcrumb black-text'>
                  {bc.name}
                </span>
              );
            }
            return (
              <Link key={idx} to={bc.url} className='breadcrumb teal-text'>
                {bc.name}
              </Link>
            );
          })}
        </div>
      </Nav>
    </div>
  );
};
