import { createSlice } from "@reduxjs/toolkit";
import { restGetPatientDetail } from "../../api/patientDoctorAction"; // For Faster Feature, Get didn't need make a new API
import { restPostPatientVisitPatientUpdate } from "../../api/patientVisit";
import { isValidJSON, momentInputDateToNumberDate, successNotif, moment } from "../../helper";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    // Bio
    noRM: "",
    prefix: "",
    suffix: "",
    name: "",
    birthLocation: "",
    birthDate: "",
    nik: "",

    mobile: "",
    gender: "",
    // Tambahan
    bloodType: "",
    nationality: "",
    address: "",
    kelurahan: "",
    kecamatan: "",
    kota: "",
    lastEducation: "",
    religion: "",
    job: "",
    martialStatus: "",
    patientGroup: "",
    paymentMethod: "",
    responsibleName: "",
    responsiblePhone: "",
    // Physical Examination
    allergies: [],
    height: 0,
    weight: 0,
    kesadaran: "",
    suhu: "",
    keadaanUmum: "",
    nadi: "",
    saturasiOksigen: "",
    tekananDarah: "",
    respirasi: "",
    notesPhysicalExamination: "",
  },
};

const patientVisitPatientUpdate = createSlice({
  name: "patientVisitPatientUpdate",
  initialState: initialState,
  reducers: {
    patientUpdateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientUpdateInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        // Bio
        noRM: "",
        prefix: "",
        suffix: "",
        name: "",
        birthLocation: "",
        birthDate: "",
        nik: "",

        mobile: "",
        gender: "",
        // Tambahan
        bloodType: "",
        nationality: "",
        address: "",
        kelurahan: "",
        kecamatan: "",
        kota: "",
        lastEducation: "",
        religion: "",
        job: "",
        martialStatus: "",
        patientGroup: "",
        paymentMethod: "",
        responsibleName: "",
        responsiblePhone: "",
        // Physical Examination
        allergies: [],
        height: 0,
        weight: 0,
        kesadaran: "",
        suhu: "",
        keadaanUmum: "",
        nadi: "",
        saturasiOksigen: "",
        tekananDarah: "",
        respirasi: "",
        notesPhysicalExamination: "",
      };
    },
    getPatientDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        // Bio
        noRM: data.noRM,
        prefix: data.prefix,
        suffix: data.suffix,
        name: data.name,
        birthLocation: data.birthLocation,
        birthDate: moment(data.birthDate).format("YYYY-MM-DD"),
        nik: data.nik,

        mobile: data.mobile,
        gender: data.gender,

        // Tambahan
        bloodType: data.bloodType,
        nationality: data.nationality,
        address: data.address,
        kelurahan: data.kelurahan,
        kecamatan: data.kecamatan,
        kota: data.kota,
        lastEducation: data.lastEducation,
        religion: data.religion,
        job: data.job,
        martialStatus: data.martialStatus,
        patientGroup: data.patientGroup,
        paymentMethod: data.paymentMethod,
        responsibleName: data.responsibleName,
        responsiblePhone: data.responsiblePhone,

        // Physical Examination
        allergies: data.allergies,
        height: data.height,
        weight: data.weight,
        kesadaran: data.kesadaran,
        suhu: data.suhu,
        keadaanUmum: data.keadaanUmum,
        nadi: data.nadi,
        saturasiOksigen: data.saturasiOksigen,
        tekananDarah: data.tekananDarah,
        respirasi: data.respirasi,
        notesPhysicalExamination: data.notesPhysicalExamination,
      };
    },
    getPatientDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPatientUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPatientDeleteStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientDeleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientDeleteError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { patientUpdateSetForm, patientUpdateInit, getPatientDetailStart, getPatientDetailSuccess, getPatientDetailError, postPatientUpdateStart, postPatientUpdateSuccess, postPatientUpdateError, postPatientDeleteStart, postPatientDeleteSuccess, postPatientDeleteError } = patientVisitPatientUpdate.actions;

export default patientVisitPatientUpdate.reducer;

export const fetchGetPatientUpdateDetail =
  ({ patientId }) =>
  async (dispatch, getState) => {
    const { isLoading } = getState().patientDetail;
    if (isLoading) return;
    try {
      dispatch(getPatientDetailStart());
      const data = await restGetPatientDetail({
        patientId,
      });
      dispatch(
        getPatientDetailSuccess({
          data,
        })
      );
    } catch (err) {
      if (isValidJSON(err)) {
        return dispatch(getPatientDetailError(JSON.parse(err).message));
      }
      dispatch(getPatientDetailError(err));
    }
  };

export const fetchPostPatientUpdate =
  ({
    next,
    patientId,
    // Bio
    noRM = "",
    prefix = "",
    suffix = "",
    name = "",
    birthLocation = "",
    birthDate = "",
    nik = "",

    mobile = "",
    gender = "",
    // Tambahan
    bloodType = "",
    nationality = "",
    address = "",
    kelurahan = "",
    kecamatan = "",
    kota = "",
    lastEducation = "",
    religion = "",
    job = "",
    martialStatus = "",
    patientGroup = "",
    paymentMethod = "",
    responsibleName = "",
    responsiblePhone = "",

    // Physical Examination
    allergies = [],
    height = 0,
    weight = 0,
    kesadaran = "",
    suhu = "",
    keadaanUmum = "",
    nadi = "",
    saturasiOksigen = "",
    tekananDarah = "",
    respirasi = "",
    notesPhysicalExamination = "",
  }) =>
  async (dispatch, getState) => {
    name = name.trim();

    if (!patientId) {
      return dispatch(postPatientUpdateError("ID tidak ditemukan"));
    }
    if (name.length < 3) {
      return dispatch(postPatientUpdateError("Nama minimal 3 karakter"));
    }
    if (mobile.length < 5) {
      return dispatch(postPatientUpdateError("No HP Terlalu Pendek"));
    }
    birthDate = momentInputDateToNumberDate(birthDate);
    if (!birthDate) {
      return dispatch(postPatientUpdateError("Tanggal lahir harus diisi"));
    }
    if (!gender) {
      return dispatch(postPatientUpdateError("Silahkan pilih gender"));
    }
    if (!(gender === "m" || gender === "f")) {
      return dispatch(postPatientUpdateError("Gender Salah"));
    }

    const { isLoading } = getState().patientDetail;
    if (isLoading) return;
    try {
      dispatch(postPatientUpdateStart());
      await restPostPatientVisitPatientUpdate({
        patientId,

        noRM,
        prefix,
        name,
        suffix,
        birthLocation, // tambahan
        birthDate,
        nik, // tambahan

        mobile,
        gender,
        // Tambahan
        bloodType,
        nationality,
        address,
        kelurahan,
        kecamatan,
        kota,
        lastEducation,
        religion,
        job,
        martialStatus,
        patientGroup,
        paymentMethod,
        responsibleName,
        responsiblePhone,
        // Physical Examination
        allergies,
        height,
        weight,
        kesadaran,
        suhu,
        keadaanUmum,
        nadi,
        saturasiOksigen,
        tekananDarah,
        respirasi,
        notesPhysicalExamination,
      });
      dispatch(postPatientUpdateSuccess());
      successNotif(`Berhasil simpan perubahan ${name}`);
      if (next) next();
    } catch (err) {
      if (isValidJSON(err)) {
        return dispatch(postPatientUpdateError(JSON.parse(err).message));
      }
      dispatch(postPatientUpdateError(err));
    }
  };
