import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainLayout, GuestLayout, NeedVerifyLayout } from './layout';
import { isRoleExists, isRoleRoot } from './helper';
import { fetchGetMyUser } from './store/auth';
import { fetchGetHome } from './store/global';
import { LoaderBlock } from './component/common';
import {
  PageForbidden403,
  PageUserIsArchived,
  PageNeedPayment402,
  PageMaintenance503,
} from './page/common';

export const App = () => {
  const dispatch = useDispatch();
  const $myUser = useSelector((state) => state.myUser);
  useEffect(() => {
    const didMount = () => {
      dispatch(fetchGetMyUser());
      dispatch(fetchGetHome());
    };
    didMount();
  }, [dispatch]);

  if ($myUser.data && $myUser.data._id) {
    window.myUser = $myUser.data;
    if ($myUser.data.verifiedAt) {
      if ($myUser.data.isArchived) {
        return (
          <Fragment>
            {$myUser.isLoading && <LoaderBlock />}
            <PageUserIsArchived useLogout />
          </Fragment>
        );
      }
      if (!isRoleExists()) {
        return (
          <Fragment>
            {$myUser.isLoading && <LoaderBlock />}
            <PageForbidden403 useLogout />
          </Fragment>
        );
      }

      if ($myUser.appState.selected === 'maintenance' && !isRoleRoot()) {
        return (
          <Fragment>
            {$myUser.isLoading && <LoaderBlock />}
            <PageMaintenance503 useLogout />
          </Fragment>
        );
      }
      if ($myUser.appState.selected === 'need-payment' && !isRoleRoot()) {
        return (
          <Fragment>
            {$myUser.isLoading && <LoaderBlock />}
            <PageNeedPayment402 useLogout />
          </Fragment>
        );
      }

      return (
        <Fragment>
          {$myUser.isLoading && <LoaderBlock />}
          <MainLayout />
        </Fragment>
      );
    }
    return (
      <Fragment>
        {$myUser.isLoading && <LoaderBlock />}
        <NeedVerifyLayout />
      </Fragment>
    );
  }

  if ($myUser.isInit) {
    window.myUser = undefined;
    return (
      <Fragment>
        {$myUser.isLoading && <LoaderBlock />}
        <GuestLayout />
      </Fragment>
    );
  }

  return <LoaderBlock />;
};
