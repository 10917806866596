import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../page/common';
import { NeedVerify, EmailVerify } from '../page/auth';

export const NeedVerifyLayout = () => {
  return (
    <Switch>
      <Route exact path='/' component={NeedVerify} />
      <Route exact path='/api/auth/email-verify' component={EmailVerify} />
      <Route component={Page404} />
    </Switch>
  );
};
