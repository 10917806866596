import { createSlice } from '@reduxjs/toolkit';
import { restGetHome } from '../../api/home';

import { isValidJSON } from '../../helper';

const initialState = {
  listDepartment: [],
  listMainComplaint: [],
  listAllergic: [],
  listDiagnosis: [],
  listAdminFee: [],
  listAction: [],
  listSubstance: [],
  isLoading: false,
  error: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    getGlobalStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getGlobalSuccess(state, action) {
      const {
        listDepartment,
        listMainComplaint,
        listAllergic,
        listDiagnosis,
        listAdminFee,
        listAction,
        listSubAction,
        listSubstance,
      } = action.payload;
      state.listDepartment = listDepartment;
      state.listMainComplaint = listMainComplaint;
      state.listAllergic = listAllergic;
      state.listDepartment = listDepartment;
      state.listDiagnosis = listDiagnosis;
      state.listAdminFee = listAdminFee;
      state.listAction = listAction;
      state.listSubAction = listSubAction;
      state.listSubstance = listSubstance;
      state.listSubstance = listSubstance;

      state.isLoading = false;
      state.error = null;
    },
    getGlobalError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getGlobalStart,
  getGlobalSuccess,
  getGlobalError,
} = globalSlice.actions;

export default globalSlice.reducer;

export const fetchGetHome = () => async (dispatch, getState) => {
  const { isLoading } = getState().global;
  if (isLoading) return;
  try {
    dispatch(getGlobalStart());
    const {
      listDepartment,
      listMainComplaint,
      listAllergic,
      listDiagnosis,
      listAdminFee,
      listAction,
      listSubAction,
      listSubstance,
    } = await restGetHome();
    dispatch(
      getGlobalSuccess({
        listDepartment,
        listMainComplaint,
        listAllergic,
        listDiagnosis,
        listAdminFee,
        listAction,
        listSubAction,
        listSubstance,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      return dispatch(getGlobalError(msg));
    }
    dispatch(getGlobalError(err));
  }
};
