import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { CleaningServiceList } from './CleaningServiceList';
import { CleaningServiceCreate } from './CleaningServiceCreate';
import { CleaningServiceUpdate } from './CleaningServiceUpdate';

export const MasterCleaningServiceRoute = () => {
  const pathname = '/admin/master-cleaning-service';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={CleaningServiceList} />
      <Route
        exact
        path={`${pathname}/create`}
        component={CleaningServiceCreate}
      />
      <Route
        exact
        path={`${pathname}/update/:personId`}
        component={CleaningServiceUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
