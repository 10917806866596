import { createSlice } from '@reduxjs/toolkit';
import {
  restGetAdminFeeDetail as restAdminGetAdminFeeDetail,
  restPostAdminFeeUpdate as restAdminPostAdminFeeUpdate,
} from '../../apiAdmin/adminFee';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    name: '',
    price: 0,
  },
};

const adminFeeDetailSlice = createSlice({
  name: 'adminFeeDetail',
  initialState: initialState,
  reducers: {
    adminFeeDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    adminFeeDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
        price: 0,
      };
    },
    getAdminFeeDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getAdminFeeDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: data.name,
        price: data.price,
      };
    },
    getAdminFeeDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postAdminFeeUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postAdminFeeUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postAdminFeeUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  adminFeeDetailSetForm,
  adminFeeDetailInit,
  getAdminFeeDetailStart,
  getAdminFeeDetailSuccess,
  getAdminFeeDetailError,
  postAdminFeeUpdateStart,
  postAdminFeeUpdateSuccess,
  postAdminFeeUpdateError,
} = adminFeeDetailSlice.actions;

export default adminFeeDetailSlice.reducer;

export const fetchAdminGetAdminFeeDetail = ({ adminFeeId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().adminFeeDetail;
  if (isLoading) return;
  try {
    dispatch(getAdminFeeDetailStart());
    const data = await restAdminGetAdminFeeDetail({
      adminFeeId,
    });
    dispatch(
      getAdminFeeDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getAdminFeeDetailError(JSON.parse(err).message));
    }
    dispatch(getAdminFeeDetailError(err));
  }
};

export const fetchAdminPostAdminFeeUpdate = ({
  next,
  adminFeeId,
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!adminFeeId) {
    return dispatch(postAdminFeeUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postAdminFeeUpdateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postAdminFeeUpdateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postAdminFeeUpdateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().adminFeeDetail;
  if (isLoading) return;
  try {
    dispatch(postAdminFeeUpdateStart());
    await restAdminPostAdminFeeUpdate({
      adminFeeId,
      name,
      price,
    });
    dispatch(postAdminFeeUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postAdminFeeUpdateError(JSON.parse(err).message));
    }
    dispatch(postAdminFeeUpdateError(err));
  }
};
