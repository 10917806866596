import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
} from '../../component/common';
import {
  confirmNotif,
  momentGeneral,
  parsePersonTypeName,
  momentNumberDateToDisplayDate,
  parseDepartmentName,
} from '../../helper';
import {
  fetchAdminGetPersonList,
  fetchAdminPostPersonArchive,
} from '../../store/person';
import { PERSON_TYPE } from '../../config';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-people`,
    name: 'Master Data Pegawai Arsip',
  },
];

export const PeopleArchiveList = () => {
  const dispatch = useDispatch();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  let listDepartmentFilter = [];

  const $personList = useSelector((state) => state.personList);
  const $personDetail = useSelector((state) => state.personDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  if (queryParams.type) {
    listDepartmentFilter = listDepartment.filter(
      (dpt) => dpt.type === queryParams.type
    );
  }

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetPersonList({ ...queryParams, isArchived: true }));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      title: `Konfirmasi Kembalikan`,
      msg: `Anda yakin akan mengembalikan ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostPersonArchive({
            isArchived: false,
            personId: data._id,
            name: data.name,
            next: () => {
              dispatch(
                fetchAdminGetPersonList({ ...queryParams, isArchived: true })
              );
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($personList.isLoading || $personDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Pegawai Arsip'}
        backURL={'/admin/super-admin'}
        onRefresh={() => {
          dispatch(
            fetchAdminGetPersonList({ ...queryParams, isArchived: true })
          );
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPersonList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
            />
            <FilterSelect
              options={PERSON_TYPE}
              filterKey={`type`}
              defaultText={`Semua Tipe`}
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPersonList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
            />
            <FilterSelect
              options={listDepartmentFilter}
              filterKey={`departmentId`}
              defaultText={`Semua Departemen`}
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPersonList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Tipe</th>
                  <th>Tgl Bergabung</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Departemen</th>
                  <th>Email</th>
                  <th>Dihapus</th>
                  <th>Dihapus Oleh</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$personList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>{parsePersonTypeName(data.type)}</td>
                      <td>{momentNumberDateToDisplayDate(data.joinDate)}</td>
                      <td>{`${data.prefix} ${data.name} ${data.suffix}`}</td>
                      <td>{data.mobile}</td>
                      <td>
                        {parseDepartmentName({
                          listDepartment,
                          departmentId: data.departmentId,
                        })}
                      </td>
                      <td>{data.email}</td>
                      <td>{momentGeneral(data.deletedAt)}</td>
                      <td title={data.deletedById}>{data.deletedByName}</td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmArchive({ e, data })}
                          className='blue-text cursor-pointer font-16'
                        >
                          <i className='fa fa-reply'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPersonList({
                    ...newQueryParams,
                    isArchived: true,
                  })
                );
              }}
              totalItem={$personList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
