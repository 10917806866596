export const PERSON_TYPE = [
  {
    _id: 'unSelect',
    name: 'Belum Dipilih',
  },
  {
    _id: 'doctor',
    name: 'Dokter',
  },
  {
    _id: 'nurse',
    name: 'Perawat',
  },
  {
    _id: 'student',
    name: 'Mahasiswa',
  },
  {
    _id: 'staff',
    name: 'Staff',
  },
  {
    _id: 'cleaning-service',
    name: 'Cleaning Service',
  },
  {
    _id: 'others',
    name: 'Lainnya',
  },
];
