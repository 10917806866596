let BASE_URL = `http://localhost:4000`;

if (process.env.REACT_APP_ENVIRONMENT === 'backend-production') {
  BASE_URL = `https://ehospital.datalinkdigital.com`;
}
if (process.env.NODE_ENV === 'production') {
  BASE_URL = `https://ehospital.datalinkdigital.com`;
}

export const BASE_API = `${BASE_URL}/api`;
export const BASE_API_ROOT = `${BASE_URL}/api-root`;
export const BASE_API_ADMIN = `${BASE_URL}/api-admin`;
export const BASE_API_LOCAL = `http://localhost:3003`;

export const APP_URL = 'localhost:3000';
