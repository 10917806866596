import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { StaffList } from './StaffList';
import { StaffCreate } from './StaffCreate';
import { StaffUpdate } from './StaffUpdate';

export const MasterStaffRoute = () => {
  const pathname = '/admin/master-staff';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={StaffList} />
      <Route exact path={`${pathname}/create`} component={StaffCreate} />
      <Route
        exact
        path={`${pathname}/update/:personId`}
        component={StaffUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
