import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';

import {
  roleCreateSetForm,
  roleCreateInit,
  fetchRootPostRoleCreate,
} from '../../store/role';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-role`,
    name: 'Master Hak Akses',
  },
  {
    url: `/root/master-role/create`,
    name: 'Tambah Baru',
  },
];

export const RoleCreate = () => {
  const dispatch = useDispatch();
  const $roleCreate = useSelector((state) => state.roleCreate);
  const { form } = $roleCreate;
  const { push } = useHistory();

  useEffect(() => {
    const didMount = () => {
      dispatch(roleCreateInit());
    };
    didMount();
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchRootPostRoleCreate({
        push,
        _id: form.id,
        name: form.name,
      })
    );
  };
  return (
    <Fragment>
      {$roleCreate.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Hak Akses - Tambah Baru'}
        backURL={'/root/master-role'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.id}
                    onChange={(e) =>
                      dispatch(
                        roleCreateSetForm({
                          key: 'id',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='ID'
                    id='_id'
                    name='_id'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='_id'>
                    ID
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        roleCreateSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='name'
                    name='name'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='name'>
                    Nama
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='red-text m-b-4 right-align'>
                    {$roleCreate.error}
                  </div>
                </div>
                <div className='col s12'>
                  <button type='submit' className='btn teal m-b-20 right'>
                    Tambah Baru
                  </button>
                  <Link
                    to={`/root/master-role`}
                    className='btn grey m-b-20 right m-r-8'
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
