import { createSlice } from '@reduxjs/toolkit';
import { restPostRoleCreate as restRootPostRoleCreate } from '../../apiRoot/role';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    id: '',
    name: '',
  },
};

const roleCreateSlice = createSlice({
  name: 'roleCreate',
  initialState: initialState,
  reducers: {
    roleCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    roleCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: '',
        name: '',
      };
    },
    postRoleCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postRoleCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postRoleCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  roleCreateSetForm,
  roleCreateInit,
  postRoleCreateStart,
  postRoleCreateSuccess,
  postRoleCreateError,
} = roleCreateSlice.actions;

export default roleCreateSlice.reducer;

export const fetchRootPostRoleCreate = ({
  push,
  _id = '',
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (_id.length < 3) {
    return dispatch(postRoleCreateError('ID minimal 3 karakter'));
  }
  if (name.length < 3) {
    return dispatch(postRoleCreateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().roleCreate;
  if (isLoading) return;
  try {
    dispatch(postRoleCreateStart());
    await restRootPostRoleCreate({
      _id,
      name,
    });
    dispatch(postRoleCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/root/master-role?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postRoleCreateError(JSON.parse(err).message));
    }
    dispatch(postRoleCreateError(err));
  }
};
