export const isValidJSON = (input) => {
  if (!input) {
    return false;
  }
  try {
    if (JSON.parse(input)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const setTimeoutAsync = (timeoutMs = 3000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeoutMs);
  });
};

export const parseGenderName = (g) => {
  if (g === 'm') {
    return 'Pria';
  }
  if (g === 'f') {
    return 'Wanita';
  }
};

export const parseFullName = ({ prefix, name, suffix }) => {
  let newName = '';
  if (prefix) {
    newName += `${prefix} `;
  }
  if (name) {
    newName += name;
  }
  if (suffix) {
    newName += ` ${suffix}`;
  }
  return newName;
};

export const parsePatientVisitStatus = ({
  isCanceled,
  patientDoctorActionId,
  patientInvoiceId,
}) => {
  if (isCanceled === true) {
    return 'Batal';
  }
  if (patientInvoiceId) {
    return 'Selesai';
  }
  if (patientDoctorActionId) {
    return 'Proses';
  }
  if (!patientDoctorActionId || !patientInvoiceId) {
    return 'Menunggu';
  }

  return '';
};

export const parsePatientDoctorActionStatus = ({
  isCanceled,
  patientDoctorActionId,
  patientInvoiceId,
}) => {
  if (isCanceled === true) {
    return 'Batal';
  }
  if (patientInvoiceId) {
    return 'Selesai';
  }
  if (patientDoctorActionId) {
    return 'Proses';
  }
  if (!patientDoctorActionId) {
    return 'Menunggu';
  }

  return '';
};

export const formatNumber = (number = 0) => {
  number = Number(number);
  if (isNaN(number)) {
    return '';
  }
  return new Intl.NumberFormat('id').format(number);
};

export const formatCurrency = (number = 0) => {
  number = Number(number);
  if (isNaN(number)) {
    return 'Rp -';
  }
  return new Intl.NumberFormat('id', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(number);
};

export const pagination = (c, l) => {
  // Fungsi Helper Pembuat array Pagination
  const current = c ? c : 1;
  const firstPage = 1;
  const lastPage = l > 0 ? l : 1;
  const range = [];
  // delta merupakan jarak antar tombol
  const delta = 2;
  // maxbutton harus bernilai ganjil
  const maxButton = 5;
  let left = current - delta > 1 ? current - delta : 1;
  let right = lastPage;
  if (current + delta < lastPage) {
    right = current + delta;
  }
  if (right < maxButton) {
    right = maxButton;
  }
  if (left + current > maxButton) {
    left = right - maxButton + 1;
  }

  if (lastPage < maxButton) {
    // jika page length < 5
    right = lastPage;
    left = 1;
  }

  for (let i = left; i <= right; i++) {
    range.push(i);
  }
  if (range[0] !== firstPage) {
    range[0] = '...';
  }
  if (range[range.length - 1] !== lastPage) {
    range[range.length - 1] = '...';
  }
  return range;
};
