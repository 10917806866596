import { createSlice } from '@reduxjs/toolkit';
import {
  restGetPatientDoctorActionDetail,
  restPostPatientDoctorActionUpdate,
  restPostPatientDoctorActionCancel,
} from '../../api/patientDoctorAction';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  isLockCreateInvoice: true,
  form: {
    patientVisitId: '',
    noDU: 0,
    doctorId: '',
    doctorFullName: '',
    nurseId: '',
    nurseFullName: '',
    nurseId2: '',
    nurseFullName2: '',
    nurseId3: '',
    nurseFullName3: '',
    mainComplaint: '',
    diagnosisList: [],
    actionList: [],
    subActionList: [],
    substanceList: [],
  },
};

const patientDoctorActionDetailSlice = createSlice({
  name: 'patientDoctorActionDetail',
  initialState: initialState,
  reducers: {
    patientDoctorActionDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientDoctorActionDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.isLockCreateInvoice = true;
      state.form = {
        patientVisitId: '',
        noDU: 0,
        doctorId: '',
        doctorFullName: '',
        nurseId: '',
        nurseFullName: '',
        nurseId2: '',
        nurseFullName2: '',
        nurseId3: '',
        nurseFullName3: '',
        mainComplaint: '',
        diagnosisList: [],
        actionList: [],
        subActionList: [],
        substanceList: [],
      };
    },
    getPatientDoctorActionDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientDoctorActionDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        patientVisitId: data.patientVisitId,
        doctorId: data.doctorId,
        doctorFullName: data.doctorFullName,
        nurseId: data.nurseId,
        nurseFullName: data.nurseFullName,
        nurseId2: data.nurseId2,
        nurseFullName2: data.nurseFullName2,
        nurseId3: data.nurseId3,
        nurseFullName3: data.nurseFullName3,
        noDU: Number(data.noDU),
        mainComplaint: data.mainComplaint,
        diagnosisList: data.diagnosisList,
        actionList: data.actionList,
        subActionList: data.subActionList || [],
        substanceList: data.substanceList,
      };
    },
    getPatientDoctorActionDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPatientDoctorActionUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientDoctorActionUpdateSuccess(state) {
      state.isLockCreateInvoice = false;
      state.isLoading = false;
      state.error = null;
    },
    postPatientDoctorActionUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientDoctorActionDetailSetForm,
  patientDoctorActionDetailInit,
  getPatientDoctorActionDetailStart,
  getPatientDoctorActionDetailSuccess,
  getPatientDoctorActionDetailError,
  postPatientDoctorActionUpdateStart,
  postPatientDoctorActionUpdateSuccess,
  postPatientDoctorActionUpdateError,
} = patientDoctorActionDetailSlice.actions;

export default patientDoctorActionDetailSlice.reducer;

export const fetchGetPatientDoctorActionDetail = ({
  patientDoctorActionId,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().patientDoctorActionDetail;
  if (isLoading) return;
  try {
    dispatch(getPatientDoctorActionDetailStart());
    const data = await restGetPatientDoctorActionDetail({
      patientDoctorActionId,
    });
    dispatch(
      getPatientDoctorActionDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(
        getPatientDoctorActionDetailError(JSON.parse(err).message)
      );
    }
    dispatch(getPatientDoctorActionDetailError(err));
  }
};

export const fetchPostPatientDoctorActionUpdate = ({
  hideSuccess = false,
  next,
  patientDoctorActionId,
  patientVisitId = '',
  noDU = '',
  doctorId = '',
  doctorFullName = '',
  nurseId = '',
  nurseFullName = '',
  nurseId2 = '',
  nurseFullName2 = '',
  nurseId3 = '',
  nurseFullName3 = '',
  mainComplaint = '',
  diagnosisList = [],
  actionList = [],
  subActionList = [],
  substanceList = [],
}) => async (dispatch, getState) => {
  if (!patientVisitId) {
    return dispatch(
      postPatientDoctorActionUpdateError('ID kunjungan tidak ditemukan')
    );
  }
  if (noDU.length < 1 || noDU === '0') {
    return dispatch(postPatientDoctorActionUpdateError('noDU harus diisi'));
  }
  if (doctorId.length < 3) {
    return dispatch(postPatientDoctorActionUpdateError('Dokter harus dipilih'));
  }
  if (!doctorFullName) {
    return dispatch(
      postPatientDoctorActionUpdateError('Nama Dokter tidak ada')
    );
  }
  if (nurseId && !nurseFullName) {
    return dispatch(
      postPatientDoctorActionUpdateError('Nama Asisten tidak ada')
    );
  }
  if (nurseId2 && !nurseFullName2) {
    return dispatch(
      postPatientDoctorActionUpdateError('Nama Asisten 2 tidak ada')
    );
  }
  if (nurseId3 && !nurseFullName3) {
    return dispatch(
      postPatientDoctorActionUpdateError('Nama Asisten 3 tidak ada')
    );
  }
  mainComplaint = mainComplaint.trim();
  if (!diagnosisList || (diagnosisList && typeof diagnosisList !== 'object')) {
    return dispatch(
      postPatientDoctorActionUpdateError(`Diagnosis tidak valid`)
    );
  }
  if (!actionList || (actionList && typeof actionList !== 'object')) {
    return dispatch(postPatientDoctorActionUpdateError(`Tindakan tidak valid`));
  }
  if (actionList.length < 1) {
    return dispatch(
      postPatientDoctorActionUpdateError(`Minimal harus mengisi 1 tindakan`)
    );
  }
  if (!subActionList || (subActionList && typeof subActionList !== 'object')) {
    return dispatch(
      postPatientDoctorActionUpdateError(`Sub Tindakan tidak valid`)
    );
  }
  if (!substanceList || (diagnosisList && typeof substanceList !== 'object')) {
    return dispatch(
      postPatientDoctorActionUpdateError(`Bahan tambahan tidak valid`)
    );
  }

  const { isLoading } = getState().patientDoctorActionDetail;
  if (isLoading) return;
  try {
    dispatch(postPatientDoctorActionUpdateStart());
    await restPostPatientDoctorActionUpdate({
      patientDoctorActionId,
      patientVisitId,
      noDU,
      doctorId,
      doctorFullName,
      nurseId,
      nurseFullName,
      nurseId2,
      nurseFullName2,
      nurseId3,
      nurseFullName3,
      mainComplaint,
      diagnosisList,
      actionList,
      subActionList,
      substanceList,
    });
    dispatch(postPatientDoctorActionUpdateSuccess());
    if (!hideSuccess) {
      successNotif(`Berhasil simpan perubahan`);
    }

    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(
        postPatientDoctorActionUpdateError(JSON.parse(err).message)
      );
    }
    dispatch(postPatientDoctorActionUpdateError(err));
  }
};

export const fetchPostPatientDoctorActionCancel = ({
  next,
  patientDoctorActionId,
  patientVisitId = '',
}) => async (dispatch, getState) => {
  if (!patientVisitId) {
    return dispatch(
      postPatientDoctorActionUpdateError('ID kunjungan tidak ditemukan')
    );
  }

  const { isLoading } = getState().patientDoctorActionDetail;
  if (isLoading) return;
  try {
    dispatch(postPatientDoctorActionUpdateStart());
    await restPostPatientDoctorActionCancel({
      patientDoctorActionId,
      patientVisitId,
    });
    dispatch(postPatientDoctorActionUpdateSuccess());

    successNotif(`Berhasil simpan perubahan`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(
        postPatientDoctorActionUpdateError(JSON.parse(err).message)
      );
    }
    dispatch(postPatientDoctorActionUpdateError(err));
  }
};
