import { createSlice } from "@reduxjs/toolkit";
import {
  restGetPersonList as restAdminGetPersonList,
  restGetPersonListArchived as restAdminGetPersonListArchived,
} from "../../apiAdmin/person";
import {
  restNurseGetDoctorListAll,
  restNurseStudentListAll,
} from "../../api/patientDoctorAction";

import { isValidJSON } from "../../helper";

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  listNurseStudent: [],
  totalNurseStudent: 0,
  isLoadingNurseStudent: false,
  error: null,
};

const personListSlice = createSlice({
  name: "personList",
  initialState: initialState,
  reducers: {
    getPersonListStart(state) {
      state.listData = [];
      state.isLoading = true;
      state.error = null;
    },
    getPersonListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPersonListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNurseStudentListAllStart(state) {
      state.isLoadingNurseStudent = true;
      state.listNurseStudent = [];
      state.error = null;
    },
    getNurseStudentListAllSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listNurseStudent = listData;
      state.totalNurseStudent = totalItem;
      state.isLoadingNurseStudent = false;
      state.error = null;
    },
    getNurseStudentListAllError(state, action) {
      state.isLoadingNurseStudent = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPersonListStart,
  getPersonListSuccess,
  getPersonListError,
  getNurseStudentListAllStart,
  getNurseStudentListAllSuccess,
  getNurseStudentListAllError,
} = personListSlice.actions;

export default personListSlice.reducer;

export const fetchAdminGetPersonList = ({
  isArchived,
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  type = "",
  departmentId = "",
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().personList;
  if (isLoading) return;
  try {
    dispatch(getPersonListStart());
    let listData = [];
    let totalItem = 0;
    let resp = {};
    if (isArchived) {
      resp = await restAdminGetPersonListArchived({
        limit,
        page,
        sort,
        sortDir,
        search,
        type,
        departmentId,
      });
    } else {
      resp = await restAdminGetPersonList({
        limit,
        page,
        sort,
        sortDir,
        search,
        type,
        departmentId,
      });
    }

    listData = resp.listData;
    totalItem = resp.totalItem;

    dispatch(
      getPersonListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPersonListError(JSON.parse(err).message));
    }
    dispatch(getPersonListError(err));
  }
};

export const fetchNurseGetDoctorListAll = ({
  doctorDepartmentId = "",
}) => async (dispatch, getState) => {
  const { isLoading } = getState().personList;
  if (isLoading) return;
  try {
    dispatch(getPersonListStart());

    const { listData, totalItem } = await restNurseGetDoctorListAll({
      doctorDepartmentId,
    });

    dispatch(
      getPersonListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPersonListError(JSON.parse(err).message));
    }
    dispatch(getPersonListError(err));
  }
};

export const fetchGetNurseStudentListAll = () => async (dispatch, getState) => {
  const { isLoadingNurseStudent } = getState().personList;
  if (isLoadingNurseStudent) return;
  try {
    dispatch(getNurseStudentListAllStart());

    const { listData, totalItem } = await restNurseStudentListAll();

    dispatch(
      getNurseStudentListAllSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getNurseStudentListAllError(JSON.parse(err).message));
    }
    dispatch(getNurseStudentListAllError(err));
  }
};
