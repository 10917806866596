import { PERSON_TYPE } from '../config';

export const parsePersonType = (_id) => {
  const personType = PERSON_TYPE.find((pt) => pt._id === _id);
  if (personType) return personType;
  return {};
};

export const parsePersonTypeName = (_id) => {
  const personType = parsePersonType(_id);
  if (personType && personType.name) {
    return personType.name;
  }
  return '';
};
