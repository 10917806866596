import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  fetchRootGetRoleList,
  fetchRootPostRoleDelete,
} from '../../store/role';
import { confirmNotif, momentGeneral } from '../../helper';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-role`,
    name: 'Master Hak Akses',
  },
];

export const RoleList = () => {
  const dispatch = useDispatch();
  const $roleList = useSelector((state) => state.roleList);
  const $roleDetail = useSelector((state) => state.roleDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchRootGetRoleList(queryParams));
    };
    didMount();
  }, [dispatch]); // eslint-disable-line

  const confirmDelete = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan menghapus ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchRootPostRoleDelete({
            roleId: data._id,
            name: data.name,
            next: () => {
              dispatch(fetchRootGetRoleList(queryParams));
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($roleList.isLoading || $roleDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Hak Akses'}
        backURL={'/root'}
        onRefresh={() => {
          dispatch(fetchRootGetRoleList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetRoleList(newQueryParams));
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/root/master-role/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nama</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$roleList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={`/root/master-role/update/${data._id}`}>
                          {data._id}
                        </Link>
                      </td>
                      <td>{data.name}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmDelete({ e, data })}
                          className='red-text cursor-pointer font-16'
                        >
                          <i className='fa fa-trash'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetRoleList(newQueryParams));
              }}
              totalItem={$roleList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
