import { createSlice } from '@reduxjs/toolkit';
import {
  restGetPatientVisitDetail,
  restPostPatientVisitUpdate,
  restPostPatientVisitCancelVisit,
} from '../../api/patientVisit';
import { restGetNursePatientVisitDetail } from '../../api/patientDoctorAction';
import {
  isValidJSON,
  successNotif,
  momentNumberDateToDisplayDate,
  warningNotif,
} from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    visitDate: '',
    doctorDepartmentId: '',
    doctorDepartmentName: '',
    patientId: '',
  },
};

const patientVisitDetailSlice = createSlice({
  name: 'patientVisitDetail',
  initialState: initialState,
  reducers: {
    patientVisitDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientVisitDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        visitDate: '',
        doctorDepartmentId: '',
        doctorDepartmentName: '',
        patientId: '',
      };
    },
    getPatientVisitDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientVisitDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        visitDate: momentNumberDateToDisplayDate(data.visitDate),
        doctorDepartmentId: data.doctorDepartmentId,
        doctorDepartmentName: data.doctorDepartmentName,
        patientId: data.patientId,
      };
    },
    getPatientVisitDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPatientVisitUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientVisitUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientVisitUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientVisitDetailSetForm,
  patientVisitDetailInit,
  getPatientVisitDetailStart,
  getPatientVisitDetailSuccess,
  getPatientVisitDetailError,
  postPatientVisitUpdateStart,
  postPatientVisitUpdateSuccess,
  postPatientVisitUpdateError,
} = patientVisitDetailSlice.actions;

export default patientVisitDetailSlice.reducer;

export const fetchGetPatientVisitDetail = ({ patientVisitId, next = ()=>{} }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().patientVisitDetail;
  if (isLoading) return;
  try {
    dispatch(getPatientVisitDetailStart());
    const data = await restGetPatientVisitDetail({
      patientVisitId,
    });
    dispatch(
      getPatientVisitDetailSuccess({
        data,
      })
    );
    if(next) next(data)
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientVisitDetailError(JSON.parse(err).message));
    }
    dispatch(getPatientVisitDetailError(err));
  }
};

export const fetchPostPatientVisitUpdate = ({
  next,
  patientVisitId,
  doctorDepartmentId = '',
  doctorDepartmentName = '',
  patientId = '',
  isMove = false,
}) => async (dispatch, getState) => {
  if (doctorDepartmentId.length < 3) {
    return dispatch(postPatientVisitUpdateError('Poliklinik harus dipilih'));
  }
  if (doctorDepartmentName.length < 3) {
    return dispatch(postPatientVisitUpdateError('Poliklinik harus dipilih'));
  }
  if (!patientId) {
    return dispatch(postPatientVisitUpdateError('Pasien belum dipilih'));
  }

  const { isLoading } = getState().patientVisitDetail;
  if (isLoading) return;
  try {
    dispatch(postPatientVisitUpdateStart());
    await restPostPatientVisitUpdate({
      patientVisitId,
      doctorDepartmentId,
      doctorDepartmentName,
      patientId,
    });
    dispatch(postPatientVisitUpdateSuccess());
    if (isMove) {
      successNotif(`Berhasil pindah ke Bagian / Poli ${doctorDepartmentName}`);
    } else {
      successNotif(`Berhasil simpan perubahan ${doctorDepartmentName}`);
    }

    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPatientVisitUpdateError(JSON.parse(err).message));
    }
    dispatch(postPatientVisitUpdateError(err));
  }
};
export const fetchPostPatientVisitCancelVisit = ({
  next,
  patientVisitId,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().patientVisitDetail;
  if (isLoading) return;
  try {
    dispatch(postPatientVisitUpdateStart());
    await restPostPatientVisitCancelVisit({
      patientVisitId,
    });
    dispatch(postPatientVisitUpdateSuccess());
    warningNotif(`Berhasil batalkan kunjungan pasien`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPatientVisitUpdateError(JSON.parse(err).message));
    }
    dispatch(postPatientVisitUpdateError(err));
  }
};

export const fetchGetNursePatientVisitDetail = ({ patientVisitId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().patientVisitDetail;
  if (isLoading) return;
  try {
    dispatch(getPatientVisitDetailStart());
    const data = await restGetNursePatientVisitDetail({
      patientVisitId,
    });
    dispatch(
      getPatientVisitDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientVisitDetailError(JSON.parse(err).message));
    }
    dispatch(getPatientVisitDetailError(err));
  }
};
