import React, { Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  successNotif,
  warningNotif,
  confirmNotif,
  momentGeneral,
  isUserRoleRoot,
  isUserRoleSuperAdmin,
  isRoleReadOnly,
} from '../../../helper';
import {
  fetchAdminGetUserAccessList,
  fetchAdminPostUserAccessUpdate,
  fetchAdminPostUserAccessUnassignPerson,
} from '../../../store/userRole';
import { ROLE_LIST } from '../../../config';

// Data Roles Hardcoded dulu
const isActiveRole = (userRoles = {}, roleId) => {
  const findRole = userRoles[roleId];
  if (findRole) {
    return true;
  }
  return false;
};

const blockButton = (user, roleId) => {
  if (isRoleReadOnly()) {
    return true;
  }
  if (
    (isUserRoleRoot(user) || isUserRoleSuperAdmin(user)) &&
    roleId === 'super-admin'
  ) {
    return true;
  }
  if (
    (isUserRoleRoot(user) || isUserRoleSuperAdmin(user)) &&
    roleId === 'read-only'
  ) {
    return true;
  }
  if (roleId === 'super-admin') {
    return true;
  }
  return false;
};

export const TableRow = ({ data: rowData }) => {
  const detailURL = '/admin/master-user-role/access-person';
  const dispatch = useDispatch();
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  const setRole = ({ e, data, isAdd, roleId = '', roleName = '', isWrite }) => {
    e.preventDefault();
    if (window.myUser._id === data._id && roleId === 'root') {
      return warningNotif('Tidak bisa mengubah akses ROOT diri sendiri');
    }
    if (window.myUser._id === data._id && roleId === 'super-admin') {
      return warningNotif('Tidak bisa mengubah akses SUPER ADMIN diri sendiri');
    }
    if (blockButton(data, roleId)) {
      return;
    }
    let msg = '';
    if (isAdd) {
      msg = `Tambahkan Akses ${data.name} : `;
      msg += `"${roleName.toUpperCase()}" `;
    } else {
      msg = `Cabut Akses ${data.name} : `;
      msg += `"${roleName.toUpperCase()}": `;
    }
    confirmNotif({
      title: 'Konfirmasi',
      msg: `${msg}?`,
      onOK: () => {
        const next = () => {
          successNotif(`BERHASIL ${msg}`);
          dispatch(fetchAdminGetUserAccessList(queryParams));
        };
        dispatch(
          fetchAdminPostUserAccessUpdate({
            userId: data._id,
            isAdd,
            roleId,
            roleName,
            next,
          })
        );
      },
    });
  };
  const unsetPersonRole = ({ e, role, data }) => {
    e.preventDefault();
    if (blockButton(data, role._id)) {
      return;
    }
    let msg = '';
    msg = `Cabut Akses ${data.name} : `;
    msg += `"${role._id.toUpperCase()}": `;
    confirmNotif({
      title: 'Konfirmasi',
      msg: `${msg}?`,
      onOK: () => {
        const next = () => {
          successNotif(`BERHASIL ${msg}`);
          dispatch(fetchAdminGetUserAccessList(queryParams));
        };
        dispatch(
          fetchAdminPostUserAccessUnassignPerson({
            userId: data._id,
            personId: data.personId,
            roleId: role._id,
            next,
          })
        );
      },
    });
  };
  const parseRow = (role) => {
    if (isActiveRole(rowData.roles, role._id)) {
      if (
        role._id === 'doctor' ||
        role._id === 'nurse' ||
        role._id === 'student'
      ) {
        return (
          <td
            onClick={(e) => {
              unsetPersonRole({ e, role, data: rowData });
            }}
            className={`center-align cursor-pointer ${
              blockButton(rowData, role._id) ? '' : 'blue-text cursor-pointer'
            }`}
          >
            <i className='fa fa-check-square fa-2x'></i>
          </td>
        );
      }
      return (
        <td
          onClick={(e) =>
            setRole({
              e,
              data: rowData,
              isAdd: false,
              roleId: role._id,
              roleName: role.name,
              isWrite: false,
            })
          }
          className={`center-align ${
            blockButton(rowData, role._id) ? '' : 'blue-text cursor-pointer'
          }`}
        >
          <i className='fa fa-check-square fa-2x'></i>
        </td>
      );
    }
    if (
      role._id === 'doctor' ||
      role._id === 'nurse' ||
      role._id === 'student'
    ) {
      if (rowData.personId) {
        return (
          <td className='center-align black-text'>
            <i className='fa fa-square-o fa-2x'></i>
          </td>
        );
      }
      if (blockButton(rowData, role._id)) {
        return (
          <td className='center-align'>
            <i className='fa fa-square-o fa-2x'></i>
          </td>
        );
      }
      return (
        <td className='center-align cursor-pointer blue-text'>
          <Link
            to={`${detailURL}/${rowData._id}?type=${role._id}&userName=${rowData.name}`}
          >
            <i className='fa fa-square-o fa-2x'></i>
          </Link>
        </td>
      );
    }
    return (
      <td
        onClick={(e) =>
          setRole({
            e,
            data: rowData,
            isAdd: true,
            roleId: role._id,
            roleName: role.name,
            isWrite: false,
          })
        }
        className={`center-align ${
          blockButton(rowData, role._id) ? '' : 'blue-text cursor-pointer'
        }`}
      >
        <i className='fa fa-square-o fa-2x'></i>
      </td>
    );
  };
  return (
    <tr>
      <td title={rowData.rolesByName}>{momentGeneral(rowData.rolesAt)}</td>
      <td>{rowData.email}</td>
      <td>{rowData.name}</td>
      <td>{rowData.personFullName}</td>
      {ROLE_LIST.map((role) => {
        return <Fragment key={role._id}>{parseRow(role)}</Fragment>;
      })}
    </tr>
  );
};
