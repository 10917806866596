import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetAdminFeeList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/admin-fee/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetAdminFeeDetail({ adminFeeId }) {
  const path = `${BASE_API_ADMIN}/admin-fee/detail/${adminFeeId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostAdminFeeCreate({ _id, name, price }) {
  const path = `${BASE_API_ADMIN}/admin-fee/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      _id,
      name,
      price,
    },
  });
}

export function restPostAdminFeeUpdate({ adminFeeId, name, price }) {
  const path = `${BASE_API_ADMIN}/admin-fee/update/${adminFeeId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
      price,
    },
  });
}
