import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { MasterSubActionRoute } from '../masterSubAction';

import { ActionList } from './ActionList';
import { ActionCreate } from './ActionCreate';
import { ActionUpdate } from './ActionUpdate';

export const MasterActionRoute = () => {
  const pathname = '/admin/master-action';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={ActionList} />
      <Route exact path={`${pathname}/create`} component={ActionCreate} />
      <Route
        exact
        path={`${pathname}/update/:actionId`}
        component={ActionUpdate}
      />

      <Route
        path={`${pathname}/master-sub-action`}
        component={MasterSubActionRoute}
      />
      <Route component={Page404} />
    </Switch>
  );
};
