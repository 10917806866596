import 'whatwg-fetch';
import { BASE_API, APP_URL } from '../config';
import { warningNotif } from '../helper';

export default function fetchApi(pathUrl, options) {
  return new Promise(async (resolve, reject) => {
    let parseOptions = options;
    if (!parseOptions) {
      parseOptions = {};
    }

    await makeCredentialsHeaders(parseOptions);
    await makePostHeadersBody(parseOptions);
    await makeAuthorization(parseOptions);

    let path = `${BASE_API}${pathUrl}`;
    if (pathUrl.startsWith('http')) {
      path = pathUrl;
    }

    let httpStatus;
    window
      .fetch(path, { ...options })
      .then((resp) => {
        httpStatus = resp.status;

        autoLogoutWhenSessionExpired(resp, httpStatus);

        if (httpStatus < 200 || httpStatus > 299) {
          return resp.text();
        }

        return resp.json();
      })
      .then((resp) => {
        if (httpStatus < 200 || httpStatus > 299) {
          return reject(resp);
        }

        return resolve(resp);
      })
      .catch(() => {
        return reject(`Internet offline, mohon periksa internet anda`);
      });
  });
}

async function makeCredentialsHeaders(parseOptions) {
  parseOptions.credentials = 'include';
  if (!parseOptions.headers) {
    parseOptions.headers = {};
  }
}

async function makePostHeadersBody(parseOptions) {
  if (parseOptions.method === 'POST') {
    if (!parseOptions.multipart) {
      parseOptions.headers['Content-Type'] = 'application/json';
      parseOptions.headers['Origin'] = APP_URL;
      if (!parseOptions.body) {
        parseOptions.body = JSON.stringify({});
      } else {
        parseOptions.body = JSON.stringify(parseOptions.body);
      }
    } else {
      delete parseOptions.multipart;
    }
  }
}

async function makeAuthorization(parseOptions) {
  const sessionId = localStorage.getItem('sessionId');
  if (sessionId && sessionId !== 'undefined') {
    parseOptions.headers.Authorization = sessionId;
  } else {
    parseOptions.headers.Authorization = '';
  }
}

function autoLogoutWhenSessionExpired(resp, httpStatus) {
  const expiredSessionId = localStorage.getItem('sessionId');
  if (httpStatus === 401 && expiredSessionId) {
    localStorage.removeItem('sessionId');
    warningNotif(
      'Sesi sudah berakhir, karena nonaktif dalam waktu lama. Silahkan login kembali'
    );
  }
  if (httpStatus === 401) {
    setTimeout(() => {
      window.location.replace('/');
    }, 3000);
  }
}
