import { createSlice } from "@reduxjs/toolkit";
import { restGetPatientInvoiceDailyList } from "../../api/patientInvoice";

import { isValidJSON, arraySortCallback } from "../../helper";

const initialState = {
  listData: [],
  totalItem: 0,
  sumOf: {
    totalInvoice: 0,
    sumOfAdministrationPrice: 0,
    sumOfTotalActionPrice: 0,
    sumOfTotalSubActionPrice: 0,
    sumOfTotalSubstancePrice: 0,
    sumOfGrandTotalPrice: 0,
  },
  isLoading: false,
  error: null,
};

const patientInvoiceDailyListSlice = createSlice({
  name: "patientInvoiceDailyList",
  initialState: initialState,
  reducers: {
    getPatientInvoiceDailyListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientInvoiceDailyListSuccess(state, action) {
      const { listData, totalItem, sumOf } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.sumOf = sumOf;
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceDailyListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPatientInvoiceDailyListStart,
  getPatientInvoiceDailyListSuccess,
  getPatientInvoiceDailyListError,
} = patientInvoiceDailyListSlice.actions;

export default patientInvoiceDailyListSlice.reducer;

export const fetchGetPatientInvoiceDailyList =
  ({
    page = 1,
    sort = "createdAt",
    sortDir = "asc",
    invoiceMonth = "202105",
  }) =>
  async (dispatch, getState) => {
    const { isLoading } = getState().patientInvoiceDailyList;
    if (isLoading) return;
    try {
      dispatch(getPatientInvoiceDailyListStart());
      const { listData, totalItem } = await restGetPatientInvoiceDailyList({
        page,
        sort,
        sortDir,
        invoiceMonth,
      });
      const sortedListData = listData.sort(
        arraySortCallback({ sort: "_id", sortDir })
      );
      const sumOf = {
        totalInvoice: 0,
        sumOfAdministrationPrice: 0,
        sumOfTotalActionPrice: 0,
        sumOfTotalSubActionPrice: 0,
        sumOfTotalSubstancePrice: 0,
        sumOfGrandTotalPrice: 0,
      }
      sortedListData.forEach((d)=>{
        if(d.totalInvoice) sumOf.totalInvoice += d.totalInvoice
        if(d.sumOfAdministrationPrice) sumOf.sumOfAdministrationPrice += d.sumOfAdministrationPrice
        if(d.sumOfTotalActionPrice) sumOf.sumOfTotalActionPrice += d.sumOfTotalActionPrice
        if(d.sumOfTotalSubActionPrice) sumOf.sumOfTotalSubActionPrice += d.sumOfTotalSubActionPrice
        if(d.sumOfTotalSubstancePrice) sumOf.sumOfTotalSubstancePrice += d.sumOfTotalSubstancePrice
        if(d.sumOfGrandTotalPrice) sumOf.sumOfGrandTotalPrice += d.sumOfGrandTotalPrice
      })
      dispatch(
        getPatientInvoiceDailyListSuccess({
          listData: sortedListData,
          totalItem,
          sumOf: sumOf,
        })
      );
    } catch (err) {
      if (isValidJSON(err)) {
        return dispatch(
          getPatientInvoiceDailyListError(JSON.parse(err).message)
        );
      }
      dispatch(getPatientInvoiceDailyListError(err));
    }
  };
