import { createSlice } from '@reduxjs/toolkit';
import { restGetActionList as restAdminGetActionList } from '../../apiAdmin/action';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const actionListSlice = createSlice({
  name: 'actionList',
  initialState: initialState,
  reducers: {
    getActionListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getActionListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getActionListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getActionListStart,
  getActionListSuccess,
  getActionListError,
} = actionListSlice.actions;

export default actionListSlice.reducer;

export const fetchAdminGetActionList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  doctorDepartmentId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().actionList;
  if (isLoading) return;
  try {
    dispatch(getActionListStart());
    const { listData, totalItem } = await restAdminGetActionList({
      limit,
      page,
      sort,
      sortDir,
      search,
      doctorDepartmentId,
    });
    dispatch(
      getActionListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getActionListError(JSON.parse(err).message));
    }
    dispatch(getActionListError(err));
  }
};
