import { createSlice } from '@reduxjs/toolkit';
import { restPostConfigCreate as restRootPostConfigCreate } from '../../apiRoot/config';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    id: '',
    name: '',
    selected: '',
    options: [],
  },
};

const configCreateSlice = createSlice({
  name: 'configCreate',
  initialState: initialState,
  reducers: {
    configCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    configCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: '',
        name: '',
        selected: '',
        options: [],
      };
    },
    postConfigCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postConfigCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postConfigCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  configCreateSetForm,
  configCreateInit,
  postConfigCreateStart,
  postConfigCreateSuccess,
  postConfigCreateError,
} = configCreateSlice.actions;

export default configCreateSlice.reducer;

export const fetchRootPostConfigCreate = ({
  push,
  _id = '',
  name = '',
  selected = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (_id.length < 3) {
    return dispatch(postConfigCreateError('ID minimal 3 karakter'));
  }
  if (name.length < 3) {
    return dispatch(postConfigCreateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().configCreate;
  if (isLoading) return;
  try {
    dispatch(postConfigCreateStart());
    await restRootPostConfigCreate({
      _id,
      name,
      selected,
    });
    dispatch(postConfigCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/root/master-config?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postConfigCreateError(JSON.parse(err).message));
    }
    dispatch(postConfigCreateError(err));
  }
};
