import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Img, Label } from '../../../component/common';
import { parseRoleName, parseDepartmentName } from '../../../helper';

const Div = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 12px;

  .basic {
  }
  .info {
    margin-left: 16px;
    font-size: 12px;
    width: 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  }
  .photo-container {
    width: 20%;
    min-height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  :global(.home-avatar) {
    height: 6em;
    width: 6em;
  }
  .label {
    min-width: 14px;
  }

  .basic.card-panel {
    margin-bottom: 0px;
  }

  .header.card-panel {
    margin-top: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Profile = () => {
  const $myUser = useSelector((state) => state.myUser);
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const { data } = $myUser;
  const rolesArr = data.roles ? Object.keys(data.roles) : [];
  return (
    <Div className='basic card-panel' v>
      <div className='photo-container'>
        <Img className='home-avatar circle' />
      </div>
      <div className='info'>
        <div className='field font-18'>
          <i className='label fa fa-user' />
          <span>{data.name}</span>
        </div>
        <div className='field font-18'>
          <i className='label fa fa-envelope' />
          <span>{` ${data.email}`}</span>
        </div>
        <div className='field font-18 m-v-8'>
          <i className='label fa fa-lock' />
          {rolesArr.map((r) => (
            <Label
              key={r}
              color='teal'
              text={parseRoleName({
                roleId: r,
              })}
            />
          ))}
        </div>
        {data.personId && (
          <Fragment>
            <div className='field font-18'>
              <i className='label fa fa-address-card-o' />
              <span>{` ${data.personFullName}`}</span>
            </div>
            <div className='field font-18'>
              <i className='label fa fa-briefcase' />
              <span>
                {`${parseDepartmentName({
                  listDepartment,
                  departmentId: data.personDepartmentId,
                })}`}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </Div>
  );
};
