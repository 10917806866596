import fetchApi from '../api/fetchApi';
import { BASE_API } from '../config';

// GET
export function restGetPatientDoctorActionList({
  limit = 10,
  page = 1,
  sort = 'visitDate',
  sortDir = 'desc',
  search = '',
  patientId = '',
}) {
  let path = `${BASE_API}/patient-doctor-action/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&patientId=${patientId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restNurseGetPatientVisitList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  visitDate = '',
  doctorDepartmentId = '',
}) {
  let path = `${BASE_API}/patient-doctor-action/patient-visit-list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&visitDate=${visitDate}`;
  path += `&doctorDepartmentId=${doctorDepartmentId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}

export function restNurseGetDoctorListAll({ doctorDepartmentId = '' }) {
  let path = `${BASE_API}/patient-doctor-action/doctor-list-all`;
  path += `?doctorDepartmentId=${doctorDepartmentId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restNurseStudentListAll() {
  let path = `${BASE_API}/patient-doctor-action/nurse-student-list-all`;
  return fetchApi(path, {
    method: 'GET',
  });
}

export function restGetPatientDoctorActionDetail({ patientDoctorActionId }) {
  const path = `${BASE_API}/patient-doctor-action/detail/${patientDoctorActionId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetPatientDetail({ patientId }) {
  const path = `${BASE_API}/patient-doctor-action/patient/${patientId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetNursePatientVisitDetail({ patientVisitId }) {
  const path = `${BASE_API}/patient-doctor-action/patient-visit/${patientVisitId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostPatientDoctorActionCreate({
  patientVisitId,
  doctorId,
  doctorFullName,
}) {
  const path = `${BASE_API}/patient-doctor-action/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      patientVisitId,
      doctorId,
      doctorFullName,
      nurseId: '',
      nurseFullName: '',
      nurseId2: '',
      nurseFullName2: '',
      nurseId3: '',
      nurseFullName3: '',
    },
  });
}

export function restPostPatientDoctorActionUpdate({
  patientDoctorActionId,
  patientVisitId,
  doctorId,
  doctorFullName,
  nurseId,
  nurseFullName,
  nurseId2,
  nurseFullName2,
  nurseId3,
  nurseFullName3,
  noDU,
  mainComplaint,
  diagnosisList,
  actionList,
  subActionList,
  substanceList,
}) {
  const path = `${BASE_API}/patient-doctor-action/update/${patientDoctorActionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      patientVisitId,
      doctorId,
      doctorFullName,
      nurseId,
      nurseFullName,
      nurseId2,
      nurseFullName2,
      nurseId3,
      nurseFullName3,
      noDU,
      mainComplaint,
      diagnosisList,
      actionList,
      subActionList,
      substanceList,
    },
  });
}

export function restPostPatientDoctorActionCancel({
  patientDoctorActionId,
  patientVisitId,
}) {
  const path = `${BASE_API}/patient-doctor-action/cancel-action/${patientDoctorActionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      patientVisitId,
    },
  });
}

export function restPostPatientDoctorActionReturnRm({
  patientDoctorActionId,
  isReturnedRM,
}) {
  const path = `${BASE_API}/patient-doctor-action/return-rm/${patientDoctorActionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isReturnedRM,
    },
  });
}

export function restPostPatientUpdate({
  patientId,
  prefix,
  name,
  suffix,
  birthLocation, // tambahan
  birthDate,

  mobile,
  gender,
  // Tambahan
  bloodType,
  nationality,
  address,
  kelurahan,
  kecamatan,
  kota,
  lastEducation,
  religion,
  job,
  martialStatus,
  patientGroup,
  paymentMethod,
  responsibleName,
  responsiblePhone,
  // Physical Examination
  allergies,
  height,
  weight,
  kesadaran,
  suhu,
  keadaanUmum,
  nadi,
  saturasiOksigen,
  tekananDarah,
  respirasi,
  notesPhysicalExamination,
}) {
  const path = `${BASE_API}/patient-doctor-action/patient-update/${patientId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      prefix,
      name,
      suffix,
      birthLocation, // tambahan
      birthDate,

      mobile,
      gender,
      // Tambahan
      bloodType,
      nationality,
      address,
      kelurahan,
      kecamatan,
      kota,
      lastEducation,
      religion,
      job,
      martialStatus,
      patientGroup,
      paymentMethod,
      responsibleName,
      responsiblePhone,
      // Physical Examination
      allergies,
      height,
      weight,
      kesadaran,
      suhu,
      keadaanUmum,
      nadi,
      saturasiOksigen,
      tekananDarah,
      respirasi,
      notesPhysicalExamination,
    },
  });
}
