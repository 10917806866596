import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import { LoaderBlock, HeaderNav, BreadCrumb, SearchSort, Pagination, Label } from "../../component/common";
import { parsePatientVisitStatus, parsePatientDoctorActionStatus, parseDepartmentName, momentCountAge, momentNumberDateToDisplayDate, momentNumberDateToDisplayFullDate, parseFullName, confirmNotif, warningNotif, isRoleStudentReadOnly } from "../../helper";
import {
  fetchGetPatientDoctorActionList,
  fetchGetPatientDetail,
  // CREATE
  fetchPostPatientDoctorActionCreate,
  patientDoctorActionCreateInit,
  patientDoctorActionCreateSetForm,
  fetchPostPatientDoctorActionReturnRm,
} from "../../store/patientDoctorAction";
import { fetchNurseGetDoctorListAll } from "../../store/person";
import {
  fetchGetNursePatientVisitDetail, // Pindah Department
  patientVisitDetailSetForm,
  fetchPostPatientVisitUpdate,
} from "../../store/patientVisit";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = (doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, locationSearch) => {
  return [
    {
      url: `/patient-doctor-action`,
      name: "Bagian / Poli",
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}${locationSearch}`,
      name: doctorDepartmentName,
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`,
      name: "Pasien",
    },
  ];
};
const backURL = (doctorDepartmentId, locationSearch) => `/patient-doctor-action/department/${doctorDepartmentId}${locationSearch}`;
const detailURL = (doctorDepartmentId, patientId, patientVisitId, patientDoctorActionId, locationSearch) => `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}/action/${patientDoctorActionId}${locationSearch}`;
const patientEditURL = (doctorDepartmentId, patientId, patientVisitId, locationSearch) => `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}/update${locationSearch}`;

export const PatientDoctorActionList = () => {
  const { push } = useHistory();
  const { doctorDepartmentId, patientId, patientVisitId } = useParams();
  const dispatch = useDispatch();

  const $patientDoctorActionList = useSelector((state) => state.patientDoctorActionList);
  const $patientVisitDetail = useSelector((state) => state.patientVisitDetail);
  const $personList = useSelector((state) => state.personList);
  const $patientDoctorActionCreate = useSelector((state) => state.patientDoctorActionCreate);
  const { form } = $patientDoctorActionCreate;

  const { patientData } = $patientDoctorActionList;
  const { search: locationSearch } = useLocation();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter((dpt) => dpt.type === "doctor");
  const queryParams = queryString.parse(locationSearch);
  const doctorDepartmentName = parseDepartmentName({
    listDepartment: listDepartmentFilter,
    departmentId: doctorDepartmentId,
  });
  const currentActionDoctor = $patientDoctorActionList.listData.find((da) => da.patientVisitId === patientVisitId);

  const getListData = (queryParams, patientId) => {
    dispatch(fetchGetPatientDoctorActionList({ ...queryParams, patientId }));
  };
  const getAllData = (queryParams, patientId) => {
    getListData(queryParams, patientId);
    dispatch(fetchGetPatientDetail({ patientId }));
    dispatch(fetchGetNursePatientVisitDetail({ patientVisitId }));
    dispatch(fetchNurseGetDoctorListAll({ doctorDepartmentId }));
    dispatch(patientDoctorActionCreateInit());
  };

  const generateDoctorAction = (e) => {
    e.preventDefault();
    if (
      parsePatientVisitStatus({
        isCanceled: $patientVisitDetail.data.isCanceled,
        patientDoctorActionId: $patientVisitDetail.data.patientDoctorActionId,
        patientInvoiceId: $patientVisitDetail.data.patientInvoiceId,
      }) !== "Menunggu"
    ) {
      return;
    }
    dispatch(
      fetchPostPatientDoctorActionCreate({
        patientVisitId,
        doctorId: form.doctorId,
        doctorFullName: form.doctorFullName,
        next: () => {
          getListData(queryParams, patientId);
        },
      })
    );
  };

  const changePoli = (e) => {
    e.preventDefault();
    if ($patientVisitDetail.data.doctorDepartmentId === $patientVisitDetail.form.doctorDepartmentId) {
      return warningNotif("Poli / Bagian tidak dapat dipindah karena sama");
    }
    dispatch(
      fetchPostPatientVisitUpdate({
        patientVisitId: $patientVisitDetail.data._id,
        patientId: $patientVisitDetail.data.patientId,
        doctorDepartmentId: $patientVisitDetail.form.doctorDepartmentId,
        doctorDepartmentName: $patientVisitDetail.form.doctorDepartmentName,
        isMove: true,
        next: () => {
          push(backURL(doctorDepartmentId, locationSearch));
        },
      })
    );
  };

  const changeReturnRm = (patientDoctorActionId, isReturnedRM) => {
    confirmNotif({
      title: "Konfirmasi",
      msg: `Anda yakin rubah status ?`,
      onOK: () => {
        dispatch(
          fetchPostPatientDoctorActionReturnRm({
            patientDoctorActionId,
            isReturnedRM,
            next: () => {
              getListData(queryParams, patientId);
            },
          })
        );
      },
    });
  };

  useEffect(() => {
    const didMount = () => {
      getAllData(queryParams, patientId);
    };
    didMount();
  }, []); // eslint-disable-line

  const doCheckChangeDepartment = () => {
    return $patientVisitDetail.data.doctorDepartmentId !== $patientVisitDetail.form.doctorDepartmentId;
  };

  const doBlockButton = () => {
    return (
      parsePatientVisitStatus({
        isCanceled: $patientVisitDetail.data.isCanceled,
        patientDoctorActionId: $patientVisitDetail.data.patientDoctorActionId,
        patientInvoiceId: $patientVisitDetail.data.patientInvoiceId,
      }) !== "Menunggu" || isRoleStudentReadOnly()
    );
  };

  const uiSubActionList = (subActionList, actionId) => {
    let subActionListFilter = [];
    if (subActionList && subActionList.length > 0) {
      subActionListFilter = subActionList.filter((d) => d.actionId === actionId);
    }
    if (subActionListFilter && subActionListFilter.length > 0) {
      return (
        <ul className="m-0 m-l-16">
          {subActionListFilter.map((sa, idx) => (
            <li key={idx}>
              <i className="fa fa-circle-o"></i>
              {` `}
              {sa.name}
            </li>
          ))}
        </ul>
      );
    }
    return <span />;
  };
  return (
    <Fragment>
      {($global.isLoading || $patientDoctorActionList.isLoading || $patientDoctorActionList.isPatientLoading || $patientVisitDetail.isLoading || $personList.isLoading || $patientDoctorActionCreate.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Pasien"}
        backURL={backURL(doctorDepartmentId, locationSearch)}
        onRefresh={() => {
          getListData(queryParams, patientId);
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData(doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, locationSearch)} />
      <Div className="container">
        {/* PATIENT */}
        <div className="card m-t-0">
          <div className="card-content p-0">
            <div className="row">
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="100">
                        No RM
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.noRM}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Nama
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">
                        {patientData.gender === "f" ? <i className="fa fa-female"></i> : <i className="fa fa-male"></i>}
                        {` `}
                        {patientData.prefix} {patientData.name} {patientData.suffix}
                        {` `}
                        <Link to={patientEditURL(doctorDepartmentId, patientId, patientVisitId, locationSearch)} className="btn btn-teal btn-tiny">
                          <i className="fa fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tgl Lahir</td>
                      <td className="p-v-4">:</td>

                      <td className="p-v-4">
                        {momentNumberDateToDisplayDate(patientData.birthDate)} / {momentCountAge(patientData.birthDate) ? `${momentCountAge(patientData.birthDate)} tahun` : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">NIK</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.nik}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        No HP
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.mobile}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Pasien Baru?
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.isNewPatient ? <i className="fa fa-check-square"></i> : <i className="fa fa-square-o"></i>}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="150">
                        Alergi
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.allergies && patientData.allergies.map((aName) => <Label key={aName} text={aName} />)}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="150" title="Tinggi Badan / Berat Badan">
                        TB / BB
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">
                        {patientData.height} / {patientData.weight}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Kesadaran</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.kesadaran}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Suhu / Nadi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">
                        {patientData.suhu} / {patientData.nadi}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tekanan Darah</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.tekananDarah}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Respirasi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.respirasi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-content p-0">
            <SearchSort
              onChange={(newQueryParams) => {
                getListData(newQueryParams, patientId);
              }}
              sortOptions={[
                {
                  key: "createdAt",
                  name: "Tanggal Kunjungan",
                },
              ]}
              defaultSort="createdAt"
              defaultSortDir="desc"
            />
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>Tgl</th>
                  <th>Bagian</th>
                  <th>Dokter</th>
                  <th>Asisten</th>
                  <th>Keluhan</th>
                  <th>Diagnosis</th>
                  <th>Tindakan</th>
                  <th>Bahan Tambahan</th>
                  <th>Status</th>
                  <th>RM Kembali?</th>
                </tr>
              </thead>

              <tbody>
                {/* ROW WITH ACTION */}
                {(!currentActionDoctor || !currentActionDoctor._id) && (
                  <tr>
                    <td>{momentNumberDateToDisplayFullDate($patientVisitDetail.data.visitDate)}</td>
                    <td>
                      <select
                        value={$patientVisitDetail.form.doctorDepartmentId}
                        onChange={(e) => {
                          const dpt = listDepartmentFilter.find((p) => p._id === e.target.value);
                          dispatch(
                            patientVisitDetailSetForm({
                              key: "doctorDepartmentId",
                              value: dpt._id,
                            })
                          );
                          dispatch(
                            patientVisitDetailSetForm({
                              key: "doctorDepartmentName",
                              value: dpt.name,
                            })
                          );
                        }}
                        id="departmentId"
                        name="departmentId"
                        className="browser-default validate m-t-8"
                      >
                        {listDepartmentFilter.map((dpt) => (
                          <option key={dpt._id} value={dpt._id}>
                            {dpt.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        value={form.doctorId}
                        onChange={(e) => {
                          const person = $personList.listData.find((p) => p._id === e.target.value);
                          dispatch(
                            patientDoctorActionCreateSetForm({
                              key: "doctorId",
                              value: person && person._id ? person._id : "",
                            })
                          );
                          dispatch(
                            patientDoctorActionCreateSetForm({
                              key: "doctorFullName",
                              value: person && person._id ? parseFullName(person) : "",
                            })
                          );
                        }}
                        disabled={doCheckChangeDepartment()}
                        id="doctorId"
                        name="doctorId"
                        className="browser-default validate m-t-8"
                      >
                        <option value="" name="">
                          Pilih Dokter
                        </option>
                        {$personList.listData.map((person) => (
                          <option key={person._id} value={person._id}>
                            {parseFullName(person)}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td colSpan="5">
                      {doCheckChangeDepartment() ? (
                        <Fragment>
                          <button disabled={doBlockButton()} onClick={changePoli} type="button" className="btn btn-small purple">
                            Pindah Bagian / Poli
                          </button>
                          <span className="red-text m-l-16">{$patientDoctorActionCreate.error}</span>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <button disabled={doBlockButton()} onClick={generateDoctorAction} type="button" className="btn btn-small teal">
                            Konfirmasi Dokter &amp; Bagian / Poli
                          </button>
                          <span className="red-text m-l-16">{$patientDoctorActionCreate.error}</span>
                        </Fragment>
                      )}
                    </td>
                    <td colSpan="2">
                      {parsePatientVisitStatus({
                        isCanceled: $patientVisitDetail.data.isCanceled,
                        patientDoctorActionId: $patientVisitDetail.data.patientDoctorActionId,
                        patientInvoiceId: $patientVisitDetail.data.patientInvoiceId,
                      })}
                    </td>
                  </tr>
                )}
                {/* ROW WITHOUT ACTION */}
                {$patientDoctorActionList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={detailURL(doctorDepartmentId, patientId, patientVisitId, data._id, locationSearch)}>{momentNumberDateToDisplayFullDate(data.visitDate)}</Link>
                      </td>
                      <td>{data.doctorDepartmentName}</td>
                      <td>{data.doctorFullName}</td>
                      <td>
                        <div>{data.nurseFullName}</div>
                        {data.nurseFullName2 && <div>{data.nurseFullName2}</div>}
                        {data.nurseFullName3 && <div>{data.nurseFullName3}</div>}
                      </td>
                      <td>{data.mainComplaint}</td>
                      <td>
                        <ul className="m-0">
                          {data.diagnosisList.map((d) => (
                            <li key={d._id}>
                              <i className="fa fa-circle"></i>
                              {` `}
                              {d.icd10} {d.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul className="m-0">
                          {data.actionList.map((d) => (
                            <li key={d._id}>
                              <i className="fa fa-circle"></i>
                              {` `}
                              {d.name}
                              {uiSubActionList(data.subActionList, d._id)}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul className="m-0">
                          {data.substanceList.map((d) => (
                            <li key={d._id}>
                              <i className="fa fa-circle"></i>
                              {` `}
                              {d.qty} {d.unit} {d.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        {parsePatientDoctorActionStatus({
                          isCanceled: data.isCanceled,
                          patientDoctorActionId: data._id,
                          patientInvoiceId: data.patientInvoiceId,
                        })}
                      </td>
                      <td className="blue-text center-align cursor-pointer">
                        {data.isReturnedRM ? (
                          <i
                            onClick={() => {
                              changeReturnRm(data._id, "false");
                            }}
                            className="fa fa-check-square-o"
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              changeReturnRm(data._id, "true");
                            }}
                            className="fa fa fa-square-o"
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                getListData(newQueryParams, patientId);
              }}
              totalItem={$patientDoctorActionList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
