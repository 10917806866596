import patientInvoiceList from "./patientInvoiceList";
import patientInvoiceCreate from "./patientInvoiceCreate";
import patientInvoiceDetail from "./patientInvoiceDetail";

import patientInvoiceDailyList from "./patientInvoiceDailyList";
import patientInvoiceDailyDetail from "./patientInvoiceDailyDetail";

export * from "./patientInvoiceList";
export * from "./patientInvoiceCreate";
export * from "./patientInvoiceDetail";

export * from "./patientInvoiceDailyList";
export * from "./patientInvoiceDailyDetail";

const patientInvoiceReducers = {
  patientInvoiceList,
  patientInvoiceCreate,
  patientInvoiceDetail,
  patientInvoiceDailyList,
  patientInvoiceDailyDetail,
};

export default patientInvoiceReducers;
