import personList from './personList';
import personCreate from './personCreate';
import personDetail from './personDetail';

export * from './personList';
export * from './personCreate';
export * from './personDetail';

const personReducers = {
  personList,
  personCreate,
  personDetail,
};

export default personReducers;
