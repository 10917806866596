import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { fetchPostAuthLogout } from '../../store/auth';

export const PageMaintenance503 = ({
  useLogout = false,
  showAdminList = false,
}) => {
  console.warn(showAdminList);
  const dispatch = useDispatch();

  function onLogout(e) {
    e.preventDefault();

    dispatch(fetchPostAuthLogout());
  }

  return (
    <Fragment>
      <div className='full-center-container'>
        <h1 className='m-b-0'>503</h1>
        <h4 className='m-t-0 m-b-0'>Maintenence Server</h4>
        <p>
          Maaf sedang "Maintenence Server", silahkan tunggu beberapa saat lagi
          (15 menit - 1 jam), lalu gunakan kembali aplikasi.
        </p>
        {!useLogout && (
          <Link to='/'>
            <button className='btn'>Halaman Utama</button>
          </Link>
        )}
        {useLogout && (
          <button onClick={onLogout} className='btn'>
            Kembali ke Login
          </button>
        )}
      </div>
    </Fragment>
  );
};
