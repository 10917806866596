import React, { Fragment } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  padding: 4px;
  margin-right: 8px;
`;

export const Badge = ({
  text = '',
  color = 'light-blue',
  textColor = 'white',
  onClose = () => {
    console.warn('close function not found');
  },
}) => {
  if (text) {
    return (
      <Span className={`label ${color} ${textColor}-text`}>
        <i onClick={onClose} className="fa fa-times cursor-pointer m-r-4" />
        {text}
      </Span>
    );
  }

  return <Fragment />;
};
