import { createSlice } from '@reduxjs/toolkit';
import { restPostAdminFeeCreate as restAdminPostAdminFeeCreate } from '../../apiAdmin/adminFee';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    _id: '',
    name: '',
    price: 0,
  },
};

const adminFeeCreateSlice = createSlice({
  name: 'adminFeeCreate',
  initialState: initialState,
  reducers: {
    adminFeeCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    adminFeeCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        _id: '',
        name: '',
        price: 0,
      };
    },
    postAdminFeeCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postAdminFeeCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postAdminFeeCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  adminFeeCreateSetForm,
  adminFeeCreateInit,
  postAdminFeeCreateStart,
  postAdminFeeCreateSuccess,
  postAdminFeeCreateError,
} = adminFeeCreateSlice.actions;

export default adminFeeCreateSlice.reducer;

export const fetchAdminPostAdminFeeCreate = ({
  next,
  _id = '',
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();

  if (_id.length < 3) {
    return dispatch(postAdminFeeCreateError('ID minimal 3 karakter'));
  }
  if (name.length < 3) {
    return dispatch(postAdminFeeCreateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postAdminFeeCreateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postAdminFeeCreateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().adminFeeCreate;
  if (isLoading) return;
  try {
    dispatch(postAdminFeeCreateStart());
    await restAdminPostAdminFeeCreate({
      _id,
      name,
      price,
    });
    dispatch(postAdminFeeCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postAdminFeeCreateError(JSON.parse(err).message));
    }
    dispatch(postAdminFeeCreateError(err));
  }
};
