import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { DiagnosisList } from './DiagnosisList';
import { DiagnosisCreate } from './DiagnosisCreate';
import { DiagnosisUpdate } from './DiagnosisUpdate';

export const MasterDiagnosisRoute = () => {
  const pathname = '/admin/master-diagnosis';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={DiagnosisList} />
      <Route exact path={`${pathname}/create`} component={DiagnosisCreate} />
      <Route
        exact
        path={`${pathname}/update/:diagnosisId`}
        component={DiagnosisUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
