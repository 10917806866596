import React from "react";
import { Switch, Route } from "react-router-dom";

import { Page404 } from "../../page/common";
import { SubstanceArchiveList } from "./SubstanceArchiveList";

export const MasterSubstanceArchiveRoute = () => {
  const pathname = "/admin/super-admin/master-substance-archive";
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={SubstanceArchiveList} />
      <Route component={Page404} />
    </Switch>
  );
};
