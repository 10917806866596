import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { BreadCrumb, HeaderNav, LoaderBlock } from "../../component/common";
import {
  patientInvoiceDailyDetailInit,
  fetchGetPatientInvoiceDailyDetail,
} from "../../store/patientInvoice";
import { momentNumberDateToDisplayDate, formatCurrency } from "../../helper";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .card-content {
    padding-right: 1px !important;
  }
  .scroll-x {
    overflow-x: scroll;
  }
  td {
    padding: 4px 4px !important;
  }
`;

const breadCrumbData = (locationSearch) => {
  return [
    {
      url: `/patient-invoice-daily${locationSearch}`,
      name: "Rekap Kwitansi Harian",
    },
    {
      url: `/patient-invoice/detail`,
      name: "Detail",
    },
  ];
};
const backURL = (locationSearch) => `/patient-invoice-daily${locationSearch}`;

export const PatientInvoiceDailyDetail = () => {
  const dispatch = useDispatch();
  const { search: locationSearch } = useLocation();
  const $patientInvoiceDailyDetail = useSelector(
    (state) => state.patientInvoiceDailyDetail
  );

  const { listDataGroup, sumOf, totalItem } = $patientInvoiceDailyDetail;
  const { invoiceDate } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(patientInvoiceDailyDetailInit());
      if (invoiceDate) {
        dispatch(fetchGetPatientInvoiceDailyDetail({ invoiceDate }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const uiSubActionList = (subActionList, actionId) => {
    let subActionListFilter = [];
    if (subActionList && subActionList.length > 0) {
      subActionListFilter = subActionList.filter(
        (d) => d.actionId === actionId
      );
    }
    if (subActionListFilter && subActionListFilter.length > 0) {
      return (
        <ul className="m-0 m-l-16">
          {subActionListFilter.map((sa, idx) => (
            <li key={idx}>
              <i className="fa fa-circle-o"></i>
              {` `}
              {sa.name}
            </li>
          ))}
        </ul>
      );
    }
    return " ";
  };
  return (
    <Fragment>
      {$patientInvoiceDailyDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={`Rekap Kwitansi ${momentNumberDateToDisplayDate(
          invoiceDate
        )}`}
        backURL={backURL(locationSearch)}
        onRefresh={() => {
          dispatch(patientInvoiceDailyDetailInit());
          if (invoiceDate) {
            dispatch(fetchGetPatientInvoiceDailyDetail({ invoiceDate }));
          }
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData(locationSearch)} />
      <Div className="container">
        {Object.keys(listDataGroup).map((doctorDepartmentId) => {
          const department = listDataGroup[doctorDepartmentId];
          return (
            <div  key={doctorDepartmentId} className="card m-t-0 m-b-8">
              <div className="card-content p-0">
                <h5 className="p-0 inline-block">
                  {department.doctorDepartmentName}
                  <small className="m-l-16">
                    {department.totalItem} Invoice
                  </small>
                </h5>

                <div className="scroll-x">
                  <table className="table font-12">
                    <thead>
                      <tr>
                        <th>No Invoice</th>
                        <th>Dokter</th>
                        <th>Asisten</th>
                        <th>No RM</th>
                        <th>Nama Pasien</th>
                        <th>Status Pasien</th>
                        <th>Biaya Administrasi</th>
                        <th>Tindakan</th>
                        <th>Biaya Tindakan</th>
                        <th>Biaya SubTindakan</th>
                        <th>Bahan</th>
                        <th>Biaya Bahan</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {department.listData.map((invoice) => {
                        return (
                          <tr key={invoice._id}>
                            <td>{invoice.invoiceNo}</td>
                            <td>{invoice.doctorFullName}</td>
                            <td>
                              {invoice && invoice.nurseFullName ? (
                                <div>*{invoice.nurseFullName}</div>
                              ) : (
                                ""
                              )}
                              {invoice && invoice.nurseFullName2 ? (
                                <div>*{invoice.nurseFullName2}</div>
                              ) : (
                                ""
                              )}
                              {invoice && invoice.nurseFullName3 ? (
                                <div>*{invoice.nurseFullName3}</div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{invoice.patientNoRM}</td>
                            <td>{invoice.patientFullName}</td>
                            <td>
                              {" "}
                              {invoice.isNewPatient
                                ? "Pasien Baru"
                                : "Pasien Lama"}
                            </td>
                            <td>
                              {invoice.administrationPrice
                                ? formatCurrency(invoice.administrationPrice)
                                : formatCurrency(0)}
                            </td>
                            <td>
                              {invoice.actionList &&
                                invoice.actionList.map((act) => {
                                  return (
                                    <ol key={act._id} className="p-l-0">
                                      <li>
                                        {act.name}
                                        {uiSubActionList(
                                          invoice.subActionList,
                                          act._id
                                        )}
                                      </li>
                                    </ol>
                                  );
                                })}
                            </td>
                            <td>
                              {invoice.totalActionPrice
                                ? formatCurrency(invoice.totalActionPrice)
                                : formatCurrency(0)}
                            </td>
                            <td>
                              {invoice.totalSubActionPrice
                                ? formatCurrency(invoice.totalSubActionPrice)
                                : formatCurrency(0)}
                            </td>
                            <td>
                              {invoice.substanceList &&
                                invoice.substanceList.map((sub) => {
                                  return (
                                    <ol key={sub._id} className="p-l-0">
                                      <li>{sub.name}</li>
                                    </ol>
                                  );
                                })}
                            </td>
                            <td>
                              {invoice.totalSubstancePrice
                                ? formatCurrency(invoice.totalSubstancePrice)
                                : formatCurrency(0)}
                            </td>
                            <td>
                              {invoice.grandTotalPrice
                                ? formatCurrency(invoice.grandTotalPrice)
                                : formatCurrency(0)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="6" className="right-align">
                          TOTAL
                        </th>
                        <th>
                          {department.sumOf.administrationPrice
                            ? formatCurrency(
                                department.sumOf.administrationPrice
                              )
                            : formatCurrency(0)}
                        </th>
                        <th></th>
                        <th>
                          {department.sumOf.totalActionPrice
                            ? formatCurrency(department.sumOf.totalActionPrice)
                            : formatCurrency(0)}
                        </th>
                        <th>
                          {department.sumOf.totalSubActionPrice
                            ? formatCurrency(
                                department.sumOf.totalSubActionPrice
                              )
                            : formatCurrency(0)}
                        </th>
                        <th></th>
                        <th>
                          {department.sumOf.totalSubstancePrice
                            ? formatCurrency(
                                department.sumOf.totalSubstancePrice
                              )
                            : formatCurrency(0)}
                        </th>
                        <th>
                          {department.sumOf.grandTotalPrice
                            ? formatCurrency(department.sumOf.grandTotalPrice)
                            : formatCurrency(0)}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          );
        })}

        <div className="card m-t-0 m-b-8">
          <div className="card-content p-0">
            <h5 className="p-0 inline-block">
              Total Keseluruhan Bagian
              <small className="m-l-16">{totalItem} Invoice</small>
            </h5>

            <table className="table font-12">
              <thead>
                <tr>
                  <th>Biaya Administrasi</th>

                  <th>Biaya Tindakan</th>
                  <th>Biaya SubTindakan</th>

                  <th>Biaya Bahan</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>
                    {sumOf.administrationPrice
                      ? formatCurrency(sumOf.administrationPrice)
                      : formatCurrency(0)}
                  </th>

                  <th>
                    {sumOf.totalActionPrice
                      ? formatCurrency(sumOf.totalActionPrice)
                      : formatCurrency(0)}
                  </th>
                  <th>
                    {sumOf.totalSubActionPrice
                      ? formatCurrency(sumOf.totalSubActionPrice)
                      : formatCurrency(0)}
                  </th>

                  <th>
                    {sumOf.totalSubstancePrice
                      ? formatCurrency(sumOf.totalSubstancePrice)
                      : formatCurrency(0)}
                  </th>
                  <th>
                    {sumOf.grandTotalPrice
                      ? formatCurrency(sumOf.grandTotalPrice)
                      : formatCurrency(0)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
