import AWN from 'awesome-notifications';
// Set global options
const globalOptions = {
  position: 'top-right',
};
// Initialize instance of AWN
const notifier = new AWN(globalOptions);
//usage https://f3oall.github.io/awesome-notifications/docs/how-to-use.html
export function successNotif(msg) {
  return notifier.success(msg);
}
export function infoNotif(msg) {
  return notifier.info(msg);
}
export function warningNotif(msg) {
  return notifier.warning(msg);
}
export function errorNotif(msg) {
  return notifier.alert(msg);
}

export function alertNotif({ title, msg }) {
  const notifier = new AWN();
  notifier.confirm(msg, () => {}, false, {
    labels: {
      confirm: title,
      confirmOk: 'Ya',
      confirmCancel: 'Tidak',
    },
    icons: {
      enabled: false,
    },
  });
}

export function confirmNotif({
  title = 'Konfirmasi Hapus',
  msg = 'Yakin hapus?',
  onOK,
  noText = 'Cancel',
  yesText = 'Ya',
  yesColor = 'blue',
}) {
  const notifier = new AWN();
  notifier.modal(`
  <div class="awn-popup-title center-align">${title}</div>
  <div class="awn-popup-content center-align">${msg}</div>
  <div class="awn-buttons awn-buttons-2">
    <button class="awn-btn awn-btn-cancel grey cursor-pointer" id="awn-modal-cancel">
      ${noText}
    </button>
    <button class="awn-btn awn-btn-success ${yesColor} cursor-pointer" id="awn-modal-ok">
      ${yesText}
    </button>
  </div>
  `);
  const awnElm = window.document.querySelector('#awn-popup-wrapper');
  const btnOk = window.document.querySelector('#awn-modal-ok');
  const btnCancel = window.document.querySelector('#awn-modal-cancel');
  btnOk.addEventListener('click', onClickYes);
  btnCancel.addEventListener('click', onClickNo);
  function onClickYes() {
    awnElm.classList.add('awn-hiding');
    btnOk.removeEventListener('click', onClickYes);
    if (onOK) onOK();
    awnElm.classList.add('awn-hiding');
    awnElm.parentNode.removeChild(awnElm);
  }
  function onClickNo() {
    btnCancel.removeEventListener('click', onClickNo);
    awnElm.classList.add('awn-hiding');
    awnElm.parentNode.removeChild(awnElm);
  }
}

export function confirmWithComment({
  title,
  msg,
  inputFieldName,
  yesText,
  yesColor,
  noText,
  onOK,
}) {
  const notifier = new AWN();
  notifier.modal(`
      <div className="awn-popup-title" style="text-align: center;">${title}</div>
      <div className="awn-popup-content" style="text-align: center;">
        ${msg}
      </div>
      <div className="awn-popup-title">
        ${inputFieldName} <br />
        <input type="text" id="awn-popup-input" value="" />
      </div>
      <div className="awn-buttons awn-buttons-2">
        <button 
          className="awn-btn awn-btn-success grey" 
          id="awn-modal-cancel"
        >
          ${noText ? noText : 'Cancel'}
        </button>
        <button 
          className="awn-btn awn-btn-cancel ${yesColor ? yesColor : 'blue'}" 
          id="awn-modal-ok"
        >
          ${yesText ? yesText : 'Ya'}
        </button>
      </div>
  `);
  const awnElm = window.document.querySelector('#awn-popup-wrapper');
  const btnOk = window.document.querySelector('#awn-modal-ok');
  const btnCancel = window.document.querySelector('#awn-modal-cancel');
  const inputElm = window.document.querySelector('#awn-popup-input');
  btnOk.addEventListener('click', onClickYes);
  btnCancel.addEventListener('click', onClickNo);
  function onClickYes() {
    awnElm.classList.add('awn-hiding');
    btnOk.removeEventListener('click', onClickYes);
    onOK(inputElm.value);
  }
  function onClickNo() {
    btnCancel.removeEventListener('click', onClickNo);
    awnElm.classList.add('awn-hiding');
  }
}

export default notifier;
