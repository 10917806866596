import { createSlice } from '@reduxjs/toolkit';
import {
  restGetPatientList as restAdminGetPatientList,
  restGetPatientListArchived as restAdminGetPatientListArchived,
} from '../../apiAdmin/patient';
import { restGetPatientVisitPatientList } from '../../api/patientVisit';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const patientListSlice = createSlice({
  name: 'patientList',
  initialState: initialState,
  reducers: {
    getPatientListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPatientListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPatientListStart,
  getPatientListSuccess,
  getPatientListError,
} = patientListSlice.actions;

export default patientListSlice.reducer;

export const fetchAdminGetPatientList = ({
  isArchived,
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  isNewPatient = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientList;
  if (isLoading) return;
  try {
    dispatch(getPatientListStart());
    let listData = [];
    let totalItem = 0;
    let resp = {};
    if (isArchived) {
      resp = await restAdminGetPatientListArchived({
        limit,
        page,
        sort,
        sortDir,
        search,
        isNewPatient,
      });
    } else {
      resp = await restAdminGetPatientList({
        limit,
        page,
        sort,
        sortDir,
        search,
        isNewPatient,
      });
    }

    listData = resp.listData;
    totalItem = resp.totalItem;

    dispatch(
      getPatientListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientListError(JSON.parse(err).message));
    }
    dispatch(getPatientListError(err));
  }
};

export const fetchGetPatientVisitPatientList = ({
  limit = 50,
  page = 1,
  sort = 'name',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientList;
  if (isLoading) return;
  try {
    dispatch(getPatientListStart());

    const { listData, totalItem } = await restGetPatientVisitPatientList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });

    dispatch(
      getPatientListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientListError(JSON.parse(err).message));
    }
    dispatch(getPatientListError(err));
  }
};
