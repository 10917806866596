import { createSlice } from '@reduxjs/toolkit';
import { restPostAuthEmailResend, restPostEmailVerify } from '../../api/auth';

import { isValidJSON, setTimeoutAsync, successNotif } from '../../helper';

import { fetchPostAuthLogout } from './myUser';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  isSuccessVerify: false,
};

const resendEmailFormSlice = createSlice({
  name: 'resendEmailForm',
  initialState: initialState,
  reducers: {
    postResendEmailInit(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = false;
      state.isSuccessVerify = false;
    },
    postResendEmailStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
    },
    postResendEmailSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = true;
    },
    postResendEmailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postEmailVerifyStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isSuccessVerify = false;
    },
    postEmailVerifySuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccessVerify = true;
    },
    postEmailVerifyError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  postResendEmailInit,
  postResendEmailStart,
  postResendEmailSuccess,
  postResendEmailError,
  postEmailVerifyStart,
  postEmailVerifySuccess,
  postEmailVerifyError,
} = resendEmailFormSlice.actions;

export default resendEmailFormSlice.reducer;

export const fetchPostAuthEmailResend = ({ email }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().resendEmailForm;

  email = email.trim();

  if (email.length < 6) {
    return dispatch(postResendEmailError('Email minimal 6 karakter'));
  }

  if (isLoading) return;

  try {
    dispatch(postResendEmailStart());
    await restPostAuthEmailResend({ email });
    dispatch(postResendEmailSuccess());
    successNotif(
      `Berhasil megirim kembali Email Verifikasi, silahkan cek inbox / spam email ${email} dalam 2 menit:`
    );
    await setTimeoutAsync(5000);
    dispatch(fetchPostAuthLogout());
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postResendEmailError(JSON.parse(err).message));
    }
    dispatch(postResendEmailError(err));
  }
};

export const fetchPostEmailVerify = ({ locationSearch }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().resendEmailForm;

  if (isLoading) return;

  try {
    dispatch(postEmailVerifyStart());
    await restPostEmailVerify({ locationSearch });
    dispatch(postEmailVerifySuccess());
  } catch (err) {
    if (isValidJSON(err)) {
      const { message } = JSON.parse(err);
      if (message === 'Email already verified') {
        return dispatch(postEmailVerifyError('Email sudah terverifikasi'));
      }
      return dispatch(postEmailVerifyError(message));
    }
    dispatch(postEmailVerifyError(err));
  }
};
