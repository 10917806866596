import fetchApi from "../api/fetchApi";
import { BASE_API } from "../config";

// GET
export function restGetPatientVisitList({ limit = 10, page = 1, sort = "createdAt", sortDir = "asc", search = "", visitDate = "", doctorDepartmentId = "" }) {
  let path = `${BASE_API}/patient-visit/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&visitDate=${visitDate}`;
  path += `&doctorDepartmentId=${doctorDepartmentId}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientVisitDetail({ patientVisitId }) {
  const path = `${BASE_API}/patient-visit/detail/${patientVisitId}`;
  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientVisitPatientList({ limit = 50, page = 1, sort = "name", sortDir = "asc", search = "" }) {
  let path = `${BASE_API}/patient-visit/patient-list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: "GET",
  });
}

// POST
export function restPostPatientVisitCreate({ visitDate, doctorDepartmentId, doctorDepartmentName, patientId }) {
  const path = `${BASE_API}/patient-visit/create`;
  return fetchApi(path, {
    method: "POST",
    body: {
      visitDate,
      doctorDepartmentId,
      doctorDepartmentName,
      patientId,
    },
  });
}

export function restPostPatientVisitCreateNewPatient({
  // Patient Visit
  isNewPatient,
  visitDate,
  doctorDepartmentId,
  doctorDepartmentName,

  // Patient Bio
  noRM,
  prefix,
  name,
  suffix,
  birthLocation,
  birthDate,
  nik, // tambahan

  mobile,
  gender,
  // Tambahan
  bloodType,
  nationality,
  address,
  kelurahan,
  kecamatan,
  kota,
  lastEducation,
  religion,
  job,
  martialStatus,
  patientGroup,
  paymentMethod,
  responsibleName,
  responsiblePhone,
  // Physical Examination
  allergies,
  height,
  weight,
  kesadaran,
  suhu,
  keadaanUmum,
  nadi,
  saturasiOksigen,
  tekananDarah,
  respirasi,
  notesPhysicalExamination,
}) {
  const path = `${BASE_API}/patient-visit/create-new-patient`;
  return fetchApi(path, {
    method: "POST",
    body: {
      isNewPatient,
      // Patient Visit
      visitDate,
      doctorDepartmentId,
      doctorDepartmentName,
      // Patient Bio
      noRM,
      prefix,
      name,
      suffix,
      birthLocation,
      birthDate,
      nik, // tambahan

      mobile,
      gender,
      // Tambahan
      bloodType,
      nationality,
      address,
      kelurahan,
      kecamatan,
      kota,
      lastEducation,
      religion,
      job,
      martialStatus,
      patientGroup,
      paymentMethod,
      responsibleName,
      responsiblePhone,
      // Physical Examination
      allergies,
      height,
      weight,
      kesadaran,
      suhu,
      keadaanUmum,
      nadi,
      saturasiOksigen,
      tekananDarah,
      respirasi,
      notesPhysicalExamination,
    },
  });
}

export function restPostPatientVisitUpdate({ patientVisitId, doctorDepartmentId, doctorDepartmentName, patientId }) {
  const path = `${BASE_API}/patient-visit/update/${patientVisitId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      doctorDepartmentId,
      doctorDepartmentName,
      patientId,
    },
  });
}

export function restPostPatientVisitCancelVisit({ patientVisitId }) {
  const path = `${BASE_API}/patient-visit/cancel-visit/${patientVisitId}`;
  return fetchApi(path, {
    method: "POST",
  });
}

export function restPostPatientVisitPatientUpdate({
  patientId,
  // Patient Bio
  noRM,
  prefix,
  name,
  suffix,
  birthLocation,
  birthDate,
  nik, // tambahan

  mobile,
  gender,
  // Tambahan
  bloodType,
  nationality,
  address,
  kelurahan,
  kecamatan,
  kota,
  lastEducation,
  religion,
  job,
  martialStatus,
  patientGroup,
  paymentMethod,
  responsibleName,
  responsiblePhone,
  // Physical Examination
  allergies,
  height,
  weight,
  kesadaran,
  suhu,
  keadaanUmum,
  nadi,
  saturasiOksigen,
  tekananDarah,
  respirasi,
  notesPhysicalExamination,
}) {
  const path = `${BASE_API}/patient-visit/patient-update/${patientId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      // Patient Bio
      noRM,
      prefix,
      name,
      suffix,
      birthLocation,
      birthDate,
      nik, // tambahan

      mobile,
      gender,
      // Tambahan
      bloodType,
      nationality,
      address,
      kelurahan,
      kecamatan,
      kota,
      lastEducation,
      religion,
      job,
      martialStatus,
      patientGroup,
      paymentMethod,
      responsibleName,
      responsiblePhone,
      // Physical Examination
      allergies,
      height,
      weight,
      kesadaran,
      suhu,
      keadaanUmum,
      nadi,
      saturasiOksigen,
      tekananDarah,
      respirasi,
      notesPhysicalExamination,
    },
  });
}
