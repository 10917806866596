import { createSlice } from '@reduxjs/toolkit';
import {
  restGetUserAccessPersonList as restAdminGetUserAccessPersonList,
  restPostUserAccessAssignPerson as restAdminPostUserAccessAssignPerson,
  restPostUserAccessUnassignPerson as restAdminPostUserAccessUnassignPerson,
} from '../../apiAdmin/userAccess';

import { isValidJSON, errorNotif } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const accessPersonListSlice = createSlice({
  name: 'accessPersonList',
  initialState: initialState,
  reducers: {
    getPersonListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPersonListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPersonListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postUserAssignPersonStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserAssignPersonSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserAssignPersonError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postUserUnassignPersonStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserUnassignPersonSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserUnassignPersonError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPersonListStart,
  getPersonListSuccess,
  getPersonListError,
  postUserAssignPersonStart,
  postUserAssignPersonSuccess,
  postUserAssignPersonError,
  postUserUnassignPersonStart,
  postUserUnassignPersonSuccess,
  postUserUnassignPersonError,
} = accessPersonListSlice.actions;

export default accessPersonListSlice.reducer;

export const fetchAdminGetUserAccessPersonList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  type = '',
  departmentId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().accessPersonList;
  if (isLoading) return;
  try {
    dispatch(getPersonListStart());

    const { listData, totalItem } = await restAdminGetUserAccessPersonList({
      limit,
      page,
      sort,
      sortDir,
      search,
      type,
      departmentId,
    });

    dispatch(
      getPersonListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPersonListError(JSON.parse(err).message));
    }
    dispatch(getPersonListError(err));
  }
};

export const fetchAdminPostUserAccessAssignPerson = ({
  userId = '',
  personId = '',
  roleId = '',
  next,
}) => async (dispatch, getState) => {
  if (!personId) {
    return;
  }
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(postUserAssignPersonStart());
    await restAdminPostUserAccessAssignPerson({
      userId,
      personId,
      roleId,
    });
    dispatch(postUserAssignPersonSuccess());
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(postUserAssignPersonError(msg));
    }
    errorNotif(err);
    dispatch(postUserAssignPersonError(err));
  }
};

export const fetchAdminPostUserAccessUnassignPerson = ({
  userId = '',
  personId = '',
  roleId = '',
  next,
}) => async (dispatch, getState) => {
  if (!personId) {
    return;
  }
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(postUserUnassignPersonStart());
    await restAdminPostUserAccessUnassignPerson({
      userId,
      personId,
      roleId,
    });
    dispatch(postUserUnassignPersonSuccess());
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(postUserUnassignPersonError(msg));
    }
    errorNotif(err);
    dispatch(postUserUnassignPersonError(err));
  }
};
