import { createSlice } from '@reduxjs/toolkit';
import {
  restGetConfigDetail as restRootGetConfigDetail,
  restPostConfigUpdate as restRootPostConfigUpdate,
} from '../../apiRoot/config';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    id: '',
    name: '',
    selected: '',
    options: [],
  },
};

const configDetailSlice = createSlice({
  name: 'configDetail',
  initialState: initialState,
  reducers: {
    configDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    configDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: '',
        name: '',
        selected: '',
        options: [],
      };
    },
    getConfigDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getConfigDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: data._id,
        name: data.name,
        selected: data.selected,
        options: data.options,
      };
    },
    getConfigDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postConfigUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postConfigUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postConfigUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  configDetailSetForm,
  configDetailInit,
  getConfigDetailStart,
  getConfigDetailSuccess,
  getConfigDetailError,
  postConfigUpdateStart,
  postConfigUpdateSuccess,
  postConfigUpdateError,
} = configDetailSlice.actions;

export default configDetailSlice.reducer;

export const fetchRootGetConfigDetail = ({ configId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().configDetail;
  if (isLoading) return;
  try {
    dispatch(getConfigDetailStart());
    const data = await restRootGetConfigDetail({
      configId,
    });
    dispatch(
      getConfigDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getConfigDetailError(JSON.parse(err).message));
    }
    dispatch(getConfigDetailError(err));
  }
};

export const fetchRootPostConfigUpdate = ({
  push,
  configId,
  name = '',
  selected = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!configId) {
    return dispatch(postConfigUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postConfigUpdateError('Nama minimal 3 karakter'));
  }
  const { isLoading } = getState().configDetail;
  if (isLoading) return;
  try {
    dispatch(postConfigUpdateStart());
    await restRootPostConfigUpdate({
      configId,
      name,
      selected,
    });
    dispatch(postConfigUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/root/master-config?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postConfigUpdateError(JSON.parse(err).message));
    }
    dispatch(postConfigUpdateError(err));
  }
};
