import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
} from '../../component/common';
import {
  confirmNotif,
  momentGeneral,
  parsePersonTypeName,
  momentNumberDateToDisplayDate,
  parseDepartmentName,
  successNotif,
} from '../../helper';
import {
  fetchAdminGetUserAccessPersonList,
  fetchAdminPostUserAccessAssignPerson,
} from '../../store/userRole';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-user-role`,
    name: 'Master Akses user',
  },
  {
    url: `/root/master-user-role/access-person`,
    name: 'Hubungkan Pegawai',
  },
];

export const AccessPersonList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  let listDepartmentFilter = [];

  const $accessPersonList = useSelector((state) => state.accessPersonList);

  const { userId } = useParams();
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  if (queryParams.type) {
    listDepartmentFilter = listDepartment.filter(
      (dpt) => dpt.type === queryParams.type
    );
  }

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetUserAccessPersonList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmAddRole = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      title: 'Konfirmasi Hubungkan',
      msg: `Anda yakin akan menambahkan akses sebagai "${queryParams.type}" pada user "${queryParams.userName}" sebagai pegawai "${data.name}" ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostUserAccessAssignPerson({
            userId,
            personId: data._id,
            roleId: queryParams.type,
            next: () => {
              successNotif(
                `Berhasil menambahkan akses sebagai "${queryParams.type}" pada user "${queryParams.userName}" sebagai pegawai "${data.name}" `
              );
              push(`/admin/master-user-role`);
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {$accessPersonList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={`Hubungkan ${queryParams.userName} Pegawai`}
        backURL={'/admin/master-user-role'}
        onRefresh={() => {
          dispatch(fetchAdminGetUserAccessPersonList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetUserAccessPersonList(newQueryParams));
              }}
            />
            <FilterSelect
              options={listDepartmentFilter}
              filterKey={`departmentId`}
              defaultText={`Semua Departemen`}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetUserAccessPersonList(newQueryParams));
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Tipe</th>
                  <th>Tgl Bergabung</th>
                  <th>Nama</th>
                  <th>Departemen</th>
                  <th>Email</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$accessPersonList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>{parsePersonTypeName(data.type)}</td>
                      <td>{momentNumberDateToDisplayDate(data.joinDate)}</td>
                      <td>{`${data.prefix} ${data.name} ${data.suffix}`}</td>
                      <td>
                        {parseDepartmentName({
                          listDepartment,
                          departmentId: data.departmentId,
                        })}
                      </td>
                      <td>{data.email}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmAddRole({ e, data })}
                          className='teal-text cursor-pointer font-16'
                        >
                          <i className='fa fa-link '></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetUserAccessPersonList(newQueryParams));
              }}
              totalItem={$accessPersonList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
