import { createSlice } from '@reduxjs/toolkit';
import { restPostPatientInvoiceCreate } from '../../api/patientInvoice';
import { isValidJSON, successNotif, errorNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    patientDoctorActionId: '',
  },
};

const patientInvoiceCreateSlice = createSlice({
  name: 'patientInvoiceCreate',
  initialState: initialState,
  reducers: {
    patientInvoiceCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientInvoiceCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        patientDoctorActionId: '',
      };
    },
    postPatientInvoiceCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientInvoiceCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientInvoiceCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientInvoiceCreateSetForm,
  patientInvoiceCreateInit,
  postPatientInvoiceCreateStart,
  postPatientInvoiceCreateSuccess,
  postPatientInvoiceCreateError,
} = patientInvoiceCreateSlice.actions;

export default patientInvoiceCreateSlice.reducer;

export const fetchPostPatientInvoiceCreate = ({
  next,
  patientDoctorActionId = '',
}) => async (dispatch, getState) => {
  if (!patientDoctorActionId) {
    return dispatch(
      postPatientInvoiceCreateError('ID Tindakan dokter tidak ditemukan')
    );
  }

  const { isLoading } = getState().patientInvoiceCreate;
  if (isLoading) return;
  try {
    dispatch(postPatientInvoiceCreateStart());
    await restPostPatientInvoiceCreate({
      patientDoctorActionId,
    });
    dispatch(postPatientInvoiceCreateSuccess());
    successNotif(`Berhasil generate invoice, pasien dapat membayar di kasir`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(postPatientInvoiceCreateError(msg));
    }
    dispatch(postPatientInvoiceCreateError(err));
  }
};
