import roleList from './roleList';
import roleCreate from './roleCreate';
import roleDetail from './roleDetail';

export * from './roleList';
export * from './roleCreate';
export * from './roleDetail';

const roleReducers = {
  roleList,
  roleCreate,
  roleDetail,
};

export default roleReducers;
