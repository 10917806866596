export const isRoleExists = () => {
  if (
    window.myUser &&
    window.myUser.roles &&
    Object.keys(window.myUser.roles).length > 0
  ) {
    return true;
  }

  return false;
};

export const isRoleRoot = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  return false;
};

export const isRoleSuperAdmin = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  return false;
};

export const isRoleAdmin = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['admin']) {
    return true;
  }
  return false;
};

export const isRoleDoctor = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['doctor']) {
    return true;
  }
  return false;
};

export const isRoleNurse = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['nurse']) {
    return true;
  }

  return false;
};

export const isRoleStudent = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['student']) {
    return true;
  }
  return false;
};

export const isRoleOperator = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['admin']) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['operator']) {
    return true;
  }
  return false;
};

export const isRoleReadOnly = () => {
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['read-only']
  ) {
    return true;
  }
  return false;
};
export const isRoleStudentReadOnly = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['student']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['read-only']
  ) {
    return true;
  }
  return false;
};

export const isRoleNurseOperator = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['admin']) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['nurse']) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['operator']) {
    return true;
  }
  return false;
};

export const isRoleDoctorNurseStudent = () => {
  if (window.myUser && window.myUser.roles && window.myUser.roles['root']) {
    return true;
  }
  if (
    window.myUser &&
    window.myUser.roles &&
    window.myUser.roles['super-admin']
  ) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['doctor']) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['nurse']) {
    return true;
  }
  if (window.myUser && window.myUser.roles && window.myUser.roles['student']) {
    return true;
  }
  return false;
};
