import React, { useState } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  .tips-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TipsBox = ({
  desc = '',
  color = 'blue',
  isShowClose = false,
  onRefresh = null,
}) => {
  const { isHideBox, setIsHideBox } = useState(false);
  if (!isHideBox && desc) {
    return (
      <Div className={`card-panel white-text m-0 p-v-10 p-h-0 ${color}`}>
        <div className='container'>
          <div className='tips-content'>
            {(() => {
              if (isShowClose === true) {
                return (
                  <div className='m-r-8'>
                    <span
                      className='cursor-pointer'
                      onClick={() => {
                        setIsHideBox(true);
                      }}
                    >
                      <i className='fa fa-times' />
                    </span>
                  </div>
                );
              }
            })()}
            <div className='full-width'>{desc}</div>
            {(() => {
              if (onRefresh && typeof onRefresh === 'function') {
                return (
                  <div className='m-l-8'>
                    <span className='cursor-pointer' onClick={onRefresh}>
                      <i className='fa fa-refresh' />
                    </span>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </Div>
    );
  }

  return <span />;
};
