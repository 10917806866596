import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { RoleList } from './RoleList';
import { RoleCreate } from './RoleCreate';
import { RoleUpdate } from './RoleUpdate';

export const MasterRoleRoute = () => {
  const pathname = '/root/master-role';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={RoleList} />
      <Route exact path={`${pathname}/create`} component={RoleCreate} />
      <Route exact path={`${pathname}/update/:roleId`} component={RoleUpdate} />
      <Route component={Page404} />
    </Switch>
  );
};
