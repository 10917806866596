import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetMainComplaintList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/main-complaint/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetMainComplaintDetail({ mainComplaintId }) {
  const path = `${BASE_API_ADMIN}/main-complaint/detail/${mainComplaintId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostMainComplaintCreate({ name }) {
  const path = `${BASE_API_ADMIN}/main-complaint/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
    },
  });
}

export function restPostMainComplaintUpdate({ mainComplaintId, name }) {
  const path = `${BASE_API_ADMIN}/main-complaint/update/${mainComplaintId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
    },
  });
}
