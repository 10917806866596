import { createSlice } from '@reduxjs/toolkit';
import {
  restGetRoleDetail as restRootGetRoleDetail,
  restPostRoleUpdate as restRootPostRoleUpdate,
  restPostRoleDelete as restRootPostRoleDelete,
} from '../../apiRoot/role';
import {
  isValidJSON,
  successNotif,
  warningNotif,
  errorNotif,
} from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    id: '',
    name: '',
  },
};

const roleDetailSlice = createSlice({
  name: 'roleDetail',
  initialState: initialState,
  reducers: {
    roleDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    roleDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: '',
        name: '',
      };
    },
    getRoleDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getRoleDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        id: data._id,
        name: data.name,
      };
    },
    getRoleDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postRoleUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postRoleUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postRoleUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postRoleDeleteStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postRoleDeleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postRoleDeleteError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  roleDetailSetForm,
  roleDetailInit,
  getRoleDetailStart,
  getRoleDetailSuccess,
  getRoleDetailError,
  postRoleUpdateStart,
  postRoleUpdateSuccess,
  postRoleUpdateError,
  postRoleDeleteStart,
  postRoleDeleteSuccess,
  postRoleDeleteError,
} = roleDetailSlice.actions;

export default roleDetailSlice.reducer;

export const fetchRootGetRoleDetail = ({ roleId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().roleDetail;
  if (isLoading) return;
  try {
    dispatch(getRoleDetailStart());
    const data = await restRootGetRoleDetail({
      roleId,
    });
    dispatch(
      getRoleDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getRoleDetailError(JSON.parse(err).message));
    }
    dispatch(getRoleDetailError(err));
  }
};

export const fetchRootPostRoleUpdate = ({ push, roleId, name = '' }) => async (
  dispatch,
  getState
) => {
  name = name.trim();

  if (!roleId) {
    return dispatch(postRoleUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postRoleUpdateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().roleDetail;
  if (isLoading) return;
  try {
    dispatch(postRoleUpdateStart());
    await restRootPostRoleUpdate({
      roleId,
      name,
    });
    dispatch(postRoleUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/root/master-role?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postRoleUpdateError(JSON.parse(err).message));
    }
    dispatch(postRoleUpdateError(err));
  }
};

export const fetchRootPostRoleDelete = ({
  push,
  next,
  roleId,
  name = '',
}) => async (dispatch, getState) => {
  const savedName = name;
  if (!roleId) {
    return dispatch(postRoleDeleteError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postRoleDeleteError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().roleDetail;
  if (isLoading) return;
  try {
    dispatch(postRoleDeleteStart());
    await restRootPostRoleDelete({
      roleId,
      name,
    });
    dispatch(postRoleDeleteSuccess());
    warningNotif(`Berhasil hapus ${savedName}`);
    if (push) push('/root/master-role');
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postRoleDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postRoleDeleteError(err));
  }
};
