import { createSlice } from '@reduxjs/toolkit';
import { restGetMedicalRecordDetail } from '../../api/medicalRecord';
import { isValidJSON } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const medicalRecordDetailSlice = createSlice({
  name: 'medicalRecordDetail',
  initialState: initialState,
  reducers: {
    medicalRecordDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
    },
    getMedicalRecordDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getMedicalRecordDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
    },
    getMedicalRecordDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  medicalRecordDetailInit,
  getMedicalRecordDetailStart,
  getMedicalRecordDetailSuccess,
  getMedicalRecordDetailError,
} = medicalRecordDetailSlice.actions;

export default medicalRecordDetailSlice.reducer;

export const fetchGetMedicalRecordDetail = ({ patientId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().medicalRecordDetail;
  if (isLoading) return;
  try {
    dispatch(getMedicalRecordDetailStart());
    const data = await restGetMedicalRecordDetail({
      patientId,
    });
    dispatch(
      getMedicalRecordDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getMedicalRecordDetailError(JSON.parse(err).message));
    }
    dispatch(getMedicalRecordDetailError(err));
  }
};
