import React, { Fragment } from 'react';
import styled from 'styled-components';

import { BreadCrumb, HeaderNav } from '../../component/common';
import { RootMenu } from './component';

const Div = styled.div``;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
];

export const RootHome = () => {
  return (
    <Fragment>
      <HeaderNav headerSize='m' headerName={'Root'} backURL={'/'} />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='root-container section no-pad-bot'>
        <div className='container container-home'>
          <RootMenu />
        </div>
      </Div>
    </Fragment>
  );
};
