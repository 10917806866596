import { createSlice } from '@reduxjs/toolkit';
import { restGetRoleList as restRootGetRoleList } from '../../apiRoot/role';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const roleListSlice = createSlice({
  name: 'roleList',
  initialState: initialState,
  reducers: {
    getRoleListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getRoleListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getRoleListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getRoleListStart,
  getRoleListSuccess,
  getRoleListError,
} = roleListSlice.actions;

export default roleListSlice.reducer;

export const fetchRootGetRoleList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().roleList;
  if (isLoading) return;
  try {
    dispatch(getRoleListStart());
    const { listData, totalItem } = await restRootGetRoleList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getRoleListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getRoleListError(JSON.parse(err).message));
    }
    dispatch(getRoleListError(err));
  }
};
