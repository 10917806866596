import patientVisitList from './patientVisitList';
import patientVisitCreate from './patientVisitCreate';
import patientVisitCreateNew from './patientVisitCreateNew';
import patientVisitDetail from './patientVisitDetail';
import patientVisitPatientUpdate from './patientVisitPatientUpdate'

export * from './patientVisitList';
export * from './patientVisitCreate';
export * from './patientVisitCreateNew';
export * from './patientVisitDetail';
export * from './patientVisitPatientUpdate'

const patientVisitReducers = {
  patientVisitList,
  patientVisitCreate,
  patientVisitCreateNew,
  patientVisitDetail,
  patientVisitPatientUpdate,
};

export default patientVisitReducers;
