import { createSlice } from "@reduxjs/toolkit";
import { restGetPatientInvoiceDailyDetail } from "../../api/patientInvoice";
import { isValidJSON, arraySortCallback } from "../../helper";

const initialState = {
  listData: [],
  totalItem: 0,
  listDataGroup: {},
  sumOf: {
    administrationPrice: 0,
    totalActionPrice: 0,
    totalSubActionPrice: 0,
    totalSubstancePrice: 0,
    grandTotalPrice: 0,
  },
  isLoading: false,

  error: null,
};

const patientInvoiceDailyDetailSlice = createSlice({
  name: "patientInvoiceDailyDetail",
  initialState: initialState,
  reducers: {
    patientInvoiceDailyDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientInvoiceDailyDetailInit(state) {
      state.listData = [];
      state.totalItem = 0;
      state.listDataGroup = {};
      state.sumOf = {
        administrationPrice: 0,
        totalActionPrice: 0,
        totalSubActionPrice: 0,
        totalSubstancePrice: 0,
        grandTotalPrice: 0,
      };
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceDailyDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientInvoiceDailyDetailSuccess(state, action) {
      const { listData, totalItem, listDataGroup, sumOf } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.listDataGroup = listDataGroup;
      state.sumOf = sumOf;
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceDailyDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientInvoiceDailyDetailSetForm,
  patientInvoiceDailyDetailInit,
  getPatientInvoiceDailyDetailStart,
  getPatientInvoiceDailyDetailSuccess,
  getPatientInvoiceDailyDetailError,
} = patientInvoiceDailyDetailSlice.actions;

export default patientInvoiceDailyDetailSlice.reducer;

export const fetchGetPatientInvoiceDailyDetail =
  ({ invoiceDate }) =>
  async (dispatch, getState) => {
    const { isLoading } = getState().patientInvoiceDailyDetail;
    if (isLoading) return;
    try {
      dispatch(getPatientInvoiceDailyDetailStart());
      const { listData, totalItem } = await restGetPatientInvoiceDailyDetail({
        invoiceDate,
      });
      const { listDataGroup, sumOf } = await groupByDoctorDepartmentId(
        listData
      );
      dispatch(
        getPatientInvoiceDailyDetailSuccess({
          listData,
          listDataGroup,
          totalItem,
          sumOf,
        })
      );
    } catch (err) {
      if (isValidJSON(err)) {
        return dispatch(
          getPatientInvoiceDailyDetailError(JSON.parse(err).message)
        );
      }
      dispatch(getPatientInvoiceDailyDetailError(err));
    }
  };

async function groupByDoctorDepartmentId(listData = []) {
  const listDataGroup = {};
  const sumOf = {
    administrationPrice: 0,
    totalActionPrice: 0,
    totalSubActionPrice: 0,
    totalSubstancePrice: 0,
    grandTotalPrice: 0,
  };
  listData.forEach((data) => {
    if (data.doctorDepartmentId) {
      if (!listDataGroup[data.doctorDepartmentId]) {
        listDataGroup[data.doctorDepartmentId] = {
          doctorDepartmentId: data.doctorDepartmentId,
          doctorDepartmentName: data.doctorDepartmentName,
          listData: [],
          totalItem: 0,
          sumOf: {
            administrationPrice: 0,
            totalActionPrice: 0,
            totalSubActionPrice: 0,
            totalSubstancePrice: 0,
            grandTotalPrice: 0,
          },
        };
      }
      listDataGroup[data.doctorDepartmentId].listData.push(data);
      listDataGroup[data.doctorDepartmentId].listData.sort(
        arraySortCallback({
          sort: "createdAt",
        })
      );
      listDataGroup[data.doctorDepartmentId].totalItem =
        listDataGroup[data.doctorDepartmentId].listData.length;

      listDataGroup[data.doctorDepartmentId].sumOf.administrationPrice +=
        data.administrationPrice;
      listDataGroup[data.doctorDepartmentId].sumOf.totalActionPrice +=
        data.totalActionPrice;
      listDataGroup[data.doctorDepartmentId].sumOf.totalSubActionPrice +=
        data.totalSubActionPrice;
      listDataGroup[data.doctorDepartmentId].sumOf.totalSubstancePrice +=
        data.totalSubstancePrice;
      listDataGroup[data.doctorDepartmentId].sumOf.grandTotalPrice +=
        data.grandTotalPrice;
    }
    sumOf.administrationPrice += data.administrationPrice
    sumOf.totalActionPrice += data.totalActionPrice
    sumOf.totalSubActionPrice += data.totalSubActionPrice
    sumOf.totalSubstancePrice += data.totalSubstancePrice
    sumOf.grandTotalPrice += data.grandTotalPrice
  });
  return {listDataGroup, sumOf};
}
