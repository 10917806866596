import userRoleList from './userRoleList';
import accessPersonList from './accessPersonList';

export * from './userRoleList';
export * from './accessPersonList';

const userAccessReducers = {
  userRoleList,
  accessPersonList,
};

export default userAccessReducers;
