import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { MainComplaintList } from './MainComplaintList';
import { MainComplaintCreate } from './MainComplaintCreate';
import { MainComplaintUpdate } from './MainComplaintUpdate';

export const MasterMainComplaintRoute = () => {
  const pathname = '/admin/master-main-complaint';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={MainComplaintList} />
      <Route
        exact
        path={`${pathname}/create`}
        component={MainComplaintCreate}
      />
      <Route
        exact
        path={`${pathname}/update/:mainComplaintId`}
        component={MainComplaintUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
