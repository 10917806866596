import { createSlice } from '@reduxjs/toolkit';
import { restGetPatientInvoiceDetail } from '../../api/patientInvoice';
import { isValidJSON } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const patientInvoiceDetailSlice = createSlice({
  name: 'patientInvoiceDetail',
  initialState: initialState,
  reducers: {
    patientInvoiceDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientInvoiceDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientInvoiceDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientInvoiceDetailSetForm,
  patientInvoiceDetailInit,
  getPatientInvoiceDetailStart,
  getPatientInvoiceDetailSuccess,
  getPatientInvoiceDetailError,
} = patientInvoiceDetailSlice.actions;

export default patientInvoiceDetailSlice.reducer;

export const fetchGetPatientInvoiceDetail = ({ patientInvoiceId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().patientInvoiceDetail;
  if (isLoading) return;
  try {
    dispatch(getPatientInvoiceDetailStart());
    const data = await restGetPatientInvoiceDetail({
      patientInvoiceId,
    });
    dispatch(
      getPatientInvoiceDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientInvoiceDetailError(JSON.parse(err).message));
    }
    dispatch(getPatientInvoiceDetailError(err));
  }
};
