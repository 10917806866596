import { createSlice } from '@reduxjs/toolkit';
import { restPostActionCreate as restAdminPostActionCreate } from '../../apiAdmin/action';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    doctorDepartmentId: 'doctor',
    name: '',
    price: 0,
  },
};

const actionCreateSlice = createSlice({
  name: 'actionCreate',
  initialState: initialState,
  reducers: {
    actionCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    actionCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: 'doctor',
        name: '',
        price: 0,
      };
    },
    postActionCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postActionCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postActionCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  actionCreateSetForm,
  actionCreateInit,
  postActionCreateStart,
  postActionCreateSuccess,
  postActionCreateError,
} = actionCreateSlice.actions;

export default actionCreateSlice.reducer;

export const fetchAdminPostActionCreate = ({
  next,
  doctorDepartmentId = '',
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();

  if (doctorDepartmentId.length < 3) {
    return dispatch(postActionCreateError('Departemen harus dipilih'));
  }
  if (name.length < 3) {
    return dispatch(postActionCreateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postActionCreateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postActionCreateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().actionCreate;
  if (isLoading) return;
  try {
    dispatch(postActionCreateStart());
    await restAdminPostActionCreate({
      doctorDepartmentId,
      name,
      price,
    });
    dispatch(postActionCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postActionCreateError(JSON.parse(err).message));
    }
    dispatch(postActionCreateError(err));
  }
};
