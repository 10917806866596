import allergicList from './allergicList';
import allergicCreate from './allergicCreate';
import allergicDetail from './allergicDetail';

export * from './allergicList';
export * from './allergicCreate';
export * from './allergicDetail';

const allergicReducers = {
  allergicList,
  allergicCreate,
  allergicDetail,
};

export default allergicReducers;
