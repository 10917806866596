import { createSlice } from '@reduxjs/toolkit';
import { restGetPatientVisitList } from '../../api/patientVisit';
import { restNurseGetPatientVisitList } from '../../api/patientDoctorAction';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const patientVisitListSlice = createSlice({
  name: 'patientVisitList',
  initialState: initialState,
  reducers: {
    getPatientVisitListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientVisitListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPatientVisitListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPatientVisitListStart,
  getPatientVisitListSuccess,
  getPatientVisitListError,
} = patientVisitListSlice.actions;

export default patientVisitListSlice.reducer;

export const fetchGetPatientVisitList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  visitDate = '',
  doctorDepartmentId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientVisitList;
  if (isLoading) return;
  try {
    dispatch(getPatientVisitListStart());
    const { listData, totalItem } = await restGetPatientVisitList({
      limit,
      page,
      sort,
      sortDir,
      search,
      visitDate,
      doctorDepartmentId,
    });

    dispatch(
      getPatientVisitListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientVisitListError(JSON.parse(err).message));
    }
    dispatch(getPatientVisitListError(err));
  }
};

export const fetchNurseGetPatientVisitList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  visitDate = '',
  doctorDepartmentId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientVisitList;
  if (isLoading) return;
  try {
    dispatch(getPatientVisitListStart());
    const { listData, totalItem } = await restNurseGetPatientVisitList({
      limit,
      page,
      sort,
      sortDir,
      search,
      visitDate,
      doctorDepartmentId,
    });

    dispatch(
      getPatientVisitListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientVisitListError(JSON.parse(err).message));
    }
    dispatch(getPatientVisitListError(err));
  }
};
