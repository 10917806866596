import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetDepartmentList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  type = '',
}) {
  let path = `${BASE_API_ADMIN}/department/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&type=${type}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetDepartmentDetail({ departmentId }) {
  const path = `${BASE_API_ADMIN}/department/detail/${departmentId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostDepartmentCreate({ type, name }) {
  const path = `${BASE_API_ADMIN}/department/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      type,
      name,
    },
  });
}

export function restPostDepartmentUpdate({ departmentId, type, name }) {
  const path = `${BASE_API_ADMIN}/department/update/${departmentId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      type,
      name,
    },
  });
}
