import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import { fetchAdminGetUserAccessList } from '../../store/userRole';
import { TableHead, TableRow } from './component';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-user-role`,
    name: 'Master Akses user',
  },
];

export const UserRoleList = () => {
  const dispatch = useDispatch();

  const $userRoleList = useSelector((state) => state.userRoleList);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetUserAccessList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$userRoleList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Master Akses User'}
        backURL={'/admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetUserAccessList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <SearchSort
              sortOptions={[
                {
                  key: 'createdAt',
                  name: 'Dibuat',
                },
                {
                  key: 'rolesAt',
                  name: 'RolesAt',
                },
                {
                  key: 'name',
                  name: 'Nama',
                },
              ]}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetUserAccessList(newQueryParams));
              }}
            />
            <table className="striped bordered bg-white">
              <TableHead />
              <tbody>
                {$userRoleList.listData.map((data) => {
                  return <TableRow key={data._id} data={data} />;
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetUserAccessList(newQueryParams));
              }}
              totalItem={$userRoleList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
