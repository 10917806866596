import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { TipsBox } from './TipsBox';

const LOGO_URL = `/images/unsrat.png`;

const Div = styled.div`
  .navbar-fixed {
    /* Name Alias */
  }
  @media print {
    display: none;
  }
  position: sticky;
  top: 0px;
  height: 56px;
  z-index: 10;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  .navbar-flex {
    height: 56px;
    margin: 0 auto;
    height: 56px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
  .fa-arrow-left {
    height: 16px;
  }
  /* .header-size-l {
    font-size: 2.1rem;
  }
  .header-size-m {
    font-size: 1.6rem;
  } */
  .header-size-l {
    font-size: 1.2rem;
  }
  .header-size-m {
    font-size: 1.2rem;
  }
  .header-size-s {
    font-size: 1.2rem;
  }
  .header-size-xs {
    font-size: 0.8rem;
  }
  .title-flex {
    display: flex;
    align-items: center;
  }
  .title-with-logo {
    margin-left: -52px !important;
  }
  .brand-logo {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .logo-dimension {
    height: 3em;
    margin-right: 4px;
  }
  .back-button {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 45px;
  }
  .refresh-box {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 45px;
  }
`;

export const HeaderNav = ({
  headerName = '',
  headerSize = 'l',
  backURL = '',
  subtitle = '',
  subtitleColor = '',
  isHome = false,
  onRefresh = null,
}) => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Div className='navbar-fixed'>
        <div className='navbar-flex teal'>
          {(() => {
            if (backURL === 'BACK') {
              return (
                <div
                  onClick={() => {
                    history.goBack();
                  }}
                  className='cursor-pointer back-button'
                >
                  <i className='fa fa-arrow-left white-text' />
                </div>
              );
            }
            if (backURL) {
              return (
                <div className='cursor-pointer back-button'>
                  <Link to={backURL}>
                    <i className='fa fa-arrow-left white-text' />
                  </Link>
                </div>
              );
            }
            return <div />;
          })()}

          <div className={`title-flex ${isHome ? 'title-with-logo' : ''}`}>
            {isHome && (
              <img
                className='logo-dimension'
                src={LOGO_URL}
                alt='RSGM Unsrat'
              />
            )}
            <span className={`brand-logo center header-size-${headerSize}`}>
              {headerName}
              {(() => {
                if (isHome) {
                  return (
                    <div>
                      <span className='header-size-xs'>
                        Rumah Sakit Gigi &amp; Mulut Universitas Sam Ratulangi
                      </span>
                    </div>
                  );
                }
              })()}
            </span>
          </div>

          {(() => {
            if (onRefresh && typeof onRefresh === 'function') {
              return (
                <div className='refresh-box cursor-pointer' onClick={onRefresh}>
                  <i className='fa fa-refresh white-text' />
                </div>
              );
            }
            return <div />;
          })()}
        </div>
      </Div>
      <TipsBox desc={subtitle} color={subtitleColor} />
    </React.Fragment>
  );
};
