import fetchApi from "../api/fetchApi";
import { BASE_API } from "../config";
import { moment } from "../helper";

// GET
export function restGetPatientInvoiceList({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  invoiceDate = "",
  visitDate = "",
}) {
  let path = `${BASE_API}/patient-invoice/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&invoiceDate=${invoiceDate}`;
  path += `&visitDate=${visitDate}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientInvoiceDetail({ patientInvoiceId }) {
  const path = `${BASE_API}/patient-invoice/detail/${patientInvoiceId}`;
  return fetchApi(path, {
    method: "GET",
  });
}

export function restGetPatientInvoiceDailyList({
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  invoiceMonth = "202105",
}) {
  let path = `${BASE_API}/patient-invoice/daily-list`;
  path += `?page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&invoiceMonth=${invoiceMonth}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPatientInvoiceDailyDetail({ invoiceDate }) {
  const path = `${BASE_API}/patient-invoice/daily-detail/${invoiceDate}`;
  return fetchApi(path, {
    method: "GET",
  });
}

// POST
export function restPostPatientInvoiceCreate({ patientDoctorActionId }) {
  const path = `${BASE_API}/patient-invoice/create`;
  return fetchApi(path, {
    method: "POST",
    body: {
      patientDoctorActionId,
      invoiceDate: Number(moment().format("YYYYMMDD")),
    },
  });
}

export function restPostPatientInvoiceDelete({ patientInvoiceId }) {
  const path = `${BASE_API}/patient-invoice/delete/${patientInvoiceId}`;
  return fetchApi(path, {
    method: "POST",
    body: {},
  });
}
