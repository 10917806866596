import React, { Fragment } from 'react';
import styled from 'styled-components';

import { BreadCrumb, HeaderNav } from '../../component/common';
import { AdminMenu } from './component';

const Div = styled.div``;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
];

export const SuperAdminHome = () => {
  return (
    <Fragment>
      <HeaderNav headerSize="m" headerName={'Super Admin'} backURL={'/admin'} />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="root-container section no-pad-bot">
        <div className="container container-home">
          <AdminMenu />
        </div>
      </Div>
    </Fragment>
  );
};
