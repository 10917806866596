import React, { Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  successNotif,
  warningNotif,
  confirmNotif,
  momentGeneral,
} from '../../../helper';
import {
  fetchRootGetUserRoleList,
  fetchRootPostUserAccessUpdate,
  fetchAdminPostUserAccessUnassignPerson,
} from '../../../store/userRole';
import { ROLE_LIST_ROOT } from '../../../config';

const isActiveRole = (userRoles = {}, roleId) => {
  const findRole = userRoles[roleId];
  if (findRole) {
    return true;
  }
  return false;
};

export const TableRow = ({ data }) => {
  const detailURL = '/root/master-user-role/access-person';
  const dispatch = useDispatch();
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  const setRole = ({ e, data, isAdd, roleId = '', roleName = '', isWrite }) => {
    e.preventDefault();
    if (window.myUser._id === data._id && roleId === 'root') {
      return warningNotif('Tidak bisa mengubah akses ROOT diri sendiri');
    }
    if (window.myUser._id === data._id && roleId === 'super-admin') {
      return warningNotif('Tidak bisa mengubah akses SUPER ADMIN diri sendiri');
    }
    let msg = '';
    if (isAdd) {
      msg = `Tambahkan Akses ${data.name} : `;
      msg += `"${roleName.toUpperCase()}" `;
    } else {
      msg = `Cabut Akses ${data.name} : `;
      msg += `"${roleName.toUpperCase()}": `;
    }
    confirmNotif({
      title: 'Konfirmasi',
      msg: `${msg}?`,
      onOK: () => {
        const next = () => {
          successNotif(`BERHASIL ${msg}`);
          dispatch(fetchRootGetUserRoleList(queryParams));
        };
        dispatch(
          fetchRootPostUserAccessUpdate({
            userId: data._id,
            isAdd,
            roleId,
            roleName,
            next,
          })
        );
      },
    });
  };

  const unsetPersonRole = ({ e, role }) => {
    e.preventDefault();
    let msg = '';
    msg = `Cabut Akses ${data.name} : `;
    msg += `"${role._id.toUpperCase()}": `;
    confirmNotif({
      title: 'Konfirmasi',
      msg: `${msg}?`,
      onOK: () => {
        const next = () => {
          successNotif(`BERHASIL ${msg}`);
          dispatch(fetchRootGetUserRoleList(queryParams));
        };
        dispatch(
          fetchAdminPostUserAccessUnassignPerson({
            userId: data._id,
            personId: data.personId,
            roleId: role._id,
            next,
          })
        );
      },
    });
  };
  const parseRow = (role) => {
    if (isActiveRole(data.roles, role._id)) {
      if (
        role._id === 'doctor' ||
        role._id === 'nurse' ||
        role._id === 'student'
      ) {
        return (
          <td
            onClick={(e) => {
              unsetPersonRole({ e, role });
            }}
            className='center-align cursor-pointer blue-text'
          >
            <i className='fa fa-check-square fa-2x'></i>
          </td>
        );
      }
      return (
        <td
          onClick={(e) =>
            setRole({
              e,
              data,
              isAdd: false,
              roleId: role._id,
              roleName: role.name,
              isWrite: false,
            })
          }
          className='center-align cursor-pointer blue-text'
        >
          <i className='fa fa-check-square fa-2x'></i>
        </td>
      );
    }
    if (
      role._id === 'doctor' ||
      role._id === 'nurse' ||
      role._id === 'student'
    ) {
      if (data.personId) {
        return (
          <td className='center-align cursor-pointer black-text'>
            <i className='fa fa-square-o fa-2x'></i>
          </td>
        );
      }
      return (
        <td className='center-align cursor-pointer blue-text'>
          <Link
            to={`${detailURL}/${data._id}?type=${role._id}&userName=${data.name}`}
          >
            <i className='fa fa-square-o fa-2x'></i>
          </Link>
        </td>
      );
    }
    return (
      <td
        onClick={(e) =>
          setRole({
            e,
            data,
            isAdd: true,
            roleId: role._id,
            roleName: role.name,
            isWrite: false,
          })
        }
        className='center-align cursor-pointer blue-text'
      >
        <i className='fa fa-square-o fa-2x'></i>
      </td>
    );
  };
  return (
    <tr>
      <td title={data.rolesByName}>{momentGeneral(data.rolesAt)}</td>
      <td>{data.email}</td>
      <td>{data.name}</td>
      <td>{data.personFullName}</td>
      {ROLE_LIST_ROOT.map((role) => {
        return <Fragment key={role._id}>{parseRow(role)}</Fragment>;
      })}
    </tr>
  );
};
