import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterDate,
} from '../../component/common';
import {
  parseDepartmentName,
  momentNumberDateToDisplayFullDate,
  parsePatientVisitStatus,
  momentGeneral,
} from '../../helper';
import { fetchNurseGetPatientVisitList } from '../../store/patientVisit';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
  .cards-container {
    font-size: 40px;
  }
  @media only screen and (min-width: 601px) {
    .cards-container {
      margin-left: -11px;
      margin-right: -11px;
    }
  }
  .cyan:hover {
    background-color: #4dd0e1 !important;
  }
  .indigo:hover {
    background-color: #7986cb !important;
  }
`;

const breadCrumbData = (
  doctorDepartmentId,
  doctorDepartmentName,
  locationSearch
) => {
  return [
    {
      url: `/patient-doctor-action`,
      name: 'Bagian / Poli',
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}${locationSearch}`,
      name: doctorDepartmentName,
    },
  ];
};
const backURL = `/patient-doctor-action`;
const detailURL = (
  doctorDepartmentId,
  patientId,
  patientVisitId,
  queryParams
) => {
  queryParams.search = ''
  const locationSearch = `?${queryString.stringify(queryParams)}`
  return `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`
};

export const PatientVisitList = () => {
  const { doctorDepartmentId } = useParams();
  const dispatch = useDispatch();
  const $patientVisitList = useSelector((state) => state.patientVisitList);
  const { search: locationSearch } = useLocation();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );
  const queryParams = queryString.parse(locationSearch);
  const doctorDepartmentName = parseDepartmentName({
    listDepartment: listDepartmentFilter,
    departmentId: doctorDepartmentId,
  });

  useEffect(() => {
    const didMount = () => {
      dispatch(
        fetchNurseGetPatientVisitList({
          ...queryParams,
          doctorDepartmentId,
        })
      );
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {($patientVisitList.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={`${doctorDepartmentName}`}
        backURL={backURL}
        onRefresh={() => {
          dispatch(
            fetchNurseGetPatientVisitList({
              ...queryParams,
              doctorDepartmentId,
            })
          );
        }}
      />
      <BreadCrumb
        breadCrumbData={breadCrumbData(
          doctorDepartmentId,
          doctorDepartmentName,
          locationSearch
        )}
      />
      {queryParams.visitDate && (
        <Div className='container'>
          <div className='card m-t-0'>
            <div className='card-content p-0'>
              <SearchSort
                searchPlaceholder='Cari No RM / Nama / No HP'
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchNurseGetPatientVisitList({
                      ...newQueryParams,
                      doctorDepartmentId,
                    })
                  );
                }}
                SearchChildren={() => {
                  return (
                    <div className='m-l-16'>
                      {momentNumberDateToDisplayFullDate(queryParams.visitDate)}
                    </div>
                  );
                }}
              />
              <FilterDate
                filterKey={`visitDate`}
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchNurseGetPatientVisitList({
                      ...newQueryParams,
                      doctorDepartmentId,
                    })
                  );
                }}
              />
              <table className='striped bg-white'>
                <thead>
                  <tr>
                    <th>No Antrian</th>
                    <th>Pasien Baru?</th>
                    <th>No RM</th>
                    <th>Nama</th>
                    <th>No HP</th>
                    <th>Status</th>
                    <th>Diubah</th>
                  </tr>
                </thead>
                <tbody>
                  {$patientVisitList.listData.map((data) => {
                    return (
                      <tr key={data._id}>
                        <td>{data.visitQueue}</td>
                        <td>
                          {data.isNewPatient ? (
                            <i className='fa fa-check-square'></i>
                          ) : (
                            <i className='fa fa-square-o'></i>
                          )}
                        </td>
                        <td>{data.patientNoRM}</td>
                        <td>
                          <Link
                            to={detailURL(
                              doctorDepartmentId,
                              data.patientId,
                              data._id,
                              queryParams
                            )}
                          >
                            {data.gender === 'f' ? (
                              <i className='fa fa-female'></i>
                            ) : (
                              <i className='fa fa-male'></i>
                            )}
                            {` `}
                            {`${data.patientFullName}`}
                          </Link>
                        </td>
                        <td>{data.patientMobile}</td>
                        <td>
                          {parsePatientVisitStatus({
                            isCanceled: data.isCanceled,
                            patientDoctorActionId: data.patientDoctorActionId,
                            patientInvoiceId: data.patientInvoiceId,
                          })}
                        </td>
                        <td title={data.updatedByName}>
                          {momentGeneral(data.updatedAt)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchNurseGetPatientVisitList({
                      ...newQueryParams,
                      doctorDepartmentId,
                    })
                  );
                }}
                totalItem={$patientVisitList.totalItem}
              />
            </div>
          </div>
        </Div>
      )}
    </Fragment>
  );
};
