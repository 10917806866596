import diagnosisList from './diagnosisList';
import diagnosisCreate from './diagnosisCreate';
import diagnosisDetail from './diagnosisDetail';

export * from './diagnosisList';
export * from './diagnosisCreate';
export * from './diagnosisDetail';

const diagnosisReducers = {
  diagnosisList,
  diagnosisCreate,
  diagnosisDetail,
};

export default diagnosisReducers;
