import { createSlice } from '@reduxjs/toolkit';
import {
  restGetSubActionDetail as restAdminGetSubActionDetail,
  restPostSubActionUpdate as restAdminPostSubActionUpdate,
} from '../../apiAdmin/subAction';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    doctorDepartmentId: 'doctor',
    name: '',
    price: 0,
  },
};

const subActionDetailSlice = createSlice({
  name: 'subActionDetail',
  initialState: initialState,
  reducers: {
    subActionDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    subActionDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: 'doctor',
        name: '',
        price: 0,
      };
    },
    getSubActionDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSubActionDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: data.doctorDepartmentId,
        name: data.name,
        price: data.price,
      };
    },
    getSubActionDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postSubActionUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postSubActionUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postSubActionUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  subActionDetailSetForm,
  subActionDetailInit,
  getSubActionDetailStart,
  getSubActionDetailSuccess,
  getSubActionDetailError,
  postSubActionUpdateStart,
  postSubActionUpdateSuccess,
  postSubActionUpdateError,
} = subActionDetailSlice.actions;

export default subActionDetailSlice.reducer;

export const fetchAdminGetSubActionDetail = ({
  subActionId,
  actionId,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().subActionDetail;
  if (isLoading) return;
  try {
    dispatch(getSubActionDetailStart());
    const data = await restAdminGetSubActionDetail({
      actionId,
      subActionId,
    });
    dispatch(
      getSubActionDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getSubActionDetailError(JSON.parse(err).message));
    }
    dispatch(getSubActionDetailError(err));
  }
};

export const fetchAdminPostSubActionUpdate = ({
  next,
  actionId,
  subActionId,
  doctorDepartmentId = '',
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();
  if (actionId.length < 3) {
    return dispatch(
      postSubActionUpdateError('Tindakan tidak ada, coba refresh')
    );
  }
  if (doctorDepartmentId.length < 3) {
    return dispatch(postSubActionUpdateError('Departemen harus dipilih'));
  }
  if (name.length < 3) {
    return dispatch(postSubActionUpdateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postSubActionUpdateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postSubActionUpdateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().subActionDetail;
  if (isLoading) return;
  try {
    dispatch(postSubActionUpdateStart());
    await restAdminPostSubActionUpdate({
      actionId,
      subActionId,
      doctorDepartmentId,
      name,
      price,
    });
    dispatch(postSubActionUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postSubActionUpdateError(JSON.parse(err).message));
    }
    dispatch(postSubActionUpdateError(err));
  }
};
