import React from "react";
import { Switch, Route } from "react-router-dom";

import { Page404 } from "../../page/common";
import { PatientInvoiceDailyList } from "./PatientInvoiceDailyList";
import { PatientInvoiceDailyDetail } from "./PatientInvoiceDailyDetail";

export const PatientInvoiceDailyRoute = () => {
  const pathname = "/patient-invoice-daily";
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PatientInvoiceDailyList} />
      <Route
        exact
        path={`${pathname}/detail/:invoiceDate`}
        component={PatientInvoiceDailyDetail}
      />

      <Route component={Page404} />
    </Switch>
  );
};
