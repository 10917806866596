import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import {
  configDetailSetForm,
  configDetailInit,
  fetchRootGetConfigDetail,
  fetchRootPostConfigUpdate,
} from '../../store/config';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-config`,
    name: 'Master Konfigurasi',
  },
  {
    url: `/root/master-config/update`,
    name: 'Update',
  },
];

export const ConfigUpdate = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const $configDetail = useSelector((state) => state.configDetail);
  const { data, form } = $configDetail;
  const { configId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(configDetailInit());
      if (configId) {
        dispatch(fetchRootGetConfigDetail({ configId }));
      }
    };
    didMount();
  }, [dispatch, configId]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchRootPostConfigUpdate({
        push,
        configId: data._id,
        name: form.name,
        selected: form.selected,
      })
    );
  };

  return (
    <Fragment>
      {$configDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Konfigurasi - Update'}
        backURL={'/root/master-config'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    defaultValue={form.id}
                    placeholder='ID'
                    id='_id'
                    name='_id'
                    type='text'
                    className='validate'
                    disabled
                  />
                  <label className='active' htmlFor='_id'>
                    ID
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        configDetailSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='name'
                    name='name'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='name'>
                    Nama
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col m3 s12'>
                  <select
                    value={form.selected}
                    onChange={(e) =>
                      dispatch(
                        configDetailSetForm({
                          key: 'selected',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Selected'
                    id='selected'
                    name='selected'
                    type='text'
                    className='browser-default validate m-t-8'
                  >
                    <option value=''>Pilih Selected</option>
                    {form.options.map((opt) => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>
                  <label className='active' htmlFor='selected'>
                    Selected
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='red-text m-b-4 right-align'>
                    {$configDetail.error}
                  </div>
                </div>
                <div className='col s12'>
                  <button type='submit' className='btn teal m-b-20 right'>
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/root/master-config`}
                    className='btn grey m-b-20 right m-r-8'
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
