import adminFeeList from './adminFeeList';
import adminFeeCreate from './adminFeeCreate';
import adminFeeDetail from './adminFeeDetail';

export * from './adminFeeList';
export * from './adminFeeCreate';
export * from './adminFeeDetail';

const adminFeeReducers = {
  adminFeeList,
  adminFeeCreate,
  adminFeeDetail,
};

export default adminFeeReducers;
