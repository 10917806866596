import { createSlice } from '@reduxjs/toolkit';
import { restPostPatientVisitCreate } from '../../api/patientVisit';
import {
  isValidJSON,
  momentInputDateToNumberDate,
  successNotif,
  errorNotif,
} from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    visitDate: '',
    doctorDepartmentId: '',
    doctorDepartmentName: '',
    patientId: '',
  },
};

const patientVisitCreateSlice = createSlice({
  name: 'patientVisitCreate',
  initialState: initialState,
  reducers: {
    patientVisitCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientVisitCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        visitDate: '',
        doctorDepartmentId: '',
        doctorDepartmentName: '',
        patientId: '',
      };
    },
    postPatientVisitCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientVisitCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientVisitCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientVisitCreateSetForm,
  patientVisitCreateInit,
  postPatientVisitCreateStart,
  postPatientVisitCreateSuccess,
  postPatientVisitCreateError,
} = patientVisitCreateSlice.actions;

export default patientVisitCreateSlice.reducer;

export const fetchPostPatientVisitCreate = ({
  next,
  visitDate = '',
  doctorDepartmentId = '',
  doctorDepartmentName = '',
  patientId = '',
}) => async (dispatch, getState) => {
  visitDate = momentInputDateToNumberDate(visitDate);
  if (!visitDate) {
    return dispatch(
      postPatientVisitCreateError('Tanggal kunjungan harus diisi')
    );
  }

  if (doctorDepartmentId.length < 3) {
    return dispatch(postPatientVisitCreateError('Poliklinik harus dipilih'));
  }
  if (doctorDepartmentName.length < 3) {
    return dispatch(postPatientVisitCreateError('Poliklinik harus dipilih'));
  }
  if (!patientId) {
    return dispatch(postPatientVisitCreateError('Pasien belum dipilih'));
  }

  const { isLoading } = getState().patientVisitCreate;
  if (isLoading) return;

  try {
    dispatch(postPatientVisitCreateStart());
    await restPostPatientVisitCreate({
      visitDate,
      doctorDepartmentId,
      doctorDepartmentName,
      patientId,
    });
    dispatch(postPatientVisitCreateSuccess());
    successNotif(
      `Berhasil menambah pasien untuk masuk poli, ${doctorDepartmentName}`
    );
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const errString = JSON.parse(err).message;
      errorNotif(errString);
      return dispatch(postPatientVisitCreateError(errString));
    }
    dispatch(postPatientVisitCreateError(err));
  }
};
