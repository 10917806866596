import { ROLE_LIST_ROOT } from '../config';

export const parseDepartmentName = ({ listDepartment = [], departmentId }) => {
  if (listDepartment.length < 1) {
    return '';
  }
  if (departmentId === 'doctor') {
    return 'Semua Poli';
  }
  const found = listDepartment.find((dpt) => dpt._id === departmentId);
  if (!found || !found.name) {
    return '';
  }
  return found.name;
};

export const parseMainComplaintName = ({
  listMainComplaint = [],
  mainComplaintId = '',
}) => {
  if (listMainComplaint.length < 1) {
    return '';
  }
  const found = listMainComplaint.find((d) => d._id === mainComplaintId);
  if (!found || !found.name) {
    return '';
  }
  return found.name;
};

export const parseAllergicName = ({ listAllergic = [], allergicId = '' }) => {
  if (listAllergic.length < 1) {
    return '';
  }
  const found = listAllergic.find((d) => d._id === allergicId);
  if (!found || !found.name) {
    return '';
  }
  return found.name;
};

export const parseDiagnosisName = ({
  listDiagnosis = [],
  diagnosisId = '',
}) => {
  if (listDiagnosis.length < 1) {
    return '';
  }
  const found = listDiagnosis.find((d) => d._id === diagnosisId);
  if (!found || !found.name) {
    return '';
  }
  return found.name;
};

export const parseDiagnosisCode = ({
  listDiagnosis = [],
  diagnosisId = '',
}) => {
  if (listDiagnosis.length < 1) {
    return '';
  }
  const found = listDiagnosis.find((d) => d._id === diagnosisId);
  if (!found || !found.icd10) {
    return '';
  }
  return found.icd10;
};

export const parseRoleName = ({ roleId }) => {
  if (ROLE_LIST_ROOT.length < 1) {
    return '';
  }
  const found = ROLE_LIST_ROOT.find((role) => role._id === roleId);
  if (!found || !found.name) {
    return '';
  }
  return found.name;
};
