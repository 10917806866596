import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isRoleReadOnly } from '../../helper';
import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';

import {
  userCreateSetForm,
  userCreateInit,
  fetchAdminPostUserCreate,
} from '../../store/user';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-user`,
    name: 'Master User',
  },
  {
    url: `/admin/master-user/create`,
    name: 'Tambah Baru',
  },
];

const blockButton = () => {
  if (isRoleReadOnly()) {
    return true;
  }
};

export const UserCreate = () => {
  const dispatch = useDispatch();
  const $userCreate = useSelector((state) => state.userCreate);
  const { form } = $userCreate;
  const { push } = useHistory();

  useEffect(() => {
    const didMount = () => {
      dispatch(userCreateInit());
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    if (blockButton()) {
      return;
    }
    dispatch(
      fetchAdminPostUserCreate({
        push,
        isVerified: form.isVerified,
        name: form.name,
        email: form.email,
        password: form.password,
      })
    );
  };
  return (
    <Fragment>
      {$userCreate.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Master User Tambah Baru'}
        backURL={'/admin/master-user'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <div className="cursor-pointer blue-text p-t-4">
                    {form.isVerified ? (
                      <i
                        onClick={() =>
                          dispatch(
                            userCreateSetForm({
                              key: 'isVerified',
                              value: false,
                            })
                          )
                        }
                        className="fa fa-check-square fa-2x"
                      ></i>
                    ) : (
                      <i
                        onClick={() =>
                          dispatch(
                            userCreateSetForm({
                              key: 'isVerified',
                              value: true,
                            })
                          )
                        }
                        className="fa fa-square-o fa-2x"
                      ></i>
                    )}
                  </div>
                  <label className="active" htmlFor="_id">
                    Verifikasi Email?
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        userCreateSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    type="text"
                    className="validate"
                  />
                  <label className="active">Nama</label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.email}
                    onChange={(e) =>
                      dispatch(
                        userCreateSetForm({
                          key: 'email',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Email"
                    type="email"
                    className="validate"
                  />
                  <label className="active">Email</label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.password}
                    onChange={(e) =>
                      dispatch(
                        userCreateSetForm({
                          key: 'password',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Password"
                    type="password"
                    className="validate"
                  />
                  <label className="active">Password</label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$userCreate.error}
                  </div>
                </div>
                <div className="col s12">
                  <button
                    type="submit"
                    className="btn teal m-b-20 right"
                    disabled={blockButton()}
                  >
                    Tambah Baru
                  </button>
                  <Link
                    to={`/admin/master-user`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
