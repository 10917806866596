export const ROLE_LIST = [
  {
    _id: 'super-admin',
    name: 'Super Admin',
  },
  {
    _id: 'admin',
    name: 'Admin',
  },
  {
    _id: 'doctor',
    name: 'Dokter',
  },
  {
    _id: 'nurse',
    name: 'Perawat',
  },
  {
    _id: 'student',
    name: 'Mahasiswa',
  },
  {
    _id: 'operator',
    name: 'Operator',
  },
  {
    _id: 'read-only',
    name: 'Read Only',
  },
];

export const ROLE_LIST_ROOT = [
  {
    _id: 'root',
    name: 'Root',
  },
  {
    _id: 'super-admin',
    name: 'Super Admin',
  },
  {
    _id: 'admin',
    name: 'Admin',
  },
  {
    _id: 'doctor',
    name: 'Dokter',
  },
  {
    _id: 'nurse',
    name: 'Perawat',
  },
  {
    _id: 'student',
    name: 'Mahasiswa',
  },
  {
    _id: 'operator',
    name: 'Operator',
  },
  {
    _id: 'read-only',
    name: 'Read Only',
  },
];
