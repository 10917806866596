import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
} from '../../component/common';
import {
  confirmNotif,
  momentGeneral,
  parsePersonTypeName,
  momentNumberDateToDisplayDate,
  parseDepartmentName,
} from '../../helper';
import {
  fetchAdminGetPersonList,
  fetchAdminPostPersonArchive,
} from '../../store/person';
import { PERSON_TYPE } from '../../config';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-people`,
    name: 'Master Data Pegawai',
  },
];

export const PeopleList = () => {
  const dispatch = useDispatch();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  let listDepartmentFilter = [];

  const $personList = useSelector((state) => state.personList);
  const $personDetail = useSelector((state) => state.personDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  if (queryParams.type) {
    listDepartmentFilter = listDepartment.filter(
      (dpt) => dpt.type === queryParams.type
    );
  }

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetPersonList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan menghapus ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostPersonArchive({
            isArchived: true,
            personId: data._id,
            name: data.name,
            next: () => {
              dispatch(fetchAdminGetPersonList(queryParams));
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($personList.isLoading || $personDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Pegawai'}
        backURL={'/admin/super-admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetPersonList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPersonList(newQueryParams));
              }}
            />
            <FilterSelect
              options={PERSON_TYPE}
              filterKey={`type`}
              defaultText={`Semua Tipe`}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPersonList(newQueryParams));
              }}
            />
            <FilterSelect
              options={listDepartmentFilter}
              filterKey={`departmentId`}
              defaultText={`Semua Departemen`}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPersonList(newQueryParams));
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Tipe</th>
                  <th>Tgl Bergabung</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Departemen</th>
                  <th>Email</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$personList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>{parsePersonTypeName(data.type)}</td>
                      <td>{momentNumberDateToDisplayDate(data.joinDate)}</td>
                      <td>
                        <Link
                          to={`/admin/super-admin/master-people/update/${data._id}`}
                        >
                          {`${data.prefix} ${data.name} ${data.suffix}`}
                        </Link>
                      </td>
                      <td>{data.mobile}</td>
                      <td>
                        {parseDepartmentName({
                          listDepartment,
                          departmentId: data.departmentId,
                        })}
                      </td>
                      <td>{data.email}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmArchive({ e, data })}
                          className='red-text cursor-pointer font-16'
                        >
                          <i className='fa fa-times'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPersonList(newQueryParams));
              }}
              totalItem={$personList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
