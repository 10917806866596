import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { PatientVisitList } from './PatientVisitList';
import { PatientVisitSearch } from './PatientVisitSearch';
import { PatientVisitNew } from './PatientVisitNew';
import { PatientVisitUpdate } from './PatientVisitUpdate';

export const PatientVisitRoute = () => {
  const pathname = '/patient-visit';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PatientVisitList} />
      <Route exact path={`${pathname}/search`} component={PatientVisitSearch} />
      <Route exact path={`${pathname}/new`} component={PatientVisitNew} />
      <Route
        exact
        path={`${pathname}/update/:patientVisitId`}
        component={PatientVisitUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
