import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { confirmNotif, isRoleSuperAdmin } from '../../../helper';
import { fetchAdminPostUserRemoveAllSession } from '../../../store/user';
import { fetchPostAuthLogout } from '../../../store/auth';
import { LoaderBlock } from '../../../component/common';

const Div = styled.div`
  .menus {
    /* ALIAS */
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .menu-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 18px;
    text-align: center;
  }
  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 14em;
  }

  .cursor-pointer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
  }
  .menu-icon {
    font-size: 30px !important;
  }

  .menu-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }

  .empty-min-width {
    min-width: 14em;
  }
`;

export const AdminMenu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [listMenuUi, setListMenuUi] = useState([]);
  const $userDetail = useSelector((state) => state.userDetail);

  const emptyMenu = {
    url: '',
    icon: 'fa fa-battery-empty',
    name: 'empty',
  };
  let listMenu = [
    {
      url: `${pathname}/master-user`,
      icon: 'fa fa-users',
      name: 'Master User',
    },
    {
      url: `${pathname}/master-user-role`,
      icon: 'fa fa-lock',
      name: 'Master Akses User',
    },
    {
      url: `${pathname}/master-doctor`,
      icon: 'fa fa-user-md',
      name: 'Master Data Dokter',
    },
    {
      url: `${pathname}/master-nurse`,
      icon: 'fa fa-female',
      name: 'Master Data Perawat',
    },
    {
      url: `${pathname}/master-student`,
      icon: 'fa fa-graduation-cap',
      name: 'Master Data Mahasiswa Co-Asisten',
    },
    {
      url: `${pathname}/master-staff`,
      icon: 'fa fa-id-card',
      name: 'Master Data Staf',
    },
    {
      url: `${pathname}/master-cleaning-service`,
      icon: 'fa fa-recycle',
      name: 'Master Data Cleaning Service',
    },
    {
      url: `${pathname}/master-patient`,
      icon: 'fa fa-file-text',
      name: 'Master Data Medical Record Pasien',
    },
    {
      url: `${pathname}/master-main-complaint`,
      icon: 'fa fa-frown-o',
      name: 'Master Data Keluhan Utama',
    },
    {
      url: `${pathname}/master-allergic`,
      icon: 'fa fa-braille',
      name: 'Master Data Riwayat Alergi',
    },
    {
      url: `${pathname}/master-diagnosis`,
      icon: 'fa fa-heartbeat',
      name: 'Master Data Diagnosis',
    },
    {
      url: `${pathname}/master-admin-fee`,
      icon: 'fa fa-money',
      name: 'Master Data Biaya Admin',
    },
    {
      url: `${pathname}/master-action`,
      icon: 'fa fa-stethoscope',
      name: 'Master Data Tindakan',
    },
    {
      url: `${pathname}/master-substance`,
      icon: 'fa fa-flask',
      name: 'Master Data Bahan',
    },
    {
      url: `${pathname}/logout-all-user`,
      type: 'function',
      icon: 'fa fa-window-restore',
      function: () => {
        confirmNotif({
          msg: `Anda yakin akan logout semua pegawai ?`,
          onOK: () => {
            dispatch(
              fetchAdminPostUserRemoveAllSession({
                next: () => {
                  dispatch(fetchPostAuthLogout());
                },
              })
            );
          },
        });
      },
      name: 'Logout Semua Pegawai',
    },
  ];

  if (isRoleSuperAdmin()) {
    listMenu.push({
      url: `${pathname}/super-admin`,
      icon: 'fa fa-codepen',
      name: 'Super Admin',
    });
  }

  useEffect(() => {
    const handleMenuPerCase = () => {
      let newListMenuUi = [];
      handleMenuRemainder();

      function handleMenuRemainder() {
        if (listMenu.length % 4 > 0) {
          // Insert empty remainder
          const remainder = 4 - (listMenu.length % 4);
          for (let idx = 0; idx < remainder; idx++) {
            listMenu.push(emptyMenu);
          }
        }

        handleMenuUi();
      }
      function handleMenuUi() {
        let newMenu = [];

        listMenu.forEach((v, idx) => {
          newMenu.push(v);

          if ((idx + 1) % 4 === 0) {
            newListMenuUi.push(newMenu);

            newMenu = [];
          }
        });
        setListMenuUi(newListMenuUi);
      }
    };

    if (listMenuUi.length < 1) {
      handleMenuPerCase();
    }
  }, [listMenuUi, emptyMenu, listMenu]);

  let UiMenuFourth = listMenuUi.map((menuFourth, idxFourth) => {
    let UiMenu = menuFourth.map((menu, idx) => {
      let UiMenuItem = (
        <div key={`${idxFourth}-${idx}`} className='empty-min-width' />
      );
      if (menu.type === 'link') {
        UiMenuItem = (
          <a
            href={menu.url}
            key={`${idxFourth}-${idx}`}
            className={'teal-text'}
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </a>
        );
      } else if (menu.type === 'function') {
        UiMenuItem = (
          <span
            onClick={menu.function}
            className={'teal-text'}
            key={`${idxFourth}-${idx}`}
          >
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </span>
        );
      } else if (menu.url) {
        UiMenuItem = (
          <Link to={menu.url} className='teal-text' key={`${idxFourth}-${idx}`}>
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </Link>
        );
      }
      return UiMenuItem;
    });
    return (
      <div className='menu-row' key={`${idxFourth}`}>
        {UiMenu}
      </div>
    );
  });

  return (
    <Div className='menus'>
      {$userDetail.isLoading && <LoaderBlock />}
      {UiMenuFourth}
    </Div>
  );
};
