import { createSlice } from '@reduxjs/toolkit';
import {
  restGetUserList as restRootGetUserList,
  restGetUserListArchived as restRootGetUserListArchived,
} from '../../apiRoot/user';
import {
  restGetUserList as restAdminGetUserList,
  restGetUserListArchived as restAdminGetUserListArchived,
} from '../../apiAdmin/user';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const userListSlice = createSlice({
  name: 'userList',
  initialState: initialState,
  reducers: {
    userListListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    userListListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    userListListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  userListListStart,
  userListListSuccess,
  userListListError,
} = userListSlice.actions;

export default userListSlice.reducer;

export const fetchAdminGetUserList = ({
  isArchived = false,
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().userList;
  if (isLoading) return;
  try {
    dispatch(userListListStart());
    let listData = [];
    let totalItem = 0;
    let resp = {};
    if (isArchived) {
      resp = await restAdminGetUserListArchived({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    } else {
      resp = await restAdminGetUserList({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    }

    listData = resp.listData;
    totalItem = resp.totalItem;
    dispatch(
      userListListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(userListListError(JSON.parse(err).message));
    }
    dispatch(userListListError(err));
  }
};

export const fetchRootGetUserList = ({
  isArchived = false,
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().userList;
  if (isLoading) return;
  try {
    dispatch(userListListStart());
    let listData = [];
    let totalItem = 0;
    let resp = {};
    if (isArchived) {
      resp = await restRootGetUserListArchived({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    } else {
      resp = await restRootGetUserList({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    }

    listData = resp.listData;
    totalItem = resp.totalItem;
    dispatch(
      userListListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(userListListError(JSON.parse(err).message));
    }
    dispatch(userListListError(err));
  }
};
