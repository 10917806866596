import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from "../../component/common";
import { confirmNotif, momentGeneral, formatCurrency } from "../../helper";
import {
  fetchAdminGetSubstanceList,
  fetchAdminPostSubstanceArchive,
} from "../../store/substance";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: "Admin",
  },
  {
    url: `/admin/super-admin`,
    name: "Super Admin",
  },
  {
    url: `/admin/super-admin/master-substance-archive-list`,
    name: "Master Data Bahan Arsip",
  },
];

export const SubstanceArchiveList = () => {
  const dispatch = useDispatch();
  const $substanceList = useSelector((state) => state.substanceList);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(
        fetchAdminGetSubstanceList({ ...queryParams, isArchived: true })
      );
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      title: `Konfirmasi Kembalikan`,
      msg: `Anda yakin akan mengembalikan ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostSubstanceArchive({
            isArchived: false,
            substanceId: data._id,
            name: data.name,
            next: () => {
              dispatch(
                fetchAdminGetSubstanceList({ ...queryParams, isArchived: true })
              );
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {$substanceList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Master Data Bahan Arsip"}
        backURL={"/admin/-super-admin"}
        onRefresh={() => {
          dispatch(fetchAdminGetSubstanceList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetSubstanceList(newQueryParams));
              }}
              sortOptions={[
                {
                  key: "createdAt",
                  name: "Dibuat",
                },
                {
                  key: "updatedAt",
                  name: "Diubah",
                },
                {
                  key: "price",
                  name: "Tarif",
                },
                {
                  key: "name",
                  name: "Nama",
                },
              ]}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/admin/master-substance/create`}
                    className="btn-small teal white-text"
                  >
                    <i className="fa fa-plus" />
                  </Link>
                );
              }}
            />
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Tarif</th>
                  <th>Unit</th>
                  <th>Dihapus</th>
                  <th>Dihapus Uleh</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$substanceList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={`/admin/master-substance/update/${data._id}`}>
                          {data.name}
                        </Link>
                      </td>
                      <td>{formatCurrency(data.price)}</td>
                      <td>{data.unit}</td>
                      <td>{momentGeneral(data.deletedAt)}</td>
                      <td>{data.deletedByName}</td>
                      <td>
                        <span
                          onClick={(e) => confirmArchive({ e, data })}
                          className="blue-text cursor-pointer font-16"
                        >
                          <i className="fa fa-reply"></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetSubstanceList(newQueryParams));
              }}
              totalItem={$substanceList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
