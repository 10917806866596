import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { DoctorList } from './DoctorList';
import { DoctorCreate } from './DoctorCreate';
import { DoctorUpdate } from './DoctorUpdate';

export const MasterDoctorRoute = () => {
  const pathname = '/admin/master-doctor';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={DoctorList} />
      <Route exact path={`${pathname}/create`} component={DoctorCreate} />
      <Route
        exact
        path={`${pathname}/update/:personId`}
        component={DoctorUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
