import patientList from './patientList';
import patientCreate from './patientCreate';
import patientDetail from './patientDetail';

export * from './patientList';
export * from './patientCreate';
export * from './patientDetail';

const patientReducers = {
  patientList,
  patientCreate,
  patientDetail,
};

export default patientReducers;
