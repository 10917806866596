import { createSlice } from '@reduxjs/toolkit';
import {
  restGetUserAccessList as restRootGetUserAccessList,
  restPostUserAccessUpdate as restRootPostUserAccessUpdate,
} from '../../apiRoot/userAccess';
import {
  restGetUserAccessList as restAdminGetUserAccessList,
  restPostUserAccessUpdate as restAdminPostUserAccessUpdate,
} from '../../apiAdmin/userAccess';

import { isValidJSON, errorNotif } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const userRoleListSlice = createSlice({
  name: 'userRoleList',
  initialState: initialState,
  reducers: {
    getUserRoleListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getUserRoleListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getUserRoleListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postUserRoleUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserRoleUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserRoleUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getUserRoleListStart,
  getUserRoleListSuccess,
  getUserRoleListError,
  postUserRoleUpdateStart,
  postUserRoleUpdateSuccess,
  postUserRoleUpdateError,
} = userRoleListSlice.actions;

export default userRoleListSlice.reducer;

export const fetchAdminGetUserAccessList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(getUserRoleListStart());
    const { listData, totalItem } = await restAdminGetUserAccessList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getUserRoleListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getUserRoleListError(JSON.parse(err).message));
    }
    dispatch(getUserRoleListError(err));
  }
};

export const fetchAdminPostUserAccessUpdate = ({
  userId,
  isAdd,
  roleId = '',
  roleName = '',
  next,
}) => async (dispatch, getState) => {
  if (!roleId || !roleName) {
    return;
  }
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(postUserRoleUpdateStart());
    await restAdminPostUserAccessUpdate({
      userId,
      isAdd,
      roleId,
      roleName,
    });
    dispatch(postUserRoleUpdateSuccess());
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(postUserRoleUpdateError(msg));
    }
    errorNotif(err);
    dispatch(postUserRoleUpdateError(err));
  }
};

export const fetchRootGetUserRoleList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(getUserRoleListStart());
    const { listData, totalItem } = await restRootGetUserAccessList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getUserRoleListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getUserRoleListError(JSON.parse(err).message));
    }
    dispatch(getUserRoleListError(err));
  }
};

export const fetchRootPostUserAccessUpdate = ({
  userId,
  isAdd,
  roleId = '',
  roleName = '',
  next,
}) => async (dispatch, getState) => {
  if (!roleId || !roleName) {
    return;
  }
  const { isLoading } = getState().userRoleList;
  if (isLoading) return;
  try {
    dispatch(postUserRoleUpdateStart());
    await restRootPostUserAccessUpdate({
      userId,
      isAdd,
      roleId,
      roleName,
    });
    dispatch(postUserRoleUpdateSuccess());
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(postUserRoleUpdateError(msg));
    }
    errorNotif(err);
    dispatch(postUserRoleUpdateError(err));
  }
};
