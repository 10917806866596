import { createSlice } from '@reduxjs/toolkit';
import { restPostSubstanceCreate as restAdminPostSubstanceCreate } from '../../apiAdmin/substance';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    name: '',
    price: 0,
    unit: '',
  },
};

const substanceCreateSlice = createSlice({
  name: 'substanceCreate',
  initialState: initialState,
  reducers: {
    substanceCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    substanceCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
        price: 0,
        unit: '',
      };
    },
    postSubstanceCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postSubstanceCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postSubstanceCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  substanceCreateSetForm,
  substanceCreateInit,
  postSubstanceCreateStart,
  postSubstanceCreateSuccess,
  postSubstanceCreateError,
} = substanceCreateSlice.actions;

export default substanceCreateSlice.reducer;

export const fetchAdminPostSubstanceCreate = ({
  next,
  name = '',
  price = 0,
  unit = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (name.length < 3) {
    return dispatch(postSubstanceCreateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postSubstanceCreateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postSubstanceCreateError('Tarif minimal 0'));
  }
  if (unit.length < 1) {
    return dispatch(postSubstanceCreateError('Unit minimal 1 karakter'));
  }

  const { isLoading } = getState().substanceCreate;
  if (isLoading) return;
  try {
    dispatch(postSubstanceCreateStart());
    await restAdminPostSubstanceCreate({
      name,
      price,
      unit,
    });
    dispatch(postSubstanceCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postSubstanceCreateError(JSON.parse(err).message));
    }
    dispatch(postSubstanceCreateError(err));
  }
};
