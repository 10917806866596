import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  Sort,
  // Pagination,
  FilterMonth,
} from "../../component/common";
import { momentNumberDateToDisplayDate, formatCurrency } from "../../helper";
import { fetchGetPatientInvoiceDailyList } from "../../store/patientInvoice";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  @media only screen and (min-width: 601px) {
    .top-separator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 1px;
      margin-right: 1px;
    }
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = [
  {
    url: `/patient-invoice-daily`,
    name: "Rekap Kwitansi Harian",
  },
];
const backURL = `/`;
const detailURL = (invoiceDate, locationSearch) =>
  `/patient-invoice-daily/detail/${invoiceDate}${locationSearch}`;

export const PatientInvoiceDailyList = () => {
  const dispatch = useDispatch();

  const $patientInvoiceDailyList = useSelector(
    (state) => state.patientInvoiceDailyList
  );
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchGetPatientInvoiceDailyList({ ...queryParams }));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$patientInvoiceDailyList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Rekap Kwitansi Harian"}
        backURL={backURL}
        onRefresh={() => {
          dispatch(fetchGetPatientInvoiceDailyList({ ...queryParams }));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <div className="top-separator">
              <FilterMonth
                filterKey={`invoiceMonth`}
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchGetPatientInvoiceDailyList({
                      ...newQueryParams,
                    })
                  );
                }}
              />
              <Sort
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchGetPatientInvoiceDailyList({
                      ...newQueryParams,
                    })
                  );
                }}
                sortOptions={[
                  {
                    key: "createdAt",
                    name: "Dibuat",
                  },
                ]}
              />
            </div>
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>Tgl Invoice</th>
                  <th>Total Invoice</th>
                  <th>Total Biaya Admin</th>
                  <th>Total Tindakan</th>
                  <th>Total Sub Tindakan</th>
                  <th>Total Bahan</th>
                  <th>Grand Total Harian</th>
                </tr>
              </thead>
              <tbody>
                {$patientInvoiceDailyList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={detailURL(data._id, locationSearch)}>
                          {momentNumberDateToDisplayDate(data._id)}
                        </Link>
                      </td>
                      <td>{data.totalInvoice}</td>
                      <td>{formatCurrency(data.sumOfAdministrationPrice)}</td>
                      <td>{formatCurrency(data.sumOfTotalActionPrice)}</td>
                      <td>{formatCurrency(data.sumOfTotalSubActionPrice)}</td>
                      <td>{formatCurrency(data.sumOfTotalSubstancePrice)}</td>
                      <td>{formatCurrency(data.sumOfGrandTotalPrice)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{$patientInvoiceDailyList.sumOf.totalInvoice}</th>
                  <th>
                    {formatCurrency(
                      $patientInvoiceDailyList.sumOf.sumOfAdministrationPrice
                    )}
                  </th>
                  <th>
                    {formatCurrency(
                      $patientInvoiceDailyList.sumOf.sumOfTotalActionPrice
                    )}
                  </th>
                  <th>
                    {formatCurrency(
                      $patientInvoiceDailyList.sumOf.sumOfTotalSubActionPrice
                    )}
                  </th>
                  <th>
                    {formatCurrency(
                      $patientInvoiceDailyList.sumOf.sumOfTotalSubstancePrice
                    )}
                  </th>
                  <th>
                    {formatCurrency(
                      $patientInvoiceDailyList.sumOf.sumOfGrandTotalPrice
                    )}
                  </th>
                </tr>
              </tfoot>
            </table>
            {/* <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchGetPatientInvoiceDailyList({
                    ...newQueryParams,
                  })
                );
              }}
              totalItem={$patientInvoiceDailyList.totalItem}
            /> */}
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
