import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
  FilterDate,
} from '../../component/common';
import {
  parseDepartmentName,
  momentNumberDateToDisplayFullDate,
  parsePatientVisitStatus,
  momentGeneral,
} from '../../helper';
import { fetchGetPatientVisitList } from '../../store/patientVisit';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
  .cards-container {
    font-size: 40px;
  }
  @media only screen and (min-width: 601px) {
    .cards-container {
      margin-left: -11px;
      margin-right: -11px;
    }
  }
  .cyan:hover {
    background-color: #4dd0e1 !important;
  }
  .indigo:hover {
    background-color: #7986cb !important;
  }
`;

const breadCrumbData = [
  {
    url: `/patient-visit`,
    name: 'Pendaftaran Pasien',
  },
];
const backURL = `/`;
const detailURL = (patientVisitId, locationSearch) =>
  `/patient-visit/update/${patientVisitId}${locationSearch}`;
const searchURL = (locationSearch) => `/patient-visit/search${locationSearch}`;
const newURL = (locationSearch) => `/patient-visit/new${locationSearch}`;

export const PatientVisitList = () => {
  const dispatch = useDispatch();
  const $patientVisitList = useSelector((state) => state.patientVisitList);
  const { search: locationSearch } = useLocation();
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(
        fetchGetPatientVisitList({
          ...queryParams,
        })
      );
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {($patientVisitList.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Pendaftaran Pasien'}
        backURL={backURL}
        onRefresh={() => {
          dispatch(
            fetchGetPatientVisitList({
              ...queryParams,
            })
          );
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      {queryParams.visitDate && (
        <Div className='container'>
          <div className='cards-container'>
            <div className='row'>
              <div className='col m6 s12'>
                <Link to={searchURL(locationSearch)}>
                  <div className='card-panel cyan white-text'>Pasien Lama</div>
                </Link>
              </div>
              <div className='col m6 s12'>
                <Link to={newURL(locationSearch)}>
                  <div className='card-panel indigo white-text '>
                    Pasien Baru
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='card m-t-0'>
            <div className='card-content p-0'>
              <SearchSort
                searchPlaceholder='Cari No RM / Nama / No HP'
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchGetPatientVisitList({
                      ...newQueryParams,
                    })
                  );
                }}
                SearchChildren={() => {
                  return (
                    <div className='m-l-16'>
                      {momentNumberDateToDisplayFullDate(queryParams.visitDate)}
                    </div>
                  );
                }}
              />
              <FilterDate
                filterKey={`visitDate`}
                onChange={(newQueryParams) => {
                  dispatch(fetchGetPatientVisitList({ ...newQueryParams }));
                }}
              />
              <FilterSelect
                options={listDepartmentFilter}
                filterKey={`doctorDepartmentId`}
                defaultText={`Semua Poliklinik`}
                onChange={(newQueryParams) => {
                  dispatch(fetchGetPatientVisitList({ ...newQueryParams }));
                }}
              />
              <table className='striped bg-white'>
                <thead>
                  <tr>
                    <th>No Antrian</th>
                    <th>Pasien Baru</th>
                    <th>Poliklinik</th>
                    <th>No RM</th>
                    <th>Nama</th>
                    <th>No HP</th>
                    <th>Status</th>
                    <th>Diubah</th>
                  </tr>
                </thead>
                <tbody>
                  {$patientVisitList.listData.map((data) => {
                    return (
                      <tr key={data._id}>
                        <td>{data.visitQueue}</td>
                        <td>
                          {data.isNewPatient ? (
                            <i className='fa fa-check-square'></i>
                          ) : (
                            <i className='fa fa-square-o'></i>
                          )}
                        </td>
                        <td>
                          {parseDepartmentName({
                            listDepartment: listDepartmentFilter,
                            departmentId: data.doctorDepartmentId,
                          })}
                        </td>
                        <td>{data.patientNoRM}</td>
                        <td>
                          <Link to={detailURL(data._id, locationSearch)}>
                            {data.gender === 'f' ? (
                              <i className='fa fa-female'></i>
                            ) : (
                              <i className='fa fa-male'></i>
                            )}
                            {` `}
                            {`${data.patientFullName}`}
                          </Link>
                        </td>
                        <td>{data.patientMobile}</td>
                        <td>
                          {parsePatientVisitStatus({
                            isCanceled: data.isCanceled,
                            patientDoctorActionId: data.patientDoctorActionId,
                            patientInvoiceId: data.patientInvoiceId,
                          })}
                        </td>
                        <td title={data.updatedByName}>
                          {momentGeneral(data.updatedAt)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                onChange={(newQueryParams) => {
                  dispatch(
                    fetchGetPatientVisitList({
                      ...newQueryParams,
                    })
                  );
                }}
                totalItem={$patientVisitList.totalItem}
              />
            </div>
          </div>
        </Div>
      )}
    </Fragment>
  );
};
