/* eslint-disable */
import React from "react";
import MaskedInput from "react-maskedinput";

export const InputNoRm = ({
  value = "",
  onChange = (e) => console.info(e.target.value),
}) => {
  return (
    <MaskedInput
      mask="W.1111111.11.1111"
      value={value}
      formatCharacters={{
        W: {
          validate(char) {
            return /\w/.test(char);
          },
          transform(char) {
            return char.toUpperCase();
          },
        },
        "1": {
          validate(char) {
            return /^(?=.*\d)[\d ]+$/.test(char);
          },
          transform(char) {
            return char;
          },
        },
      }}
      onChange={onChange}
    />
  );
};
