import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { NurseList } from './NurseList';
import { NurseCreate } from './NurseCreate';
import { NurseUpdate } from './NurseUpdate';

export const MasterNurseRoute = () => {
  const pathname = '/admin/master-nurse';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={NurseList} />
      <Route exact path={`${pathname}/create`} component={NurseCreate} />
      <Route
        exact
        path={`${pathname}/update/:personId`}
        component={NurseUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
