import React, { Fragment } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  padding: 4px;
  margin-right: 8px;
`;

export const Label = ({
  text = '',
  color = 'light-blue',
  textColor = 'white',
}) => {
  if (text) {
    return <Span className={`label ${color} ${textColor}-text`}>{text}</Span>;
  }

  return <Fragment />;
};
