import { createSlice } from '@reduxjs/toolkit';
import {
  restGetPatientDoctorActionList,
  restGetPatientDetail,
} from '../../api/patientDoctorAction';

import { isValidJSON } from '../../helper';

const initialState = {
  patientData: {},
  listData: [],
  totalItem: 0,
  isLoading: false,
  isPatientLoading: false,
  error: null,
};

const patientDoctorActionListSlice = createSlice({
  name: 'patientDoctorActionList',
  initialState: initialState,
  reducers: {
    getPatientDoctorActionListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientDoctorActionListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPatientDoctorActionListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPatientDetailStart(state) {
      state.isPatientLoading = true;
      state.error = null;
    },
    getPatientDetailSuccess(state, action) {
      const { data } = action.payload;
      state.patientData = data;
      state.isPatientLoading = false;
      state.error = null;
    },
    getPatientDetailError(state, action) {
      state.isPatientLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPatientDoctorActionListStart,
  getPatientDoctorActionListSuccess,
  getPatientDoctorActionListError,
  getPatientDetailStart,
  getPatientDetailSuccess,
  getPatientDetailError,
} = patientDoctorActionListSlice.actions;

export default patientDoctorActionListSlice.reducer;

export const fetchGetPatientDoctorActionList = ({
  limit = 10,
  page = 1,
  sort = 'visitDate',
  sortDir = 'desc',
  search = '',
  visitDate = '',
  patientId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientDoctorActionList;
  if (isLoading) return;
  try {
    dispatch(getPatientDoctorActionListStart());
    const { listData, totalItem } = await restGetPatientDoctorActionList({
      limit,
      page,
      sort,
      sortDir,
      search,
      visitDate,
      patientId,
    });

    dispatch(
      getPatientDoctorActionListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientDoctorActionListError(JSON.parse(err).message));
    }
    dispatch(getPatientDoctorActionListError(err));
  }
};

export const fetchGetPatientDetail = ({ patientId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().patientDetail;
  if (isLoading) return;
  try {
    dispatch(getPatientDetailStart());
    const data = await restGetPatientDetail({
      patientId,
    });
    dispatch(
      getPatientDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientDetailError(JSON.parse(err).message));
    }
    dispatch(getPatientDetailError(err));
  }
};
