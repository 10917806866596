import { createSlice } from '@reduxjs/toolkit';
import { restPostSubActionCreate as restAdminPostSubActionCreate } from '../../apiAdmin/subAction';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    doctorDepartmentId: 'doctor',
    name: '',
    price: 0,
  },
};

const subActionCreateSlice = createSlice({
  name: 'subActionCreate',
  initialState: initialState,
  reducers: {
    subActionCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    subActionCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: 'doctor',
        name: '',
        price: 0,
      };
    },
    postSubActionCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postSubActionCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postSubActionCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  subActionCreateSetForm,
  subActionCreateInit,
  postSubActionCreateStart,
  postSubActionCreateSuccess,
  postSubActionCreateError,
} = subActionCreateSlice.actions;

export default subActionCreateSlice.reducer;

export const fetchAdminPostSubActionCreate = ({
  next,
  actionId,
  doctorDepartmentId = '',
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();

  if (actionId.length < 3) {
    return dispatch(
      postSubActionCreateError('Tindakan tidak ada, coba refresh')
    );
  }
  if (doctorDepartmentId.length < 3) {
    return dispatch(postSubActionCreateError('Departemen harus dipilih'));
  }
  if (name.length < 3) {
    return dispatch(postSubActionCreateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postSubActionCreateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postSubActionCreateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().subActionCreate;
  if (isLoading) return;
  try {
    dispatch(postSubActionCreateStart());
    await restAdminPostSubActionCreate({
      actionId,
      doctorDepartmentId,
      name,
      price,
    });
    dispatch(postSubActionCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postSubActionCreateError(JSON.parse(err).message));
    }
    dispatch(postSubActionCreateError(err));
  }
};
