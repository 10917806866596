import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BreadCrumb, HeaderNav, LoaderBlock } from "../../component/common";
import { formatCurrency } from "../../helper";
import {
  substanceDetailSetForm,
  substanceDetailInit,
  fetchAdminGetSubstanceDetail,
  fetchAdminPostSubstanceUpdate,
} from "../../store/substance";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: "Admin",
  },
  {
    url: `/admin/master-substance`,
    name: "Master Data Bahan",
  },
  {
    url: `/admin/master-substance/update`,
    name: "Update",
  },
];

export const SubstanceUpdate = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();
  const $substanceDetail = useSelector((state) => state.substanceDetail);
  const { data, form } = $substanceDetail;
  const { substanceId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(substanceDetailInit());
      if (substanceId) {
        dispatch(fetchAdminGetSubstanceDetail({ substanceId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostSubstanceUpdate({
        next: () => {
          push("/admin/master-substance?sort=updatedAt&sortDir=desc");
        },
        substanceId: data._id,
        name: form.name,
        unit: form.unit,
        price: form.price,
      })
    );
  };

  return (
    <Fragment>
      {$substanceDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Update"}
        backURL={"/admin/master-substance"}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        substanceDetailSetForm({
                          key: "name",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.price}
                    onChange={(e) =>
                      dispatch(
                        substanceDetailSetForm({
                          key: "price",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tarif"
                    id="price"
                    price="price"
                    type="number"
                    className="validate"
                  />
                  <label className="active" htmlFor="price">
                    Tarif <b>{formatCurrency(form.price)}</b>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.unit}
                    onChange={(e) =>
                      dispatch(
                        substanceDetailSetForm({
                          key: "unit",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Unit"
                    id="unit"
                    name="unit"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="unit">
                    Unit
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$substanceDetail.error}
                  </div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right">
                    Simpan Perubahan
                  </button>
                  <Link
                    to={`/admin/master-substance`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
