import { createSlice } from "@reduxjs/toolkit";
import {
  restGetSubstanceList as restAdminGetSubstanceList,
  restGetSubstanceListArchived as restAdminGetSubstanceListArchived,
} from "../../apiAdmin/substance";

import { isValidJSON } from "../../helper";

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const substanceListSlice = createSlice({
  name: "substanceList",
  initialState: initialState,
  reducers: {
    getSubstanceListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSubstanceListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getSubstanceListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSubstanceListStart,
  getSubstanceListSuccess,
  getSubstanceListError,
} = substanceListSlice.actions;

export default substanceListSlice.reducer;

export const fetchAdminGetSubstanceList = ({
  isArchived,
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().substanceList;
  if (isLoading) return;
  try {
    let listData = [];
    let totalItem = 0;
    let resp = {};

    dispatch(getSubstanceListStart());
    if (isArchived) {
      resp = await restAdminGetSubstanceListArchived({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    } else {
      resp = await restAdminGetSubstanceList({
        limit,
        page,
        sort,
        sortDir,
        search,
      });
    }

    listData = resp.listData;
    totalItem = resp.totalItem;

    dispatch(
      getSubstanceListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getSubstanceListError(JSON.parse(err).message));
    }
    dispatch(getSubstanceListError(err));
  }
};
