import { createSlice } from '@reduxjs/toolkit';
import { restGetConfigList as restRootGetConfigList } from '../../apiRoot/config';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const configListSlice = createSlice({
  name: 'configList',
  initialState: initialState,
  reducers: {
    getConfigListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getConfigListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getConfigListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getConfigListStart,
  getConfigListSuccess,
  getConfigListError,
} = configListSlice.actions;

export default configListSlice.reducer;

export const fetchRootGetConfigList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().configList;
  if (isLoading) return;
  try {
    dispatch(getConfigListStart());
    const { listData, totalItem } = await restRootGetConfigList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getConfigListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getConfigListError(JSON.parse(err).message));
    }
    dispatch(getConfigListError(err));
  }
};
