import { createSlice } from "@reduxjs/toolkit";
import {
  restGetPersonDetail as restAdminGetPersonDetail,
  restPostPersonUpdate as restAdminPostPersonUpdate,
  restPostPersonArchive as restAdminPostPersonArchive,
} from "../../apiAdmin/person";
import {
  isValidJSON,
  momentNumberDateToInputDate,
  momentInputDateToNumberDate,
  successNotif,
  warningNotif,
  errorNotif,
} from "../../helper";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    joinDate: "",
    gender: "",
    prefix: "",
    suffix: "",
    name: "",
    personNo: "",
    mobile: "",
    departmentId: "",
  },
};

const personDetailSlice = createSlice({
  name: "personDetail",
  initialState: initialState,
  reducers: {
    personDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    personDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        joinDate: "",
        gender: "",
        prefix: "",
        suffix: "",
        name: "",
        personNo: "",
        mobile: "",
        departmentId: "",
      };
    },
    getPersonDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPersonDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        joinDate: momentNumberDateToInputDate(data.joinDate),
        gender: data.gender,
        prefix: data.prefix,
        suffix: data.suffix,
        name: data.name,
        personNo: data.personNo,
        mobile: data.mobile,
        departmentId: data.departmentId,
      };
    },
    getPersonDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPersonUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPersonUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPersonUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPersonDeleteStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPersonDeleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPersonDeleteError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  personDetailSetForm,
  personDetailInit,
  getPersonDetailStart,
  getPersonDetailSuccess,
  getPersonDetailError,
  postPersonUpdateStart,
  postPersonUpdateSuccess,
  postPersonUpdateError,
  postPersonDeleteStart,
  postPersonDeleteSuccess,
  postPersonDeleteError,
} = personDetailSlice.actions;

export default personDetailSlice.reducer;

export const fetchAdminGetPersonDetail = ({ personId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().personDetail;
  if (isLoading) return;
  try {
    dispatch(getPersonDetailStart());
    const data = await restAdminGetPersonDetail({
      personId,
    });
    dispatch(
      getPersonDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPersonDetailError(JSON.parse(err).message));
    }
    dispatch(getPersonDetailError(err));
  }
};

export const fetchAdminPostPersonUpdate = ({
  next,
  personId,
  joinDate = "",
  gender = "",
  prefix = "",
  suffix = "",
  name = "",
  personNo = "",
  mobile = "",
  departmentId = "",
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!personId) {
    return dispatch(postPersonUpdateError("ID tidak ditemukan"));
  }
  joinDate = momentInputDateToNumberDate(joinDate);
  if (!joinDate) {
    return dispatch(postPersonUpdateError("Tanggal bergabung harus diisi"));
  }
  if (!gender) {
    return dispatch(postPersonUpdateError("Silahkan pilih gender"));
  }
  if (!(gender === "m" || gender === "f")) {
    return dispatch(postPersonUpdateError("Gender Salah"));
  }
  if (name.length < 3) {
    return dispatch(postPersonUpdateError("Nama minimal 3 karakter"));
  }
  if (personNo.length < 3) {
    return dispatch(postPersonUpdateError("Nomor Induk minimal 3 karakter"));
  }
  if (mobile.length < 5) {
    return dispatch(postPersonUpdateError("No HP Terlalu Pendek"));
  }
  if (!departmentId) {
    return dispatch(postPersonUpdateError("Pastikan sudah memilih department"));
  }

  const { isLoading } = getState().personDetail;
  if (isLoading) return;
  try {
    dispatch(postPersonUpdateStart());
    await restAdminPostPersonUpdate({
      personId,
      joinDate,
      gender,
      prefix,
      suffix,
      name,
      personNo,
      mobile,
      departmentId,
    });
    dispatch(postPersonUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPersonUpdateError(JSON.parse(err).message));
    }
    dispatch(postPersonUpdateError(err));
  }
};

export const fetchAdminPostPersonArchive = ({
  next,
  isArchived,
  personId = "",
  name = "",
}) => async (dispatch, getState) => {
  const savedName = name;

  if (!personId) {
    errorNotif("ID tidak ditemukan");
    return dispatch(postPersonDeleteError("ID tidak ditemukan"));
  }
  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postPersonDeleteStart());
    await restAdminPostPersonArchive({
      personId,
      isArchived,
    });
    dispatch(postPersonDeleteSuccess());
    if (isArchived) {
      warningNotif(`Berhasil arsipkan ${savedName}`);
    } else {
      successNotif(`Berhasil mengembalikan ${savedName}`);
    }
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postPersonDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postPersonDeleteError(err));
  }
};
