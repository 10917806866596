import { createSlice } from '@reduxjs/toolkit';
import { restPostDepartmentCreate as restAdminPostDepartmentCreate } from '../../apiAdmin/department';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    type: 'unSelect',
    name: '',
  },
};

const departmentCreateSlice = createSlice({
  name: 'departmentCreate',
  initialState: initialState,
  reducers: {
    departmentCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    departmentCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: 'unSelect',
        name: '',
      };
    },
    postDepartmentCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postDepartmentCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postDepartmentCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  departmentCreateSetForm,
  departmentCreateInit,
  postDepartmentCreateStart,
  postDepartmentCreateSuccess,
  postDepartmentCreateError,
} = departmentCreateSlice.actions;

export default departmentCreateSlice.reducer;

export const fetchAdminPostDepartmentCreate = ({
  push,
  type = 'unSelect',
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (type.length < 3) {
    return dispatch(postDepartmentCreateError('Type harus ada'));
  }
  if (name.length < 3) {
    return dispatch(postDepartmentCreateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().departmentCreate;
  if (isLoading) return;
  try {
    dispatch(postDepartmentCreateStart());
    await restAdminPostDepartmentCreate({
      type,
      name,
    });
    dispatch(postDepartmentCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push)
      push('/admin/super-admin/master-department?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postDepartmentCreateError(JSON.parse(err).message));
    }
    dispatch(postDepartmentCreateError(err));
  }
};
