import React from "react";
import { Switch, Route } from "react-router-dom";

import { RootLayout } from "./RootLayout";
import { AdminLayout } from "./AdminLayout";

import { Page404 } from "../page/common";
import { Home } from "../page/home";
import { PatientVisitRoute } from "../page/patientVisit";
import { PatientDoctorActionRoute } from "../page/patientDoctorAction";
import { PatientInvoiceRoute } from "../page/patientInvoice";
import { PatientInvoiceDailyRoute } from "../page/patientInvoiceDaily";
import { MedicalRecordRoute } from "../page/medicalRecord";

export const MainLayout = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/patient-visit" component={PatientVisitRoute} />
      <Route
        path="/patient-doctor-action"
        component={PatientDoctorActionRoute}
      />
      <Route path="/patient-invoice" component={PatientInvoiceRoute} />
      <Route
        path="/patient-invoice-daily"
        component={PatientInvoiceDailyRoute}
      />
      <Route path="/medical-record" component={MedicalRecordRoute} />

      <Route path="/root" component={RootLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Route component={Page404} />
    </Switch>
  );
};
