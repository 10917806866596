import fetchApi from "../api/fetchApi";
import { BASE_API_ADMIN } from "../config";

// GET
export function restGetPersonList({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  type = "",
  departmentId = "",
}) {
  let path = `${BASE_API_ADMIN}/person/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&type=${type}`;
  path += `&departmentId=${departmentId}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPersonListArchived({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
  type = "",
  departmentId = "",
}) {
  let path = `${BASE_API_ADMIN}/person/list-archived`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&type=${type}`;
  path += `&departmentId=${departmentId}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetPersonDetail({ personId }) {
  const path = `${BASE_API_ADMIN}/person/detail/${personId}`;
  return fetchApi(path, {
    method: "GET",
  });
}

// POST
export function restPostPersonCreate({
  joinDate,
  type,
  gender,
  prefix,
  suffix,
  name,
  personNo,
  mobile,
  departmentId,
}) {
  const path = `${BASE_API_ADMIN}/person/create`;
  return fetchApi(path, {
    method: "POST",
    body: {
      joinDate,
      type,
      gender,
      prefix,
      suffix,
      name,
      personNo,
      mobile,
      departmentId,
    },
  });
}

export function restPostPersonUpdate({
  personId,
  joinDate,
  gender,
  prefix,
  suffix,
  name,
  personNo,
  mobile,
  departmentId,
}) {
  const path = `${BASE_API_ADMIN}/person/update/${personId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      joinDate,
      gender,
      prefix,
      suffix,
      name,
      personNo,
      mobile,
      departmentId,
    },
  });
}

export function restPostPersonArchive({ personId, isArchived }) {
  const path = `${BASE_API_ADMIN}/person/archive/${personId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      isArchived,
    },
  });
}
