import { createSlice } from '@reduxjs/toolkit';
import {
  restGetPasswordResetList as restRootGetPasswordResetList,
  restPostPasswordResetDeleteAll as restRootPostPasswordResetDeleteAll,
} from '../../apiRoot/passwordReset';
import {
  restGetPasswordResetList as restAdminGetPasswordResetList,
  restPostPasswordResetDeleteAll as restAdminPostPasswordResetDeleteAll,
} from '../../apiAdmin/passwordReset';

import { isValidJSON, warningNotif } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const passwordResetListSlice = createSlice({
  name: 'passwordResetList',
  initialState: initialState,
  reducers: {
    getPasswordResetListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPasswordResetListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPasswordResetListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postPasswordResetDeleteAllStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPasswordResetDeleteAllSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPasswordResetDeleteAllError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPasswordResetListStart,
  getPasswordResetListSuccess,
  getPasswordResetListError,
  postPasswordResetDeleteAllStart,
  postPasswordResetDeleteAllSuccess,
  postPasswordResetDeleteAllError,
} = passwordResetListSlice.actions;

export default passwordResetListSlice.reducer;

export const fetchAdminGetPasswordResetList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().passwordResetList;
  if (isLoading) return;
  try {
    dispatch(getPasswordResetListStart());
    const { listData, totalItem } = await restAdminGetPasswordResetList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getPasswordResetListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPasswordResetListError(JSON.parse(err).message));
    }
    dispatch(getPasswordResetListError(err));
  }
};

export const fetchAdminPostPasswordResetDeleteAll = ({ next }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().passwordResetList;
  if (isLoading) return;
  try {
    dispatch(postPasswordResetDeleteAllStart());
    await restAdminPostPasswordResetDeleteAll();
    dispatch(postPasswordResetDeleteAllSuccess());
    warningNotif('Semua data berhasil dihapus');
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPasswordResetDeleteAllError(JSON.parse(err).message));
    }
    dispatch(postPasswordResetDeleteAllError(err));
  }
};

export const fetchRootGetPasswordResetList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().passwordResetList;
  if (isLoading) return;
  try {
    dispatch(getPasswordResetListStart());
    const { listData, totalItem } = await restRootGetPasswordResetList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getPasswordResetListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPasswordResetListError(JSON.parse(err).message));
    }
    dispatch(getPasswordResetListError(err));
  }
};

export const fetchRootPostPasswordResetDeleteAll = ({ next }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().passwordResetList;
  if (isLoading) return;
  try {
    dispatch(postPasswordResetDeleteAllStart());
    await restRootPostPasswordResetDeleteAll();
    dispatch(postPasswordResetDeleteAllSuccess());
    warningNotif('Semua data berhasil dihapus');
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPasswordResetDeleteAllError(JSON.parse(err).message));
    }
    dispatch(postPasswordResetDeleteAllError(err));
  }
};
