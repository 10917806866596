import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const Form = styled.form`
  display: flex;
  align-items: center;
  .sort-box {
    /* container class alias */
  }
  input[type='text'] {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
    margin-bottom: 0px;
  }
  input[type='text'] {
    display: inline-block;
    width: 235px;
    height: 23px;
  }
  .input-icon-right {
    position: relative;
  }
  .input-icon-right > i {
    margin: -9px 0px 0px -24px;
    z-index: 4;
    display: inline-block;
    position: absolute;
    width: 16px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
`;

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const Search = ({
  searchPlaceholder = 'Pencarian',
  defaultSearch = '',
  minLength = 3,
  onChange = defaultOnChange,
  children = <span className='searchSlot'></span>,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();
  const [inputSearch, setInputSearch] = useState('');
  const [error, setError] = useState('');

  const queryParams = queryString.parse(locationSearch);
  const search = queryParams.search ? queryParams.search : defaultSearch;

  useEffect(() => {
    const didMount = () => {
      setInputSearch(search);
      setError('');
    };
    didMount();
  }, []); // eslint-disable-line

  const setSearch = (e) => {
    e.preventDefault();
    const v = inputSearch;
    setError('');
    if (v && v.length < minLength) {
      return setError(`Minimal ${minLength} karakter`);
    }
    const newQueryParams = queryParams;
    newQueryParams.search = v;
    if (
      newQueryParams.search === defaultSearch ||
      newQueryParams.search === ''
    ) {
      delete newQueryParams.search;
    }
    if (newQueryParams.page) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  const removeSearch = (e) => {
    e.preventDefault();
    setInputSearch('');
    const newQueryParams = queryParams;
    newQueryParams.search = '';
    delete newQueryParams.search;
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  return (
    <Form onSubmit={setSearch} className='sort-box m-v-2'>
      <input
        value={inputSearch}
        onChange={(e) => setInputSearch(e.target.value)}
        placeholder={searchPlaceholder}
        type='text'
      />
      <div onClick={removeSearch} className='input-icon-right'>
        <i className='fa fa-times grey-text' />
      </div>
      <button type='submit' className='btn-small white black-text'>
        <i className='fa fa-search' />
      </button>
      {children}
      <div className='m-l-8 red-text'>{error}</div>
    </Form>
  );
};
