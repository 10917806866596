import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  confirmNotif,
  momentGeneral,
  momentNumberDateToDisplayDate,
  momentCountAge,
} from '../../helper';
import {
  fetchAdminGetPatientList,
  fetchAdminPostPatientArchive,
} from '../../store/patient';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-patient`,
    name: 'Master Data Medical Record Pasien',
  },
];

export const PatientList = () => {
  const dispatch = useDispatch();

  const $patientList = useSelector((state) => state.patientList);
  const $patientDetail = useSelector((state) => state.patientDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetPatientList({ ...queryParams, type: 'patient' }));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan menghapus ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchAdminPostPatientArchive({
            patientId: data._id,
            name: data.name,
            isArchived: true,
            next: () => {
              dispatch(
                fetchAdminGetPatientList({ ...queryParams, type: 'patient' })
              );
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($patientList.isLoading || $patientDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Medical Record Pasien'}
        backURL={'/admin'}
        onRefresh={() => {
          dispatch(
            fetchAdminGetPatientList({ ...queryParams, type: 'patient' })
          );
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPatientList({
                    ...newQueryParams,
                    type: 'patient',
                  })
                );
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/admin/master-patient/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Pasien Baru</th>
                  <th>No RM</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>Umur</th>
                  <th>Tgl Lahir</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$patientList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {data.isNewPatient ? (
                          <i className='fa fa-check-square'></i>
                        ) : (
                          <i className='fa fa-square-o'></i>
                        )}
                      </td>
                      <td>{data.noRM}</td>
                      <td>
                        <Link to={`/admin/master-patient/update/${data._id}`}>
                          {data.gender === 'f' ? (
                            <i className='fa fa-female'></i>
                          ) : (
                            <i className='fa fa-male'></i>
                          )}
                          {` `}
                          {`${data.prefix} ${data.name} ${data.suffix}`}
                        </Link>
                      </td>
                      <td>{data.mobile}</td>
                      <td>{momentCountAge(data.birthDate)}</td>
                      <td>{momentNumberDateToDisplayDate(data.birthDate)}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => confirmArchive({ e, data })}
                          className='red-text cursor-pointer font-16'
                        >
                          <i className='fa fa-times'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetPatientList({
                    ...newQueryParams,
                    type: 'patient',
                  })
                );
              }}
              totalItem={$patientList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
