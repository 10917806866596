import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
  FilterSelect,
} from '../../component/common';
import {
  momentGeneral,
  formatCurrency,
  parseDepartmentName,
} from '../../helper';
import { fetchAdminGetActionList } from '../../store/action';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-action`,
    name: 'Master Data Tindakan',
  },
];

export const ActionList = () => {
  const dispatch = useDispatch();
  const $actionList = useSelector((state) => state.actionList);
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDoctorDepartment = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );
  const listDepartmentFilter = [
    {
      _id: 'doctor',
      name: 'Semua Poliklinik',
    },
    ...listDoctorDepartment,
  ];
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetActionList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {($actionList.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Data Tindakan'}
        backURL={'/admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetActionList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetActionList(newQueryParams));
              }}
              sortOptions={[
                {
                  key: 'createdAt',
                  name: 'Dibuat',
                },
                {
                  key: 'updatedAt',
                  name: 'Diubah',
                },
                {
                  key: 'price',
                  name: 'Tarif',
                },
                {
                  key: 'name',
                  name: 'Nama',
                },
              ]}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/admin/master-action/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <FilterSelect
              options={listDepartmentFilter}
              filterKey={`doctorDepartmentId`}
              defaultText={`Tampilkan Semua`}
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetActionList(newQueryParams));
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Poli</th>
                  <th>Nama</th>
                  <th>tarif</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$actionList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {parseDepartmentName({
                          listDepartment,
                          departmentId: data.doctorDepartmentId,
                        })}
                      </td>
                      <td>
                        <Link to={`/admin/master-action/update/${data._id}`}>
                          {data.name}
                        </Link>
                        {` `}
                        {` `}
                        <Link
                          to={`/admin/master-action/master-sub-action/${data.name}/${data.doctorDepartmentId}/${data._id}`}
                          className='btn btn-teal btn-tiny'
                        >
                          <i className='fa fa-arrow-right'></i>
                        </Link>
                      </td>
                      <td>{formatCurrency(data.price)}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetActionList(newQueryParams));
              }}
              totalItem={$actionList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
