import subActionList from './subActionList';
import subActionCreate from './subActionCreate';
import subActionDetail from './subActionDetail';

export * from './subActionList';
export * from './subActionCreate';
export * from './subActionDetail';

const subActionReducers = {
  subActionList,
  subActionCreate,
  subActionDetail,
};

export default subActionReducers;
