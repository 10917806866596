import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { AdminFeeList } from './AdminFeeList';
import { AdminFeeCreate } from './AdminFeeCreate';
import { AdminFeeUpdate } from './AdminFeeUpdate';

export const MasterAdminFeeRoute = () => {
  const pathname = '/admin/master-admin-fee';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={AdminFeeList} />
      <Route exact path={`${pathname}/create`} component={AdminFeeCreate} />
      <Route
        exact
        path={`${pathname}/update/:adminFeeId`}
        component={AdminFeeUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
