import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { UserRoleList } from './UserRoleList';
import { AccessPersonList } from './AccessPersonList';

export const MasterUserRoleRoute = () => {
  const pathname = '/root/master-user-role';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={UserRoleList} />
      <Route
        exact
        path={`${pathname}/access-person/:userId`}
        component={AccessPersonList}
      />
      <Route component={Page404} />
    </Switch>
  );
};
