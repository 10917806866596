import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const Div = styled.div`
  display: flex;
  align-items: center;
  .limit-box {
    /* container class alias */
  }

  select {
    display: block;
    width: 58px;
    height: 35px;
  }
`;

const defaultLimitOptions = [10, 20, 50, 100];

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const PaginationShow = ({
  limitOptions = defaultLimitOptions,
  defaultLimit = 10,
  defaultPage = 1,
  totalItem = 0,
  onChange = defaultOnChange,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();

  const queryParams = queryString.parse(locationSearch);
  const limit = queryParams.limit ? Number(queryParams.limit) : defaultLimit;
  const page = queryParams.page ? Number(queryParams.page) : defaultPage;

  const pageTimesLimit = page * limit;
  const startItem = pageTimesLimit - limit + 1;
  const endItem = pageTimesLimit < totalItem ? pageTimesLimit : totalItem;

  const changeLimit = (e) => {
    e.preventDefault();
    const v = e.target.value;
    const newQueryParams = queryParams;
    newQueryParams.limit = v;
    if (newQueryParams.limit === String(defaultLimit)) {
      delete newQueryParams.limit;
    }
    if (newQueryParams.page) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  return (
    <Div className='m-v-2'>
      <select onChange={changeLimit} value={limit}>
        {limitOptions.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
      <div className='m-l-8'>
        Menampilkan {startItem}-{endItem} dari total {totalItem}
      </div>
    </Div>
  );
};
