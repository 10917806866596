import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetUserList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/user/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetUserListArchived({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/user/list-archived`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetUserDetail({ userId }) {
  const path = `${BASE_API_ADMIN}/user/detail/${userId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostUserCreate({ isVerified, email, name, password }) {
  const path = `${BASE_API_ADMIN}/user/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isVerified,
      name,
      email,
      password,
    },
  });
}

export function restPostUserUpdate({ userId, isVerified, name, password }) {
  const path = `${BASE_API_ADMIN}/user/update/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isVerified,
      name,
      password,
    },
  });
}

export function restPostUserArchive({ userId, isArchived }) {
  const path = `${BASE_API_ADMIN}/user/archive/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isArchived,
    },
  });
}

export function restPostUserDelete({ userId }) {
  const path = `${BASE_API_ADMIN}/user/delete/${userId}`;
  return fetchApi(path, {
    method: 'POST',
  });
}

export function restPostUserRemoveAllSession() {
  const path = `${BASE_API_ADMIN}/user/remove-all-session`;
  return fetchApi(path, {
    method: 'POST',
  });
}
