import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import {
  departmentCreateSetForm,
  departmentCreateInit,
  fetchAdminPostDepartmentCreate,
} from '../../store/department';
import { PERSON_TYPE } from '../../config';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  select {
    width: 300px;
    margin-top: 8px;
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/super-admin/master-department`,
    name: 'Master Departemen',
  },
  {
    url: `/admin/super-admin/master-department/create`,
    name: 'Tambah Baru',
  },
];

export const DepartmentCreate = () => {
  const dispatch = useDispatch();
  const $departmentCreate = useSelector((state) => state.departmentCreate);
  const { form } = $departmentCreate;
  const { push } = useHistory();

  useEffect(() => {
    const didMount = () => {
      dispatch(departmentCreateInit());
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostDepartmentCreate({
        push,
        type: form.type,
        name: form.name,
      })
    );
  };
  return (
    <Fragment>
      {$departmentCreate.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Tambah Baru'}
        backURL={'/admin/super-admin/master-department'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <select
                    value={form.type}
                    onChange={(e) =>
                      dispatch(
                        departmentCreateSetForm({
                          key: 'type',
                          value: e.target.value,
                        })
                      )
                    }
                    id="type"
                    name="type"
                    type="text"
                    className="browser-default validate"
                  >
                    {PERSON_TYPE.map((pt) => (
                      <option key={pt._id} value={pt._id}>
                        {pt.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="type">
                    Tipe
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        departmentCreateSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$departmentCreate.error}
                  </div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right">
                    Tambah Baru
                  </button>
                  <Link
                    to={`/admin/super-admin/master-department`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
