import { createSlice } from '@reduxjs/toolkit';
import { restGetMedicalRecordActionList } from '../../api/medicalRecord';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const medicalRecordActionListSlice = createSlice({
  name: 'medicalRecordActionList',
  initialState: initialState,
  reducers: {
    getmedicalRecordActionListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getmedicalRecordActionListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getmedicalRecordActionListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getmedicalRecordActionListStart,
  getmedicalRecordActionListSuccess,
  getmedicalRecordActionListError,
} = medicalRecordActionListSlice.actions;

export default medicalRecordActionListSlice.reducer;

export const fetchGetMedicalRecordActionList = ({
  limit = 10,
  page = 1,
  sort = 'visitDate',
  sortDir = 'desc',
  search = '',
  visitDate = '',
  patientId = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().medicalRecordActionList;
  if (isLoading) return;
  try {
    dispatch(getmedicalRecordActionListStart());
    const { listData, totalItem } = await restGetMedicalRecordActionList({
      limit,
      page,
      sort,
      sortDir,
      search,
      visitDate,
      patientId,
    });

    dispatch(
      getmedicalRecordActionListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getmedicalRecordActionListError(JSON.parse(err).message));
    }
    dispatch(getmedicalRecordActionListError(err));
  }
};
