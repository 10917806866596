import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  isRoleNurseOperator,
  isRoleDoctorNurseStudent,
  isRoleRoot,
  isRoleAdmin,
  moment,
} from "../../../helper";
import { fetchPostAuthLogout } from "../../../store/auth";

const Div = styled.div`
  .menus {
    /* ALIAS */
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .menu-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 18px;
    text-align: center;
  }
  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 4em;
  }

  .cursor-pointer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
  }
  .menu-icon {
    font-size: 30px !important;
  }

  .menu-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }

  .empty-min-width {
    min-width: 60px;
  }
`;

export const HomeMenu = () => {
  const dispatch = useDispatch();

  const [listMenuUi, setListMenuUi] = useState([]);

  const emptyMenu = {
    url: "",
    icon: "fa fa-battery-empty",
    name: "empty",
  };
  let listMenu = [];
  if (isRoleNurseOperator()) {
    listMenu.push({
      url: `/patient-visit?visitDate=${moment().format("YYYYMMDD")}`,
      icon: "fa fa-user-plus",
      name: "Pendaftaran Pasien",
    });
  }
  if (isRoleDoctorNurseStudent()) {
    listMenu.push({
      url: "/patient-doctor-action",
      icon: "fa fa-hospital-o",
      name: "Bagian / Poli",
    });
  }
  if (isRoleNurseOperator()) {
    listMenu.push({
      url: `/patient-invoice?invoiceDate=${moment().format(
        "YYYYMMDD"
      )}&sort=createdAt&sortDir=desc`,
      icon: "fa fa-files-o ",
      name: "Kwitansi",
    });
    listMenu.push({
      url: `/patient-invoice-daily?invoiceMonth=${moment().format(
        "YYYYMM"
      )}&sort=createdAt&sortDir=desc`,
      icon: "fa fa-calendar",
      name: "Rekap Kwitansi Harian",
    });
  }
  listMenu.push({
    url: `medical-record`,
    icon: "fa fa-file-text",
    name: "Medical Record",
  });
  if (isRoleAdmin()) {
    listMenu.push({
      url: "/admin",
      icon: "fa fa-code",
      name: "Admin",
    });
  }
  if (isRoleRoot()) {
    listMenu.push({
      url: "/root",
      icon: "fa fa-database",
      name: "Root",
    });
  }
  listMenu.push({
    type: "function",
    function: () => {
      dispatch(fetchPostAuthLogout());
    },
    url: "/log-out",
    icon: "fa fa-sign-out",
    name: "Logout",
  });

  useEffect(() => {
    const handleMenuPerCase = () => {
      let newListMenuUi = [];
      handleMenuRemainder();

      function handleMenuRemainder() {
        if (listMenu.length % 4 > 0) {
          // Insert empty remainder
          const remainder = 4 - (listMenu.length % 4);
          for (let idx = 0; idx < remainder; idx++) {
            listMenu.push(emptyMenu);
          }
        }

        handleMenuUi();
      }
      function handleMenuUi() {
        let newMenu = [];

        listMenu.forEach((v, idx) => {
          newMenu.push(v);

          if ((idx + 1) % 4 === 0) {
            newListMenuUi.push(newMenu);

            newMenu = [];
          }
        });
        setListMenuUi(newListMenuUi);
      }
    };

    if (listMenuUi.length < 1) {
      handleMenuPerCase();
    }
  }, [listMenuUi, emptyMenu, listMenu]);

  let UiMenuFourth = listMenuUi.map((menuFourth, idxFourth) => {
    let UiMenu = menuFourth.map((menu, idx) => {
      let UiMenuItem = (
        <div key={`${idxFourth}-${idx}`} className="empty-min-width" />
      );
      if (menu.type === "link") {
        UiMenuItem = (
          <a
            href={menu.url}
            key={`${idxFourth}-${idx}`}
            className={"teal-text"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="menu">
              <div className="button-circle-panel hoverable cursor-pointer">
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className="menu-label">{menu.name}</div>
            </div>
          </a>
        );
      } else if (menu.type === "function") {
        UiMenuItem = (
          <span
            onClick={menu.function}
            className={"teal-text cursor-pointer"}
            key={`${idxFourth}-${idx}`}
          >
            <div className="menu">
              <div className="button-circle-panel hoverable cursor-pointer">
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className="menu-label">{menu.name}</div>
            </div>
          </span>
        );
      } else if (menu.url) {
        UiMenuItem = (
          <Link to={menu.url} className="teal-text" key={`${idxFourth}-${idx}`}>
            <div className="menu">
              <div className="button-circle-panel hoverable cursor-pointer">
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className="menu-label">{menu.name}</div>
            </div>
          </Link>
        );
      }
      return UiMenuItem;
    });
    return (
      <div className="menu-row" key={`${idxFourth}`}>
        {UiMenu}
      </div>
    );
  });

  return <Div className="menus">{UiMenuFourth}</Div>;
};
