import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { PeopleArchiveList } from './PeopleArchiveList';

export const MasterPeopleArchiveRoute = () => {
  const pathname = '/admin/super-admin/master-people-archive';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PeopleArchiveList} />
      <Route component={Page404} />
    </Switch>
  );
};
