import { createSlice } from '@reduxjs/toolkit';
import { restGetAdminFeeList as restAdminGetAdminFeeList } from '../../apiAdmin/adminFee';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const adminFeeListSlice = createSlice({
  name: 'adminFeeList',
  initialState: initialState,
  reducers: {
    getAdminFeeListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getAdminFeeListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getAdminFeeListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getAdminFeeListStart,
  getAdminFeeListSuccess,
  getAdminFeeListError,
} = adminFeeListSlice.actions;

export default adminFeeListSlice.reducer;

export const fetchAdminGetAdminFeeList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().adminFeeList;
  if (isLoading) return;
  try {
    dispatch(getAdminFeeListStart());
    const { listData, totalItem } = await restAdminGetAdminFeeList({
      limit,
      page,
      sort,
      sortDir,
      search,
    });
    dispatch(
      getAdminFeeListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getAdminFeeListError(JSON.parse(err).message));
    }
    dispatch(getAdminFeeListError(err));
  }
};
