import { createSlice } from '@reduxjs/toolkit';
import {
  restGetActionDetail as restAdminGetActionDetail,
  restPostActionUpdate as restAdminPostActionUpdate,
} from '../../apiAdmin/action';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    doctorDepartmentId: 'doctor',
    name: '',
    price: 0,
  },
};

const actionDetailSlice = createSlice({
  name: 'actionDetail',
  initialState: initialState,
  reducers: {
    actionDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    actionDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: 'doctor',
        name: '',
        price: 0,
      };
    },
    getActionDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getActionDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        doctorDepartmentId: data.doctorDepartmentId,
        name: data.name,
        price: data.price,
      };
    },
    getActionDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postActionUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postActionUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postActionUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  actionDetailSetForm,
  actionDetailInit,
  getActionDetailStart,
  getActionDetailSuccess,
  getActionDetailError,
  postActionUpdateStart,
  postActionUpdateSuccess,
  postActionUpdateError,
} = actionDetailSlice.actions;

export default actionDetailSlice.reducer;

export const fetchAdminGetActionDetail = ({ actionId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().actionDetail;
  if (isLoading) return;
  try {
    dispatch(getActionDetailStart());
    const data = await restAdminGetActionDetail({
      actionId,
    });
    dispatch(
      getActionDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getActionDetailError(JSON.parse(err).message));
    }
    dispatch(getActionDetailError(err));
  }
};

export const fetchAdminPostActionUpdate = ({
  next,
  actionId,
  doctorDepartmentId = '',
  name = '',
  price = 0,
}) => async (dispatch, getState) => {
  name = name.trim();

  if (doctorDepartmentId.length < 3) {
    return dispatch(postActionUpdateError('Departemen harus dipilih'));
  }
  if (name.length < 3) {
    return dispatch(postActionUpdateError('Nama minimal 3 karakter'));
  }
  price = Number(price);
  if (isNaN(price)) {
    return dispatch(postActionUpdateError('Tarif minimal 0'));
  }
  if (price < 0) {
    return dispatch(postActionUpdateError('Tarif minimal 0'));
  }

  const { isLoading } = getState().actionDetail;
  if (isLoading) return;
  try {
    dispatch(postActionUpdateStart());
    await restAdminPostActionUpdate({
      actionId,
      doctorDepartmentId,
      name,
      price,
    });
    dispatch(postActionUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postActionUpdateError(JSON.parse(err).message));
    }
    dispatch(postActionUpdateError(err));
  }
};
