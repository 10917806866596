import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { UserList } from './UserList';
import { UserCreate } from './UserCreate';
import { UserUpdate } from './UserUpdate';

export const MasterUserRoute = () => {
  const pathname = '/root/master-user';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={UserList} />
      <Route exact path={`${pathname}/create`} component={UserCreate} />
      <Route exact path={`${pathname}/update/:userId`} component={UserUpdate} />
      <Route component={Page404} />
    </Switch>
  );
};
