import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { SubActionList } from './SubActionList';
import { SubActionCreate } from './SubActionCreate';
import { SubActionUpdate } from './SubActionUpdate';

export const MasterSubActionRoute = () => {
  const pathname =
    '/admin/master-action/master-sub-action/:actionName/:doctorDepartmentId/:actionId';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={SubActionList} />
      <Route exact path={`${pathname}/create`} component={SubActionCreate} />
      <Route
        exact
        path={`${pathname}/update/:subActionId`}
        component={SubActionUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
