import fetchApi from './fetchApi';
import { BASE_API } from '../config';

// GET
export function restGetMedicalRecordList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  isNewPatient = '',
}) {
  let path = `${BASE_API}/medical-record/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&isNewPatient=${isNewPatient}`;

  return fetchApi(path, {
    method: 'GET',
  });
}

export function restGetMedicalRecordDetail({ patientId }) {
  const path = `${BASE_API}/medical-record/detail/${patientId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

export function restGetMedicalRecordActionList({
  limit = 10,
  page = 1,
  sort = 'visitDate',
  sortDir = 'desc',
  search = '',
  patientId = '',
}) {
  let path = `${BASE_API}/medical-record/action-list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&patientId=${patientId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
