import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { isRoleRoot } from '../helper';
import { Page404, PageForbidden403 } from '../page/common';
import { RootHome } from '../pageRoot/rootHome';
import { MasterRoleRoute } from '../pageRoot/masterRole';
import { MasterUserRoute } from '../pageRoot/masterUser';
import { MasterUserRoleRoute } from '../pageRoot/masterUserRole';
import { MasterPasswordResetRoute } from '../pageRoot/masterPasswordReset';
import { MasterUserArchiveRoute } from '../pageRoot/masterUserArchive';
import { MasterConfigRoute } from '../pageRoot/masterConfig';

export const RootLayout = () => {
  const pathname = '/root';
  if (isRoleRoot()) {
    return (
      <Switch>
        <Route exact path={`${pathname}`} component={RootHome} />
        <Route path={`${pathname}/master-role`} component={MasterRoleRoute} />
        <Route
          path={`${pathname}/master-user-role`}
          component={MasterUserRoleRoute}
        />
        <Route path={`${pathname}/master-user`} component={MasterUserRoute} />
        <Route
          path={`${pathname}/master-password-reset`}
          component={MasterPasswordResetRoute}
        />
        <Route
          path={`${pathname}/master-user-archive`}
          component={MasterUserArchiveRoute}
        />
        <Route
          path={`${pathname}/master-config`}
          component={MasterConfigRoute}
        />
        <Route component={Page404} />
      </Switch>
    );
  }
  return <PageForbidden403 />;
};
