import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { DepartmentList } from './DepartmentList';
import { DepartmentCreate } from './DepartmentCreate';
import { DepartmentUpdate } from './DepartmentUpdate';

export const MasterDepartmentRoute = () => {
  const pathname = '/admin/super-admin/master-department';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={DepartmentList} />
      <Route exact path={`${pathname}/create`} component={DepartmentCreate} />
      <Route
        exact
        path={`${pathname}/update/:departmentId`}
        component={DepartmentUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
