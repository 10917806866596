import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetSubActionList({
  actionId,
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  doctorDepartmentId = '',
}) {
  let path = `${BASE_API_ADMIN}/sub-action/list/${actionId}`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&doctorDepartmentId=${doctorDepartmentId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetSubActionDetail({ actionId, subActionId }) {
  const path = `${BASE_API_ADMIN}/sub-action/detail/${actionId}/${subActionId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostSubActionCreate({
  actionId,
  doctorDepartmentId,
  name,
  price,
}) {
  const path = `${BASE_API_ADMIN}/sub-action/create/${actionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      doctorDepartmentId,
      name,
      price,
    },
  });
}

export function restPostSubActionUpdate({
  actionId,
  subActionId,
  doctorDepartmentId,
  name,
  price,
}) {
  const path = `${BASE_API_ADMIN}/sub-action/update/${actionId}/${subActionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      doctorDepartmentId,
      name,
      price,
    },
  });
}
