import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import logger from 'redux-logger';

import globalReducers from './global';
import authReducers from './auth';
import roleReducers from './role';
import configReducers from './config';
import passwordResetReducers from './passwordReset';
import userReducers from './user';
import userRoleReducers from './userRole';

import departmentReducers from './department';
import personReducers from './person';

import patientReducers from './patient';
import mainComplaintReducers from './mainComplaint';
import allergicReducers from './allergic';
import diagnosisReducers from './diagnosis';

import adminFeeReducers from './adminFee';
import actionReducers from './action';
import subActionReducers from './subAction';
import substanceReducers from './substance';
// Client
import patientVisitReducers from './patientVisit';
import patientDoctorActionReducers from './patientDoctorAction';
import patientInvoiceReducers from './patientInvoice';
import medicalRecordReducers from './medicalRecord';

const reducer = {
  ...globalReducers,
  ...authReducers,
  ...roleReducers,
  ...configReducers,
  ...passwordResetReducers,
  ...userReducers,
  ...userRoleReducers,
  ...departmentReducers,
  ...personReducers,
  ...patientReducers,
  ...mainComplaintReducers,
  ...allergicReducers,
  ...diagnosisReducers,
  ...adminFeeReducers,
  ...actionReducers,
  ...subActionReducers,
  ...substanceReducers,
  // Client
  ...patientVisitReducers,
  ...patientDoctorActionReducers,
  ...patientInvoiceReducers,
  ...medicalRecordReducers,
};

const middleware = [
  ...getDefaultMiddleware(),
  // logger
];

export default configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});
