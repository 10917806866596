import { createSlice } from '@reduxjs/toolkit';
import { restPostAuthRegister } from '../../api/auth';

import { isValidJSON, setTimeoutAsync, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
};

const registerFormSlice = createSlice({
  name: 'registerForm',
  initialState: initialState,
  reducers: {
    postRegisterInit(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = false;
    },
    postRegisterStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postRegisterSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = true;
    },
    postRegisterError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  postRegisterInit,
  postRegisterStart,
  postRegisterSuccess,
  postRegisterError,
} = registerFormSlice.actions;

export default registerFormSlice.reducer;

export const fetchPostAuthRegister = ({
  push,
  email,
  name,
  password,
  passwordConfirmation,
}) => async (dispatch, getState) => {
  const { isLoading } = getState().registerForm;

  email = email.trim();
  name = name.trim();
  password = password.trim();
  passwordConfirmation = passwordConfirmation.trim();

  if (email.length < 6) {
    return dispatch(postRegisterError('Email minimal 6 karakter'));
  }
  if (name.length < 6) {
    return dispatch(postRegisterError('nama minimal 3 karakter'));
  }
  if (password.length < 6) {
    return dispatch(postRegisterError('password minimal 6 karakter'));
  }
  if (password !== passwordConfirmation) {
    return dispatch(
      postRegisterError('password tidak sama dengan password konfirmasi')
    );
  }

  if (isLoading) return;

  try {
    dispatch(postRegisterStart());
    await restPostAuthRegister({ email, name, password, passwordConfirmation });
    dispatch(postRegisterSuccess());
    successNotif(
      `Berhasil Registrasi, silahkan cek inbox / spam email ${email} dalam 2 menit:`
    );
    await setTimeoutAsync(5000);
    if (push) push('/');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postRegisterError(JSON.parse(err).message));
    }
    dispatch(postRegisterError(err));
  }
};
