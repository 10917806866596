import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { BreadCrumb, HeaderNav, LoaderBlock } from '../../component/common';
import { formatCurrency } from '../../helper';
import {
  substanceCreateSetForm,
  substanceCreateInit,
  fetchAdminPostSubstanceCreate,
} from '../../store/substance';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-substance`,
    name: 'Master Data Bahan',
  },
  {
    url: `/admin/master-substance/create`,
    name: 'Tambah Baru',
  },
];

export const SubstanceCreate = () => {
  const dispatch = useDispatch();
  const $substanceCreate = useSelector((state) => state.substanceCreate);
  const { form } = $substanceCreate;
  const { push } = useHistory();

  useEffect(() => {
    const didMount = () => {
      dispatch(substanceCreateInit());
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostSubstanceCreate({
        next: () => {
          push('/admin/master-substance?sort=createdAt&sortDir=desc');
        },
        name: form.name,
        price: form.price,
        unit: form.unit,
      })
    );
  };
  return (
    <Fragment>
      {$substanceCreate.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Create'}
        backURL={'/admin/master-substance'}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <form onSubmit={onSubmit}>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        substanceCreateSetForm({
                          key: 'name',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='name'
                    name='name'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='name'>
                    Nama
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.price}
                    onChange={(e) =>
                      dispatch(
                        substanceCreateSetForm({
                          key: 'price',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Tarif'
                    id='price'
                    name='price'
                    type='number'
                    className='validate'
                  />
                  <label className='active' htmlFor='price'>
                    Tarif <b>{formatCurrency(form.price)}</b>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12'>
                  <input
                    value={form.unit}
                    onChange={(e) =>
                      dispatch(
                        substanceCreateSetForm({
                          key: 'unit',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Unit'
                    id='unit'
                    name='unit'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='unit'>
                    Unit
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='red-text m-b-4 right-align'>
                    {$substanceCreate.error}
                  </div>
                </div>
                <div className='col s12'>
                  <button type='submit' className='btn teal m-b-20 right'>
                    Tambah Baru
                  </button>
                  <Link
                    to={`/admin/master-substance`}
                    className='btn grey m-b-20 right m-r-8'
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
