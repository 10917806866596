import { createSlice } from '@reduxjs/toolkit';
import { restGetDepartmentList as restAdminGetDepartmentList } from '../../apiAdmin/department';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const departmentListSlice = createSlice({
  name: 'departmentList',
  initialState: initialState,
  reducers: {
    getDepartmentListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getDepartmentListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getDepartmentListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getDepartmentListStart,
  getDepartmentListSuccess,
  getDepartmentListError,
} = departmentListSlice.actions;

export default departmentListSlice.reducer;

export const fetchAdminGetDepartmentList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  type = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().departmentList;
  if (isLoading) return;
  try {
    dispatch(getDepartmentListStart());
    const { listData, totalItem } = await restAdminGetDepartmentList({
      limit,
      page,
      sort,
      sortDir,
      search,
      type,
    });
    dispatch(
      getDepartmentListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getDepartmentListError(JSON.parse(err).message));
    }
    dispatch(getDepartmentListError(err));
  }
};
