import fetchApi from '../api/fetchApi';
import { BASE_API_ROOT } from '../config';

// GET
export function restGetUserAccessList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ROOT}/user-access/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostUserAccessUpdate({ userId, isAdd, roleId, roleName }) {
  const path = `${BASE_API_ROOT}/user-access/update/${userId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      isAdd,
      roleId,
      roleName,
    },
  });
}
