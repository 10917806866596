import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  momentGeneral,
  formatCurrency,
  parseDepartmentName,
} from '../../helper';
import { fetchAdminGetSubActionList } from '../../store/subAction';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = (actionName, doctorDepartmentId, actionId) => [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/master-action`,
    name: 'Master Data Tindakan',
  },
  {
    url: `/admin/master-action/master-sub-action/${actionName}/${doctorDepartmentId}/${actionId}`,
    name: `Sub Tindakan "${actionName}"`,
  },
];

const backURL = '/admin/master-action';
const createURL = (actionName, doctorDepartmentId, actionId) =>
  `/admin/master-action/master-sub-action/${actionName}/${doctorDepartmentId}/${actionId}/create`;
const updateURL = (actionName, doctorDepartmentId, actionId, id) =>
  `/admin/master-action/master-sub-action/${actionName}/${doctorDepartmentId}/${actionId}/update/${id}`;

export const SubActionList = () => {
  const { actionName, doctorDepartmentId, actionId } = useParams();
  const dispatch = useDispatch();
  const $subActionList = useSelector((state) => state.subActionList);
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;

  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetSubActionList({ actionId, ...queryParams }));
    };
    didMount();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {($subActionList.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={`Sub Tindakan "${actionName}"`}
        backURL={backURL}
        onRefresh={() => {
          dispatch(fetchAdminGetSubActionList({ actionId, ...queryParams }));
        }}
      />
      <BreadCrumb
        breadCrumbData={breadCrumbData(
          actionName,
          doctorDepartmentId,
          actionId
        )}
      />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetSubActionList({ actionId, ...newQueryParams })
                );
              }}
              sortOptions={[
                {
                  key: 'createdAt',
                  name: 'Dibuat',
                },
                {
                  key: 'updatedAt',
                  name: 'Diubah',
                },
                {
                  key: 'price',
                  name: 'Tarif',
                },
                {
                  key: 'name',
                  name: 'Nama',
                },
              ]}
              SearchChildren={() => {
                return (
                  <Link
                    to={createURL(actionName, doctorDepartmentId, actionId)}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>Poli</th>
                  <th>Tindakan</th>
                  <th>Sub Tindakan</th>
                  <th>tarif</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$subActionList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        {parseDepartmentName({
                          listDepartment,
                          departmentId: data.doctorDepartmentId,
                        })}
                      </td>
                      <td>{actionName}</td>
                      <td>
                        <Link
                          to={updateURL(
                            actionName,
                            doctorDepartmentId,
                            actionId,
                            data._id
                          )}
                        >
                          {data.name}
                        </Link>
                      </td>
                      <td>{formatCurrency(data.price)}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(
                  fetchAdminGetSubActionList({ actionId, ...newQueryParams })
                );
              }}
              totalItem={$subActionList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
