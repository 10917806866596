import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { LoaderBlock } from '../../component/common';

import { fetchPostEmailVerify } from '../../store/auth';

const Div = styled.div`
  .center-container {
    /* Root name alias */
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-dimension {
    height: 1em;
    margin-right: 4px;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  .btn {
    display: block;
    width: 100%;
  }
`;

export const EmailVerify = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locationSearch = useLocation().search;
  const $resendEmailForm = useSelector((state) => state.resendEmailForm);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchPostEmailVerify({ locationSearch }));
    };
    didMount();
  }, [dispatch, locationSearch]);

  useEffect(() => {
    const willUnmount = () => {
      history.go(0);
    };
    return willUnmount;
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {$resendEmailForm.isLoading && <LoaderBlock />}
      <Div>
        <h3 className='center-align'>
          <span className='font-24'>Verifikasi Email</span>
        </h3>
        <div className='login-box'>
          <div className='row'>
            <div className='col s12'>
              {!$resendEmailForm.isSuccessVerify &&
                $resendEmailForm.loading && (
                  <div className='teal-text center-align m-b-4'>
                    Sedang mengverifikasi Email
                  </div>
                )}
              {$resendEmailForm.isSuccessVerify && (
                <div className='teal-text center-align m-b-4 font-20'>
                  Email terverifikasi silahkan login kembali
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <div className='red-text center-align m-b-4'>
                {$resendEmailForm.error}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <Link to={`/`}>
                <div className='btn teal m-b-20'>Kembali ke Login</div>
              </Link>
            </div>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
