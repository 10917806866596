import { createSlice } from '@reduxjs/toolkit';
import {
  restPostPatientDoctorActionCreate,
  restPostPatientDoctorActionReturnRm,
} from '../../api/patientDoctorAction';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  patientVisit: {},
  form: {
    patientVisitId: '',
    noDU: '',
    doctorId: '',
    doctorFullName: '',
    nurseId: '',
    nurseFullName: '',
  },
};

const patientDoctorActionCreateSlice = createSlice({
  name: 'patientDoctorActionCreate',
  initialState: initialState,
  reducers: {
    patientDoctorActionCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientDoctorActionCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        patientVisitId: '',
        noDU: '',
        doctorId: '',
        doctorFullName: '',
        nurseId: '',
        nurseFullName: '',
      };
    },
    postPatientDoctorActionCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientDoctorActionCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientDoctorActionCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  patientDoctorActionCreateSetForm,
  patientDoctorActionCreateInit,
  postPatientDoctorActionCreateStart,
  postPatientDoctorActionCreateSuccess,
  postPatientDoctorActionCreateError,
} = patientDoctorActionCreateSlice.actions;

export default patientDoctorActionCreateSlice.reducer;

export const fetchPostPatientDoctorActionCreate = ({
  next,
  patientVisitId = '',
  doctorId = '',
  doctorFullName = '',
}) => async (dispatch, getState) => {
  if (!patientVisitId) {
    return dispatch(
      postPatientDoctorActionCreateError('ID kunjungan tidak ditemukan')
    );
  }
  if (doctorId.length < 3) {
    return dispatch(postPatientDoctorActionCreateError('Dokter harus dipilih'));
  }
  if (!doctorFullName) {
    return dispatch(
      postPatientDoctorActionCreateError('Nama Dokter tidak ada')
    );
  }

  const { isLoading } = getState().patientDoctorActionCreate;
  if (isLoading) return;
  try {
    dispatch(postPatientDoctorActionCreateStart());
    await restPostPatientDoctorActionCreate({
      patientVisitId,
      doctorId,
      doctorFullName,
    });
    dispatch(postPatientDoctorActionCreateSuccess());
    successNotif(`Berhasil menyimpan data Rekam Medis Pasien`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(
        postPatientDoctorActionCreateError(JSON.parse(err).message)
      );
    }
    dispatch(postPatientDoctorActionCreateError(err));
  }
};

export const fetchPostPatientDoctorActionReturnRm = ({
  next,
  patientDoctorActionId,
  isReturnedRM = 'false',
}) => async (dispatch, getState) => {
  const { isLoading } = getState().patientDoctorActionDetail;
  if (isLoading) return;
  try {
    dispatch(postPatientDoctorActionCreateStart());
    await restPostPatientDoctorActionReturnRm({
      patientDoctorActionId,
      isReturnedRM,
    });
    dispatch(postPatientDoctorActionCreateSuccess());
    successNotif(`Berhasil simpan perubahan`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(
        postPatientDoctorActionCreateError(JSON.parse(err).message)
      );
    }
    dispatch(postPatientDoctorActionCreateError(err));
  }
};
