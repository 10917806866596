import fetchApi from "../api/fetchApi";
import { BASE_API_ADMIN } from "../config";

// GET
export function restGetSubstanceList({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
}) {
  let path = `${BASE_API_ADMIN}/substance/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetSubstanceListArchived({
  limit = 10,
  page = 1,
  sort = "createdAt",
  sortDir = "asc",
  search = "",
}) {
  let path = `${BASE_API_ADMIN}/substance/list-archived`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetSubstanceDetail({ substanceId }) {
  const path = `${BASE_API_ADMIN}/substance/detail/${substanceId}`;
  return fetchApi(path, {
    method: "GET",
  });
}

// POST
export function restPostSubstanceCreate({ name, price, unit }) {
  const path = `${BASE_API_ADMIN}/substance/create`;
  return fetchApi(path, {
    method: "POST",
    body: {
      name,
      price,
      unit,
    },
  });
}

export function restPostSubstanceUpdate({ substanceId, name, price, unit }) {
  const path = `${BASE_API_ADMIN}/substance/update/${substanceId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      name,
      price,
      unit,
    },
  });
}

export function restPostSubstanceArchive({ substanceId, isArchived }) {
  const path = `${BASE_API_ADMIN}/substance/archive/${substanceId}`;
  return fetchApi(path, {
    method: "POST",
    body: {
      isArchived,
    },
  });
}
