import { createSlice } from '@reduxjs/toolkit';
import { restGetMedicalRecordList } from '../../api/medicalRecord';

import { isValidJSON } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const medicalRecordListSlice = createSlice({
  name: 'medicalRecordList',
  initialState: initialState,
  reducers: {
    getMedicalRecordListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getMedicalRecordListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getMedicalRecordListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMedicalRecordListStart,
  getMedicalRecordListSuccess,
  getMedicalRecordListError,
} = medicalRecordListSlice.actions;

export default medicalRecordListSlice.reducer;

export const fetchGetMedicalRecordList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  isNewPatient = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().medicalRecordList;
  if (isLoading) return;
  try {
    dispatch(getMedicalRecordListStart());
    let listData = [];
    let totalItem = 0;
    let resp = {};
    resp = await restGetMedicalRecordList({
      limit,
      page,
      sort,
      sortDir,
      search,
      isNewPatient,
    });

    listData = resp.listData;
    totalItem = resp.totalItem;

    dispatch(
      getMedicalRecordListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getMedicalRecordListError(JSON.parse(err).message));
    }
    dispatch(getMedicalRecordListError(err));
  }
};
