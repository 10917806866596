import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  fetchAdminGetPasswordResetList,
  fetchAdminPostPasswordResetDeleteAll,
} from '../../store/passwordReset';
import { confirmNotif, momentGeneral } from '../../helper';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: 'Admin',
  },
  {
    url: `/admin/super-admin`,
    name: 'Super Admin',
  },
  {
    url: `/admin/master-user-role`,
    name: 'Master Password Reset',
  },
];

export const PasswordResetList = () => {
  const dispatch = useDispatch();
  const $passwordResetList = useSelector((state) => state.passwordResetList);

  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchAdminGetPasswordResetList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmDeleteAll = (e) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan menghapus semua data?`,
      onOK: () => {
        dispatch(
          fetchAdminPostPasswordResetDeleteAll({
            next: () => dispatch(fetchAdminGetPasswordResetList(queryParams)),
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {$passwordResetList.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={'Master Password Reset'}
        backURL={'/admin/super-admin'}
        onRefresh={() => {
          dispatch(fetchAdminGetPasswordResetList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPasswordResetList(newQueryParams));
              }}
              SearchChildren={() => {
                return (
                  <button
                    onClick={confirmDeleteAll}
                    type="button"
                    className="btn-small red white-text"
                  >
                    <i className="fa fa-trash" />
                  </button>
                );
              }}
            />
            <table className="striped bg-white">
              <thead>
                <tr>
                  <th>_id</th>
                  <th>userId</th>
                  <th>token</th>
                  <th>expiredAt</th>
                  <th>Dibuat</th>
                </tr>
              </thead>
              <tbody>
                {$passwordResetList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>{data._id}</td>
                      <td>{data.userId}</td>
                      <td>{data.token}</td>
                      <td>{momentGeneral(data.expiredAt)}</td>
                      <td>{momentGeneral(data.createdAt)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchAdminGetPasswordResetList(newQueryParams));
              }}
              totalItem={$passwordResetList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
