import { createSlice } from '@reduxjs/toolkit';
import { restPostAuthResetRequest } from '../../api/auth';

import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
};

const forgotPasswordFormSlice = createSlice({
  name: 'forgotPasswordForm',
  initialState: initialState,
  reducers: {
    postForgotPasswordInit(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = false;
    },
    postForgotPasswordStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isSuccess = false;
    },
    postForgotPasswordSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.isSuccess = true;
    },
    postForgotPasswordError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  postForgotPasswordInit,
  postForgotPasswordStart,
  postForgotPasswordSuccess,
  postForgotPasswordError,
} = forgotPasswordFormSlice.actions;

export default forgotPasswordFormSlice.reducer;

export const fetchPostAuthResetRequest = ({ email }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().forgotPasswordForm;

  email = email.trim();

  if (email.length < 6) {
    return dispatch(postForgotPasswordError('Email minimal 6 karakter'));
  }

  if (isLoading) return;

  try {
    dispatch(postForgotPasswordStart());
    await restPostAuthResetRequest({ email });
    dispatch(postForgotPasswordSuccess());
    successNotif(
      'Link untuk reset password berhasil dikirim, silahkan "Cek Email" dalam waktu 2 menit'
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postForgotPasswordError(JSON.parse(err).message));
    }
    dispatch(postForgotPasswordError(err));
  }
};
