import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { isRoleAdmin, isRoleSuperAdmin } from '../helper';
import { Page404, PageForbidden403 } from '../page/common';

import { SuperAdminLayout } from './SuperAdminLayout';
import { AdminHome } from '../pageAdmin/adminHome';
import { MasterUserRoute } from '../pageAdmin/masterUser';
import { MasterUserRoleRoute } from '../pageAdmin/masterUserRole';
import { MasterDoctorRoute } from '../pageAdmin/masterDoctor';
import { MasterNurseRoute } from '../pageAdmin/masterNurse';
import { MasterStudentRoute } from '../pageAdmin/masterStudent';
import { MasterStaffRoute } from '../pageAdmin/masterStaff';
import { MasterCleaningServiceRoute } from '../pageAdmin/masterCleaningService';
import { MasterPatientRoute } from '../pageAdmin/masterPatient';
import { MasterMainComplaintRoute } from '../pageAdmin/masterMainComplaint';
import { MasterAllergicRoute } from '../pageAdmin/masterAllergic';
import { MasterDiagnosisRoute } from '../pageAdmin/masterDiagnosis';
import { MasterAdminFeeRoute } from '../pageAdmin/masterAdminFee';
import { MasterActionRoute } from '../pageAdmin/masterAction';
import { MasterSubstanceRoute } from '../pageAdmin/masterSubstance';

export const AdminLayout = () => {
  const pathname = '/admin';
  if (isRoleAdmin()) {
    return (
      <Switch>
        <Route exact path={`${pathname}`} component={AdminHome} />
        <Route
          path={`${pathname}/master-user-role`}
          component={MasterUserRoleRoute}
        />
        <Route path={`${pathname}/master-user`} component={MasterUserRoute} />
        <Route
          path={`${pathname}/master-doctor`}
          component={MasterDoctorRoute}
        />
        <Route path={`${pathname}/master-nurse`} component={MasterNurseRoute} />
        <Route
          path={`${pathname}/master-student`}
          component={MasterStudentRoute}
        />
        <Route path={`${pathname}/master-staff`} component={MasterStaffRoute} />
        <Route
          path={`${pathname}/master-cleaning-service`}
          component={MasterCleaningServiceRoute}
        />
        <Route
          path={`${pathname}/master-patient`}
          component={MasterPatientRoute}
        />
        <Route
          path={`${pathname}/master-main-complaint`}
          component={MasterMainComplaintRoute}
        />
        <Route
          path={`${pathname}/master-allergic`}
          component={MasterAllergicRoute}
        />
        <Route
          path={`${pathname}/master-diagnosis`}
          component={MasterDiagnosisRoute}
        />
        <Route
          path={`${pathname}/master-admin-fee`}
          component={MasterAdminFeeRoute}
        />
        <Route
          path={`${pathname}/master-action`}
          component={MasterActionRoute}
        />
        <Route
          path={`${pathname}/master-substance`}
          component={MasterSubstanceRoute}
        />
        {/* Super Admin */}
        {isRoleSuperAdmin() && (
          <Route
            path={`${pathname}/super-admin`}
            component={SuperAdminLayout}
          />
        )}
        <Route component={Page404} />
      </Switch>
    );
  }
  return <PageForbidden403 />;
};
