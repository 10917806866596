import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const Div = styled.div`
  .menus {
    /* ALIAS */
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .menu-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 18px;
    text-align: center;
  }
  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 4em;
  }

  .cursor-pointer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
  }
  .menu-icon {
    font-size: 30px !important;
  }

  .menu-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }

  .empty-min-width {
    min-width: 60px;
  }
`;

export const RootMenu = () => {
  const { pathname } = useLocation();
  const [listMenuUi, setListMenuUi] = useState([]);

  const emptyMenu = {
    url: '',
    icon: 'fa fa-battery-empty',
    name: 'empty',
  };
  let listMenu = [
    {
      url: `${pathname}/master-role`,
      icon: 'fa fa-key',
      name: 'Master Hak Akses',
    },
    {
      url: `${pathname}/master-user`,
      icon: 'fa fa-users',
      name: 'Master User',
    },
    {
      url: `${pathname}/master-user-role`,
      icon: 'fa fa-lock',
      name: 'Master Akses User',
    },
    {
      url: `${pathname}/master-password-reset`,
      icon: 'fa fa-hashtag',
      name: 'Master Password Reset',
    },
    {
      url: `${pathname}/master-user-archive`,
      icon: 'fa fa-users',
      name: 'Master User Arsip',
    },
    {
      url: `${pathname}/master-config`,
      icon: 'fa fa-cogs',
      name: 'Master Konfigurasi',
    },
  ];

  useEffect(() => {
    const handleMenuPerCase = () => {
      let newListMenuUi = [];
      handleMenuRemainder();

      function handleMenuRemainder() {
        if (listMenu.length % 4 > 0) {
          // Insert empty remainder
          const remainder = 4 - (listMenu.length % 4);
          for (let idx = 0; idx < remainder; idx++) {
            listMenu.push(emptyMenu);
          }
        }

        handleMenuUi();
      }
      function handleMenuUi() {
        let newMenu = [];

        listMenu.forEach((v, idx) => {
          newMenu.push(v);

          if ((idx + 1) % 4 === 0) {
            newListMenuUi.push(newMenu);

            newMenu = [];
          }
        });
        setListMenuUi(newListMenuUi);
      }
    };

    if (listMenuUi.length < 1) {
      handleMenuPerCase();
    }
  }, [listMenuUi, emptyMenu, listMenu]);

  let UiMenuFourth = listMenuUi.map((menuFourth, idxFourth) => {
    let UiMenu = menuFourth.map((menu, idx) => {
      let UiMenuItem = (
        <div key={`${idxFourth}-${idx}`} className='empty-min-width' />
      );
      if (menu.type === 'link') {
        UiMenuItem = (
          <a
            href={menu.url}
            key={`${idxFourth}-${idx}`}
            className={'teal-text'}
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </a>
        );
      } else if (menu.type === 'function') {
        UiMenuItem = (
          <span
            onClick={menu.function}
            className={'teal-text cursor-pointer'}
            key={`${idxFourth}-${idx}`}
          >
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </span>
        );
      } else if (menu.url) {
        UiMenuItem = (
          <Link to={menu.url} className='teal-text' key={`${idxFourth}-${idx}`}>
            <div className='menu'>
              <div className='button-circle-panel hoverable cursor-pointer'>
                <i className={`${menu.icon} menu-icon`} />
              </div>

              <div className='menu-label'>{menu.name}</div>
            </div>
          </Link>
        );
      }
      return UiMenuItem;
    });
    return (
      <div className='menu-row' key={`${idxFourth}`}>
        {UiMenu}
      </div>
    );
  });

  return <Div className='menus'>{UiMenuFourth}</Div>;
};
