import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const Div = styled.div`
  select {
    display: block;
    width: 247px;
  }
`;

const defaultOptions = [
  {
    _id: '1',
    name: 'Satu',
  },
  {
    _id: '2',
    name: 'Dua',
  },
];

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const FilterSelect = ({
  options = defaultOptions,
  filterKey = 'dummyKey',
  defaultValue = '',
  defaultText = 'Tampilkan Semua',
  onChange = defaultOnChange,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();

  const queryParams = queryString.parse(locationSearch);
  const value = queryParams[filterKey] ? queryParams[filterKey] : defaultValue;

  const changeValue = (e) => {
    e.preventDefault();
    const v = e.target.value;
    const newQueryParams = queryParams;
    newQueryParams[filterKey] = v;
    if (newQueryParams[filterKey] === '') {
      delete newQueryParams[filterKey];
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  return (
    <Div className="m-v-2">
      <select onChange={changeValue} value={value}>
        <option value={''}>{defaultText}</option>
        {options.map((option) => {
          return (
            <option
              key={option._id}
              value={option._id}
              className="browser-default"
            >
              {option.name}
            </option>
          );
        })}
      </select>
    </Div>
  );
};
