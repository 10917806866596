import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { PatientArchiveList } from './PatientArchiveList';

export const MasterPatientArchiveRoute = () => {
  const pathname = '/admin/super-admin/master-patient-archive';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={PatientArchiveList} />
      <Route component={Page404} />
    </Switch>
  );
};
