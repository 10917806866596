import React from 'react';
import styled from 'styled-components';

import { ROLE_LIST_ROOT } from '../../../config';

// Data Roles Hardcoded dulu

const Thead = styled.thead`
  .container-class {
    /* container class alias */
  }
`;

export const TableHead = () => {
  return (
    <Thead>
      <tr>
        <th rowSpan='2'>roleUpdated</th>
        <th rowSpan='2'>email</th>
        <th rowSpan='2'>Nama User</th>
        <th rowSpan='2'>Nama Pegawai</th>
        {ROLE_LIST_ROOT.map((data) => {
          return (
            <th key={data._id} className='center-align'>
              {data.name}
            </th>
          );
        })}
      </tr>
    </Thead>
  );
};
