import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BreadCrumb, HeaderNav, LoaderBlock, Badge } from "../../component/common";
import { patientUpdateSetForm, patientUpdateInit, fetchGetPatientUpdateDetail, fetchPostPatientUpdate } from "../../store/patientDoctorAction";
import { momentFormatInputDate, moment, momentCountAge, parseDepartmentName } from "../../helper";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .badge-box {
    padding-top: 2.2em;
  }
`;

const breadCrumbData = (doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, locationSearch) => {
  return [
    {
      url: `/patient-doctor-action`,
      name: "Bagian / Poli",
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}${locationSearch}`,
      name: doctorDepartmentName,
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`,
      name: "Pasien",
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}/update${locationSearch}`,
      name: "Update Data Pasien",
    },
  ];
};
const backURL = (doctorDepartmentId, patientId, patientVisitId, locationSearch) => `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`;

export const PatientUpdate = () => {
  const { doctorDepartmentId, patientId, patientVisitId } = useParams();
  const { search: locationSearch } = useLocation();

  const dispatch = useDispatch();
  const { push } = useHistory();
  const $global = useSelector((state) => state.global);
  const $patientUpdate = useSelector((state) => state.patientUpdate);
  const { listDepartment, listAllergic } = $global;

  const { data, form } = $patientUpdate;
  const listDepartmentFilter = listDepartment.filter((dpt) => dpt.type === "doctor");
  const doctorDepartmentName = parseDepartmentName({
    listDepartment: listDepartmentFilter,
    departmentId: doctorDepartmentId,
  });

  useEffect(() => {
    const didMount = () => {
      dispatch(patientUpdateInit());
      if (patientId) {
        dispatch(fetchGetPatientUpdateDetail({ patientId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line

  const setAllergic = (e) => {
    e.preventDefault();
    const allergicName = e.target.value.trim();
    let newAllergies = form.allergies && form.allergies.length > 0 ? form.allergies : [];
    newAllergies = newAllergies.filter((aName) => aName !== allergicName);
    newAllergies.push(allergicName);
    dispatch(
      patientUpdateSetForm({
        key: "allergies",
        value: newAllergies.sort(),
      })
    );
  };
  const removeAllergic = (allergicName) => {
    allergicName = allergicName.trim();
    let newAllergies = form.allergies && form.allergies.length > 0 ? form.allergies : [];
    newAllergies = newAllergies.filter((aName) => aName !== allergicName);
    dispatch(
      patientUpdateSetForm({
        key: "allergies",
        value: newAllergies.sort(),
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchPostPatientUpdate({
        next: () => {
          push(backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch));
        },
        patientId: data._id,
        // Bio
        noRM: form.noRM,
        prefix: form.prefix,
        suffix: form.suffix,
        name: form.name,
        birthLocation: form.birthLocation,
        birthDate: form.birthDate,
        nik: form.nik,

        mobile: form.mobile,
        gender: form.gender,
        // Tambahan
        bloodType: form.bloodType,
        nationality: form.nationality,
        address: form.address,
        kelurahan: form.kelurahan,
        kecamatan: form.kecamatan,
        kota: form.kota,
        lastEducation: form.lastEducation,
        religion: form.religion,
        job: form.job,
        martialStatus: form.martialStatus,
        patientGroup: form.patientGroup,
        paymentMethod: form.paymentMethod,
        responsibleName: form.responsibleName,
        responsiblePhone: form.responsiblePhone,
        // Physical Examination
        allergies: form.allergies,
        height: form.height,
        weight: form.weight,
        kesadaran: form.kesadaran,
        suhu: form.suhu,
        keadaanUmum: form.keadaanUmum,
        nadi: form.nadi,
        saturasiOksigen: form.saturasiOksigen,
        tekananDarah: form.tekananDarah,
        respirasi: form.respirasi,
        notesPhysicalExamination: form.notesPhysicalExamination,
      })
    );
  };

  return (
    <Fragment>
      {($patientUpdate.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav headerSize="m" headerName={"Update"} backURL={backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch)} />
      <BreadCrumb breadCrumbData={breadCrumbData(doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, locationSearch)} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col s12 font-20">
                  <b>Formulir Biodata Pasien</b>
                </div>
              </div>
              <div className="row">
                <div className="input-field col m3 s12">
                  <input defaultValue={data.noRM} placeholder="Nomor RM" id="noRM" name="noRM" disabled={true} />
                  <label className="active" htmlFor="noRM">
                    Nomor RM
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <input defaultValue={data.nik} placeholder="NIK" id="noRM" name="noRM" disabled={true} />
                  <label className="active" htmlFor="noRM">
                    NIK
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <input
                    value={form.mobile}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "mobile",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="No Handphone"
                    id="mobile"
                    name="mobile"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="mobile">
                    No Handphone <b>contoh: 08122222222</b>
                  </label>
                </div>
              </div>
      
        
              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.prefix}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "prefix",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Gelar Depan"
                    id="prefix"
                    name="prefix"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="prefix">
                    Gelar Depan
                  </label>
                </div>
                <div className="input-field col m6 s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "name",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama <b>{`${form.prefix} ${form.name} ${form.suffix}`}</b>
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.suffix}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "suffix",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Gelar Belakang"
                    id="suffix"
                    name="suffix"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="suffix">
                    Gelar Belakang
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.gender}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "gender",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Jenis Kelamin"
                    id="gender"
                    name="gender"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="m">Pria</option>
                    <option value="f">Wanita</option>
                  </select>
                  <label className="active" htmlFor="gender">
                    Jenis Kelamin
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.birthLocation}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "birthLocation",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tempat Lahir"
                    id="birthLocation"
                    name="birthLocation"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="birthLocation">
                    Tempat Lahir
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <input
                    value={form.birthDate}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "birthDate",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tanggal Lahir"
                    id="birthDate"
                    name="birthDate"
                    type="date"
                    min={moment("19200101", "YYYYMMDD").format("YYYY-MM-DD")}
                    max={moment().endOf("month").format("YYYY-MM-DD")}
                    className="validate"
                  />
                  <label className="active" htmlFor="birthDate">
                    Tanggal Lahir <b>{momentFormatInputDate(form.birthDate)}</b>
                    {" / "}
                    <b>{momentCountAge(form.birthDate)} tahun</b>
                  </label>
                </div>
              </div>

     
              {/* Formulir Data Pasien */}
              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.bloodType}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "bloodType",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Departemen"
                    id="bloodType"
                    name="bloodType"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="AB">AB</option>
                    <option value="O">O</option>
                  </select>
                  <label className="active" htmlFor="bloodType">
                    Golongan Darah
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <select
                    value={form.nationality}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "nationality",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Departemen"
                    id="nationality"
                    name="nationality"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="WNI">WNI</option>
                    <option value="WNA">WNA</option>
                  </select>
                  <label className="active" htmlFor="nationality">
                    Kependudukan
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m12 s12">
                  <input
                    value={form.address}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "address",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Alamat"
                    id="address"
                    name="address"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="address">
                    Alamat
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.kelurahan}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "kelurahan",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kelurahan"
                    id="kelurahan"
                    name="kelurahan"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="kelurahan">
                    Kelurahan
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.kecamatan}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "kecamatan",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kecamatan"
                    id="kecamatan"
                    name="kecamatan"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="kecamatan">
                    Kecamatan
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.kota}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "kota",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kabupaten / Kota"
                    id="kota"
                    name="kota"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="kota">
                    Kabupaten / Kota
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.lastEducation}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "lastEducation",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Pendidikan Terakhir"
                    id="lastEducation"
                    name="lastEducation"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="SD">SD</option>
                    <option value="SMP">SMP</option>
                    <option value="SMA">SMA</option>
                    <option value="Diploma">Diploma</option>
                    <option value="S1">S1</option>
                    <option value="S2">S2</option>
                    <option value="S3">S3</option>
                  </select>
                  <label className="active" htmlFor="lastEducation">
                    Pendidikan Terakhir
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <select
                    value={form.religion}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "religion",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Agama"
                    id="religion"
                    name="religion"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen">Kristen</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Buddha">Buddha</option>
                    <option value="Konguchu">Konguchu</option>
                    <option value="Lainnya">Lainnya</option>
                  </select>
                  <label className="active" htmlFor="religion">
                    Agama
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <select
                    value={form.job}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "job",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Pekerjaan"
                    id="job"
                    name="job"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="PNS">PNS</option>
                    <option value="TNI/POLRI">TNI/POLRI</option>
                    <option value="Wiraswasta">Wiraswasta</option>
                    <option value="Pelajar/Mahasiswa">Pelajar/Mahasiswa</option>
                    <option value="Lainnya">Lainnya</option>
                  </select>
                  <label className="active" htmlFor="job">
                    Pekerjaan
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <select
                    value={form.martialStatus}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "martialStatus",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Status Pernikahan"
                    id="martialStatus"
                    name="martialStatus"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="Belum Kawin">Belum Kawin</option>
                    <option value="Kawin">Kawin</option>
                    <option value="Janda">Janda</option>
                    <option value="Duda">Duda</option>
                  </select>
                  <label className="active" htmlFor="martialStatus">
                    Status Pernikahan
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.patientGroup}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "patientGroup",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kelompok"
                    id="patientGroup"
                    name="patientGroup"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="Umum">Umum</option>
                    <option value="Mahasiswa Unsrat">Mahasiswa Unsrat</option>
                    <option value="Karyawan/Dosen Unsrat">Karyawan/Dosen Unsrat</option>
                  </select>
                  <label className="active" htmlFor="patientGroup">
                    Kelompok
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <select
                    value={form.paymentMethod}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "paymentMethod",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Cara Pembayaran"
                    id="paymentMethod"
                    name="paymentMethod"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="Asuransi/Jaminan Kesehatan">Asuransi/Jaminan Kesehatan</option>
                    <option value="Umum/Mandiri">Umum/Mandiri</option>
                  </select>
                  <label className="active" htmlFor="paymentMethod">
                    Cara Pembayaran
                  </label>
                </div>
              </div>

              <div className="row">
                <div className='input-field col m6 s12'>
                  <input
                    value={form.responsibleName}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: 'responsibleName',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='Nama'
                    id='responsibleName'
                    name='responsibleName'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='responsibleName'>
                    Nama Penanggung Jawab
                  </label>
                </div>

                <div className='input-field col m3 s12'>
                  <input
                    value={form.responsibleMobile}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: 'responsibleMobile',
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder='No Handphone'
                    id='responsibleMobile'
                    name='responsibleMobile'
                    type='text'
                    className='validate'
                  />
                  <label className='active' htmlFor='responsibleMobile'>
                    No Telepon / HP Penanggung Jawab
                  </label>
                </div>
              </div>
            
              <div className="row">
                <div className="col s12 font-20">
                  <b>Pemeriksaan Fisik</b>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select value={""} onChange={setAllergic} placeholder="Alergi" id="allergies" name="allergies" type="text" className="browser-default validate m-t-8">
                    <option value="">Silahkan Pilih</option>
                    {listAllergic.map((allergic) => (
                      <option key={allergic._id} value={allergic.name}>
                        {allergic.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="allergies">
                    Riwayat Alergi
                  </label>
                </div>
                <div className="col m9 s12 badge-box">
                  {form.allergies.map((aName) => (
                    <Badge key={aName} text={aName} onClose={() => removeAllergic(aName)} />
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.height}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "height",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tinggi Badan (cm)"
                    id="height"
                    name="height"
                    type="number"
                    min="0"
                    max="300"
                    className="validate"
                  />
                  <label className="active" htmlFor="height">
                    Tinggi Badan (cm)
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.weight}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "weight",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Berat Badan (kg)"
                    id="weight"
                    name="weight"
                    type="number"
                    min="0"
                    max="300"
                    className="validate"
                  />
                  <label className="active" htmlFor="weight">
                    Berat Badan (kg)
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.kesadaran}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "kesadaran",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Kesadaran"
                    id="kesadaran"
                    name="kesadaran"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="kesadaran">
                    Kesadaran
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.suhu}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "suhu",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Suhu"
                    id="suhu"
                    name="suhu"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="suhu">
                    Suhu
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.keadaanUmum}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "keadaanUmum",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Keadaan Umum"
                    id="keadaanUmum"
                    name="keadaanUmum"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="keadaanUmum">
                    Keadaan Umum
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.nadi}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "nadi",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nadi"
                    id="nadi"
                    name="nadi"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="nadi">
                    Nadi
                  </label>
                </div>
               
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.saturasiOksigen}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "saturasiOksigen",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Saturasi Oksigen"
                    id="saturasiOksigen"
                    name="saturasiOksigen"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="saturasiOksigen">
                    Saturasi Oksigen
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.tekananDarah}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "tekananDarah",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tekanan Darah"
                    id="tekananDarah"
                    name="tekananDarah"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="tekananDarah">
                    Tekanan Darah
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.respirasi}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "respirasi",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Respirasi"
                    id="respirasi"
                    name="respirasi"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="respirasi">
                    Respirasi
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m12 s12">
                  <input
                    value={form.notesPhysicalExamination}
                    onChange={(e) =>
                      dispatch(
                        patientUpdateSetForm({
                          key: "notesPhysicalExamination",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Catatan Tambahan"
                    id="notesPhysicalExamination"
                    name="notesPhysicalExamination"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="notesPhysicalExamination">
                    Catatan Tambahan
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">{$patientUpdate.error}</div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right">
                    Simpan Perubahan
                  </button>
                  <Link to={backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch)} className="btn grey m-b-20 right m-r-8">
                    Batal
                  </Link>
                </div>
              </div>
         
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
