import fetchApi from './fetchApi';
import { BASE_API } from '../config';

// GET
export function restGetHome() {
  const path = `${BASE_API}/home`;
  return fetchApi(path, {
    method: 'GET',
  });
}
