import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetActionList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  doctorDepartmentId = '',
}) {
  let path = `${BASE_API_ADMIN}/action/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;
  path += `&doctorDepartmentId=${doctorDepartmentId}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetActionDetail({ actionId }) {
  const path = `${BASE_API_ADMIN}/action/detail/${actionId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostActionCreate({ doctorDepartmentId, name, price }) {
  const path = `${BASE_API_ADMIN}/action/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      doctorDepartmentId,
      name,
      price,
    },
  });
}

export function restPostActionUpdate({
  actionId,
  doctorDepartmentId,
  name,
  price,
}) {
  const path = `${BASE_API_ADMIN}/action/update/${actionId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      doctorDepartmentId,
      name,
      price,
    },
  });
}
