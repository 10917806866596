import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BreadCrumb, HeaderNav, LoaderBlock } from "../../component/common";
import {
  personCreateSetForm,
  personCreateInit,
  fetchAdminPostPersonCreate,
} from "../../store/person";
import { momentFormatInputDate, moment } from "../../helper";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/admin`,
    name: "Admin",
  },
  {
    url: `/admin/master-cleaning-service`,
    name: "Master Data Cleaning Service",
  },
  {
    url: `/admin/master-cleaning-service/create`,
    name: "Tambah Baru",
  },
];

export const CleaningServiceCreate = () => {
  const dispatch = useDispatch();
  const $global = useSelector((state) => state.global);
  const $personCreate = useSelector((state) => state.personCreate);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === "cleaning-service"
  );
  const { form } = $personCreate;
  const { push } = useHistory();

  useEffect(() => {
    const didMount = () => {
      dispatch(personCreateInit());
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminPostPersonCreate({
        next: () => {
          push(`/admin/master-cleaning-service?sort=createdAt&sortDir=desc`);
        },
        type: "cleaning-service",
        joinDate: form.joinDate,
        gender: form.gender,
        prefix: form.prefix,
        suffix: form.suffix,
        name: form.name,
        personNo: form.personNo,
        mobile: form.mobile,
        departmentId: form.departmentId,
      })
    );
  };
  return (
    <Fragment>
      {($personCreate.isLoading || $global.isLoading) && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Tambah Baru"}
        backURL={"/admin/master-cleaning-service"}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.joinDate}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "joinDate",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Tanggal Bergabung"
                    id="joinDate"
                    name="joinDate"
                    type="date"
                    min={moment("19800101", "YYYYMMDD").format("YYYY-MM-DD")}
                    max={moment().endOf("month").format("YYYY-MM-DD")}
                    className="validate"
                  />
                  <label className="active" htmlFor="joinDate">
                    Tanggal Bergabung{" "}
                    <b>{momentFormatInputDate(form.joinDate)}</b>
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.mobile}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "mobile",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="No Handphone"
                    id="mobile"
                    name="mobile"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="mobile">
                    No Handphone <b>contoh: 08122222222</b>
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.personNo}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "personNo",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="No Induk"
                    id="personNo"
                    name="personNo"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="personNo">
                    No Induk
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.prefix}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "prefix",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Gelar Depan"
                    id="prefix"
                    name="prefix"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="prefix">
                    Gelar Depan
                  </label>
                </div>
                <div className="input-field col m6 s12">
                  <input
                    value={form.name}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "name",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Nama"
                    id="name"
                    name="name"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="name">
                    Nama <b>{`${form.prefix} ${form.name} ${form.suffix}`}</b>
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <input
                    value={form.suffix}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "suffix",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Gelar Belakang"
                    id="suffix"
                    name="suffix"
                    type="text"
                    className="validate"
                  />
                  <label className="active" htmlFor="suffix">
                    Gelar Belakang
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.gender}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "gender",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Gender"
                    id="gender"
                    name="gender"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    <option value="m">Pria</option>
                    <option value="f">Wanita</option>
                  </select>
                  <label className="active" htmlFor="gender">
                    Gender
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <select
                    value={form.departmentId}
                    onChange={(e) =>
                      dispatch(
                        personCreateSetForm({
                          key: "departmentId",
                          value: e.target.value,
                        })
                      )
                    }
                    placeholder="Departemen"
                    id="departmentId"
                    name="departmentId"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    {listDepartmentFilter.map((dpt) => (
                      <option key={dpt._id} value={dpt._id}>
                        {dpt.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="departmentId">
                    Departemen
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">
                    {$personCreate.error}
                  </div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right">
                    Tambah Baru
                  </button>
                  <Link
                    to={`/admin/master-cleaning-service`}
                    className="btn grey m-b-20 right m-r-8"
                  >
                    Batal
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
