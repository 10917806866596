import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LOGO_URL from '../../images/unsrat.png';
import { LoaderBlock } from '../../component/common';

import { fetchPostAuthLogin } from '../../store/auth';

const Div = styled.div`
  .center-container {
    /* Root name alias */
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-dimension {
    height: 1.6em;
    margin-right: 4px;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
`;

export const Login = () => {
  const dispatch = useDispatch();
  const $loginForm = useSelector((state) => state.loginForm);

  const [inputEmail, setEmail] = useState('');
  const [inputPassword, setPassword] = useState('');

  function onSubmitLogin(e) {
    e.preventDefault();
    dispatch(
      fetchPostAuthLogin({
        email: inputEmail,
        password: inputPassword,
      })
    );
  }

  return (
    <Fragment>
      {$loginForm.isLoading && <LoaderBlock />}
      <Div>
        <h3 className='center-align'>
          <img className='logo-dimension' src={LOGO_URL} alt='RSGM Unsrat' />
          <br />
          <span className='font-24'>E-Hospital</span>
        </h3>
        <div className='login-box'>
          <form onSubmit={onSubmitLogin}>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='true'
                  className='validate'
                />
                <label className='active' htmlFor='email'>
                  Email
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  className='validate'
                />
                <label className='active' htmlFor='password'>
                  Password
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <div className='red-text m-b-4'>{$loginForm.error}</div>
                <button type='submit' className='btn teal m-b-20'>
                  Login
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Link to={`/forgot-password`}>
                  <span className='right teal-text'>Lupa Password?</span>
                </Link>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Link to={`/register`}>
                  <span className='right teal-text'>Registrasi</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </Div>
    </Fragment>
  );
};
