import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetDiagnosisList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/diagnosis/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetDiagnosisDetail({ diagnosisId }) {
  const path = `${BASE_API_ADMIN}/diagnosis/detail/${diagnosisId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostDiagnosisCreate({ icd10, name }) {
  const path = `${BASE_API_ADMIN}/diagnosis/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      icd10,
      name,
    },
  });
}

export function restPostDiagnosisUpdate({ diagnosisId, icd10, name }) {
  const path = `${BASE_API_ADMIN}/diagnosis/update/${diagnosisId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      icd10,
      name,
    },
  });
}
