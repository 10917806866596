import { createSlice } from "@reduxjs/toolkit";
import { restPostPersonCreate as restAdminPostPersonCreate } from "../../apiAdmin/person";
import {
  isValidJSON,
  momentInputDateToNumberDate,
  successNotif,
} from "../../helper";

const initialState = {
  isLoading: false,
  error: null,
  form: {
    type: "",
    joinDate: "",
    gender: "",
    prefix: "",
    suffix: "",
    name: "",
    personNo: "",
    mobile: "",
    departmentId: "",
  },
};

const personCreateSlice = createSlice({
  name: "personCreate",
  initialState: initialState,
  reducers: {
    personCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    personCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: "",
        joinDate: "",
        gender: "",
        prefix: "",
        suffix: "",
        name: "",
        personNo: "",
        mobile: "",
        departmentId: "",
      };
    },
    postPersonCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPersonCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPersonCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  personCreateSetForm,
  personCreateInit,
  postPersonCreateStart,
  postPersonCreateSuccess,
  postPersonCreateError,
} = personCreateSlice.actions;

export default personCreateSlice.reducer;

export const fetchAdminPostPersonCreate = ({
  next,
  type = "unSelect",
  joinDate = "",
  gender = "",
  prefix = "",
  suffix = "",
  name = "",
  personNo = "",
  mobile = "",
  departmentId = "",
}) => async (dispatch, getState) => {
  name = name.trim();

  if (type.length < 3) {
    return dispatch(postPersonCreateError("Type harus ada"));
  }
  joinDate = momentInputDateToNumberDate(joinDate);
  if (!joinDate) {
    return dispatch(postPersonCreateError("Tanggal bergabung harus diisi"));
  }
  if (!gender) {
    return dispatch(postPersonCreateError("Silahkan pilih gender"));
  }
  if (!(gender === "m" || gender === "f")) {
    return dispatch(postPersonCreateError("Gender Salah"));
  }
  if (name.length < 3) {
    return dispatch(postPersonCreateError("Nama minimal 3 karakter"));
  }
  if (personNo.length < 3) {
    return dispatch(postPersonCreateError("Nomor Induk minimal 3 karakter"));
  }
  if (mobile.length < 5) {
    return dispatch(postPersonCreateError("No HP Terlalu Pendek"));
  }
  if (!departmentId) {
    return dispatch(postPersonCreateError("Pastikan sudah memilih department"));
  }

  const { isLoading } = getState().personCreate;
  if (isLoading) return;
  try {
    dispatch(postPersonCreateStart());
    await restAdminPostPersonCreate({
      type,
      joinDate,
      gender,
      prefix,
      suffix,
      name,
      personNo,
      mobile,
      departmentId,
    });
    dispatch(postPersonCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postPersonCreateError(JSON.parse(err).message));
    }
    dispatch(postPersonCreateError(err));
  }
};
