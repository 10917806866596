import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { SubstanceList } from './SubstanceList';
import { SubstanceCreate } from './SubstanceCreate';
import { SubstanceUpdate } from './SubstanceUpdate';

export const MasterSubstanceRoute = () => {
  const pathname = '/admin/master-substance';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={SubstanceList} />
      <Route exact path={`${pathname}/create`} component={SubstanceCreate} />
      <Route
        exact
        path={`${pathname}/update/:substanceId`}
        component={SubstanceUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
