import medicalRecordList from './medicalRecordList';
import medicalRecordDetail from './medicalRecordDetail';
import medicalRecordActionList from './medicalRecordActionList';

export * from './medicalRecordList';
export * from './medicalRecordDetail';
export * from './medicalRecordActionList';

const medicalRecordReducers = {
  medicalRecordList,
  medicalRecordDetail,
  medicalRecordActionList,
};

export default medicalRecordReducers;
