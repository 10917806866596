import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const Div = styled.div`
  display: flex;
  align-items: center;
  .sort-box {
    /* container class alias */
  }
  select {
    display: block;
    width: 250px;
    height: 35px;
  }
`;

const defaultSortOptions = [
  {
    key: 'createdAt',
    name: 'Dibuat',
  },
  {
    key: 'updatedAt',
    name: 'Diubah',
  },
  {
    key: 'name',
    name: 'Nama',
  },
];

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const Sort = ({
  sortOptions = defaultSortOptions,
  defaultSort = 'createdAt',
  defaultSortDir = 'asc',
  onChange = defaultOnChange,
  children = <span className='sortSlot'></span>,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();

  const queryParams = queryString.parse(locationSearch);
  const sort = queryParams.sort ? queryParams.sort : defaultSort;
  const sortDir = queryParams.sortDir ? queryParams.sortDir : defaultSortDir;

  const changeSort = (e) => {
    const v = e.target.value;
    const newQueryParams = queryParams;
    newQueryParams.sort = v;
    if (newQueryParams.sort === defaultSort && sortDir === defaultSortDir) {
      delete newQueryParams.sort;
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  const changeSortDir = (e) => {
    e.preventDefault();
    let v = 'asc';
    if (sortDir === 'asc') {
      v = 'desc';
    }
    const newQueryParams = queryParams;
    newQueryParams.sortDir = v;
    if (newQueryParams.sortDir === defaultSortDir) {
      delete newQueryParams.sortDir;
      if (sort === defaultSort) {
        delete newQueryParams.sort;
      }
    } else if (sort === defaultSort) {
      newQueryParams.sort = sort;
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  return (
    <Div className='m-v-2'>
      {children}
      <button
        onClick={changeSortDir}
        type='button'
        className='btn-small white black-text'
      >
        {sortDir === 'asc' && <i className='fa fa-sort-numeric-asc' />}
        {sortDir !== 'asc' && <i className='fa fa-sort-numeric-desc' />}
      </button>
      <select value={sort} onChange={changeSort}>
        {sortOptions.map((option) => {
          return (
            <option key={option.key} value={option.key}>
              {option.name}
            </option>
          );
        })}
      </select>
    </Div>
  );
};
