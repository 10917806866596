import actionList from './actionList';
import actionCreate from './actionCreate';
import actionDetail from './actionDetail';

export * from './actionList';
export * from './actionCreate';
export * from './actionDetail';

const actionReducers = {
  actionList,
  actionCreate,
  actionDetail,
};

export default actionReducers;
