import { createSlice } from '@reduxjs/toolkit';
import { restPostAuthLogout } from '../../api/auth';
import { restGetMyUser } from '../../api/user';
import { isValidJSON, errorNotif } from '../../helper';

const initialState = {
  data: {},
  appState: {},
  isInit: false,
  isLoading: false,
  error: null,
};

const myUserSlice = createSlice({
  name: 'myUser',
  initialState: initialState,
  reducers: {
    getMyUserStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getMyUserSuccess(state, action) {
      const { user, appState } = action.payload;
      state.data = user;
      state.appState = appState;
      state.isInit = true;
      state.isLoading = false;
      state.error = null;
    },
    getMyUserError(state, action) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload;
    },
    postLogoutStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postLogoutSuccess(state) {
      state.data = {};
      state.isLoading = false;
    },
    postLogoutError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMyUserStart,
  getMyUserSuccess,
  getMyUserError,
  postLogoutStart,
  postLogoutSuccess,
  postLogoutError,
} = myUserSlice.actions;

export default myUserSlice.reducer;

export const fetchGetMyUser = () => async (dispatch, getState) => {
  const { isLoading } = getState().myUser;
  if (isLoading) return;
  try {
    dispatch(getMyUserStart());
    const { user, appState } = await restGetMyUser();
    dispatch(
      getMyUserSuccess({
        user,
        appState,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      const errMessage = JSON.parse(err).message;
      if (
        errMessage === 'User dinonaktifkan oleh admin, silahkan hubungi admin.'
      ) {
        errorNotif(errMessage);
      }
      localStorage.removeItem('sessionId');
      return dispatch(getMyUserError(errMessage));
    }
    dispatch(getMyUserError(err));
  }
};

export const fetchPostAuthLogout = () => async (dispatch, getState) => {
  const { isLoading } = getState().myUser;
  if (isLoading) return;
  try {
    dispatch(postLogoutStart());
    await restPostAuthLogout();
    dispatch(postLogoutSuccess());
    localStorage.removeItem('sessionId');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postLogoutError(JSON.parse(err).message));
    }
    dispatch(postLogoutError(err));
  }
};
