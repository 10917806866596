import React from "react";
import { Switch, Route } from "react-router-dom";

import { isRoleSuperAdmin } from "../helper";
import { Page404, PageForbidden403 } from "../page/common";

import { SuperAdminHome } from "../pageSuperAdmin/superAdminHome";
import { MasterDepartmentRoute } from "../pageSuperAdmin/masterDepartment";
import { MasterPasswordResetRoute } from "../pageSuperAdmin/masterPasswordReset";
import { MasterUserArchiveRoute } from "../pageSuperAdmin/masterUserArchive";
import { MasterPeopleRoute } from "../pageSuperAdmin/masterPeople";
import { MasterPeopleArchiveRoute } from "../pageSuperAdmin/masterPeopleArchive";
import { MasterPatientArchiveRoute } from "../pageSuperAdmin/masterPatientArchive";
import { MasterSubstanceArchiveRoute } from "../pageSuperAdmin/masterSubstanceArchive";

export const SuperAdminLayout = () => {
  const pathname = "/admin/super-admin";
  if (isRoleSuperAdmin()) {
    return (
      <Switch>
        <Route exact path={`${pathname}`} component={SuperAdminHome} />
        <Route
          path={`${pathname}/master-department`}
          component={MasterDepartmentRoute}
        />
        <Route
          path={`${pathname}/master-password-reset`}
          component={MasterPasswordResetRoute}
        />
        <Route
          path={`${pathname}/master-user-archive`}
          component={MasterUserArchiveRoute}
        />
        <Route
          path={`${pathname}/master-people`}
          component={MasterPeopleRoute}
        />
        <Route
          path={`${pathname}/master-people-archive`}
          component={MasterPeopleArchiveRoute}
        />
        <Route
          path={`${pathname}/master-patient-archive`}
          component={MasterPatientArchiveRoute}
        />
        <Route
          path={`${pathname}/master-substance-archive`}
          component={MasterSubstanceArchiveRoute}
        />
        <Route component={Page404} />
      </Switch>
    );
  }
  return <PageForbidden403 />;
};
