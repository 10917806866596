import momentImport from "moment";
import { MOMENT_LOCALE_ID } from "./momentLocale";

let mInstance;
if (!mInstance) {
  momentImport.locale("id", MOMENT_LOCALE_ID);
  mInstance = momentImport;
  window.moment = momentImport;
}

export const moment = mInstance;

export const momentGeneral = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input);
  if (!date.isValid()) {
    return "";
  }
  return date.format("DD MMM YYYY HH:mm");
};

export const momentGeneralDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input);
  if (!date.isValid()) {
    return "";
  }
  return date.format("DD MMM YYYY");
};

export const momentFormatInputDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYY-MM-DD");
  if (!date.isValid()) {
    return "";
  }

  return date.format("DD MMM YYYY");
};

export const momentConvertToJSDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYY-MM-DD");
  if (!date.isValid()) {
    return "";
  }
  return date._d;
};

export const momentCountAge = (birthDate) => {
  if (!birthDate) {
    return "";
  }
  const curDate = moment(birthDate, "YYYYMMDD");
  if (!curDate.isValid()) {
    return "";
  }
  const years = moment().diff(curDate._d, "years");
  if (!years) {
    return "";
  }
  return years;
};

export const momentNumberDateToDisplayFullDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYYMMDD");
  if (!date.isValid()) {
    return "";
  }
  return date.format("dddd, DD MMM YYYY");
};

export const momentNumberDateToDisplayDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYYMMDD");
  if (!date.isValid()) {
    return "";
  }
  return date.format("DD MMM YYYY");
};

export const momentNumberDateToInputDate = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYYMMDD");
  if (!date.isValid()) {
    return "";
  }
  return date.format("YYYY-MM-DD");
};

export const momentInputDateToNumberDate = (input) => {
  if (!input) {
    return 0;
  }
  const date = moment(input, "YYYY-MM-DD");
  if (!date.isValid()) {
    return 0;
  }
  return Number(date.format("YYYYMMDD"));
};

export const momentNumberDateToInputMonth = (input) => {
  if (!input) {
    return "";
  }
  const date = moment(input, "YYYYMM");
  if (!date.isValid()) {
    return "";
  }
  return date.format("YYYY-MM");
};

export const momentInputDateToNumberMonth = (input) => {
  if (!input) {
    return 0;
  }
  const date = moment(input, "YYYY-MM");
  if (!date.isValid()) {
    return 0;
  }
  return Number(date.format("YYYYMM"));
};
