import { createSlice } from '@reduxjs/toolkit';
import {
  restGetDepartmentDetail as restAdminGetDepartmentDetail,
  restPostDepartmentUpdate as restAdminPostDepartmentUpdate,
} from '../../apiAdmin/department';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    type: 'unSelect',
    name: '',
  },
};

const departmentDetailSlice = createSlice({
  name: 'departmentDetail',
  initialState: initialState,
  reducers: {
    departmentDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    departmentDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: 'unSelect',
        name: '',
      };
    },
    getDepartmentDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getDepartmentDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: data.type,
        name: data.name,
      };
    },
    getDepartmentDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postDepartmentUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postDepartmentUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postDepartmentUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  departmentDetailSetForm,
  departmentDetailInit,
  getDepartmentDetailStart,
  getDepartmentDetailSuccess,
  getDepartmentDetailError,
  postDepartmentUpdateStart,
  postDepartmentUpdateSuccess,
  postDepartmentUpdateError,
} = departmentDetailSlice.actions;

export default departmentDetailSlice.reducer;

export const fetchAdminGetDepartmentDetail = ({ departmentId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().departmentDetail;
  if (isLoading) return;
  try {
    dispatch(getDepartmentDetailStart());
    const data = await restAdminGetDepartmentDetail({
      departmentId,
    });
    dispatch(
      getDepartmentDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getDepartmentDetailError(JSON.parse(err).message));
    }
    dispatch(getDepartmentDetailError(err));
  }
};

export const fetchAdminPostDepartmentUpdate = ({
  push,
  departmentId,
  type = '',
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!departmentId) {
    return dispatch(postDepartmentUpdateError('ID tidak ditemukan'));
  }
  if (type.length < 3) {
    return dispatch(postDepartmentUpdateError('Type harus ada'));
  }
  if (name.length < 3) {
    return dispatch(postDepartmentUpdateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().departmentDetail;
  if (isLoading) return;
  try {
    dispatch(postDepartmentUpdateStart());
    await restAdminPostDepartmentUpdate({
      departmentId,
      type,
      name,
    });
    dispatch(postDepartmentUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push)
      push('/admin/super-admin/master-department?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postDepartmentUpdateError(JSON.parse(err).message));
    }
    dispatch(postDepartmentUpdateError(err));
  }
};
