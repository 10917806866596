import fetchApi from '../api/fetchApi';
import { BASE_API_ROOT } from '../config';

// GET
export function restGetRoleList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ROOT}/role/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetRoleDetail({ roleId }) {
  const path = `${BASE_API_ROOT}/role/detail/${roleId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostRoleCreate({ _id, name }) {
  const path = `${BASE_API_ROOT}/role/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      _id,
      name,
    },
  });
}

export function restPostRoleUpdate({ roleId, name }) {
  const path = `${BASE_API_ROOT}/role/update/${roleId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
    },
  });
}

export function restPostRoleDelete({ roleId }) {
  const path = `${BASE_API_ROOT}/role/delete/${roleId}`;
  return fetchApi(path, {
    method: 'POST',
  });
}
