import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import LOGO_URL from '../../images/logo.png';
import { LoaderBlock } from '../../component/common';

import { postRegisterInit, fetchPostAuthRegister } from '../../store/auth';

const Div = styled.div`
  .center-container {
    /* Root name alias */
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-dimension {
    height: 1em;
    margin-right: 4px;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
`;

export const Register = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const $registerForm = useSelector((state) => state.registerForm);

  const [inputEmail, setEmail] = useState('');
  const [inputName, setName] = useState('');
  const [inputPassword, setPassword] = useState('');
  const [inputPasswordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    const didMount = () => {
      dispatch(postRegisterInit());
    };
    didMount();
  }, [dispatch]);

  function onRegister(e) {
    e.preventDefault();
    dispatch(
      fetchPostAuthRegister({
        push,
        email: inputEmail,
        name: inputName,
        password: inputPassword,
        passwordConfirmation: inputPasswordConfirmation,
      })
    );
  }

  return (
    <Fragment>
      {$registerForm.isLoading && <LoaderBlock />}
      <Div>
        <h3 className='center-align'>
          {/* <img
            className='logo-dimension'
            src={LOGO_URL}
            alt='RSGM Unsrat'
          /> */}
          <br />
          <span className='font-24'>Registrasi</span>
        </h3>
        <div className='login-box'>
          <form onSubmit={onRegister}>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='true'
                  className='validate'
                  disabled={$registerForm.isSuccess}
                />
                <label className='active' htmlFor='email'>
                  Email
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputName}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  id='name'
                  name='name'
                  type='text'
                  autoComplete='false'
                  className='validate'
                  disabled={$registerForm.isSuccess}
                />
                <label className='active' htmlFor='name'>
                  Nama
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='false'
                  className='validate'
                  disabled={$registerForm.isSuccess}
                />
                <label className='active' htmlFor='password'>
                  Password
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputPasswordConfirmation}
                  onChange={(e) => {
                    setPasswordConfirmation(e.target.value);
                  }}
                  id='rePassword'
                  name='rePassword'
                  type='password'
                  autoComplete='false'
                  className='validate'
                  disabled={$registerForm.isSuccess}
                />
                <label className='active' htmlFor='rePassword'>
                  Ulangi Password
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <div className='red-text m-b-4'>{$registerForm.error}</div>
                {$registerForm.isSuccess && (
                  <div className='teal-text m-b-4'>
                    Berhasil registrasi, silahkan cek email anda dalam 2 menit.
                  </div>
                )}
                <button
                  type='submit'
                  className='btn teal m-b-20'
                  disabled={$registerForm.isSuccess}
                >
                  Registrasi
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Link to={`/`}>
                  <span className='right teal-text'>Kembali ke Login</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </Div>
    </Fragment>
  );
};
