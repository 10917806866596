import substanceList from './substanceList';
import substanceCreate from './substanceCreate';
import substanceDetail from './substanceDetail';

export * from './substanceList';
export * from './substanceCreate';
export * from './substanceDetail';

const substanceReducers = {
  substanceList,
  substanceCreate,
  substanceDetail,
};

export default substanceReducers;
