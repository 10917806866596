import mainComplaintList from './mainComplaintList';
import mainComplaintCreate from './mainComplaintCreate';
import mainComplaintDetail from './mainComplaintDetail';

export * from './mainComplaintList';
export * from './mainComplaintCreate';
export * from './mainComplaintDetail';

const mainComplaintReducers = {
  mainComplaintList,
  mainComplaintCreate,
  mainComplaintDetail,
};

export default mainComplaintReducers;
