import React from 'react';

const NO_PHOTO_URL = `/images/nophoto.png`;

function addDefaultSrc(e) {
  e.target.src = NO_PHOTO_URL;
}

export const Img = ({ personId = '', alt = '', className = '' }) => {
  const imgUrl = `https://cloud.eabsen.com/files/photos/profile/${window.companyId}/${personId}/thumb_c_small.jpg`;
  return (
    <img
      src={imgUrl}
      alt={alt}
      className={`${className}`}
      onError={addDefaultSrc}
    />
  );
};
