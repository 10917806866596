import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { UserArchiveList } from './UserArchiveList';

export const MasterUserArchiveRoute = () => {
  const pathname = '/root/master-user-archive';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={UserArchiveList} />
      <Route component={Page404} />
    </Switch>
  );
};
