import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import LOGO_URL from '../../images/logo.png';
import { LoaderBlock } from '../../component/common';

import {
  fetchPostAuthResetRequest,
  postForgotPasswordInit,
} from '../../store/auth';

const Div = styled.div`
  .center-container {
    /* Root name alias */
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-dimension {
    height: 1em;
    margin-right: 4px;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
`;

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const $forgotPasswordForm = useSelector((state) => state.forgotPasswordForm);

  const [inputEmail, setEmail] = useState('');

  function onSubmitEmail(e) {
    e.preventDefault();
    dispatch(
      fetchPostAuthResetRequest({
        email: inputEmail,
      })
    );
  }

  useEffect(() => {
    const didMount = () => {
      dispatch(postForgotPasswordInit());
    };

    didMount();
  }, [dispatch]);
  return (
    <Fragment>
      {$forgotPasswordForm.isLoading && <LoaderBlock />}
      <Div>
        <h3 className='center-align'>
          {/* <img
            className='logo-dimension'
            src={LOGO_URL}
            alt='RSGM Unsrat'
          /> */}
          <br />
          <span className='font-24'>Lupa Password</span>
        </h3>
        <div className='login-box'>
          <form onSubmit={onSubmitEmail}>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  value={inputEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='true'
                  className='validate'
                  disabled={$forgotPasswordForm.isSuccess}
                />
                <label className='active' htmlFor='email'>
                  Email
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <div className='red-text m-b-4'>
                  {$forgotPasswordForm.error}
                </div>
                {$forgotPasswordForm.isSuccess && (
                  <div className='teal-text m-b-4'>
                    Link untuk reset password berhasil dikirim, silahkan "Cek
                    Email" dalam waktu 2 menit
                  </div>
                )}
                <button
                  disabled={$forgotPasswordForm.isSuccess}
                  type='submit'
                  className='btn teal m-b-20'
                >
                  Kirim Email
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Link to={`/`}>
                  <span className='right teal-text'>Kembali ke Login</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </Div>
    </Fragment>
  );
};
