import { createSlice } from '@reduxjs/toolkit';
import {
  restGetMainComplaintDetail as restAdminGetMainComplaintDetail,
  restPostMainComplaintUpdate as restAdminPostMainComplaintUpdate,
} from '../../apiAdmin/mainComplaint';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    name: '',
  },
};

const mainComplaintDetailSlice = createSlice({
  name: 'mainComplaintDetail',
  initialState: initialState,
  reducers: {
    mainComplaintDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    mainComplaintDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
      };
    },
    getMainComplaintDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getMainComplaintDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        type: data.type,
        name: data.name,
      };
    },
    getMainComplaintDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postMainComplaintUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postMainComplaintUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postMainComplaintUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  mainComplaintDetailSetForm,
  mainComplaintDetailInit,
  getMainComplaintDetailStart,
  getMainComplaintDetailSuccess,
  getMainComplaintDetailError,
  postMainComplaintUpdateStart,
  postMainComplaintUpdateSuccess,
  postMainComplaintUpdateError,
} = mainComplaintDetailSlice.actions;

export default mainComplaintDetailSlice.reducer;

export const fetchAdminGetMainComplaintDetail = ({ mainComplaintId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().mainComplaintDetail;
  if (isLoading) return;
  try {
    dispatch(getMainComplaintDetailStart());
    const data = await restAdminGetMainComplaintDetail({
      mainComplaintId,
    });
    dispatch(
      getMainComplaintDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getMainComplaintDetailError(JSON.parse(err).message));
    }
    dispatch(getMainComplaintDetailError(err));
  }
};

export const fetchAdminPostMainComplaintUpdate = ({
  push,
  mainComplaintId,
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (!mainComplaintId) {
    return dispatch(postMainComplaintUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postMainComplaintUpdateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().mainComplaintDetail;
  if (isLoading) return;
  try {
    dispatch(postMainComplaintUpdateStart());
    await restAdminPostMainComplaintUpdate({
      mainComplaintId,
      name,
    });
    dispatch(postMainComplaintUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/admin/master-main-complaint?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postMainComplaintUpdateError(JSON.parse(err).message));
    }
    dispatch(postMainComplaintUpdateError(err));
  }
};
