import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { ConfigList } from './ConfigList';
import { ConfigCreate } from './ConfigCreate';
import { ConfigUpdate } from './ConfigUpdate';

export const MasterConfigRoute = () => {
  const pathname = '/root/master-config';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={ConfigList} />
      <Route exact path={`${pathname}/create`} component={ConfigCreate} />
      <Route
        exact
        path={`${pathname}/update/:configId`}
        component={ConfigUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
