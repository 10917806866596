import { createSlice } from '@reduxjs/toolkit';
import { restPostMainComplaintCreate as restAdminPostMainComplaintCreate } from '../../apiAdmin/mainComplaint';
import { isValidJSON, successNotif } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  form: {
    name: '',
  },
};

const mainComplaintCreateSlice = createSlice({
  name: 'mainComplaintCreate',
  initialState: initialState,
  reducers: {
    mainComplaintCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    mainComplaintCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        name: '',
      };
    },
    postMainComplaintCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postMainComplaintCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postMainComplaintCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  mainComplaintCreateSetForm,
  mainComplaintCreateInit,
  postMainComplaintCreateStart,
  postMainComplaintCreateSuccess,
  postMainComplaintCreateError,
} = mainComplaintCreateSlice.actions;

export default mainComplaintCreateSlice.reducer;

export const fetchAdminPostMainComplaintCreate = ({
  push,
  name = '',
}) => async (dispatch, getState) => {
  name = name.trim();

  if (name.length < 3) {
    return dispatch(postMainComplaintCreateError('Nama minimal 3 karakter'));
  }

  const { isLoading } = getState().mainComplaintCreate;
  if (isLoading) return;
  try {
    dispatch(postMainComplaintCreateStart());
    await restAdminPostMainComplaintCreate({
      name,
    });
    dispatch(postMainComplaintCreateSuccess());
    successNotif(`Berhasil membuat ${name}`);
    if (push) push('/admin/master-main-complaint?sort=createdAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postMainComplaintCreateError(JSON.parse(err).message));
    }
    dispatch(postMainComplaintCreateError(err));
  }
};
