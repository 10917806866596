import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetPasswordResetList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/password-reset/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
// POST
export function restPostPasswordResetDeleteAll() {
  const path = `${BASE_API_ADMIN}/password-reset/delete-all`;
  return fetchApi(path, {
    method: 'POST',
  });
}
