import React from 'react';
import styled from 'styled-components';

const Div = styled.footer`
  @media print {
    display: none;
  }
  width: 100%;
  padding: 0px;
  height: 28px;
  .footer-position {
  }
  .footer-flex {
    display: flex;
    align-items: center;
  }
`;

export const Footer = () => {
  return (
    <Div>
      <div className='container'>
        <div className='footer-flex'>
          <div>
            E-Hospital
            <span
              className='teal-text text-darken-2'
              // href='https://ekin.sulutprov.go.id/'
              // target='_blank'
            >
              {` Rumah Sakit Gigi dan Mulut (RSGM) Universitas Sam Ratulangi`}
            </span>
            {` @2021`}
          </div>
        </div>
      </div>
    </Div>
  );
};
