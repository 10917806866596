import myUser from './myUser';
import loginForm from './loginForm';
import forgotPasswordForm from './forgotPasswordForm';
import resetForm from './resetForm';
import registerForm from './registerForm';
import resendEmailForm from './resendEmailForm';

export * from './myUser';
export * from './loginForm';
export * from './forgotPasswordForm';
export * from './resetForm';
export * from './registerForm';
export * from './resendEmailForm';

const authReducers = {
  myUser,
  loginForm,
  forgotPasswordForm,
  resetForm,
  registerForm,
  resendEmailForm,
};

export default authReducers;
