import { createSlice } from '@reduxjs/toolkit';
import {
  restGetUserDetail as restRootGetUserDetail,
  restPostUserUpdate as restRootPostUserUpdate,
  restPostUserArchive as restRootPostUserArchive,
} from '../../apiRoot/user';
import {
  restGetUserDetail as restAdminGetUserDetail,
  restPostUserUpdate as restAdminPostUserUpdate,
  restPostUserArchive as restAdminPostUserArchive,
  restPostUserRemoveAllSession as restAdminPostUserRemoveAllSession,
} from '../../apiAdmin/user';
import {
  isValidJSON,
  successNotif,
  warningNotif,
  errorNotif,
} from '../../helper';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  form: {
    isVerified: false,
    name: '',
    email: '',
    password: '',
  },
};

const userDetailSlice = createSlice({
  name: 'userDetail',
  initialState: initialState,
  reducers: {
    userDetailSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    userDetailInit(state) {
      state.data = {};
      state.isLoading = false;
      state.error = null;
      state.form = {
        isVerified: false,
        name: '',
        email: '',
        password: '',
      };
    },
    getUserDetailStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getUserDetailSuccess(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
      state.error = null;
      state.form = {
        isVerified: data.verifiedAt ? true : false,
        name: data.name,
        email: data.email,
        password: '',
      };
    },
    getUserDetailError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postUserUpdateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserUpdateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserUpdateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postUserDeleteStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postUserDeleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postUserDeleteError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  userDetailSetForm,
  userDetailInit,
  getUserDetailStart,
  getUserDetailSuccess,
  getUserDetailError,
  postUserUpdateStart,
  postUserUpdateSuccess,
  postUserUpdateError,
  postUserDeleteStart,
  postUserDeleteSuccess,
  postUserDeleteError,
} = userDetailSlice.actions;

export default userDetailSlice.reducer;

export const fetchAdminGetUserDetail = ({ userId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(getUserDetailStart());
    const data = await restAdminGetUserDetail({
      userId,
    });
    dispatch(
      getUserDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getUserDetailError(JSON.parse(err).message));
    }
    dispatch(getUserDetailError(err));
  }
};

export const fetchAdminPostUserUpdate = ({
  push,
  userId,
  isVerified,
  name = '',
  password = '',
}) => async (dispatch, getState) => {
  name = name.trim();
  password = password.trim();

  if (!userId) {
    return dispatch(postUserUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postUserUpdateError('Nama minimal 3 karakter'));
  }
  if (password && password.length < 6) {
    return dispatch(postUserUpdateError('Password minimal 6 karakter'));
  }

  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postUserUpdateStart());

    await restAdminPostUserUpdate({
      userId,
      isVerified,
      name,
      password,
    });
    dispatch(postUserUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/admin/master-user?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postUserUpdateError(JSON.parse(err).message));
    }
    dispatch(postUserUpdateError(err));
  }
};

export const fetchAdminPostUserArchive = ({
  push,
  next,
  isArchived,
  name,
  userId,
}) => async (dispatch, getState) => {
  const savedName = name;

  if (!userId) {
    errorNotif('ID tidak ditemukan');
    return dispatch(postUserDeleteError('ID tidak ditemukan'));
  }

  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postUserDeleteStart());
    await restAdminPostUserArchive({
      userId,
      isArchived,
    });
    dispatch(postUserDeleteSuccess());
    if (isArchived) {
      warningNotif(`Berhasil arsipkan ${savedName}`);
    } else {
      successNotif(`Berhasil mengembalikan ${savedName}`);
    }

    if (push) push('/admin/master-user');
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postUserDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postUserDeleteError(err));
  }
};
export const fetchAdminPostUserRemoveAllSession = ({ next }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postUserDeleteStart());
    await restAdminPostUserRemoveAllSession();
    dispatch(postUserDeleteSuccess());
    successNotif(`Berhasil logout semua pegawai`);
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postUserDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postUserDeleteError(err));
  }
};

// ROOT
export const fetchRootGetUserDetail = ({ userId }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(getUserDetailStart());
    const data = await restRootGetUserDetail({
      userId,
    });
    dispatch(
      getUserDetailSuccess({
        data,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getUserDetailError(JSON.parse(err).message));
    }
    dispatch(getUserDetailError(err));
  }
};

export const fetchRootPostUserUpdate = ({
  push,
  userId,
  isVerified,
  name = '',
  password = '',
}) => async (dispatch, getState) => {
  name = name.trim();
  password = password.trim();

  if (!userId) {
    return dispatch(postUserUpdateError('ID tidak ditemukan'));
  }
  if (name.length < 3) {
    return dispatch(postUserUpdateError('Nama minimal 3 karakter'));
  }
  if (password && password.length < 6) {
    return dispatch(postUserUpdateError('Password minimal 6 karakter'));
  }

  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postUserUpdateStart());

    await restRootPostUserUpdate({
      userId,
      isVerified,
      name,
      password,
    });
    dispatch(postUserUpdateSuccess());
    successNotif(`Berhasil simpan perubahan ${name}`);
    if (push) push('/root/master-user?sort=updatedAt&sortDir=desc');
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postUserUpdateError(JSON.parse(err).message));
    }
    dispatch(postUserUpdateError(err));
  }
};

export const fetchRootPostUserArchive = ({
  push,
  next,
  isArchived,
  name,
  userId,
}) => async (dispatch, getState) => {
  const savedName = name;

  if (!userId) {
    errorNotif('ID tidak ditemukan');
    return dispatch(postUserDeleteError('ID tidak ditemukan'));
  }

  const { isLoading } = getState().userDetail;
  if (isLoading) return;
  try {
    dispatch(postUserDeleteStart());
    await restRootPostUserArchive({
      userId,
      isArchived,
    });
    dispatch(postUserDeleteSuccess());
    if (isArchived) {
      warningNotif(`Berhasil arsipkan ${savedName}`);
    } else {
      successNotif(`Berhasil mengembalikan ${savedName}`);
    }

    if (push) push('/root/master-user');
    if (next) next();
  } catch (err) {
    if (isValidJSON(err)) {
      errorNotif(JSON.parse(err).message);
      return dispatch(postUserDeleteError(JSON.parse(err).message));
    }
    errorNotif(err);
    dispatch(postUserDeleteError(err));
  }
};
