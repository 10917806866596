import { createSlice } from '@reduxjs/toolkit';
import { restGetPatientInvoiceList, restPostPatientInvoiceDelete } from '../../api/patientInvoice';

import { isValidJSON, warningNotif, errorNotif } from '../../helper';

const initialState = {
  listData: [],
  totalItem: 0,
  isLoading: false,
  error: null,
};

const patientInvoiceListSlice = createSlice({
  name: 'patientInvoiceList',
  initialState: initialState,
  reducers: {
    getPatientInvoiceListStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPatientInvoiceListSuccess(state, action) {
      const { listData, totalItem } = action.payload;
      state.listData = listData;
      state.totalItem = totalItem;
      state.isLoading = false;
      state.error = null;
    },
    getPatientInvoiceListError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPatientInvoiceListStart,
  getPatientInvoiceListSuccess,
  getPatientInvoiceListError,
} = patientInvoiceListSlice.actions;

export default patientInvoiceListSlice.reducer;

export const fetchGetPatientInvoiceList = ({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
  invoiceDate = '',
  visitDate = '',
}) => async (dispatch, getState) => {
  search = search.trim();
  const { isLoading } = getState().patientInvoiceList;
  if (isLoading) return;
  try {
    dispatch(getPatientInvoiceListStart());
    const { listData, totalItem } = await restGetPatientInvoiceList({
      limit,
      page,
      sort,
      sortDir,
      search,
      invoiceDate,
      visitDate,
    });

    dispatch(
      getPatientInvoiceListSuccess({
        listData,
        totalItem,
      })
    );
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(getPatientInvoiceListError(JSON.parse(err).message));
    }
    dispatch(getPatientInvoiceListError(err));
  }
};


export const fetchPostPatientInvoiceDelete = ({
  patientInvoiceId, next
}) => async (dispatch, getState) =>{
  if (!patientInvoiceId) {
    return dispatch(
      getPatientInvoiceListError('ID Tindakan dokter tidak ditemukan')
    );
  }

  const { isLoading } = getState().patientInvoiceCreate;
  if (isLoading) return;
  try {
    dispatch(getPatientInvoiceListStart());
    await restPostPatientInvoiceDelete({
      patientInvoiceId,
    });
    dispatch(
      getPatientInvoiceListSuccess({
        listData: [],
        totalItem: 0,
      })
    );
    
    if (next) next();
    warningNotif(`berhasil hapus invoice dan data terkait`);
 


  } catch (err) {
    if (isValidJSON(err)) {
      const msg = JSON.parse(err).message;
      errorNotif(msg);
      return dispatch(getPatientInvoiceListError(msg));
    }
    dispatch(getPatientInvoiceListError(err));
  }
}