import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { moment } from '../../helper';
import { LoaderBlock, HeaderNav, BreadCrumb } from '../../component/common';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .cards-container {
    font-size: 28px;
  }
  @media only screen and (min-width: 601px) {
    .cards-container {
      margin-left: -11px;
      margin-right: -11px;
    }
  }

  .cyan:hover {
    background-color: #4dd0e1 !important;
  }
  .light-blue:hover {
    background-color: #4fc3f7 !important;
  }
  .blue:hover {
    background-color: #64b5f6 !important;
  }
  .indigo:hover {
    background-color: #7986cb !important;
  }

  .deep-purple:hover {
    background-color: #9575cd !important;
  }
  .purple:hover {
    background-color: #ba68c8 !important;
  }
  .pink:hover {
    background-color: #f06292 !important;
  }
  .red:hover {
    background-color: #e57373 !important;
  }

  .orange:hover {
    background-color: #ffb74d !important;
  }
`;

const breadCrumbData = () => {
  return [
    {
      url: `/patient-doctor-action`,
      name: 'Bagian / Poli',
    },
  ];
};
const backURL = () => `/`;

const parseColorClass = (idx = 0) => {
  idx = idx + 1;
  if (idx > 8) idx = idx - 8;
  if (idx > 16) idx = idx - 16;

  if (idx % 8 === 0) return 'cyan';
  if (idx % 7 === 0) return 'light-blue';
  if (idx % 6 === 0) return 'blue';
  if (idx % 5 === 0) return 'indigo';
  if (idx % 4 === 0) return 'deep-purple';
  if (idx % 3 === 0) return 'purple';
  if (idx % 2 === 0) return 'pink';
  if (idx % 1 === 0) return 'red';
  return 'orange';
};

export const ListDoctorDepartment = () => {
  const $global = useSelector((state) => state.global);
  const { listDepartment } = $global;
  const listDepartmentFilter = listDepartment.filter(
    (dpt) => dpt.type === 'doctor'
  );

  return (
    <Fragment>
      {$global.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Bagian / Poli'}
        backURL={backURL()}
      />
      <BreadCrumb breadCrumbData={breadCrumbData()} />
      <Div className='container'>
        <div className='cards-container'>
          <div className='row'>
            {listDepartmentFilter.map((dpt, idx) => {
              return (
                <div className='col m3 s12' key={dpt._id}>
                  <Link
                    to={`/patient-doctor-action/department/${
                      dpt._id
                    }?visitDate=${moment().format(
                      'YYYYMMDD'
                    )}&sort=createdAt&sortDir=desc`}
                  >
                    <div
                      className={`card-panel white-text ${parseColorClass(
                        idx
                      )}`}
                    >
                      {dpt.name}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
