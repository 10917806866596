import configList from './configList';
import configCreate from './configCreate';
import configDetail from './configDetail';

export * from './configList';
export * from './configCreate';
export * from './configDetail';

const configReducers = {
  configList,
  configCreate,
  configDetail,
};

export default configReducers;
