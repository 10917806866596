import React from 'react';
import styled from 'styled-components';

import { Search } from './Search';
import { Sort } from './Sort';

const Div = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1px;
    margin-right: 1px;
  }
  .pagination-wrapper {
    /* container class alias */
  }
`;

const defaultSortOptions = [
  {
    key: 'createdAt',
    name: 'Dibuat',
  },
  {
    key: 'updatedAt',
    name: 'Diubah',
  },
  {
    key: 'name',
    name: 'Nama',
  },
];

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const SearchSort = ({
  searchPlaceholder = 'Pencarian',
  defaultSearch = '',
  minLength = 3,
  sortOptions = defaultSortOptions,
  defaultSort = 'createdAt',
  defaultSortDir = 'asc',
  onChange = defaultOnChange,
  SearchChildren = () => <span className='searchSlot'></span>,
  SortChildren = () => <span className='sortSlot'></span>,
}) => {
  return (
    <Div>
      <Search
        onChange={onChange}
        defaultSearch={defaultSearch}
        minLength={minLength}
        searchPlaceholder={searchPlaceholder}
      >
        <SearchChildren />
      </Search>
      <Sort
        onChange={onChange}
        sortOptions={sortOptions}
        defaultSort={defaultSort}
        defaultSortDir={defaultSortDir}
      >
        <SortChildren />
      </Sort>
    </Div>
  );
};
