import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import {
  fetchRootGetUserList,
  fetchRootPostUserArchive,
} from '../../store/user';
import { confirmNotif, momentGeneral } from '../../helper';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-user`,
    name: 'Master User',
  },
];

export const UserList = () => {
  const dispatch = useDispatch();
  const $userList = useSelector((state) => state.userList);
  const $userDetail = useSelector((state) => state.userDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchRootGetUserList(queryParams));
    };
    didMount();
  }, []); // eslint-disable-line

  const confirmArchive = ({ e, data }) => {
    e.preventDefault();
    confirmNotif({
      msg: `Anda yakin akan arsipkan ${data.name} ?`,
      onOK: () => {
        dispatch(
          fetchRootPostUserArchive({
            isArchived: true,
            userId: data._id,
            name: data.name,
            next: () => {
              dispatch(fetchRootGetUserList(queryParams));
            },
          })
        );
      },
    });
  };

  return (
    <Fragment>
      {($userList.isLoading || $userDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master User'}
        backURL={'/root'}
        onRefresh={() => {
          dispatch(fetchRootGetUserList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetUserList(newQueryParams));
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/root/master-user/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th className='center-align'>Verified</th>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Nama User</th>
                  <th>Nama Pegawai</th>

                  <th>Dibuat</th>
                  <th>Diubah</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {$userList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td className='center-align'>
                        {data.verifiedAt ? (
                          <i className='fa fa-check-square'></i>
                        ) : (
                          <i className='fa fa-square-o'></i>
                        )}
                      </td>
                      <td>
                        <Link to={`/root/master-user/update/${data._id}`}>
                          {data._id}
                        </Link>
                      </td>
                      <td>{data.email}</td>
                      <td>{data.name}</td>
                      <td>{data.personFullName}</td>

                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                      <td width='1' className='center-align'>
                        <span
                          onClick={(e) => {
                            if (data._id !== window.myUser._id) {
                              confirmArchive({ e, data });
                            }
                          }}
                          className={`font-16 ${
                            data._id === window.myUser._id
                              ? 'cursor-block'
                              : 'red-text cursor-pointer'
                          }`}
                        >
                          <i className='fa fa-times'></i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetUserList(newQueryParams));
              }}
              totalItem={$userList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
