import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  LoaderBlock,
  HeaderNav,
  BreadCrumb,
  SearchSort,
  Pagination,
} from '../../component/common';
import { fetchRootGetConfigList } from '../../store/config';
import { momentGeneral } from '../../helper';

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
`;

const breadCrumbData = [
  {
    url: `/root`,
    name: 'Root',
  },
  {
    url: `/root/master-config`,
    name: 'Master Konfigurasi',
  },
];

export const ConfigList = () => {
  const dispatch = useDispatch();
  const $configList = useSelector((state) => state.configList);
  const $configDetail = useSelector((state) => state.configDetail);
  const { search: locationSearch } = useLocation();
  const queryParams = queryString.parse(locationSearch);

  useEffect(() => {
    const didMount = () => {
      dispatch(fetchRootGetConfigList(queryParams));
    };
    didMount();
  }, [dispatch]); // eslint-disable-line

  return (
    <Fragment>
      {($configList.isLoading || $configDetail.loading) && <LoaderBlock />}
      <HeaderNav
        headerSize='m'
        headerName={'Master Konfigurasi'}
        backURL={'/root'}
        onRefresh={() => {
          dispatch(fetchRootGetConfigList(queryParams));
        }}
      />
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <Div className='container'>
        <div className='card m-t-0'>
          <div className='card-content p-0'>
            <SearchSort
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetConfigList(newQueryParams));
              }}
              SearchChildren={() => {
                return (
                  <Link
                    to={`/root/master-config/create`}
                    className='btn-small teal white-text'
                  >
                    <i className='fa fa-plus' />
                  </Link>
                );
              }}
            />
            <table className='striped bg-white'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nama</th>
                  <th>Selected</th>
                  <th>Dibuat</th>
                  <th>Diubah</th>
                </tr>
              </thead>
              <tbody>
                {$configList.listData.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td>
                        <Link to={`/root/master-config/update/${data._id}`}>
                          {data._id}
                        </Link>
                      </td>
                      <td>{data.name}</td>
                      <td>{data.selected}</td>
                      <td title={data.createdByName}>
                        {momentGeneral(data.createdAt)}
                      </td>
                      <td title={data.updatedByName}>
                        {momentGeneral(data.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              onChange={(newQueryParams) => {
                dispatch(fetchRootGetConfigList(newQueryParams));
              }}
              totalItem={$configList.totalItem}
            />
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
