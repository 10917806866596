import { createSlice } from "@reduxjs/toolkit";
import { restPostPatientCreate as restAdminPostPatientCreate } from "../../apiAdmin/patient";
import { isValidJSON, momentInputDateToNumberDate, successNotif } from "../../helper";

const initialState = {
  isLoading: false,
  error: null,
  form: {
    // Bio
    noRM: "",
    prefix: "",
    suffix: "",
    name: "",
    birthLocation: "",
    birthDate: "",
    nik: "",

    mobile: "",
    gender: "",
    // Tambahan
    bloodType: "",
    nationality: "",
    address: "",
    kelurahan: "",
    kecamatan: "",
    kota: "",
    lastEducation: "",
    religion: "",
    job: "",
    martialStatus: "",
    patientGroup: "",
    paymentMethod: "",
    responsibleName: "",
    responsiblePhone: "",
    // Physical Examination
    allergies: [],
    height: 0,
    weight: 0,
    kesadaran: "",
    suhu: "",
    keadaanUmum: "",
    nadi: "",
    saturasiOksigen: "",
    tekananDarah: "",
    respirasi: "",
    notesPhysicalExamination: "",
  },
};

const patientCreateSlice = createSlice({
  name: "patientCreate",
  initialState: initialState,
  reducers: {
    patientCreateSetForm(state, { payload }) {
      state.form[payload.key] = payload.value;
    },
    patientCreateInit(state) {
      state.isLoading = false;
      state.error = null;
      state.form = {
        // Bio
        noRM: "",
        prefix: "",
        suffix: "",
        name: "",
        birthLocation: "",
        birthDate: "",
        nik: "",

        mobile: "",
        gender: "",
        // Tambahan
        bloodType: "",
        nationality: "",
        address: "",
        kelurahan: "",
        kecamatan: "",
        kota: "",
        lastEducation: "",
        religion: "",
        job: "",
        martialStatus: "",
        patientGroup: "",
        paymentMethod: "",
        responsibleName: "",
        responsiblePhone: "",
        // Physical Examination
        allergies: [],
        height: 0,
        weight: 0,
        kesadaran: "",
        suhu: "",
        keadaanUmum: "",
        nadi: "",
        saturasiOksigen: "",
        tekananDarah: "",
        respirasi: "",
        notesPhysicalExamination: "",
      };
    },
    postPatientCreateStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postPatientCreateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postPatientCreateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { patientCreateSetForm, patientCreateInit, postPatientCreateStart, postPatientCreateSuccess, postPatientCreateError } = patientCreateSlice.actions;

export default patientCreateSlice.reducer;

export const fetchAdminPostPatientCreate =
  ({
    next,
    // Bio
    noRM = "",
    prefix = "",
    suffix = "",
    name = "",
    birthLocation = "",
    birthDate = "",
    nik = "",

    mobile = "",
    gender = "",
    // Tambahan
    bloodType = "",
    nationality = "",
    address = "",
    kelurahan = "",
    kecamatan = "",
    kota = "",
    lastEducation = "",
    religion = "",
    job = "",
    martialStatus = "",
    patientGroup = "",
    paymentMethod = "",
    responsibleName = "",
    responsiblePhone = "",

    // Physical Examination
    allergies = [],
    height = 0,
    weight = 0,
    kesadaran = "",
    suhu = "",
    keadaanUmum = "",
    nadi = "",
    saturasiOksigen = "",
    tekananDarah = "",
    respirasi = "",
    notesPhysicalExamination = "",
  }) =>
  async (dispatch, getState) => {
    name = name.trim();

    noRM = noRM.toUpperCase();

    if (name.length < 3) {
      return dispatch(postPatientCreateError("Nama minimal 3 karakter"));
    }
    if (mobile.length < 5) {
      return dispatch(postPatientCreateError("No HP Terlalu Pendek"));
    }
    birthDate = momentInputDateToNumberDate(birthDate);
    if (!birthDate) {
      return dispatch(postPatientCreateError("Tanggal lahir harus diisi"));
    }
    if (!gender) {
      return dispatch(postPatientCreateError("Silahkan pilih gender"));
    }
    if (!(gender === "m" || gender === "f")) {
      return dispatch(postPatientCreateError("Gender Salah"));
    }

    const { isLoading } = getState().patientCreate;
    if (isLoading) return;
    try {
      dispatch(postPatientCreateStart());
      await restAdminPostPatientCreate({
        noRM,
        prefix,
        name,
        suffix,
        birthLocation, // tambahan
        birthDate,
        nik, // tambahan

        mobile,
        gender,
        // Tambahan
        bloodType,
        nationality,
        address,
        kelurahan,
        kecamatan,
        kota,
        lastEducation,
        religion,
        job,
        martialStatus,
        patientGroup,
        paymentMethod,
        responsibleName,
        responsiblePhone,
        // Physical Examination
        allergies,
        height,
        weight,
        kesadaran,
        suhu,
        keadaanUmum,
        nadi,
        saturasiOksigen,
        tekananDarah,
        respirasi,
        notesPhysicalExamination,
      });
      dispatch(postPatientCreateSuccess());
      successNotif(`Berhasil membuat ${name}`);
      if (next) next();
    } catch (err) {
      if (isValidJSON(err)) {
        return dispatch(postPatientCreateError(JSON.parse(err).message));
      }
      dispatch(postPatientCreateError(err));
    }
  };
