/* eslint-disable */
import React from "react";
import Select from "react-select";
import styled from "styled-components";

const optionsExample = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Div = styled.div`
  .select-search__input {
    height: 40px;
  }
`;

export const SelectSearchByValue = ({
  value = "",
  options = optionsExample,
  className = "",
  onChange = (value) => console.info(value),
}) => {
  const onSelectChange = (selected) => {
    onChange(selected.value);
  };
  return (
    <Div>
      <Select
        value={options.find((o) => o.value === value)}
        search={true}
        onChange={onSelectChange}
        options={[
          { value: "", label: "Silahkan Pilih" },
          ...options,
        ]}
        className={className}
      />
    </Div>
  );
};
