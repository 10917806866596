import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useParams, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LoaderBlock, HeaderNav, BreadCrumb, Label } from "../../component/common";
import { SelectSearchByValue } from "../../component/input";
import { parseDepartmentName, momentCountAge, momentNumberDateToDisplayDate, parsePatientDoctorActionStatus, confirmNotif, parseFullName, isRoleStudentReadOnly } from "../../helper";
import {
  fetchGetPatientDetail,
  // UPDATE
  patientDoctorActionDetailSetForm,
  patientDoctorActionDetailInit,
  fetchGetPatientDoctorActionDetail,
  fetchPostPatientDoctorActionUpdate,
  fetchPostPatientDoctorActionCancel,
} from "../../store/patientDoctorAction";
import { fetchNurseGetDoctorListAll, fetchGetNurseStudentListAll } from "../../store/person";
import { fetchPostPatientInvoiceCreate } from "../../store/patientInvoice";

const Div = styled.div`
  .container-class {
    /* container class alias */
  }
  .diagnosis-list {
    margin-top: 20px;
  }
  .qty-input {
    height: 16px !important;
  }
  .sub-action-container {
    margin-top: -30px !important;
  }
`;

const breadCrumbData = (doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, patientDoctorActionId, locationSearch) => {
  return [
    {
      url: `/patient-doctor-action`,
      name: "Bagian / Poli",
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}${locationSearch}`,
      name: doctorDepartmentName,
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`,
      name: "Pasien",
    },
    {
      url: `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}/action/${patientDoctorActionId}${locationSearch}`,
      name: "Tindakan",
    },
  ];
};
const backURL = (doctorDepartmentId, patientId, patientVisitId, locationSearch) => `/patient-doctor-action/department/${doctorDepartmentId}/patient/${patientId}/visit/${patientVisitId}${locationSearch}`;

export const PatientDoctorActionUpdate = () => {
  const { doctorDepartmentId, patientId, patientVisitId, patientDoctorActionId } = useParams();
  const dispatch = useDispatch();

  const $patientDoctorActionList = useSelector((state) => state.patientDoctorActionList);
  const $patientDoctorActionDetail = useSelector((state) => state.patientDoctorActionDetail);
  const $patientInvoiceCreate = useSelector((state) => state.patientInvoiceCreate);
  const { form, data: patientDoctorActionData } = $patientDoctorActionDetail;

  const { patientData } = $patientDoctorActionList;
  const { search: locationSearch } = useLocation();
  const { push } = useHistory();
  const $global = useSelector((state) => state.global);
  const { listMainComplaint, listDepartment, listDiagnosis, listAction, listSubAction, listSubstance } = $global;
  const listDepartmentFilter = listDepartment.filter((dpt) => dpt.type === "doctor");
  const listActionFilter = listAction.filter((act) => act.doctorDepartmentId === doctorDepartmentId);
  const $personList = useSelector((state) => state.personList);

  const doctorDepartmentName = parseDepartmentName({
    listDepartment: listDepartmentFilter,
    departmentId: doctorDepartmentId,
  });

  const getAllData = (patientId, patientDoctorActionId) => {
    if (!patientData || !patientData._id) {
      dispatch(fetchGetPatientDetail({ patientId }));
    }
    if ($personList.listData.length < 1) {
      dispatch(fetchNurseGetDoctorListAll({ doctorDepartmentId }));
    }
    if ($personList.listNurseStudent.length < 1) {
      dispatch(fetchGetNurseStudentListAll());
    }

    dispatch(patientDoctorActionDetailInit());
    dispatch(fetchGetPatientDoctorActionDetail({ patientDoctorActionId }));
  };

  useEffect(() => {
    const didMount = () => {
      getAllData(patientId, patientDoctorActionId);
    };
    didMount();
  }, []); // eslint-disable-line

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      parsePatientDoctorActionStatus({
        isCanceled: patientDoctorActionData.isCanceled,
        patientDoctorActionId: patientDoctorActionData._id,
        patientInvoiceId: patientDoctorActionData.patientInvoiceId,
      }) !== "Proses"
    ) {
      return;
    }
    dispatch(
      fetchPostPatientDoctorActionUpdate({
        next: () => {},
        patientDoctorActionId,
        patientVisitId: form.patientVisitId,
        noDU: String(form.noDU),
        doctorId: form.doctorId,
        doctorFullName: form.doctorFullName,
        nurseId: form.nurseId,
        nurseFullName: form.nurseFullName,
        nurseId2: form.nurseId2,
        nurseFullName2: form.nurseFullName2,
        nurseId3: form.nurseId3,
        nurseFullName3: form.nurseFullName3,
        mainComplaint: form.mainComplaint,
        diagnosisList: form.diagnosisList,
        actionList: form.actionList,
        subActionList: form.subActionList,
        substanceList: form.substanceList,
      })
    );
  };

  const confirmCancel = (e) => {
    e.preventDefault();
    if (
      parsePatientDoctorActionStatus({
        isCanceled: patientDoctorActionData.isCanceled,
        patientDoctorActionId: patientDoctorActionData._id,
        patientInvoiceId: patientDoctorActionData.patientInvoiceId,
      }) !== "Proses"
    ) {
      return;
    }
    confirmNotif({
      title: "Konfirmasi",
      msg: `Batalkan tindakan pasien "${patientDoctorActionData.patientFullName}" ?`,
      onOK: () => {
        dispatch(
          fetchPostPatientDoctorActionCancel({
            patientDoctorActionId: patientDoctorActionData._id,
            patientVisitId: patientVisitId,
            next: () => {
              push(backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch));
            },
          })
        );
      },
    });
  };

  const generateInvoice = (e) => {
    e.preventDefault();
    if ($patientDoctorActionDetail.isLockCreateInvoice) {
      return;
    }
    dispatch(
      fetchPostPatientDoctorActionUpdate({
        // Save and Generate
        hideSuccess: true,
        next: () => {
          // Call Generate
          dispatch(
            fetchPostPatientInvoiceCreate({
              patientDoctorActionId: patientDoctorActionData._id,
              next: () => {
                push(backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch));
              },
            })
          );
        },
        patientDoctorActionId,
        patientVisitId: form.patientVisitId,
        noDU: String(form.noDU),
        doctorId: form.doctorId,
        doctorFullName: form.doctorFullName,
        nurseId: form.nurseId,
        nurseFullName: form.nurseFullName,
        nurseId2: form.nurseId2,
        nurseFullName2: form.nurseFullName2,
        nurseId3: form.nurseId3,
        nurseFullName3: form.nurseFullName3,
        mainComplaint: form.mainComplaint,
        diagnosisList: form.diagnosisList,
        actionList: form.actionList,
        subActionList: form.subActionList,
        substanceList: form.substanceList,
      })
    );
  };

  const setListData = (key, listData, id) => {
    const selectedData = { ...listData.find((d) => d._id === id) };
    if (key === "substanceList") {
      selectedData.qty = 1;
      selectedData.sumPrice = selectedData.price;
    }
    if (selectedData && key === "subActionList") {
      // Karena SubActionList Bisa Double
      let newListData = form[key] && form[key].length > 0 ? form[key] : [];
      newListData = JSON.parse(JSON.stringify(newListData));
      newListData.push(selectedData);
      dispatch(
        patientDoctorActionDetailSetForm({
          key,
          value: newListData,
        })
      );
    } else if (selectedData) {
      let newListData = form[key] && form[key].length > 0 ? form[key] : [];
      newListData = JSON.parse(JSON.stringify(newListData));
      newListData = newListData.filter((d) => d._id !== id);
      newListData.push(selectedData);
      dispatch(
        patientDoctorActionDetailSetForm({
          key,
          value: newListData,
        })
      );
    }
  };
  const removeListData = (key, id) => {
    let newListData = form[key] && form[key].length > 0 ? form[key] : [];
    newListData = JSON.parse(JSON.stringify(newListData));
    if (key === "subActionList") {
      // Karena SubActionList Bisa Double
      newListData.splice(id, 1);
      dispatch(
        patientDoctorActionDetailSetForm({
          key,
          value: newListData,
        })
      );
    } else {
      newListData = newListData.filter((d) => d._id !== id);
      dispatch(
        patientDoctorActionDetailSetForm({
          key,
          value: newListData,
        })
      );
    }
  };

  const changeSubstanceQty = (idx, qty) => {
    const newList = JSON.parse(JSON.stringify(form.substanceList));
    const selectedSubstance = newList[idx];
    if (selectedSubstance && qty > 0) {
      selectedSubstance.qty = Number(qty);
      selectedSubstance.sumPrice = selectedSubstance.price * Number(qty);
      dispatch(
        patientDoctorActionDetailSetForm({
          key: "substanceList",
          value: newList,
        })
      );
    }
  };

  const setDiagnosisList = (v) => {
    const diagnosisId = v.trim();
    if (diagnosisId) {
      setListData("diagnosisList", listDiagnosis, diagnosisId);
    }
  };
  const removeDiagnosis = (diagnosisId) => removeListData("diagnosisList", diagnosisId);

  const setActionList = (e) => {
    e.preventDefault();
    const actionId = e.target.value.trim();
    setListData("actionList", listActionFilter, actionId);
  };
  const removeAction = (actionId) => {
    const removeActionInSubAction = (key, actionId) => {
      let newListData = form[key] && form[key].length > 0 ? form[key] : [];
      newListData = newListData.filter((d) => d.actionId !== actionId);
      dispatch(
        patientDoctorActionDetailSetForm({
          key,
          value: newListData,
        })
      );
    };
    removeActionInSubAction("subActionList", actionId);
    return removeListData("actionList", actionId);
  };

  const setSubActionList = (e) => {
    e.preventDefault();
    const subActionId = e.target.value.trim();
    setListData("subActionList", listSubAction, subActionId);
  };
  const removeSubAction = (subActionId) => removeListData("subActionList", subActionId);

  const setSubstanceList = (e) => {
    e.preventDefault();
    const substanceId = e.target.value.trim();
    setListData("substanceList", listSubstance, substanceId);
  };
  const removeSubstance = (substanceId) => removeListData("substanceList", substanceId);

  const doBlockButton = () => {
    return (
      parsePatientDoctorActionStatus({
        isCanceled: patientDoctorActionData.isCanceled,
        patientDoctorActionId: patientDoctorActionData._id,
        patientInvoiceId: patientDoctorActionData.patientInvoiceId,
      }) !== "Proses" || isRoleStudentReadOnly()
    );
  };

  const uiSubAction = (actionId, actionName) => {
    const listSubActionFilter = listSubAction.filter((sa) => sa.actionId === actionId);
    if (listSubActionFilter && listSubActionFilter.length > 0 && actionName) {
      return (
        <div className="row">
          <div className="input-field col m6 s12">
            <select value={""} onChange={setSubActionList} id="subActionList" name="subActionList" type="text" className="browser-default validate m-t-8">
              <option value="">Silahkan Pilih</option>
              {listSubActionFilter.map((subAction) => (
                <option key={subAction._id} value={subAction._id}>
                  {subAction.name}
                </option>
              ))}
            </select>
            <label className="active" htmlFor="subActionList">
              {" "}
            </label>
          </div>
          <div className="col m6 s12">
            <table className="table-no-border diagnosis-list">
              <tbody>
                {form.subActionList.map((d, idx) => {
                  if (d.actionId === actionId) {
                    return (
                      <tr key={idx}>
                        <td
                          className="red-text cursor-pointer"
                          onClick={() => {
                            removeSubAction(d.idx);
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </td>
                        <td>{d.name}</td>
                      </tr>
                    );
                  }

                  return ``;
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return <span />;
  };

  return (
    <Fragment>
      {($global.isLoading || $personList.isLoading || $personList.isLoadingNurseStudent || $patientDoctorActionList.isPatientLoading || $patientDoctorActionDetail.isLoading || $patientInvoiceCreate.isLoading) && <LoaderBlock />}
      <HeaderNav headerSize="m" headerName={"Tindakan"} backURL={backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch)} />
      <BreadCrumb breadCrumbData={breadCrumbData(doctorDepartmentId, doctorDepartmentName, patientId, patientVisitId, patientDoctorActionId, locationSearch)} />
      <Div className="container">
        {/* PATIENT */}
        <div className="card m-t-0">
          <div className="card-content p-0">
            <div className="row">
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="100">
                        No RM
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.noRM}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Nama
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">
                        {patientData.gender === "f" ? <i className="fa fa-female"></i> : <i className="fa fa-male"></i>}
                        {` `}
                        {patientData.prefix} {patientData.name} {patientData.suffix}
                        {` `}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tgl Lahir</td>
                      <td className="p-v-4">:</td>

                      <td className="p-v-4">
                        {momentNumberDateToDisplayDate(patientData.birthDate)} / {momentCountAge(patientData.birthDate) ? `${momentCountAge(patientData.birthDate)} tahun` : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">NIK</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.nik}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        No HP
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.mobile}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="100">
                        Pasien Baru?
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.isNewPatient ? <i className="fa fa-check-square"></i> : <i className="fa fa-square-o"></i>}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col m6 s12">
                <table className="table-no-border">
                  <tbody>
                    <tr>
                      <td className="p-v-4" width="150">
                        Alergi
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">{patientData.allergies && patientData.allergies.map((aName) => <Label key={aName} text={aName} />)}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4" width="150" title="Tinggi Badan / Berat Badan">
                        TB / BB
                      </td>
                      <td className="p-v-4" width="5">
                        :
                      </td>
                      <td className="p-v-4">
                        {patientData.height} / {patientData.weight}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Kesadaran</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.kesadaran}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Suhu / Nadi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">
                        {patientData.suhu} / {patientData.nadi}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Tekanan Darah</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.tekananDarah}</td>
                    </tr>
                    <tr>
                      <td className="p-v-4">Respirasi</td>
                      <td className="p-v-4">:</td>
                      <td className="p-v-4">{patientData.respirasi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-content p-0">
            <form onSubmit={onSubmit}>
              <div className="row p-t-16">
                <div className="col m6 s12">
                  Status:
                  <b>
                    {parsePatientDoctorActionStatus({
                      isCanceled: patientDoctorActionData.isCanceled,
                      patientDoctorActionId: patientDoctorActionData._id,
                      patientInvoiceId: patientDoctorActionData.patientInvoiceId,
                    })}
                  </b>
                </div>
                <div className="col m6 s12">
                  <button type="button" className="btn red right" disabled={doBlockButton()} onClick={confirmCancel}>
                    Batalkan Tindakan
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={form.doctorId}
                    onChange={(e) => {
                      const person = $personList.listData.find((p) => p._id === e.target.value);
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "doctorId",
                          value: person && person._id ? person._id : "",
                        })
                      );
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "doctorFullName",
                          value: person && person._id ? parseFullName(person) : "",
                        })
                      );
                    }}
                    id="doctorId"
                    name="doctorId"
                    className="browser-default validate m-t-8"
                  >
                    <option value="" name="">
                      Pilih Dokter
                    </option>
                    {$personList.listData.map((person) => (
                      <option key={person._id} value={person._id}>
                        {parseFullName(person)}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="doctorId">
                    Dokter
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <SelectSearchByValue
                    value={form.nurseId}
                    onChange={(v) => {
                      const person = $personList.listNurseStudent.find((p) => p._id === v);
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseId",
                          value: person && person._id ? person._id : "",
                        })
                      );
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseFullName",
                          value: person && person._id ? parseFullName(person) : "",
                        })
                      );
                    }}
                    id="nurseId"
                    name="nurseId"
                    className="browser-default validate m-t-8"
                    options={$personList.listNurseStudent.map((ns) => ({
                      ...ns,
                      value: ns._id,
                      label: parseFullName(ns),
                    }))}
                  />
                  <label className="active" htmlFor="nurseId">
                    Asisten 1
                  </label>
                </div>

                <div className="input-field col m3 s12">
                  <SelectSearchByValue
                    value={form.nurseId2}
                    onChange={(v) => {
                      const person = $personList.listNurseStudent.find((p) => p._id === v);
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseId2",
                          value: person && person._id ? person._id : "",
                        })
                      );
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseFullName2",
                          value: person && person._id ? parseFullName(person) : "",
                        })
                      );
                    }}
                    id="nurseId2"
                    name="nurseId2"
                    className="browser-default validate m-t-8"
                    options={$personList.listNurseStudent.map((ns) => ({
                      ...ns,
                      value: ns._id,
                      label: parseFullName(ns),
                    }))}
                  />
                  <label className="active" htmlFor="nurseId2">
                    Asisten 2
                  </label>
                </div>
                <div className="input-field col m3 s12">
                  <SelectSearchByValue
                    value={form.nurseId3}
                    onChange={(v) => {
                      const person = $personList.listNurseStudent.find((p) => p._id === v);
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseId3",
                          value: person && person._id ? person._id : "",
                        })
                      );
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "nurseFullName3",
                          value: person && person._id ? parseFullName(person) : "",
                        })
                      );
                    }}
                    id="nurseId3"
                    name="nurseId3"
                    className="browser-default validate m-t-8"
                    options={$personList.listNurseStudent.map((ns) => ({
                      ...ns,
                      value: ns._id,
                      label: parseFullName(ns),
                    }))}
                  />
                  <label className="active" htmlFor="nurseId3">
                    Asisten 3
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col m3 s12">
                  <input
                    value={form.noDU}
                    onChange={(e) => {
                      const val = Number(e.target.value);
                      if (val <= 100 && val >= 0) {
                        dispatch(
                          patientDoctorActionDetailSetForm({
                            key: "noDU",
                            value: val,
                          })
                        );
                      }
                    }}
                    placeholder="Nomor DU"
                    id="noDU"
                    name="noDU"
                    type="number"
                    min="0"
                    max="100"
                    className="validate"
                  />
                  <label className="active" htmlFor="noDU">
                    Nomor DU
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col m3 s12">
                  <select
                    value={""}
                    onChange={(e) => {
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "mainComplaint",
                          value: form.mainComplaint + e.target.value + " ",
                        })
                      );
                    }}
                    id="mainComplaintList"
                    name="mainComplaintList"
                    type="text"
                    className="browser-default validate m-t-8"
                  >
                    <option value="">Silahkan Pilih</option>
                    {listMainComplaint.map((mainComplaint) => (
                      <option key={mainComplaint._id} value={mainComplaint.name}>
                        {mainComplaint.icd10} {mainComplaint.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="mainComplaint">
                    Keluhan Utama
                  </label>
                </div>
                <div className="input-field col s12">
                  <textarea
                    value={form.mainComplaint}
                    onChange={(e) => {
                      dispatch(
                        patientDoctorActionDetailSetForm({
                          key: "mainComplaint",
                          value: e.target.value,
                        })
                      );
                    }}
                    id="mainComplaint"
                    className="materialize-textarea m-0 p-0"
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <SelectSearchByValue
                    value={""}
                    onChange={setDiagnosisList}
                    id="diagnosisList"
                    name="diagnosisList"
                    type="text"
                    className="browser-default validate m-t-8"
                    options={listDiagnosis.map((diagnosis) => ({
                      ...diagnosis,
                      value: diagnosis._id,
                      label: `${diagnosis.icd10} ${diagnosis.name}`,
                    }))}
                  />
                  <label className="active" htmlFor="diagnosisList">
                    Diagnosis
                  </label>
                </div>
                <div className="col m9 s12">
                  <table className="table-no-border diagnosis-list">
                    <tbody>
                      {form.diagnosisList.map((d) => (
                        <tr key={d._id}>
                          <td
                            width="10"
                            className="red-text cursor-pointer"
                            onClick={() => {
                              removeDiagnosis(d._id);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </td>
                          <td>
                            {d.icd10} {d.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select value={""} onChange={setActionList} id="actionList" name="actionList" type="text" className="browser-default validate m-t-8">
                    <option value="">Silahkan Pilih</option>
                    {listActionFilter.map((action) => (
                      <option key={action._id} value={action._id}>
                        {action.icd10} {action.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="actionList">
                    Tindakan
                  </label>
                </div>
                <div className="col m9 s12 m-t-30">
                  {form.actionList.map((d) => (
                    <div className="row" key={d._id}>
                      <div className="col m4 s12">
                        <span
                          width="10"
                          className="red-text cursor-pointer m-r-14"
                          onClick={() => {
                            removeAction(d._id);
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </span>
                        <span>{d.name}</span>
                      </div>
                      {/* SUB_ACTION */}
                      <div className="col m8 s12 sub-action-container">{uiSubAction(d._id, d.name)}</div>
                      {/* SUB_ACTION */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="input-field col m3 s12">
                  <select value={""} onChange={setSubstanceList} id="substanceList" name="substanceList" type="text" className="browser-default validate m-t-8">
                    <option value="">Silahkan Pilih</option>
                    {listSubstance.map((substance) => (
                      <option key={substance._id} value={substance._id}>
                        {substance.icd10} {substance.name}
                      </option>
                    ))}
                  </select>
                  <label className="active" htmlFor="substanceList">
                    Bahan Tambahan
                  </label>
                </div>
                <div className="col m9 s12">
                  <table className="table-no-border diagnosis-list">
                    <tbody>
                      {form.substanceList.map((d, idx) => (
                        <tr key={d._id}>
                          <td
                            width="10"
                            className="red-text cursor-pointer"
                            onClick={() => {
                              removeSubstance(d._id);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </td>
                          <td width="100">
                            <input
                              value={d.qty}
                              onChange={(e) => {
                                changeSubstanceQty(idx, e.target.value);
                              }}
                              min="0"
                              max="100000"
                              id="qty"
                              name="qty"
                              type="number"
                              className="m-b-0 qty-input"
                            />
                          </td>
                          <td width="100">
                            <i>{d.unit}</i>
                          </td>
                          <td>{d.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <div className="red-text m-b-4 right-align">{$patientDoctorActionDetail.error}</div>
                </div>
                <div className="col s12">
                  <button type="submit" className="btn teal m-b-20 right" disabled={doBlockButton()}>
                    Simpan Perubahan
                  </button>
                  <Link to={backURL(doctorDepartmentId, patientId, patientVisitId, locationSearch)} className="btn grey m-b-20 right m-r-8">
                    Kembali
                  </Link>
                  <button type="button" className="btn blue" disabled={doBlockButton() || $patientDoctorActionDetail.isLockCreateInvoice} onClick={generateInvoice}>
                    Generate Invoice
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
