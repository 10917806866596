import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import {
  moment,
  momentNumberDateToInputDate,
  momentInputDateToNumberDate,
} from '../../helper';

const Div = styled.div`
  input {
    display: block !important;
    width: 238px !important;
    padding-left: 8px !important;
  }
`;

const defaultOnChange = (newQueryParams) => {
  console.warn(`newQueryParams`, newQueryParams);
};

export const FilterDate = ({
  filterKey = 'dummyKey',
  defaultValue = '',
  onChange = defaultOnChange,
}) => {
  const { search: locationSearch, pathname } = useLocation();
  const { push } = useHistory();

  const queryParams = queryString.parse(locationSearch);
  const value = queryParams[filterKey] ? queryParams[filterKey] : defaultValue;

  const changeValue = (e) => {
    e.preventDefault();
    const v = e.target.value;
    const newQueryParams = queryParams;
    newQueryParams[filterKey] = momentInputDateToNumberDate(v);
    if (newQueryParams[filterKey] === '') {
      delete newQueryParams[filterKey];
    }
    onChange(newQueryParams);
    push(`${pathname}?${queryString.stringify(newQueryParams)}`);
  };

  return (
    <Div className='m-v-2'>
      <input
        value={momentNumberDateToInputDate(value)}
        onChange={changeValue}
        id='filterDate'
        name='filterDate'
        type='date'
        min={moment('19800101', 'YYYYMMDD').format('YYYY-MM-DD')}
        max={moment().add(14, 'days').format('YYYY-MM-DD')}
        className='validate'
      />
    </Div>
  );
};
