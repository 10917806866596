import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Page404 } from '../../page/common';
import { ListDoctorDepartment } from './ListDoctorDepartment';
import { PatientVisitList } from './PatientVisitList';
import { PatientDoctorActionList } from './PatientDoctorActionList';
import { PatientDoctorActionUpdate } from './PatientDoctorActionUpdate';
import { PatientUpdate } from './PatientUpdate';

export const PatientDoctorActionRoute = () => {
  const pathname = '/patient-doctor-action';
  return (
    <Switch>
      <Route exact path={`${pathname}`} component={ListDoctorDepartment} />
      <Route
        exact
        path={`${pathname}/department/:doctorDepartmentId`}
        component={PatientVisitList}
      />
      <Route
        exact
        path={`${pathname}/department/:doctorDepartmentId/patient/:patientId/visit/:patientVisitId`}
        component={PatientDoctorActionList}
      />
      <Route
        exact
        path={`${pathname}/department/:doctorDepartmentId/patient/:patientId/visit/:patientVisitId/action/:patientDoctorActionId`}
        component={PatientDoctorActionUpdate}
      />
      <Route
        exact
        path={`${pathname}/department/:doctorDepartmentId/patient/:patientId/visit/:patientVisitId/update`}
        component={PatientUpdate}
      />
      <Route component={Page404} />
    </Switch>
  );
};
