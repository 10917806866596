import fetchApi from '../api/fetchApi';
import { BASE_API_ADMIN } from '../config';

// GET
export function restGetAllergicList({
  limit = 10,
  page = 1,
  sort = 'createdAt',
  sortDir = 'asc',
  search = '',
}) {
  let path = `${BASE_API_ADMIN}/allergic/list`;
  path += `?limit=${limit}`;
  path += `&page=${page}`;
  path += `&sort=${sort}`;
  path += `&sortDir=${sortDir}`;
  path += `&search=${search}`;

  return fetchApi(path, {
    method: 'GET',
  });
}
export function restGetAllergicDetail({ allergicId }) {
  const path = `${BASE_API_ADMIN}/allergic/detail/${allergicId}`;
  return fetchApi(path, {
    method: 'GET',
  });
}

// POST
export function restPostAllergicCreate({ name }) {
  const path = `${BASE_API_ADMIN}/allergic/create`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
    },
  });
}

export function restPostAllergicUpdate({ allergicId, name }) {
  const path = `${BASE_API_ADMIN}/allergic/update/${allergicId}`;
  return fetchApi(path, {
    method: 'POST',
    body: {
      name,
    },
  });
}
