import { createSlice } from '@reduxjs/toolkit';
import { restPostAuthLogin } from '../../api/auth';

import { isValidJSON } from '../../helper';
import { fetchGetHome } from '../global';
import { fetchGetMyUser } from './myUser';

const initialState = {
  isLoading: false,
  error: null,
};

const loginFormSlice = createSlice({
  name: 'loginForm',
  initialState: initialState,
  reducers: {
    postLoginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    postLoginSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    postLoginError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  postLoginStart,
  postLoginSuccess,
  postLoginError,
} = loginFormSlice.actions;

export default loginFormSlice.reducer;

export const fetchPostAuthLogin = ({ email, password }) => async (
  dispatch,
  getState
) => {
  const { isLoading } = getState().loginForm;

  email = email.trim();
  password = password.trim();

  if (email.length < 6) {
    return dispatch(postLoginError('Email minimal 6 karakter'));
  }
  if (password.length < 6) {
    return dispatch(postLoginError('password minimal 6 karakter'));
  }

  if (isLoading) return;

  try {
    dispatch(postLoginStart());
    const resp = await restPostAuthLogin({ email, password });
    localStorage.setItem('sessionId', resp.sessionId);

    const nextTick = () => {
      dispatch(postLoginSuccess());
      dispatch(fetchGetMyUser());
      dispatch(fetchGetHome());
    };
    nextTick();
  } catch (err) {
    if (isValidJSON(err)) {
      return dispatch(postLoginError(JSON.parse(err).message));
    }
    dispatch(postLoginError(err));
  }
};
