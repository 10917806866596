import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { BreadCrumb, HeaderNav, LoaderBlock } from "../../component/common";
import {
  patientInvoiceDetailInit,
  fetchGetPatientInvoiceDetail,
} from "../../store/patientInvoice";
import { momentNumberDateToDisplayDate, formatCurrency } from "../../helper";

const Div = styled.div`
  @page {
    margin: 0;
  }
  @media print {
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
      Helvetica Neue, Arial, sans-serif !important;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 80px;
    .card {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    .print-button {
      display: none;
    }
    .font-14 {
      font-size: 16px !important;
      line-height: 12px !important;
    }
    .font-12 {
      font-size: 14px !important;
      line-height: 12px !important;
    }
  }
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
    Helvetica Neue, Arial, sans-serif !important;
  .font-14 {
    font-size: 16px !important;
  }
  .font-12 {
    font-size: 14px !important;
  }
  .container-class {
    /* container class alias */
  }
  .card-content {
    padding-right: 1px !important;
  }
  td {
    padding: 4px 4px !important;
  }
`;

const breadCrumbData = (locationSearch) => {
  return [
    {
      url: `/patient-invoice${locationSearch}`,
      name: "Kwitansi",
    },
    {
      url: `/patient-invoice/detail`,
      name: "Detail",
    },
  ];
};
const backURL = (locationSearch) => `/patient-invoice${locationSearch}`;

export const PatientInvoiceDetail = () => {
  const dispatch = useDispatch();
  const { search: locationSearch } = useLocation();
  const $patientInvoiceDetail = useSelector(
    (state) => state.patientInvoiceDetail
  );

  const { data } = $patientInvoiceDetail;
  const { patientInvoiceId } = useParams();

  useEffect(() => {
    const didMount = () => {
      dispatch(patientInvoiceDetailInit());
      if (patientInvoiceId) {
        dispatch(fetchGetPatientInvoiceDetail({ patientInvoiceId }));
      }
    };
    didMount();
  }, []); // eslint-disable-line
  const uiSubActionList = (subActionList, actionId) => {
    let subActionListFilter = [];
    if (subActionList && subActionList.length > 0) {
      subActionListFilter = subActionList.filter(
        (d) => d.actionId === actionId
      );
    }
    if (subActionListFilter && subActionListFilter.length > 0) {
      return (
        <ul className="m-0 m-l-16">
          {subActionListFilter.map((sa, idx) => (
            <li key={idx}>
              <i className="fa fa-circle-o"></i>
              {` `}
              {sa.name}
            </li>
          ))}
        </ul>
      );
    }
    return " ";
  };
  const uiSubActionPriceList = (subActionList, actionId) => {
    let subActionListFilter = [];
    if (subActionList && subActionList.length > 0) {
      subActionListFilter = subActionList.filter(
        (d) => d.actionId === actionId
      );
    }
    if (subActionListFilter && subActionListFilter.length > 0) {
      return (
        <ul className="m-0 m-l-16">
          {subActionListFilter.map((sa, idx) => (
            <li key={idx}>{formatCurrency(sa.price)}</li>
          ))}
        </ul>
      );
    }
    return " ";
  };
  return (
    <Fragment>
      {$patientInvoiceDetail.isLoading && <LoaderBlock />}
      <HeaderNav
        headerSize="m"
        headerName={"Detail"}
        backURL={backURL(locationSearch)}
      />
      <BreadCrumb breadCrumbData={breadCrumbData(locationSearch)} />
      <Div className="container">
        <div className="card m-t-0">
          <div className="card-content p-0">
            <table className="table-no-border font-12">
              <tbody>
                <tr>
                  <td className="center-align font-14" colSpan="8">
                    <b>RUMAH SAKIT GIGI DAN MULUT - UNSRAT</b>
                    <span
                      className="blue-text cursor-pointer print-button"
                      title="print"
                      onClick={() => window.print()}
                    >
                      {" "}
                      <i className="fa fa-print"></i>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="center-align b-b-1" colSpan="8">
                    <b>
                      Jl. Dr. Soetomo No. 3, Kel. Pinaesaan, Kec. Wenang - Kota
                      Manado
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Nomor RM</td>
                  <td>:</td>
                  <td>{data.patientNoRM}</td>
                  <td width="200"></td>
                  <td className="center-align b-1" colSpan="4">
                    KWITANSI PEMBAYARAN{" "}
                  </td>
                </tr>
                <tr>
                  <td width="80">Nama Pasien</td>
                  <td>:</td>
                  <td colSpan="2">{data.patientFullName}</td>
                  <td>No:</td>
                  <td className="right-align" colSpan="3">
                    {data.invoiceNo}
                  </td>
                </tr>
                <tr>
                  <td>Poliklinik</td>
                  <td>:</td>
                  <td colSpan="3">{data.doctorDepartmentName}</td>
                </tr>
                <tr>
                  <td>Dokter</td>
                  <td>:</td>
                  <td colSpan="3">{data.doctorFullName}</td>
                  <td className="b-1 right-align" colSpan="3">
                    {momentNumberDateToDisplayDate(data.visitDate)}
                  </td>
                </tr>
                <tr>
                  <td>Asisten</td>
                  <td>:</td>
                  <td colSpan="8">
                    {data.nurseFullName}
                    {data.nurseFullName2 ? `, ${data.nurseFullName2}` : ""}
                    {data.nurseFullName3 ? `, ${data.nurseFullName3}` : ""}
                  </td>
                </tr>

                <tr>
                  <td colSpan="8" className="center-align">
                    <b>RINCIAN</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6" className="b-1 center-align">
                    <b>ADMINISTRASI</b>
                  </td>
                  <td colSpan="2" className="b-1 center-align">
                    <b>BIAYA</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6" className="b-l-1 b-r-1">
                    &nbsp;&nbsp;{data.isNewPatient ? "Pasien Baru" : "Pasien Lama"}
                  </td>
                  <td colSpan="2" className="b-r-1 right-align">
                    {data.administrationPrice
                      ? formatCurrency(data.administrationPrice)
                      : formatCurrency(0)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="6" className="b-l-1 b-r-1">
                    <b>TINDAKAN</b>
                  </td>
                  <td colSpan="2" className="b-r-1 right-align"></td>
                </tr>
                {data.actionList &&
                  data.actionList.map((act) => {
                    return (
                      <tr key={act._id}>
                        <td colSpan="6" className="b-l-1 b-r-1">
                          &nbsp;&nbsp;{act.name}
                          {uiSubActionList(data.subActionList, act._id)}
                        </td>
                        <td colSpan="2" className="b-r-1 right-align">
                          {formatCurrency(act.price)}
                          {uiSubActionPriceList(data.subActionList, act._id)}
                        </td>
                      </tr>
                    );
                  })}

                {data.substanceList && data.substanceList.length > 0 && (
                  <Fragment>
                    <tr>
                      <td colSpan="6" className="b-l-1 b-r-1">
                        <b>TAMBAHAN BAHAN</b>
                      </td>
                      <td colSpan="2" className="b-r-1 right-align"></td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="b-l-1">
                        <b>&nbsp;&nbsp;Jenis Bahan</b>
                      </td>
                      <td>
                        <b>Harga</b>
                      </td>
                      <td>
                        <b>Jumlah</b>
                      </td>
                      <td className="b-r-1"></td>
                      <td colSpan="2" className="b-r-1 right-align"></td>
                    </tr>
                    {data.substanceList.map((sub) => {
                      return (
                        <tr key={sub._id}>
                          <td colSpan="3" className="b-l-1">
                            &nbsp;&nbsp;{sub.name}
                          </td>
                          <td>{formatCurrency(sub.price)}</td>
                          <td>
                            {sub.qty}
                            {` `}
                            {sub.unit && sub.unit !== "default" && sub.unit}
                          </td>
                          <td colSpan="1" className="b-r-1"></td>
                          <td colSpan="2" className="b-r-1 right-align">
                            {formatCurrency(sub.sumPrice)}
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                )}

                <tr>
                  <td colSpan="3" className="b-t-1"></td>

                  <td colSpan="3" className="b-1 right-align">
                    <i>Subtotal Tindakan</i>
                  </td>
                  <td colSpan="2" className="b-r-1 b-t-1 b-b-1 right-align">
                    {formatCurrency(data.totalActionPrice)}
                  </td>
                </tr>

                {data.totalSubActionPrice > 0 && (
                  <tr>
                    <td colSpan="3" className=""></td>
                    <td colSpan="3" className="b-1 right-align">
                      <i>Subtotal Sub Tindakan</i>
                    </td>
                    <td colSpan="2" className="b-r-1 b-t-1 b-b-1 right-align">
                      {formatCurrency(data.totalSubActionPrice)}
                    </td>
                  </tr>
                )}
                {data.totalSubstancePrice > 0 && (
                  <tr>
                    <td colSpan="3" className=""></td>

                    <td colSpan="3" className="b-1 right-align">
                      <i>Subtotal Bahan</i>
                    </td>
                    <td colSpan="2" className="b-r-1 b-t-1 b-b-1 right-align">
                      {formatCurrency(data.totalSubstancePrice)}
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan="3" className=""></td>

                  <td colSpan="3" className="b-1 right-align" width="140">
                    <b>TOTAL</b>
                  </td>
                  <td
                    colSpan="2"
                    className="b-r-1 b-t-1 b-b-1 right-align"
                    width="120"
                  >
                    <b>{formatCurrency(data.grandTotalPrice)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Div>
    </Fragment>
  );
};
